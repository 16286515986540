import { IMAGE_DISHA_MESSAGE_AVATAR } from "../../../../../../../Assets";
import Container from "./styles";

const Loader = () => {
  return (
    <Container>
      <img src={IMAGE_DISHA_MESSAGE_AVATAR} alt="" />
      <div className="loader-container">
        <span className="loader-dots"></span>
        <span className="loader-dots"></span>
        <span className="loader-dots"></span>
      </div>
    </Container>
  );
};

export default Loader;
