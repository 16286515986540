import moment from "moment";

import useGetReduxState from "../../../Hooks/useGetReduxState";
import Spinner from "../../../UI/UIComponents/Spinner";
import langText from "../translations";
import Station from "./Station";
const train =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/train-icon-list.png";

const Content = ({ stations, handleClick, current, isLoading }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  if (isLoading)
    return (
      <div
        style={{
          height: "100%",
          position: "relative",
          padding: "50px 15px",
          overflowY: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Spinner
          borderWidth={"4px"}
          activeColor={"#6495ed"}
          inactiveColor={"#f9f9f9"}
          speed={"1s"}
          size={"50px"}
        />
      </div>
    );

  return (
    <div
      style={{
        height: "100%",
        position: "relative",
        padding: "0px 15px",
        overflowY: "auto",
      }}
    >
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <p style={{ fontSize: "14px", marginBottom: "10px" }}>
          {langText[lang].current}{" "}
        </p>{" "}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "5px",
            fontSize: "18px",
            fontWeight: "600",
            color: "#5e5e5e",
            border: "1px solid #cdcdcd",
            width: "fit-content",
            margin: "0 auto",
            padding: "10px 20px",
            borderRadius: "8px",
          }}
        >
          <img src={train} style={{ width: "22px" }} alt="" />
          <p style={{ textAlign: "left" }}>
            {current.name} ({current.code})
          </p>
          <div style={{ textAlign: "center", marginLeft: "25px" }}>
            <h4 style={{ fontSize: "14px", fontWeight: "500" }}>
              {moment(current.date).format("DD MMMM")}
            </h4>
            <p style={{ fontSize: "12px", fontWeight: "500" }}>
              {moment(current.time, "hh:mm").format("hh:mm a")}
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          borderTop: "2px solid #ebebeb",
          margin: "18px 0px",
        }}
      ></div>

      <p
        style={{
          textAlign: "center",
          fontSize: "14px",
          color: "lightslategray",
          marginBottom: "15px",
        }}
      >
        {langText[lang].following}
      </p>

      <div
        className="stations"
        style={{
          minHeight: "100px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        {stations
          .filter((station) => station.code !== current.code)
          .map((station) => {
            return (
              <Station
                station={station}
                OnClick={() =>
                  handleClick({
                    code: station.code,
                    time: station.time,
                    date: station.date,
                  })
                }
              />
            );
          })}

        {/* {stations.map((station) => (
          <Station station={station} OnClick={handleClick} />
        ))} */}
      </div>
    </div>
  );
};

export default Content;
