const translations = {
  hi: {
    text: "क्षमा करें, मैं आपको समझ नहीं पायी। कृपया फिर से लिखें या एक अलग प्रश्न पूछें या नीचे दिए गए विकल्पों में से कोई एक चुनें:",
    buttons: {
      book: "टिकट बुक करें",
      refund: "रिफंड की स्थिति",
      pnr: "PNR की स्थिति",
    },
    customer: "कस्टमर केयर हेल्प-लाइन:",
    or: "या",
    language: "भाषा",
  },
  en: {
    text: "Sorry, I could not understand you. Please rephrase or ask a different question, or choose one the following options:",
    buttons: {
      book: "Book Ticket",
      refund: "Check Refund Status",
      pnr: "Check PNR Status",
    },
    customer: "Customer Care Help line:",
    or: "OR",
    language: "Language",
  },
  gu: {
    text: "માફ કરશો, હું તમને સમજી શક્યો નહીં. મહેરબાની કરીને ફરીથી લખો અથવા કોઈ અલગ પ્રશ્ન પૂછો અથવા નીચેના વિકલ્પોમાંથી એક પસંદ કરો.",
    buttons: {
      book: "તમારી ટિકિટ બુક કરો",
      refund: "રિફંડની સ્થિતિ",
      pnr: "પી. એન. આર. સ્થિતિ",
    },
    customer: "ગ્રાહક સંભાળ હેલ્પલાઈનઃ",
    or: "અથવા",
    language: "ભાષા",
  },
};

export default translations;
