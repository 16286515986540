import React from "react";
import Drawer from "../../../UI/UIComponents/Drawer";
import Content from "./Content";
import Header from "./Header";

const EditTrains = ({ handleClose, findTrains }) => {
  return (
    <Drawer
      content={<Content findTrains={findTrains} />}
      header={<Header />}
      footer={<React.Fragment></React.Fragment>}
      showCross={true}
      showHeaderSeperator={true}
      tatkal={false}
      fullHeight={false}
      handleCloseDrawer={handleClose}
    />
  );
};

export default EditTrains;
