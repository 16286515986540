import { isValidFlatNumber, isValidGstNumber, isValidPin } from "./validations";

const GSTFormValues = {
  gstnumber: "gstnumber",
  gstcompany: "gstcompany",
  gstflat: "gstflat",
  gststreet: "gststreet",
  gstarea: "gstarea",
  gstpincode: "gstpincode",
  gststate: "gststate",
  gstcity: "gstcity",
};

export const handleChangeGST = (e, gst) => {
  let values = gst.values;
  let errors = gst.errors;

  if (e.target.name === GSTFormValues.gstnumber) {
    let v = isValidGstNumber(e.target.value);

    if (v.isError) errors.gstnumber = v.errorMsg;
    else errors.gstnumber = "";
  }

  if (e.target.name === GSTFormValues.gstflat) {
    let v = isValidFlatNumber(e.target.value);

    if (v.isError) errors.gstflat = v.errorMsg;
    else errors.gstflat = "";
  }

  if (e.target.name === GSTFormValues.gstpincode) {
    let v = isValidPin(e.target.value);

    if (v.isError) errors.gstpincode = v.errorMsg;
    else errors.gstpincode = "";
  }

  if (
    e.target.name !== GSTFormValues.gstnumber &&
    e.target.name !== GSTFormValues.gstflat &&
    e.target.name !== GSTFormValues.gstpincode
  ) {
    errors = { ...errors, [e.target.name]: "" };
  }

  values = { ...values, [e.target.name]: e.target.value };
  return { values, errors };
};
