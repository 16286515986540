import moment from "moment";

const getPaymentMode = (payMode) => {
  if (payMode === "DC") return "Debit Card";
  if (payMode === "CC") return "Credit Card";

  return payMode;
};

const getUserCreditExpectedDate = (dateTime) => {
  if (!dateTime) return null;

  parseDateTimeForBackendDumbFucks(dateTime);
};

const parseDateTimeForBackendDumbFucks = (dateTime) => {
  if (!dateTime) return null;

  if (
    !dateTime.includes("T") &&
    dateTime.includes(" ") &&
    dateTime.includes(".")
  )
    return moment(dateTime.split(".")[0], "YYYY-MM-DD hh:mm:ss");
  else if (dateTime.includes("T")) {
    return moment(dateTime.split(".")[0], "YYYY-MM-DDTh:m:s");
  } else return moment(dateTime, "MMM DD, YYYY, hh:mm:ss a");
};

const parseData = (data) => {
  if (data.isForFailed)
    return {
      pnr: "",
      orderId: data.orderId,
      isForFailed: data.isForFailed,
      rrn: data.refundDetailInfoList[0].rrn || null,
      showRRNasBankTxnId: !(
        data.refundDetailInfoList[0].payMethod === "DC" ||
        data.refundDetailInfoList[0].payMethod === "CC"
      ),
      status: data.resultInfo.resultMsg.replace("Successfull", "Successful"),
      paidAmount: parseFloat(data.txnAmount).toFixed(2),
      refundedAmount: parseFloat(data.refundAmount).toFixed(2),
      deductedAmount: (
        parseFloat(data.txnAmount) - parseFloat(data.refundAmount)
      ).toFixed(2),
      userCreditExpectedDate: getUserCreditExpectedDate(
        data.refundDetailInfoList[0].userCreditExpectedDate,
      ),
      paymentMode: getPaymentMode(data.refundDetailInfoList[0].payMethod),
      txnTimestamp: parseDateTimeForBackendDumbFucks(data.txnTimestamp),
      isUserCreditInitiated:
        data.userCreditInitiateStatus &&
        data.userCreditInitiateStatus === "SUCCESS",
      userCreditInitiateTime: parseDateTimeForBackendDumbFucks(
        data.userCreditInitiateTimestamp,
      ),
    };

  return {
    orderId: "",
    isForFailed: data.isForFailed,
    pnr: data.cancellationData?.PNR || "",
    rrn: data.refundDetailInfoList[0].rrn || null,
    showRRNasBankTxnId:
      data.refundDetailInfoList[0].payMethod === "DC" ||
      data.refundDetailInfoList[0].payMethod === "CC",
    status: data.resultInfo.resultMsg.replace("Successfull", "Successful"),
    paidAmount: parseFloat(data.txnAmount).toFixed(2),
    refundedAmount: parseFloat(data.refundAmount).toFixed(2),
    deductedAmount: (
      parseFloat(data.txnAmount) - parseFloat(data.refundAmount)
    ).toFixed(2),
    userCreditExpectedDate: parseDateTimeForBackendDumbFucks(
      data.refundDetailInfoList[0].userCreditExpectedDate,
    ),
    paymentMode: getPaymentMode(data.refundDetailInfoList[0].payMethod),
    txnTimestamp: parseDateTimeForBackendDumbFucks(
      data.txnTimestamp,
      ),
    isUserCreditInitiated:
      data.userCreditInitiateStatus &&
      data.userCreditInitiateStatus === "SUCCESS",
    userCreditInitiateTime: parseDateTimeForBackendDumbFucks(
      data.userCreditInitiateTimestamp,
    ),
  };
};

export default parseData;
