import Drawer from "../../UI/UIComponents/Drawer";
import Content from "./Content/Content";
import { CloseDrawer } from "../../Helpers/EventEmitter";
import React from "react";

const AddressPicker = ({ addresses, handleClose, callBack, headerText }) => {
  return (
    <Drawer
      header={<>{headerText}</>}
      content={
        <Content
          addresses={addresses}
          handleClick={(value) => {
            CloseDrawer();
            callBack(value);
          }}
        />
      }
      footer={<React.Fragment></React.Fragment>}
      handleCloseDrawer={handleClose}
      showCross={true}
      tatkal={false}
      fullHeight={false}
      showHeaderSeperator={true}
    />
  );
};

export default AddressPicker;
