import moment from "moment";
import store from "../../Store";
import langTextPassengers from "../BookingHistory/Components/BookingDetails/Content/translations";
const parseDateTimeForBackendDumbFucks = (dateTime) => {
  if (
    !dateTime.includes("T") &&
    dateTime.includes(" ") &&
    dateTime.includes(".")
  )
    return moment(dateTime.split(".")[0], "YYYY-MM-DD hh:mm:ss");
  else if (dateTime.includes("T")) {
    return moment(dateTime.split(".")[0], "YYYY-MM-DDTh:m:s");
  } else return moment(dateTime, "MMM DD, YYYY, hh:mm:ss a");
};

const makeArray = (array, isFor) => {
  let arr = [];
  if (Array.isArray(array)) arr = array;
  else arr = [array];
  if (isFor !== "TDR")
    arr = arr.filter((passenger) => passenger.currentStatus !== "CAN");
  return arr;
};

const constructPassenger = (passenger) => {
  return {
    name: toTitleCase(passenger.passengerName),
    age: passenger.passengerAge,
    gender: getGender(passenger.passengerGender),
    status: passenger.currentStatus,
  };
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const getGender = (code) => {
  const lang = store.getState().behaviour.lang;
  if (code === "M") return langTextPassengers[lang].male;
  if (code === "F") return langTextPassengers[lang].female;
  else return langTextPassengers[lang].transgender;
};

export const parseBooking = (data, isFor) => {
  return {
    source: data.boardingStn,
    destination: data.destStn,
    date: parseDateTimeForBackendDumbFucks(data.boardingDate),
    pnr: data.pnrNumber,
    trainName: toTitleCase(data.trainName),
    trainNumber: data.trainNumber,
    passengers: makeArray(data.psgnDtlList, isFor).map((passenger) =>
      constructPassenger(passenger),
    ),
  };
};
