import useGetReduxState from "../Hooks/useGetReduxState";
import useSendQuery from "../Hooks/useSendQuery/useSendQuery";
const bubble =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/suggestion.svg";
const arrow =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/arrow.svg";
const AlsoTry = ({ questions }) => {
  const { sendQueryPayload } = useSendQuery(() => {});
  const handleClick = (query) => {
    sendQueryPayload({ data: null, query: query, inputType: "" });
  };
  const lang = useGetReduxState().behaviour.lang;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "16px",
        marginTop: "16px",
      }}
    >
      <div
        style={{
          color: "black",
          fontWeight: "bold",
          padding: "4px",
        }}
      >
        <img
          src={bubble}
          style={{
            width: "16px",
            marginRight: "8px",
          }}
        />
        {lang === "en" ? (
          <span>You may also try</span>
        ) : (
          <span>आप निम्नलिखित भी पूछ सकते हैं</span>
        )}
      </div>
      <div
        style={{
          color: "#0778fb",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "8px",
        }}
      >
        {questions.map((element: string, index) => {
          return (
            <div
              key={index}
              style={{
                cursor: "pointer",
                width: "fit-content",
                marginTop: "6px",
              }}
              onClick={() => handleClick(element)}
            >
              <img src={arrow} style={{ width: "10px", marginRight: "8px" }} />
              <span style={{ fontSize: "14px" }}>{element}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AlsoTry;
