export const getAvailabilityColor = (status) => {
  let check = status.toLowerCase();

  if (
    check.includes("regret") ||
    check.includes("deleted") ||
    check.includes("suspended") ||
    check.includes("departed") ||
    check.includes("not")
  )
    return "#A5A4A6";

  if (
    check.includes("available") ||
    check.includes("curr_avbl") ||
    check.includes("rac")
  )
    return "#33A80E";

  if (check.includes("wl")) return "#E0880D";

  return "#A5A4A6";
};
