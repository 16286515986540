import { faLongArrowRight, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState } from "react";
import { getStationName } from "../../Helpers/getStationName";
import useGetReduxState from "../../Hooks/useGetReduxState";
import EditTrains from "../Trains/EditTrains";
import Container from "./styles";

const JourneyDetails = ({ findTrains }) => {
  const { source, destination, date } = useGetReduxState().journey;
  const [edit, setEdit] = useState(false);

  return (
    <Container>
      <div className="first">
        <p
          style={{
            color: "#000000",
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          {moment(date, "YYYYMMDD").format("DD MMM")}
        </p>
        <span
          style={{
            fontSize: "9px",
            fontWeight: "500",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {moment(date, "YYYYMMDD").format("dddd")}
        </span>
      </div>

      <div className="second">
        <div style={{ textAlign: "center" }}>
          <p
            style={{
              fontSize: "14px",
              color: "#000000",
              fontWeight: "600",
            }}
          >
            {source}
          </p>
          <p
            style={{
              fontSize: "10px",
              marginTop: "4px",
            }}
          >
            {getStationName(source)}
          </p>
        </div>

        <FontAwesomeIcon
          icon={faLongArrowRight}
          style={{
            marginRight: "16px",
            marginLeft: "16px",
            fontSize: "20px",
            color: "#424242",
          }}
        />

        <div style={{ textAlign: "center" }}>
          <p
            style={{
              fontSize: "14px",
              color: "#000000",
              fontWeight: "600",
            }}
          >
            {destination}
          </p>
          <p
            style={{
              fontSize: "10px",
              marginTop: "4px",
            }}
          >
            {getStationName(destination)}
          </p>
        </div>
      </div>
      <div className="third">
        <FontAwesomeIcon
          icon={faPen}
          style={{
            fontSize: "13px",
            color: "#424242",
            cursor: "pointer",
          }}
          onClick={() => {
            setEdit(true);
          }}
        />
      </div>
      {edit && (
        <EditTrains
          handleClose={() => setEdit(false)}
          findTrains={findTrains}
        />
      )}
    </Container>
  );
};

export default JourneyDetails;
