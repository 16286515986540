import { useEffect, useState } from "react";

import BookingHistoryContainer from "./BookingHistory.styles";
import Booking from "./Components/Booking";
import Tabs from "./Components/Tabs";
import { parseBookings } from "./utils";
import langText from "./translations";
import useGetReduxState from "../../Hooks/useGetReduxState";
const BookingHistory = ({ data, activeBookingTab }) => {
  const [currentTab, setCurrentTab] = useState(activeBookingTab);
  const { upcoming, cancelled, failed, completed, pending } =
    parseBookings(data);
  const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;
  const getBookings = () => {
    switch (currentTab) {
      case 1:
        return upcoming;
      case 2:
        return cancelled;
      case 3:
        return failed;
      case 4:
        return completed;
      case 5:
        return pending;
    }
  };

  useEffect(() => {}, []);

  return (
    <BookingHistoryContainer>
      <h1>
        <img src="https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/history.svg" />
        {langText[lang].header}
      </h1>
      <Tabs
        currentTab={currentTab}
        handleChangeTab={(tabNumber) => setCurrentTab(tabNumber)}
      />
      {currentTab === 3 && (
        <p className="advisory">
          <span>{langText[lang].advisory.bold}</span>
          {langText[lang].advisory.light}
        </p>
      )}

      {currentTab === 5 && (
        <p className="advisory">
          <span>{langText[lang].advisoryPending.bold}</span>
          {langText[lang].advisoryPending.light}
        </p>
      )}

      <div
        className={
          currentTab === 3 ? "bookings-container failed" : "bookings-container"
        }
      >
        {getBookings().length > 0 ? (
          getBookings().map((booking) => (
            <Booking tabNumber={currentTab} data={booking} />
          ))
        ) : (
          <p className="no-booking-message">{langText[lang].no}</p>
        )}
      </div>
    </BookingHistoryContainer>
  );
};
export default BookingHistory;
