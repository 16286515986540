import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import useGetReduxState from "../../Hooks/useGetReduxState";
import langText from "./translations";
const payment =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/QR-code_payment.svg";
const otp2 =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/otp2.svg";
const Content = () => {
  const lang = useGetReduxState().behaviour.lang;
  const { mobile, email } = useGetReduxState().app.irctcDetails;
  const userId =
    useGetReduxState().passengerform.journeyPreferences.irctcUserId;
  return (
    <div style={{ padding: "0px 15px 15px 15px", fontFamily: "'Montserrat',sans-serif" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
          margin: "0 auto",
          paddingTop: "12px",
        }}
        className="steps"
      >
        {/* <span>
          {" "}
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{
              fontSize: "28px",
              color: "#2196f3",
              marginLeft: "5px",
            }}
            onClick={() => {}}
          />
        </span> */}
        {/* <hr
          style={{
            width: "25%",
            borderTop: "2px solid #2196f3",
          }}
        /> */}
        {/* <img style={{ width: "35px" }} src={payment} alt="" /> */}
        {/* <hr
          style={{
            width: "25%",
            borderTop: "2px solid #e4e4e4",
          }}
        /> */}
        {/* <img style={{ width: "30px" }} src={otp2} alt="" /> */}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          // marginTop: "10px",
        }}
        className="steps"
      >
        {/* <p
          style={{
            fontSize: "14px",
            textAlign: "center",
            color: "#326abb",
            fontWeight: 600,
          }}
        >
          {langText[lang].steps.id}
        </p> */}

        {/* <p
          style={{
            fontSize: "14px",
            color: "#326abb",
            fontWeight: 600,
            textAlign: "center",
            marginLeft: "-16px",
          }}
        >
          {langText[lang].steps.payment}
        </p> */}

        {/* <p
          style={{
            fontSize: "14px",
            textAlign: "center",
            color: "#326abb",
            fontWeight: 600,
          }}
        >
          {langText[lang].steps.otp}
        </p> */}
      </div>
      {/* Stepper */}

      {lang === "en" && (
        <React.Fragment>
          {/* <p
            style={{
              fontSize: "14px",
              color: "#505050",
              lineHeight: "20px",
              margin: "0",
              textAlign: "justify",
              marginTop: "12px",
            }}
          >
            {langText[lang].text1}{" "}
            <span style={{ fontSize: "16px", fontWeight: "600" }}>
              {userId}
            </span>
            . <span>{langText[lang].subtext1}</span>
          </p>

          <div
            style={{
              width: "100%",
              borderTop: "1px solid gray",
              margin: "8px 0px",
            }}
          ></div> */}

          {/* <p
            style={{
              margin: "0",
              lineHeight: "20px",
              color: "#373838",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {langText[lang].text2}{" "}
            <span
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#2196f3",
              }}
            >
              {mobile}
            </span>{" "}
            {langText[lang].text3}{" "}
            <span
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#2196f3",
              }}
            >
              {email}
            </span>
          </p> */}

          {/* <div
            style={{
              width: "100%",
              borderTop: "1px solid gray",
              margin: "8px 0px",
            }}
          ></div> */}

          <p
            style={{
              color: "#e4764b",
              margin: "0",
              lineHeight: "18px",
              fontWeight: "500",

              textAlign: "justify",
              fontSize: "14px",
            }}
          >
            {langText[lang].text4}
          </p>
        </React.Fragment>
      )}

      {lang === "hi" && (
        <React.Fragment>
          {/* <p
            style={{
              fontSize: "14px",
              color: "#505050",
              lineHeight: "20px",
              margin: "0",
              textAlign: "justify",
              marginTop: "12px",
            }}
          >
            {langText[lang].text1}{" "}
            <span style={{ fontSize: "16px", fontWeight: "600" }}>
              {userId}
            </span>
            . <span>{langText[lang].subtext1}</span>
          </p> */}

          {/* <div
            style={{
              width: "100%",
              borderTop: "1px solid gray",
              margin: "8px 0px",
            }}
          ></div> */}

          {/* <p
            style={{
              margin: "0",
              lineHeight: "20px",
              color: "#373838",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {langText[lang].text2}{" "}
            <span
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#2196f3",
              }}
            >
              {mobile}
            </span>{" "}
            <span>या</span>{" "}
            <span
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#2196f3",
              }}
            >
              {email}
            </span>{" "}
            {langText[lang].text3}
          </p> */}

          {/* <div
            style={{
              width: "100%",
              borderTop: "1px solid gray",
              margin: "8px 0px",
            }}
          ></div> */}

          <p
            style={{
              color: "#e4764b",
              margin: "0",
              lineHeight: "18px",
              fontWeight: "500",

              textAlign: "justify",
              fontSize: "14px",
            }}
          >
            {langText[lang].text4}
          </p>
        </React.Fragment>
      )}

      {lang === "gu" && (
        <React.Fragment>
          {/* <p
            style={{
              fontSize: "14px",
              color: "#505050",
              lineHeight: "20px",
              margin: "0",
              textAlign: "justify",
              marginTop: "12px",
            }}
          >
            {langText[lang].text1}{" "}
            <span style={{ fontSize: "16px", fontWeight: "600" }}>
              {userId}
            </span>
            . <span>{langText[lang].subtext1}</span>
          </p> */}
{/* 
          <div
            style={{
              width: "100%",
              borderTop: "1px solid gray",
              margin: "8px 0px",
            }}
          ></div> */}

          {/* <p
            style={{
              margin: "0",
              lineHeight: "20px",
              color: "#373838",
              fontWeight: "600",
              fontSize: "14px",
            }}
          >
            {langText[lang].text2}{" "}
            <span
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#2196f3",
              }}
            >
              {mobile}
            </span>{" "}
            {langText[lang].text3}{" "}
            <span
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#2196f3",
              }}
            >
              {email}
            </span>
          </p> */}

          {/* <div
            style={{
              width: "100%",
              borderTop: "1px solid gray",
              margin: "8px 0px",
            }}
          ></div> */}

          <p
            style={{
              color: "#e4764b",
              margin: "0",
              lineHeight: "18px",
              fontWeight: "500",

              textAlign: "justify",
              fontSize: "14px",
            }}
          >
            {langText[lang].text4}
          </p>
        </React.Fragment>
      )}
    </div>
  );
};

export default Content;
