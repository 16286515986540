const translations = {
  hi: {
    no: "आपकी कोई आने वाली बुकिंग नहीं है    ",
    wait: "कृपया प्रतीक्षा करें...",
    verifyBtn: "मोबाइल नंबर सत्यापित करें",
    verifyText:
      "अपनी आने वाली बुकिंग देखने के लिए कृपया अपना फ़ोन नंबर सत्यापित करें",
  },
  en: {
    no: "You have no upcoming bookings",
    wait: "Please wait...",
    verifyBtn: "Verify Mobile Number",
    verifyText: "Please verify your phone number to see your upcoming bookings",
  },
  gu: {
    no: "તમારી પાસે કોઈ ઇનકમિંગ બુકિંગ નથી",
    wait: "રાહ જુઓ...",
    verifyBtn: "તમારો મોબાઇલ નંબર ચકાસો",
    verifyText:
      "તમારી આગામી બુકિંગ જોવા માટે કૃપા કરીને તમારા ફોન નંબરની ચકાસણી કરો.",
  },
};

export default translations;
