import {
  SET_CXTOKEN,
  SET_DEVICE_ID,
  SET_FALLBACK,
  SET_IS_REFUND,
  SET_NEXT_CONTEXT,
  SET_PREV_CODE,
  SET_SESSION_ID,
  SET_STATUS,
  SET_USER_TOKEN,
} from "../Actions/constants";
import { App } from "./types";

const intialApp: App = {
  userToken: null,
  nextContext: null,
  cxtoken: "",
  prevCode: null,
  deviceId: null,
  status: null,
  sessionId: null,
  isFallback: false,
  isRefund: false,
  disabledSettings: {
    other: false,
    booking: false,
  },
  journeys: [],
  irctcDetails: {
    mobile: "",
    email: "",
    txnId: "",
  },
  local: JSON.stringify(null),
  401: false,
};

const reducer = (app: App = intialApp, action) => {
  switch (action.type) {
    case SET_USER_TOKEN:
      return { ...app, userToken: action.value };
    case SET_CXTOKEN:
      return { ...app, cxtoken: action.value };
    case SET_NEXT_CONTEXT:
      return { ...app, nextContext: action.value };
    case SET_SESSION_ID:
      return { ...app, sessionId: action.value };
    case SET_DEVICE_ID:
      return { ...app, deviceId: action.value };
    case SET_STATUS:
      return { ...app, status: action.value };
    case SET_PREV_CODE:
      return { ...app, prevCode: action.value };
    case SET_FALLBACK:
      return { ...app, isFallback: action.value };
    case SET_IS_REFUND:
      return { ...app, isRefund: action.value };

    case "SET_DISABLED_SETTINGS":
      return {
        ...app,
        disabledSettings: {
          other: action.other,
          booking: action.booking,
        },
      };

    case "SET_RECENT_JOURNEYS":
      return { ...app, journeys: action.value };

    case "SET_IRCTC_DETAILS":
      return { ...app, irctcDetails: action.value };

    case "SET_LOCAL_DATA":
      return { ...app, local: action.value };

    case "SET_UNAUTH":
      return { ...app, 401: true };

    default:
      return app;
  }
};

export default reducer;
