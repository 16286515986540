import {
  HIDE_ALSO_TRY,
  PLAY_AUDIO,
  SET_APP_LANGUAGE,
  SET_CURRENT,
  SET_QUERY_LANGUAGE,
  SHOW_ALSO_TRY,
  STOP_AUDIO,
  TOGGLE_MESSAGE_LOADER,
  SET_UPCOMING_BOOKINGS,
} from "../Actions/constants";
import { Behaviour } from "./types";

const intialBehaviour: Behaviour = {
  showMessageLoader: false,
  audio: {
    url: "",
    current: "-1",
  },
  input_mode: "TEXT",
  lang: "en",
  queryLang: "en",
  alsoTry: [],
  fullScreenComponent: { type: null, component: null },
  current: "",
  bookings: false,
};

const reducer = (behaviour: Behaviour = intialBehaviour, action) => {
  switch (action.type) {
    case TOGGLE_MESSAGE_LOADER:
      return { ...behaviour, showMessageLoader: !behaviour.showMessageLoader };
    case PLAY_AUDIO:
      return { ...behaviour, audio: { url: action.url, current: action.id } };
    case STOP_AUDIO:
      return { ...behaviour, audio: { url: "", current: -1 } };
    case SET_APP_LANGUAGE:
      return { ...behaviour, lang: action.lang };
    case SET_QUERY_LANGUAGE:
      return { ...behaviour, queryLang: action.lang };
    case SHOW_ALSO_TRY:
      return { ...behaviour, alsoTry: action.questions };
    case HIDE_ALSO_TRY:
      return { ...behaviour, alsoTry: [] };
    case "SET_FULLSCREEN_COMPONENT":
      return { ...behaviour, fullScreenComponent: action.value };
    case "SET_INPUT_TYPE":
      return { ...behaviour, input_mode: action.value };
    case SET_CURRENT:
      return { ...behaviour, current: action.value };

    case SET_UPCOMING_BOOKINGS:
      return { ...behaviour, bookings: action.value };
    default:
      return behaviour;
  }
};

export default reducer;
