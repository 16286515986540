import { useEffect, useState } from "react";
import { CloseDrawer } from "../../Helpers/EventEmitter";
import useGetReduxState from "../../Hooks/useGetReduxState";
import Drawer from "../../UI/UIComponents/Drawer";
import Speaker from "../../UI/UIComponents/Speaker";
import audios from "../../UI/UIComponents/Speaker/audios";
import Content from "./Content";
import translations from "./translation";
import React from "react";
import Spinner from "../../UI/UIComponents/Spinner";
import useBookingFlow from "../../Hooks/useBookingFlow";
import {
  setOtpValue,
  setVerifyOTPData,
} from "../../Store/Dispatcher/passengerForm";
import { setOtpView } from "../../Store/Dispatcher/passengerForm";
const Header = () => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        columnGap: "10px",
      }}
    >
      {translations[lang].header}
      <Speaker
        sizeBg={"25px"}
        sizeIcon={"12px"}
        audioUrl={audios[lang].NEXT_STEPS}
        disabled={false}
      />
    </div>
  );
};

const Footer = ({ loading, handleClose, handleVerifyOTP }) => {
  const getText = () => {
    if (lang === "en") return "Back";
    if (lang === "hi") return "वापस जाएं";
    if (lang === "gu") return "પાછા";
  };

  const getText2 = () => {
    if (lang === "en") return "Verify OTP and Proceed to Pay";
    if (lang === "hi") return "OTP सत्यापित करें और भुगतान के लिए आगे बढ़ें";
    if (lang === "gu") return "OTP ચકાસો અને ચૂકવણી કરવા આગળ વધો";
  };

  const getText3 = () => {
    if (lang === "en") return "Resend OTP";
    if (lang === "hi") return "OTP पुनः भेजें";
    if (lang === "gu") return "ઓ. ટી. પી. ફરીથી મોકલો";
  };

  const lang: "hi" | "en" = useGetReduxState().behaviour.lang;
  const otp = useGetReduxState().passengerform.otpValue;
  const [style, setStyle] = useState({
    width: "100%",
    border: "1px solid #326abb",
    fontSize: "18px",
    padding: loading ? "11px" : "14px",
    background: "#326abb",
    color: "white",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <button style={style} onClick={handleVerifyOTP}>
        {loading ? (
          <Spinner
            borderWidth={"3px"}
            activeColor={"#f9f9f9"}
            inactiveColor={"#326abb"}
            speed={"0.8s"}
            size={"25px"}
          />
        ) : (
          <React.Fragment>{getText2()}</React.Fragment>
        )}
      </button>
    </div>
  );
};

const OtpVerify = ({ backToReview, loading, sendOTP }) => {
  const { handleVerifyOTP, handleCloseReview } = useBookingFlow();

  const close = () => {
    setOtpView(false);
    CloseDrawer();
    setVerifyOTPData([]);
  };

  return (
    <Drawer
      header={<Header />}
      footer={
        <Footer
          loading={loading}
          handleClose={backToReview}
          handleVerifyOTP={handleVerifyOTP}
        />
      }
      content={
        <Content
          loading={loading}
          sendOTP={sendOTP}
          handleVerifyOTP={handleVerifyOTP}
          handleCloseReview={handleCloseReview}
        />
      }
      showCross={true}
      handleCloseDrawer={close}
      showHeaderSeperator={true}
      tatkal={false}
      fullHeight={false}
      onEnter={handleVerifyOTP}
    />
  );
};

export default OtpVerify;
