import moment from "moment";
import { useState } from "react";
import { getStationName } from "../../../Helpers/getStationName";
import Container from "./styles";
import ScrollContainer from "react-indiana-drag-scroll";
import { getAvailabilityColor } from "../../../Helpers/trains";
import { getSchedule } from "../../../Api/trains";
import TrainSchedule from "../TrainSchedule";
const refresh =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/refresh.png";

const Train = ({ train, getClassFiltered, handleExpand }) => {
  const [scheduleData, setScheduleData] = useState(null);

  const schedule = async () => {
    const trainData = await getSchedule({
      trainNumber: train.trainNumber,
      date: train.departureDate,
      station: train.destination,
    });
    setScheduleData(trainData);
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p style={{ margin: "0", fontSize: "12px", fontWeight: "500" }}>
          {train.trainNumber}
        </p>
        <button
          style={{
            fontSize: "12px",
            backgroundColor: "transparent",
            border: "none",
            color: "#326ABB",
            fontWeight: "600",
            cursor: "pointer",
          }}
          onClick={schedule}
        >
          Route
        </button>
      </div>
      <p
        style={{
          marginTop: "6px",
          marginBottom: "12px",
          color: "#080808",
          fontWeight: "600",
        }}
      >
        {train.trainName}
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "13px",
        }}
      >
        <p style={{ color: "#080808", margin: "0" }}>
          {moment(train.departureDate, "YYYYMMDD").format("DD MMM, ddd")}
        </p>
        <p style={{ color: "#080808", margin: "0" }}>
          {moment(train.arrivalDate, "YYYYMMDD").format("DD MMM, ddd")}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <p
          style={{
            margin: "0",
            color: "#080808",
            fontSize: "12px",
            fontWeight: "600",
          }}
        >
          {train.departureTime}
        </p>
        <div
          style={{ width: "100%", position: "relative", margin: "0px 20px" }}
        >
          <div
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              top: "-15px",
            }}
          >
            <p
              style={{
                margin: "0px",
                fontSize: "10px",
                fontWeight: "500",
                color: "#080808",
              }}
            >
              {train.duration.split(":")[0]}hrs {train.duration.split(":")[1]}
              min
            </p>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              style={{
                display: "block",
                width: "6px",
                height: "6px",
                borderRadius: "50%",
                border: "1px solid #909090",
              }}
            ></span>
            <div
              style={{ borderTop: "1px solid #cbcbcb", width: "100%" }}
            ></div>
            <span
              style={{
                display: "block",
                width: "6px",
                height: "6px",
                borderRadius: "50%",
                border: "1px solid #909090",
              }}
            ></span>
          </div>
          <div
            className="week"
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              fontSize: "9px",
              bottom: "-14px",
              fontWeight: "500",
            }}
          >
            <span
              style={{
                color: train.running.Sun ? "#000000" : "#727272",

                fontWeight: train.running.Sun ? 700 : 300,
              }}
            >
              S
            </span>
            <span
              style={{
                color: train.running.Mon ? "#000000" : "#727272",
                fontWeight: train.running.Mon ? 700 : 300,
              }}
            >
              M
            </span>
            <span
              style={{
                color: train.running.Tue ? "#000000" : "#727272",
                fontWeight: train.running.Tue ? 700 : 300,
              }}
            >
              T
            </span>
            <span
              style={{
                color: train.running.Wed ? "#000000" : "#727272",
                fontWeight: train.running.Wed ? 700 : 300,
              }}
            >
              W
            </span>
            <span
              style={{
                color: train.running.Thu ? "#000000" : "#727272",
                fontWeight: train.running.Thu ? 700 : 300,
              }}
            >
              T
            </span>
            <span
              style={{
                color: train.running.Fri ? "#000000" : "#727272",
                fontWeight: train.running.Fri ? 700 : 300,
              }}
            >
              F
            </span>
            <span
              style={{
                color: train.running.Sat ? "#000000" : "#727272",
                fontWeight: train.running.Sat ? 700 : 300,
              }}
            >
              S
            </span>
          </div>
        </div>
        <p
          style={{
            margin: "0",
            color: "#080808",
            fontSize: "12px",
            fontWeight: "600",
          }}
        >
          {train.arrivalTime}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "12px",
          fontWeight: "600",
          marginTop: "15px",
          marginBottom: "8px",
        }}
      >
        <p>
          {getStationName(train.source)} ({train.source})
        </p>
        <p>
          {getStationName(train.destination)} ({train.destination})
        </p>
      </div>
      {train.tickets && (
        <ScrollContainer className="tickets">
          {getClassFiltered(train.tickets)
            .slice()
            .reverse()
            .map((ticket) => (
              <>
                {ticket.cached ? (
                  <div style={{ display: "inline-block", marginRight: "8px" }}>
                    <div
                      className={"ticket-new"}
                      // props.click(props.train, ticket)
                      onClick={() =>
                        handleExpand({ train, jClass: ticket.class })
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            margin: "0",
                            fontSize: "10px",
                            color: "#000000",
                            fontWeight: "600",
                          }}
                        >
                          {ticket.class}
                        </p>
                        <p
                          style={{
                            margin: "0",
                            fontSize: "10px",
                            color: "#000000",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          ₹{ticket.fare}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "5px 0px",
                        }}
                      >
                        <span
                          style={{
                            width: "10px",
                            height: "10px",
                            backgroundColor: getAvailabilityColor(
                              ticket.availablityStatus,
                            ),
                            borderRadius: "50%",
                          }}
                        ></span>
                        <p
                          style={{
                            margin: "0",
                            fontSize: "10px",
                            fontWeight: "600",
                            marginLeft: "4px",
                            color: getAvailabilityColor(
                              ticket.availablityStatus,
                            ),
                          }}
                        >
                          {" "}
                          {ticket.availablityStatus}
                        </p>
                      </div>
                    </div>
                    <p
                      style={{
                        marginTop: "4px",
                        fontSize: "10px",
                        textAlign: "left",
                        color: "#595959",
                        marginLeft: "3px",
                      }}
                    >
                      {ticket.time}
                    </p>
                  </div>
                ) : (
                  <div style={{ display: "inline-block", marginRight: "8px" }}>
                    <div
                      className={"ticket-new"}
                      // props.click(props.train, ticket)
                      onClick={() =>
                        handleExpand({ train, jClass: ticket.class })
                      }
                    >
                      <p
                        style={{
                          margin: "0",
                          fontSize: "10px",
                          color: "#000000",
                          fontWeight: "600",
                        }}
                      >
                        {ticket.class}
                      </p>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "4px 0px",
                        }}
                      >
                        <img
                          style={{ width: "12px", marginRight: "5px" }}
                          src={refresh}
                          alt=""
                        />
                        <p
                          style={{
                            margin: "0",
                            fontSize: "10px",
                            fontWeight: "600",
                            color: "#4B89F2",
                          }}
                        >
                          Refresh
                        </p>
                      </div>
                    </div>
                    <p
                      className="availablityStatus"
                      style={{
                        marginTop: "4px",
                        fontSize: "10px",
                        textAlign: "left",
                        color: "rgb(89, 89, 89)",
                        marginLeft: "3px",
                      }}
                    >
                      Click to Refresh
                    </p>
                  </div>
                )}
              </>
            ))}
        </ScrollContainer>
      )}
      {scheduleData !== null && (
        <TrainSchedule
          train={scheduleData}
          handleClose={() => setScheduleData(null)}
        />
      )}
    </Container>
  );
};

export default Train;
