import { ENTER_PNR, RENDER_PNR } from "./constants";

export const bookingSteps = (stepNumber) => {
  switch (stepNumber) {
    case 1:
      return "SELECT_SOURCE";
    case 2:
      return "SELECT_DESTINATION";

    case 3:
      return "SELECT_DATE";

    case 4:
      return "SELECT_QUOTA";

    case 7:
      return "SELECT_TRAIN";
    case 8:
      return "SELECT_PASSENGERS";
  }
};

export const pnrSteps = (stepNumber) => {
  switch (stepNumber) {
    case 1:
      return ENTER_PNR;
    case 2:
      return RENDER_PNR;
  }
};

export const tdrSteps = (stepNumber) => {
  switch (stepNumber) {
    case 1:
      return "SELECT_PNR_FOR_TDR";
    case 2:
      return "SELECT_PASSENGERS_FOR_TDR";
    case 3:
      return "SELECT_REASON_FOR_TDR";
  }
};

export const changeBoardingSteps = (stepNumber) => {
  switch (stepNumber) {
    case 1:
      return "SELECT_PNR_FOR_BOARDING";
    case 2:
      return "SELECT_BOARDING_FOR_CHANGE";

    case 3:
      return "SHOW_BOARDING_CHANGED";
  }
};

export const cancelTicketSteps = (stepNumber) => {
  switch (stepNumber) {
    case 1:
      return "SELECT_PNR_FOR_CANCEL";
    case 2:
      return "SELECT_PASSENGERS_FOR_CANCEL";
    case 3:
      return "SHOW_CANCEL_REPORT";
  }
};

export const BookingHistorySteps = (stepNumber) => {
  switch (stepNumber) {
  }
};
