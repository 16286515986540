import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import { getStationName } from "../../../../../Helpers/getStationName";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import BoardingPicker from "../../../../../Pickers/BoardingPicker";
import { setJourneyPreferences } from "../../../../../Store/Dispatcher/passengerForm";
import { setTrain } from "../../../../../Store/Dispatcher/train";
import langText from "../translations";

const boardingIcon =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/boarding2-refactored.png";
const info =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/info-refactored.png";

const Boarding = () => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  const boardingChosen = useGetReduxState().journey.boardingStation;

  const preferences = useGetReduxState().passengerform.journeyPreferences;

  const train = useGetReduxState().train;
  const journey = useGetReduxState().journey;
  const [open, setOpen] = useState(false);

  const [text, setText] = useState(false);

  useEffect(() => {
    setJourneyPreferences({ ...preferences, boardingStation: boardingChosen });
  }, []);

  return (
    <div className="boarding" style={{ padding: "10px 15px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          columnGap: "5px",
        }}
      >
        <img src={boardingIcon} alt="" style={{ width: "24px" }} />
        <p
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "rgb(71 71 71)",
          }}
        >
          {langText[lang].boarding.text}
        </p>
        <img
          onClick={() => setText((prev) => !prev)}
          style={{ width: "16px", cursor: "pointer" }}
          src={info}
          alt=""
        />
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          border: "1px solid gray",
          padding: "8px",
          borderRadius: "6px",
          cursor: "pointer",
          marginTop: "6px",
        }}
        onClick={() => setOpen(true)}
      >
        <p>
          {getStationName(preferences.boardingStation)} (
          {preferences.boardingStation})
        </p>
        <FontAwesomeIcon icon={faCaretDown} />
      </div>

      {text && (
        <span
          style={{
            fontSize: "12px",
            lineHeight: "1.1",
            display: "block",
            marginTop: "5px",
            color: "slategray",
          }}
        >
          {langText[lang].boarding.subtext}
        </span>
      )}

      {open && (
        <BoardingPicker
          handleClose={() => {
            setOpen(false);
          }}
          trainNumber={train.trainnumber}
          date={journey.date}
          current={preferences.boardingStation}
          sourcePoint={train.source}
          callBack={({ code, time, date }) => {
            setJourneyPreferences({ ...preferences, boardingStation: code });
            setTrain({
              ...train,
              // source: code,
              departureDate: moment(date).format("YYYYMMDD"),
              departureTime: time,
            });
          }}
        />
      )}
    </div>
  );
};

export default Boarding;
