import moment from "moment";

import Container from "./styles";
import parseData from "./utils";
import langText from "./translations";
import useGetReduxState from "../../Hooks/useGetReduxState";
const icon =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/refund.svg" as string;
const RefundStatus = ({ data }) => {
  const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;

  const {
    isForFailed,
    pnr,
    txnTimestamp,
    orderId,
    rrn,
    showRRNasBankTxnId,
    status,
    paidAmount,
    refundedAmount,
    deductedAmount,
    userCreditExpectedDate,
    paymentMode,
    isUserCreditInitiated,
    userCreditInitiateTime,
  } = parseData(data);

  return (
    <Container>
      <div className="header">
        <img src={icon} alt="" />
        <p>{langText[lang].header}</p>
      </div>
      <div className="first-details">
        <div>
          {isForFailed ? (
            <p>{langText[lang].order}:</p>
          ) : (
            <p>{langText[lang].pnr}:</p>
          )}
          {showRRNasBankTxnId ? (
            <p>{langText[lang].bankTxnId}:</p>
          ) : (
            <p>RRN: </p>
          )}
          <p>{langText[lang].status}: </p>
          <p>{langText[lang].payMode}:</p>
        </div>
        <div>
          {isForFailed ? <p>{orderId}</p> : <p>{pnr}</p>}
          <p>{rrn}</p>
          <p>{status}</p>
          <p>{paymentMode}</p>
        </div>
      </div>
      <div className="seperator"></div>
      <div className="money">
        <p>
          {langText[lang].paidAmount}: <span>₹ {paidAmount}</span>
        </p>
        <p>
          {langText[lang].deductedAmount}: <span>₹ {deductedAmount}</span>
        </p>
        <div className="hr"></div>
        <h2>
          {langText[lang].refundAmount}: <span>₹ {refundedAmount}</span>
        </h2>
      </div>
      <div className="seperator"></div>
      <div className="credit-status">
        <p>
          {langText[lang].dateTimeTxn}:{" "}
          <span>
            {moment(txnTimestamp).format("DD MMMM, YYYY : hh:mm a")}
          </span>
        </p>

        {isUserCreditInitiated ? (
          <p>
            {langText[lang].dateTimeRefund}:{" "}
            <span>
              {moment(userCreditInitiateTime).format("DD MMMM, YYYY : hh:mm a")}
            </span>{" "}
          </p>
        ) : (
          userCreditExpectedDate && (
            <p>
              {langText[lang].dateTimeExpected}:{" "}
              <span>
                {moment(userCreditExpectedDate).format(
                  "DD MMMM, YYYY : hh:mm a",
                )}
              </span>
            </p>
          )
        )}
      </div>
    </Container>
  );
};

export default RefundStatus;
