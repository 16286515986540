import store from "..";
import { IrctcConfig } from "../Reducers/types";
import * as actions from "../Actions/passengerform";

export const setIrctcConfig = (config: IrctcConfig) => {
  store.dispatch(actions.setIrctcConfig(config));
};

export const setCurrentPassengerFormStep = (step) => {
  store.dispatch(actions.setPassengerFormStep(step));
};

export const setPassengers = (passengers) => {
  store.dispatch(actions.setPassengers(passengers));
};

export const setSavedPassengers = (passengers) => {
  store.dispatch(actions.setSavedPassengers(passengers));
};

export const setJourneyPreferences = (preferences) => {
  store.dispatch(actions.setJourneyPreferences(preferences));
};

export const setGstDetails = (details) => {
  store.dispatch(actions.setGstDetails(details));
};

export const setAdditionalDetails = (details) => {
  store.dispatch(actions.setAdditionalDetails(details));
};

export const setOtpValue = (data) => {
  // console.log(data)
  store.dispatch(actions.setInputOTP(data));
};

export const setVerifyOTPData = (data) => {
  store.dispatch(actions.setVerifyOTPData(data));
};

export const setBookingData = (data) => {
  store.dispatch(actions.setBookingData(data));
};

export const setShowNextSteps = (data) => {
  store.dispatch(actions.setShowNextSteps(data));
};

export const setOtpView = (data) => {
  store.dispatch(actions.setOtpView(data));
};

export const setShowBookingSteps = (data) => {
  store.dispatch(actions.setShowBookingSteps(data));
};

export const setCurrentBookingStep = (data) => {
  store.dispatch(actions.setCurrentBookingStep(data));
};

export const setPaymentData = (data) => {
  store.dispatch(actions.setPaymentData(data));
};
