import moment from "moment";

const parseDateTimeForBackendDumbFucks = (dateTime) => {
  if (
    !dateTime.includes("T") &&
    dateTime.includes(" ") &&
    dateTime.includes(".")
  )
    return moment(dateTime.split(".")[0], "YYYY-MM-DD hh:mm:ss");
  else if (dateTime.includes("T")) {
    return moment(dateTime.split(".")[0], "YYYY-MM-DDTh:m:s");
  } else return moment(dateTime, "MMM DD, YYYY, hh:mm:ss a");
};

const makeArray = (array) => {
  if (Array.isArray(array)) return array;
  else return [array];
};

const getGender = (code) => {
  if (code === "M") return "Male";
  if (code === "F") return "Female";
  else return "Transgender";
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const getBerthDetail = (detail) => {
  if (detail === "" || detail === "0" || !detail) return "N/A";

  return detail;
};

const constructassengers = (passengers) => {
  return makeArray(passengers).map((passenger) => {
    return {
      name: toTitleCase(passenger.passengerName),
      gender: getGender(passenger.passengerGender),
      age: passenger.passengerAge + "years",
      atBooking: {
        status: passenger.bookingStatus,
        seat: getBerthDetail(passenger.bookingBerthNo),
        berth: getBerthDetail(passenger.bookingBerthCode),
        coach: getBerthDetail(passenger.bookingCoachId),
      },
      atCurrent: {
        status: passenger.currentStatus,
        seat: getBerthDetail(passenger.currentBerthNo),
        berth: getBerthDetail(passenger.currentBerthCode),
        coach: getBerthDetail(passenger.currentCoachId),
      },
    };
  });
};

const constructOtherDetails = (details) => {
  return {
    invoice: details?.invoiceNumber || "NA",
    indianRailAddress: "Indian Railways, New Delhi",
    sac: details?.sacCode || "NA",
    supplierGstIn: details?.gstinSuplier || "NA",
    recipientGstIn: "NA",
    recipientName: "NA",
    recipientAddress: "NA",
    taxable: details?.taxableAmt || "NA",
    cgstRate: details?.cgstRate ? details?.cgstRate + "%" : "NA",
    cgstAmount: details?.irctcCgstCharge || "NA",
    sgstRate: details?.sgstRate ? details?.sgstRate + "%" : "NA",
    sgstAmount: details?.irctcSgstCharge || "NA",
    ugstRate: details?.ugstRate ? details?.ugstRate + "%" : "NA",
    ugstAmount: details?.irctcUgstCharge || "NA",
    igstRate: details?.igstRate ? details?.igstRate + "%" : "NA",
    igstAmount: details?.irctcIgstCharge || "NA",
    totalTax: "0.0",
    placeOfSupply: details?.suplierAddress || "Indian Railways, New Delhi",
    statecodeName:
      details?.prsSuplierState && details?.prsSuplierStateCode
        ? details?.prsSuplierState + "/" + details?.prsSuplierStateCode
        : "Delhi/DL",
  };
};

export const parseBooking = (data) => {
  const bookingData = data;

  return {
    pnr: bookingData.pnrNumber,
    trainName: toTitleCase(bookingData.trainName),
    trainNumber: bookingData.trainNumber,
    source: bookingData.fromStn,
    destination: bookingData.destStn,
    boarding: bookingData.boardingStn,
    bookingDate: parseDateTimeForBackendDumbFucks(bookingData.bookingDate),
    orderId: bookingData.clientTransactionId,
    jClass: bookingData.journeyClass,
    quota: bookingData.journeyQuota,
    departureDate: parseDateTimeForBackendDumbFucks(bookingData.journeyDate),
    arrivalDate: parseDateTimeForBackendDumbFucks(bookingData.destArrvDate),
    boardingDate: parseDateTimeForBackendDumbFucks(bookingData.boardingDate),
    departureTime: bookingData.departureTime,
    arrivalTime: bookingData.arrivalTime,
    otherDetails: constructOtherDetails(bookingData.gstCharge),
    distance: bookingData.distance,
    passengers: constructassengers(bookingData.psgnDtlList),
    QR: bookingData.QR,
    fares: {
      ticket: parseFloat(bookingData.totalFare).toFixed(2),
      catering: (parseFloat(bookingData.cateringcharge).toFixed(2)).replaceAll("NaN","0.00"),
      convenience: (
        parseFloat(bookingData.wpServiceCharge) +
        parseFloat(bookingData.wpServiceTax)
      ).toFixed(2),
      ctc: "11.80",
      insurance: parseFloat(bookingData.insuranceCharge).toFixed(2),
      total: (parseFloat(bookingData.totalCollectibleAmount) + 11.8).toFixed(2),
    },
  };
};
