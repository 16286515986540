import Container from "./styles";

const Spinner = ({ borderWidth, activeColor, inactiveColor, speed, size }) => {
  return (
    <Container
      borderWidth={borderWidth}
      activeColor={activeColor}
      inactiveColor={inactiveColor}
      speed={speed}
      size={size}
    ></Container>
  );
};

export default Spinner;
