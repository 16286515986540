import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getBookingDetails } from "../../../../../Api/irctc";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import BoardingPicker from "../../../../../Pickers/BoardingPicker";
import DatePicker from "../../../../../Pickers/DatePicker";
import QuotaPicker from "../../../../../Pickers/QuotaPicker";
import StationPicker from "../../../../../Pickers/StationPicker";
import TdrReasonPicker from "../../../../../Pickers/TdrReasonPicker";
import {
  setJourneyDate,
  setJourneyDestination,
  setJourneyQuota,
  setJourneySource,
} from "../../../../../Store/Dispatcher/journey";
import { RootState } from "../../../../../Store/Reducers";
import langText from "./translations";
import Container from "./styles";
import { getStationName } from "../../../../../Helpers/getStationName";
import { getQuotaName } from "../../../../../Helpers/getQuotaName";
import React from "react";

const Select = ({ current, handleSendQueryPayload }) => {
  const [openDrawer, setOpenDrawer] = useState("");
  const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;
  const { source, destination, date } = useSelector(
    (state: RootState) => state.journey
  );
  const messages = useGetReduxState().messages;
  const getPlaceholder = (current) => {
    switch (current) {
      case "SELECT_SOURCE":
        return langText[lang].source;
      case "SELECT_DESTINATION":
        return langText[lang].destination;
      case "SELECT_DATE":
        return langText[lang].date;
      case "SELECT_QUOTA":
        return langText[lang].quota;
      case "SELECT_BOARDING_FOR_CHANGE":
        return langText[lang].boarding;
      case "SELECT_REASON_FOR_TDR":
        return langText[lang].tdr;
    }
  };

  useEffect(() => {
    if (current !== "SELECT_BOARDING_FOR_CHANGE")
      setTimeout(() => {
        setOpenDrawer(current);
      }, 3000);
  }, []);

  useEffect(() => {
    if (current === "SELECT_BOARDING_FOR_CHANGE") {
      getDetailsForBoarding();
    }
  }, []);

  const [boardingData, setBoardingData] = useState({
    trainNo: "",
    date: "",
    current: "",
    start: "",
    end: "",
  });

  const getDetailsForBoarding = async () => {
    let msg = messages.slice(-2);
    let pnr = msg[0].text ? msg[0].text : msg[1].text;
    let data = await getBookingDetails(pnr);

    let d = {
      trainNo: data.trainNumber,
      date: moment(data.journeyDate, "DD MMM YYYY").format("YYYYMMDD"),
      current: data.boardingStn,

      start: data.fromStn,
      end: data.resvnUptoStn,
    };

    setBoardingData(d);
  };

  return (
    <React.Fragment>
      <Container onClick={() => setOpenDrawer(current)}>
        <p>{getPlaceholder(current)}</p>
        <FontAwesomeIcon
          icon={faCaretDown}
          style={{
            color: "grey",
          }}
        />
      </Container>
      {openDrawer === "SELECT_SOURCE" && (
        <StationPicker
          pickerType="SOURCE"
          handleClose={() => setOpenDrawer("")}
          source={source}
          destination={destination}
          callBack={(value) => {
            setJourneySource(value);

            handleSendQueryPayload({
              query: `${getStationName(value)} (${value})`,
              data: value,
              inputType: "",
            });
            setOpenDrawer("");
          }}
        />
      )}

      {openDrawer === "SELECT_DESTINATION" && (
        <StationPicker
          pickerType="DESTINATION"
          handleClose={() => setOpenDrawer("")}
          source={source}
          destination={destination}
          callBack={(value) => {
            setJourneyDestination(value);
            handleSendQueryPayload({
              query: `${getStationName(value)} (${value})`,
              data: value,
              inputType: "",
            });
            setOpenDrawer("");
          }}
        />
      )}

      {openDrawer === "SELECT_DATE" && (
        <DatePicker
          handleClose={() => setOpenDrawer("")}
          callBack={(value) => {
            setJourneyDate(value);
            handleSendQueryPayload({
              query: `${moment(value, "YYYYMMDD").format("DD MMM, YYYY")}`,
              data: value,
              inputType: "",
            });
            setOpenDrawer("");
          }}
        />
      )}

      {openDrawer === "SELECT_QUOTA" && (
        <QuotaPicker
          handleClose={() => setOpenDrawer("")}
          callBack={(value) => {
            setJourneyQuota(value);
            handleSendQueryPayload({
              query: `${getQuotaName(value)} (${value})`,
              data: value,
              inputType: "",
            });
            setOpenDrawer("");
          }}
          currentDate={date}
        />
      )}

      {openDrawer === "SELECT_REASON_FOR_TDR" && (
        <TdrReasonPicker handleClose={() => setOpenDrawer("")} />
      )}
      {openDrawer === "SELECT_BOARDING_FOR_CHANGE" && boardingData.current && (
        <BoardingPicker
          // @ts-ignore
          callBack={({ code, time, date }) => {
            handleSendQueryPayload({
              query: `${getStationName(code)} (${code})`,
              data: code,
              inputType: "",
            });
            setBoardingData({
              trainNo: "",
              date: "",
              current: "",
              start: "",
              end: "",
            });
          }}
          handleClose={() => setOpenDrawer("")}
          trainNumber={boardingData.trainNo}
          current={boardingData.current}
          date={boardingData.date}
          sourcePoint={boardingData.current}
        />
      )}
    </React.Fragment>
  );
};

export default Select;
