import { SET_FAQS } from "../Actions/constants";
import { Faqs } from "./types";

const intialFaqs: Faqs = {
  hi: [],
  en: [],
};

const reducer = (faqs: Faqs = intialFaqs, action) => {
  switch (action.type) {
    case SET_FAQS:
      return { ...faqs, [action.lang]: action.data };

    default:
      return faqs;
  }
};

export default reducer;
