const translations = {
  en: {
    captcha: "Captcha",
    testCaptcha:
      "This question is for testing whether you are a human in order to prevent automated spam submission.",
    enterCaptcha: "Enter the numbers shown in the image above without spaces",
    captchaRule: "without spaces and case sensitive",
    action: "Verify",
    error: "Incorrect Captcha! Please Try Again",
  },
  hi: {
    captcha: "कैप्चा",
    testCaptcha:
      "यह प्रश्न ऑटोमेटेड स्पैम सबमिशन को रोकने के लिए परीक्षण करने के लिए है कि क्या आप एक इंसान हैं।",
    enterCaptcha: "ऊपर दी गई छवि में दिखाए गए नंबर दर्ज करें, बिना स्पेस के",
    captchaRule: "बिना स्पेस और केस सेंसिटिव",
    action: "पुष्टि करें",
    error: "गलत कैप्चा! कृपया पुन: प्रयास करें",
  },
  gu: {
    captcha: "કેપ્ચા",
    testCaptcha:
      "આ પ્રશ્ન સ્વયંચાલિત સ્પામ સબમિશનને રોકવા માટે તમે માનવ છો કે કેમ તે ચકાસવા માટે છે.",
    enterCaptcha: "ખાલી જગ્યાઓ વિના, ઉપરની છબીમાં દર્શાવેલ સંખ્યાઓ દાખલ કરો.",
    captchaRule: "કોઈ જગ્યા અને કેસ સંવેદનશીલ નથી",
    action: "પુષ્ટિ કરો",
    error: "ખોટો કેપ્ચા! મહેરબાની કરીને ફરી પ્રયાસ કરો",
  },
};

export default translations;
