const translations = {
  hi: {
    name: {
      header: "अपना पूरा नाम भरें",
      subtext: "आपका नाम मुझे आपको बेहतर तरीके से जानने देगा।",
      placeholder: "अपना नाम दर्ज करें",
    },
    otp: {
      header: "OTP की पुष्टि",
      subtext: " पर भेजे गए OTP को दर्ज करें",
      placeholder: "अपना OTP दर्ज करें",
      didnt: "OTP प्राप्त नहीं हुआ?",
      resendin: " में OTP दोबारा भेजें",
      resendbtn: "OTP पुनः भेजें",
    },
    phone: {
      header: "अपना मोबाइल नंबर दर्ज करें",
      text: "पुष्टि के लिए आपको 6 अंकों का OTP प्राप्त होगा",
      subtext: "अधिमानतः, IRCTC के साथ आपका पंजीकृत मोबाइल नंबर",
      placeholder: "मोबाइल नंबर दर्ज करें",
      success: "OTP सफलतापूर्वक भेज दिया गया है।",
    },
    footer: {
      otp: "OTP की पुष्टि करें",
      name: "जारी रखें",
      phone: "OTP प्राप्त करें",
    },
    header: "लॉग इन करें",
  },
  en: {
    name: {
      header: "Enter Your Full Name",
      subtext: "Your Name will let me get to know you better.",
      placeholder: "Enter your Name",
    },
    otp: {
      header: "OTP Verification",
      subtext: "Enter the OTP sent to ",
      placeholder: "Enter your OTP",
      didnt: "Didn't recieve the OTP?",
      resendin: "Resend OTP in ",
      resendbtn: "Resend OTP",
    },
    phone: {
      header: "Enter Your Mobile Number",
      text: "You will recieve a 6-digit OTP for verification",
      subtext: "Preferably, your registered mobile number with IRCTC",
      placeholder: "Enter mobile number",
      success: "OTP has been sent successfully.",
    },
    footer: {
      otp: "Verify OTP",
      name: "Continue",
      phone: "Get OTP",
    },
    header: "Login",
  },
  gu: {
    name: {
      header: "તમારું પૂરું નામ ભરો.",
      subtext: "તમારું નામ મને તમને વધુ સારી રીતે જાણવાની મંજૂરી આપશે.",
      placeholder: "તમારું નામ દાખલ કરો",
    },
    otp: {
      header: "ઓટિપી ચકાસણી",
      subtext: "મોકલવામાં આવેલ ઓ. ટી. પી. દાખલ કરો",
      placeholder: "તમારો ઓટિપી દાખલ કરો",
      didnt: "શું તમને ઓ. ટી. પી. મળ્યો નથી?",
      resendin: "પર ફરીથી ઓ. ટી. પી. મોકલો",
      resendbtn: "ઓ. ટી. પી. ફરીથી મોકલો",
    },
    phone: {
      header: "તમારો મોબાઇલ નંબર દાખલ કરો",
      text: "તમને પુષ્ટિ માટે 6 અંકનો ઓ. ટી. પી. પ્રાપ્ત થશે.",
      subtext:
        "પ્રાધાન્યમાં, આઈ. આર. સી. ટી. સી. સાથે તમારો નોંધાયેલો મોબાઇલ નંબર.",
      placeholder: "તમારો મોબાઇલ નંબર દાખલ કરો",
      success: "ઓ. ટી. પી. સફળતાપૂર્વક મોકલવામાં આવ્યો છે.",
    },
    footer: {
      otp: "ઓ. ટી. પી. ચકાસો",
      name: "ચાલુ રાખો",
      phone: "ઓટિપી મેળવો",
    },
    header: "લૉગ ઇન કરો",
  },
};

export default translations;
