import { useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { getUpcomingBookings } from "../../../../../../../Api/utils";
import useGetReduxState from "../../../../../../../Hooks/useGetReduxState";

import Booking from "./Booking";
import Container from "./styles";
import { parseBookings } from "./utils";
import langText from "./translations";
import Login from "../../../../../../../Templates/Login2";
import { CloseAllDrawers } from "../../../../../../../Helpers/EventEmitter";
import ClearFilter from "../../../../../../../Templates/Trains/NoTrains/ClearFilter";

const UpcomingBookings = () => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const { cxtoken } = useGetReduxState().app;
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [openLogin, setOpenLogin] = useState(false);


  const readBookings = async () => {
    setLoading(true);
    const data = await getUpcomingBookings();

    setBookings(parseBookings(data));
    setLoading(false);
  };

  useEffect(() => {
    if (cxtoken) readBookings();
  }, []);
  const verifyMobile = () => {
    setOpenLogin(true);
  };

  if (loading)
    return (
      <p
        style={{
          fontSize: "14px",
          lineHeight: "14px",
          color: "rgb(96, 125, 139)",
          fontWeight: "400",
          textAlign: "center",
          margin: "30px 0px",
          width: "100%",
        }}
      >
        {langText[lang].wait}
      </p>
    );

  if (!cxtoken)
    return (
      <div>
        <p
          style={{
            fontSize: "14px",
            lineHeight: "14px",
            color: "rgb(96, 125, 139)",
            fontWeight: "400",
            textAlign: "center",
            margin: "15px 0px",
            width: "100%",
          }}
        >
          {langText[lang].verifyText}
        </p>
        <button
          style={{
            backgroundColor: "rgb(52, 109, 179)",
            border: "1px solid rgb(52, 109, 179)",
            borderRadius: "15px",
            color: "#fff",
            display: "block",
            fontSize: "14px",
            fontWeight: "600",
            margin: "14px auto",

            padding: "8px 16px",
            transition: "all .3s",
            cursor: "pointer",
          }}
          onClick={verifyMobile}
        >
          {langText[lang].verifyBtn}
        </button>
        {openLogin && (
          <Login
            handleCloseDrawer={() => {
              setOpenLogin(false);
            }}
            handleLogin={() => {
              readBookings();
              CloseAllDrawers();
            }}
          />
        )}
      </div>
    );

  if (bookings.length < 1)
    return (
      <p
        style={{
          fontSize: "14px",
          lineHeight: "14px",
          color: "rgb(96, 125, 139)",
          fontWeight: "400",
          textAlign: "center",
          margin: "30px 0px",
          width: "100%",
        }}
      >
        {langText[lang].no}
      </p>
    );

  return (
    <Container>
      <ScrollContainer className="home-bookings">
        {bookings.map((booking) => (
          <Booking booking={booking} />
        ))}
      </ScrollContainer>
    </Container>
  );
};

export default UpcomingBookings;
