import { faMicrophone, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import { Microphone } from "../../../Chatbot/Microphone";
import { isBlockTime } from "../../../Helpers/misc";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import { setJourney, setJourneyQuota } from "../../../Store/Dispatcher/journey";
import Speaker from "../../../UI/UIComponents/Speaker";
import JourneyDetails from "../../JourneyDetails";
import EditTrains from "../EditTrains";
import Loader from "../Loader";
import Maintainance from "../Maintainance";
import ClearFilter from "../NoTrains/ClearFilter";
import NotFound from "../NoTrains/NotFound";
import Settings from "./Components/Settings";
import Trains from "./Components/Trains";
import Container from "./styles";
import audios from "../../../UI/UIComponents/Speaker/audios";

const TrainsContainer = ({
  trains,
  getTrains,
  loading,
  noTrains,
  updateCache,
  sendTrain,
}) => {
  const [sortMode, setSortMode] = useState(0);
  const [filterMode, setFilterMode] = useState(0);
  const journey = useGetReduxState().journey;
  const [trainsToRender, setTrainsToRender] = useState([]);
  const [key, setKey] = useState("");
  const [mic, setMic] = useState(false);
  const makeClone = (arr) => {
    return JSON.parse(JSON.stringify(arr));
  };
  const translations = {
    en: {
      content: "Train Name/Number",
    },
    hi: {
      content: "ट्रेन का नाम/संख्या",
    },

    gu: {
      content: "ટ્રેનનું નામ/નંબર",
    },
  };
  const Sortby = (trainsArr) => {
    if (!trainsArr || trainsArr?.length === 0) return [];

    if (sortMode === 0) return trainsArr;

    let arr = makeClone(trainsArr);

    let newTrains: any = arr;

    if (sortMode === 1) {
      newTrains = newTrains.sort(function (a, b) {
        var textA = a.trainName.toUpperCase();
        var textB = b.trainName.toUpperCase();
        return textA.localeCompare(textB);
      });
      return newTrains;
    } else if (sortMode === 2) {
      newTrains = newTrains.sort(function (a, b) {
        var arrivalA = a.arrivalTime;
        var arrivalB = b.arrivalTime;

        let totalA =
          parseInt(arrivalA.split(":")[0]) * 60 +
          parseInt(arrivalA.split(":")[1]);
        let totalB =
          parseInt(arrivalB.split(":")[0]) * 60 +
          parseInt(arrivalB.split(":")[1]);
        return totalA < totalB ? -1 : 1;
      });
      return newTrains;
    } else if (sortMode === 3) {
      newTrains = newTrains.sort(function (a, b) {
        var departureA = a.departureTime;
        var departureB = b.departureTime;
        let totalA =
          parseInt(departureA.split(":")[0]) * 60 +
          parseInt(departureA.split(":")[1]);
        let totalB =
          parseInt(departureB.split(":")[0]) * 60 +
          parseInt(departureB.split(":")[1]);
        return totalA < totalB ? -1 : 1;
      });

      return newTrains;
    } else if (sortMode === 4) {
      newTrains = newTrains.sort(function (a, b) {
        var durationA = a.duration;
        var durationB = b.duration;
        let totalA =
          parseInt(durationA.split(":")[0]) * 60 +
          parseInt(durationA.split(":")[1]);
        let totalB =
          parseInt(durationB.split(":")[0]) * 60 +
          parseInt(durationB.split(":")[1]);
        return totalA < totalB ? -1 : 1;
      });

      return newTrains;
    }
  };

  const getFilterMode = (mode) => {
    let startTime = "";
    let endtime = "";
    switch (mode) {
      case 11:
      case 21:
        startTime = "00:00";
        endtime = "06:00";
        break;
      case 12:
      case 22:
        startTime = "06:00";
        endtime = "12:00";
        break;
      case 13:
      case 23:
        startTime = "12:00";
        endtime = "18:00";
        break;
      case 14:
      case 24:
        startTime = "18:00";
        endtime = "24:00";
        break;
    }

    return {
      startTime: moment(startTime, "hh:mm"),
      endtime: moment(endtime, "hh:mm"),
    };
  };

  const FilterByTime = (trainsArr) => {
    if (!trainsArr || trainsArr?.length === 0) return [];

    if (filterMode === 0) return trainsArr;
    let { startTime, endtime } = getFilterMode(filterMode);

    let newTrains: any = [];
    let arr = makeClone(trainsArr);
    if (arr.length <= 0) return [];
    let type =
      filterMode.toString().split("")[0] === "1" ? "DEPARTURE" : "ARRIVAL";

    if (type === "ARRIVAL") {
      for (let train of arr) {
        let x = moment(train.arrivalTime, "hh:mm");
        if (moment(x).isBetween(startTime, endtime, undefined, "[]"))
          newTrains.push(train);
      }
    } else {
      for (let train of arr) {
        let x = moment(train.departureTime, "hh:mm");
        if (moment(x).isBetween(startTime, endtime, undefined, "[]"))
          newTrains.push(train);
      }
    }
    return newTrains;
  };

  const FilterByKey = (trainsArr) => {
    if (!trainsArr || trainsArr?.length === 0) return [];

    if (key === "") return trainsArr;

    let newTrains: any = [];
    let arr = makeClone(trainsArr);

    for (let train of arr) {
      if (
        train.trainName.toLowerCase().includes(key.toLowerCase()) ||
        train.trainNumber.includes(key)
      )
        newTrains.push(train);
    }

    return newTrains.length > 0 ? newTrains : trainsArr;
  };

  const getTrainsToRender = (trainsArr) => {
    if (trainsArr === null || trainsArr === undefined) return [];
    if (!(Array.isArray(trainsArr) && trainsArr.length > 0)) return [];

    let trains = makeClone(trainsArr);
    // trains = getFilteredTrains(trains);

    trains = FilterByTime(trains);

    trains = Sortby(trains);

    if (key) {
      trains = FilterByKey(trains);
    }

    setTrainsToRender(trains);
  };

  useEffect(() => {
    getTrainsToRender(trains);
  }, [sortMode, filterMode, trains, journey, key]);

  const handleSortSelect = (mode) => {
    setSortMode(mode);
  };

  const handleFilterSelect = (mode) => {
    setFilterMode(mode);
  };

  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  const handleQuotaSelect = (quota) => {
    setJourneyQuota(quota);
    getTrains({
      source: journey.source,
      destination: journey.destination,
      date: journey.date,
      quota: quota,
    });
  };

  const findTrains = ({ source, destination, date, quota }) => {
    setJourney({ source, destination, quota, date, boardingStation: "" });
    getTrains({
      source,
      destination,
      date,
      quota,
    });
  };

  const clearFilters = () => {
    setFilterMode(0);
  };
  const [edit, setEdit] = useState(false);

  if (isBlockTime().isBlock) {
    return <Maintainance message={isBlockTime().message} />;
  }
  return (
    <Container>
      <JourneyDetails findTrains={findTrains} />
      {loading ? (
        <Loader />
      ) : noTrains ? (
        <NotFound edit={() => setEdit(true)} />
      ) : trainsToRender.length < 1 ? (
        <ClearFilter clearFilters={clearFilters} />
      ) : (
        <React.Fragment>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 12px",
            }}
          >
            <div className="trains-found">
              {trainsToRender.length} Trains found
            </div>
            <Speaker
              sizeBg={"25px"}
              sizeIcon="12px"
              audioUrl={audios[lang].SELECT_TRAIN}
              disabled={false}
            />
            <div
              style={{
                width: "100%",
                marginLeft: "30px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "1px solid gray",
                padding: "6px 8px",
                borderRadius: "6px",
                position: "relative",
              }}
            >
              <FontAwesomeIcon
                icon={faSearch}
                style={{ color: "gray", fontSize: "14px" }}
              />
              <input
                type="text"
                style={{
                  width: "100%",
                  fontSize: "14px",
                  marginLeft: "6px",
                  outline: "none",
                  border: "none",
                  color: "gray",
                }}
                value={key}
                onChange={(e) => setKey(e.target.value)}
                placeholder={"Search by Name/Number"}
              />
              <FontAwesomeIcon
                style={{
                  // position: "absolute",
                  // top: "50%",
                  // transform: "translateY(-50%)",
                  // right: "10px",
                  fontSize: "16px",
                  color: "#909090",
                  cursor: "pointer",
                  marginLeft: "5px",
                }}
                icon={faMicrophone}
                onClick={() => setMic(true)}
              />
            </div>
          </div>

          <Trains
            trains={trainsToRender}
            updateCache={updateCache}
            sendTrain={sendTrain}
          />
          <Settings
            sortMode={sortMode}
            filterMode={filterMode}
            handleSortSelect={handleSortSelect}
            handleFilterSelect={handleFilterSelect}
            handleQuotaSelect={handleQuotaSelect}
          />
        </React.Fragment>
      )}
      {edit && (
        <EditTrains
          handleClose={() => setEdit(false)}
          findTrains={findTrains}
        />
      )}
      {mic && (
        <Microphone
          useEnglish={true}
          callback={(value) => {
            if (value) setKey(value);
            setMic(false);
          }}
          onClose={() => setMic(false)}
          content={translations[lang].content}
        />
      )}
    </Container>
  );
};

export default TrainsContainer;
