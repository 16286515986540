const translations = {
  en: {
    orderId: "Order Id:",
    txnId: "Transaction Id:",
    dateTxn: "Date of Transaction:",
    timeTxn: "Time of Transaction:",
    refund: "Refund Status:",
    initiated: "Initiated",
    pending: "Pending",
    initiatedMsg:
      "Refund has been initiated! Payment amount will be credited to your bank account in 3 to 7 working days",
    pendingMsg:
      "Refund will be initiated shortly! Payment amount will be credited to your bank account in 3 to 7 working days",
    txnAmount: "Transaction Amount",
    refundAmount: "Refundable Amount",
    pg: "PG charges",
    txnMode: "Mode of Transaction",
    banktxnId: "Bank Transaction ID",
    bankName: "Bank Name",
    not: "Not Available",
  },
  hi: {
    orderId: "आर्डर Id:",
    txnId: "ट्रांसेक्शन Id:",
    dateTxn: "ट्रांसेक्शन की तारीख",
    timeTxn: "ट्रांसेक्शन का समय",
    refund: "धनवापसी की स्थिति",
    initiated: "पहल की गई है",
    pending: "लंबित है",
    initiatedMsg:
      "रिफंड शुरू किया गया है! भुगतान राशि 3 से 7 कार्य दिवसों में आपके बैंक खाते में जमा कर दी जाएगी",
    pendingMsg:
      "धनवापसी शीघ्र ही शुरू की जाएगी! भुगतान राशि 3 से 7 कार्य दिवसों में आपके बैंक खाते में जमा कर दी जाएगी",
    txnAmount: "ट्रांसेक्शन की रकम ",
    refundAmount: "वापसी योग्य राशि",
    pg: "PG शुल्क",
    txnMode: "भुगतान का प्रकार",
    banktxnId: "बैंक ट्रांसेक्शन ID",
    bankName: "बैंक का नाम",
    not: "उपलब्ध नहीं है",
  },
  gu: {
    orderId: "ઓર્ડર આઈડીઃ",
    txnId: "ટ્રાન્ઝેક્શન ID:",
    dateTxn: "વ્યવહારની તારીખ",
    timeTxn: "વ્યવહારનો સમય",
    refund: "રિફંડની સ્થિતિ",
    initiated: "પહેલ કરવામાં આવી છે.",
    pending: "બાકી છે",
    initiatedMsg:
      "રિફંડ શરૂ થઈ ગયું છે! ચુકવણીની રકમ 3 થી 7 કાર્યકારી દિવસોમાં તમારા બેંક ખાતામાં જમા થઈ જશે.",
    pendingMsg:
      "રિફંડ ટૂંક સમયમાં શરૂ થશે! ચુકવણીની રકમ 3 થી 7 કાર્યકારી દિવસોમાં તમારા બેંક ખાતામાં જમા થઈ જશે.",
    txnAmount: "વ્યવહારની રકમ",
    refundAmount: "રિફંડપાત્ર રકમ",
    pg: "પીજી ચાર્જ",
    txnMode: "ચુકવણીનો પ્રકાર",
    banktxnId: "બેંક ટ્રાન્ઝેક્શન ID",
    bankName: "બેંકનું નામ",
    not: "ઉપલબ્ધ નથી",
  },
};

export default translations;
