import store from "..";
import {
  playMessageAudio,
  setAppLanguage,
  setQueryLanguage,
  stopMessageAudio,
  toggleMessageLoader,
} from "../Actions/behaviour";
import * as behaviour from "../Actions/behaviour";

export const toggleLoader = () => {
  store.dispatch(toggleMessageLoader());
};

export const playAudio = ({ id, url }: { id: any; url: string }) => {
  let audioPlayer: any = document.getElementById("myaudio") as HTMLAudioElement;
  audioPlayer.src = url;
  audioPlayer.play();

  store.dispatch(playMessageAudio({ id: id.toString(), url: url }));
};

export const stopAudio = () => {
  let audioPlayer: any = document.getElementById("myaudio") as HTMLAudioElement;
  audioPlayer.pause();
  store.dispatch(stopMessageAudio());
};

export const setAppLang = (lang) => {
  store.dispatch(setAppLanguage(lang));
};

export const setQueryLang = (lang) => {
  store.dispatch(setQueryLanguage(lang));
};

export const showAlsoTry = (query) => {
  store.dispatch(behaviour.showAlsoTry(query));
};

export const hideAlsoTry = () => {
  store.dispatch(behaviour.hideAlsoTry());
};

export const setUserToken = (value) => {
  store.dispatch(behaviour.setUserToken(value));
};

export const setIrctcDetails = ({
  email,
  mobile,
  txnId,
}: {
  email: string;
  mobile: string;
  txnId: string;
}) => {
  store.dispatch(behaviour.setIrctcDetails({ email, mobile, txnId }));
};

export const setCxToken = (value) => {
  store.dispatch(behaviour.setCxToken(value));
};

export const setNextContext = (value) => {
  store.dispatch(behaviour.setNextContext(value));
};

export const setRecentJourneys = (value) => {
  store.dispatch(behaviour.setRecentJourneys(value));
};

export const setSessionId = (value) => {
  store.dispatch(behaviour.setSessionId(value));
};

export const setDeviceId = (value) => {
  store.dispatch(behaviour.setDeviceId(value));
};

export const setDeviceStatus = (value) => {
  store.dispatch(behaviour.setStatus(value));
};

export const setPrevCode = (value) => {
  store.dispatch(behaviour.setPrevCode(value));
};

// export const showLogin = () => {
//   store.dispatch(behaviour.showLogin());
// };

// export const showTrains = () => {
//   store.dispatch(behaviour.showTrains());
// };

// export const hideTemplate = () => {
//   store.dispatch(behaviour.hideTemplate());
// };

export const setFullScreen = ({ type, component }) => {
  store.dispatch(behaviour.setFullScreenComponent({ type, component }));
};

export const setCurrent = (value) => {
  store.dispatch(behaviour.setCurrent(value));
};

export const setIsFallback = (value) => {
  store.dispatch(behaviour.setIsFallback(value));
};

export const setDisabledSettings = ({ other, booking }) => {
  store.dispatch(behaviour.setDisabledSettings({ other, booking }));
};

export const setIsRefund = (value) => {
  store.dispatch(behaviour.setIsRefund(value));
};

export const setInputType = (value: "TEXT" | "VOICE") => {
  store.dispatch(behaviour.setInputType(value));
};

export const setLocalData = (value) => {
  store.dispatch(behaviour.setLocalData(value));
};

export const setUnAuth = () => {
  store.dispatch(behaviour.setUnAuth());
};

export const setUpcomingBookings = (value) => {
  store.dispatch(behaviour.upcomingBookings(value));
};
