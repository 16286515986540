import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 7px 7px 2px 7px;
  box-shadow: ${(props) =>
    props.upperBoundary
      ? `rgb(0 0 0 / 10%) 0px -4px 5px 0px,
    rgb(0 0 0 / 10%) 1px 0px 0px 0px`
      : "none"};

  z-index: 2;
  background-color: white;

  img {
    cursor: pointer;
  }

  .lang-menu-btn {
    width: 44px;
    margin-right: 8px;
    height: 34px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #346db3;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  input {
    border: 1px solid #dad9d9;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 500;
    outline: none;
    padding: 8px 10px;
    width: 100%;
  }

  .mic-btn {
    width: 28px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .send-btn {
    width: 32px;
  }
`;

export default Container;
