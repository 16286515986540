import useGetReduxState from "../../../../Hooks/useGetReduxState";
import Additional from "./Additional";
import Boarding from "./Boarding";
import Email from "./Email";
import GST from "./GST";
import Insurance from "./Insurance";
import Container from "./styles";

const Preferences = () => {
  const irctcConfig = useGetReduxState().passengerform.irctcConfig;
  const status = useGetReduxState().train.status;

  return (
    <Container>
      {irctcConfig.showTravelInsurance && <Insurance />}

      {irctcConfig.boardingStations.length > 1 &&
        !status.toLowerCase().includes("curr_avbl") && <Boarding />}

      {(irctcConfig.boardingStations.length > 1 ||
        irctcConfig.showTravelInsurance) && <div className="seperator"></div>}

      <Additional />

      <Email />

      {irctcConfig.isGSTEnable && <GST />}
    </Container>
  );
};

export default Preferences;
