import { useState } from "react";
import ReactDOM from "react-dom";
import styles from "./ListBox.module.css";

import useGetReduxState from "../../../../../../../Hooks/useGetReduxState";
import { Microphone } from "../../../../../../../Chatbot/Microphone";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ListBox = ({ handleSelect, handleClose }) => {
  const countries = useGetReduxState().utils.countries;
  const [countryList, setCountryList] = useState(countries);
  const [value, setValue] = useState("");
  const lang = useGetReduxState().behaviour.lang;

  const [mic, setMic] = useState(false);
  const translations = {
    en: {
      content: "Your country name",
    },
    hi: {
      content: "आपका देश का नाम",
    },

    gu: {
      content: "તમારો દેશનું નામ",
    },
  };
  const handleSearch = (e) => {
    setValue(e.target.value);
    let value = e.target.value.toLowerCase();

    if (value) {
      let matched = countries.filter((country) =>
        country.country.toLowerCase().startsWith(value)
      );
      setCountryList(matched);
    } else {
      setCountryList(countries);
    }
  };

  const handleVoice = (value) => {
    if (!value) return;
    let e = {
      target: {
        value: value,
      },
    };
    setValue(value);
    handleSearch(e);
  };

  return ReactDOM.createPortal(
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: "110px",
          backgroundColor: "white",
          zIndex: 99999,
        }}
      >
        {/* <span
          style={{
            textAlign: "center",
            display: "block",
            marginTop: "-4px",
            fontSize: "12px",
            color: "gray",
          }}
        >
          You can add 6 passengers at max.
        </span> */}
        <div
          style={{
            backgroundColor: "white",
            maxHeight: "calc(100%)",
            overflowY: "auto",
            height: "fit-content",
            display: "flex",
            flexFlow: "wrap",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            position: "absolute",
            width: "100%",
            bottom: "0px",
          }}
          className={styles.container}
        >
          {countryList.map((country) => (
            <div
              className={styles.country}
              onClick={() => handleSelect(country)}
            >
              {country.country}
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          position: "absolute",
          height: "110px",
          bottom: "0px",
          backgroundColor: "white",
          zIndex: 999999,
        }}
      >
        <div className={styles.searchbox}>
          <input
            type="text"
            placeholder={
              lang === "en" ? "Search Your Country..." : "अपना देश खोजें..."
            }
            value={value}
            onChange={handleSearch}
          />
          <FontAwesomeIcon
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              right: "10px",
              fontSize: "20px",
              color: "#2d69c0",
              cursor: "pointer",
            }}
            icon={faMicrophone}
            onClick={() => setMic(true)}
          />
        </div>
        <button
          style={{
            border: "none",
            backgroundColor: "#326abb",
            color: "white",
            padding: "10px",
            width: "90%",
            borderRadius: "5px",
            cursor: "pointer",
            fontSize: "16px",
          }}
          onClick={handleClose}
        >
          {lang === "en" ? <span>Back</span> : <span>वापस जाएं</span>}
        </button>
        {mic && (
          <Microphone
            useEnglish={true}
            callback={(value) => {
              handleVoice(value);
              setMic(false);
            }}
            onClose={() => setMic(false)}
            content={translations[lang].content}
          />
        )}
      </div>
    </>,
    document.getElementById("corover-body")
  );
};

export default ListBox;
