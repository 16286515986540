import { Utils } from "./types";

const initialUtils = {
  popularStations: [],
  allStations: [],
  isBookingPending: false,
  isOtpPending: false,
  countries: [],
};

const reducer = (utils: Utils = initialUtils, action) => {
  switch (action.type) {
    case "IS_BOOKING_PENDING":
      return { ...utils, isBookingPending: action.value };

    case "IS_OTP_PENDING":
      return { ...utils, isOtpPending: action.value };

    case "SET_POPULAR_STATIONS":
      return { ...utils, popularStations: action.value };

    case "SET_ALL_STATIONS":
      return { ...utils, allStations: action.value };
    case "SET_COUNTRIES":
      return { ...utils, countries: action.value };

    default:
      return utils;
  }
};

export default reducer;
