import { faHouse, faTrainSubway } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
import useSendQuery from "../../../../Hooks/useSendQuery/useSendQuery";
import { setFullScreen } from "../../../../Store/Dispatcher/behaviour";
import {
  setJourneyDestination,
  setJourneySource,
} from "../../../../Store/Dispatcher/journey";
import Trains from "../../../Trains";

import Modal from "./Modal";

const ticket =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/ticket.png";
const ers =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/note-refactored.png";

// const langText = {
//   hi:{

//   },
//   en:{

//   }
// }

const langText = {
  hi: {
    ticket: "E-टिकट",
    ers: "ERS",
    home: "Home पर वापस जाएं",
    returnTicket: "बुक रिटर्न टिकट",
  },
  en: {
    ticket: "E-TICKET",
    ers: "ERS",
    home: "Back to Home",
    returnTicket: "Book Return Ticket",
  },
  gu: {
    ticket: "ઇ-ટિકિટ",

    ers: "ઇઆરએસ",

    home: "ઘરે પાછા ફરો",

    returnTicket: "રીટર્ન ટિકિટ બુક કરો",
  },
};

const Buttons = ({ data }) => {
  const lang = useGetReduxState().behaviour.lang;
  const { goToHome } = useSendQuery(() => {});
  // const [display, setDisplay] = useState(true);
  const journey = useGetReduxState().journey;
  useEffect(() => {
    if (!window.navigator || window.navigator.share === undefined) {
      // setDisplay(false);
    }
  }, []);

  const [drawer, showDrawer] = useState("");

  const handleCloseDrawer = () => {
    showDrawer("");
  };

  const ReturnTicket = () => {
    let source = journey.source;
    let dest = journey.destination;

    setJourneySource(dest);
    setJourneyDestination(source);

    setFullScreen({ type: "TRAINS", component: <Trains /> });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <button
          style={{
            border: "none",
            backgroundColor: "#346db3",
            color: "white",
            fontSize: "14px",
            padding: "8px",
            fontWeight: "500",
            borderRadius: "5px",
            cursor: "pointer",
            minWidth: "130px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => showDrawer("TICKET")}
        >
          <img
            src={ticket}
            style={{ width: "18px", marginRight: "6px" }}
            alt=""
          />
          {langText[lang].ticket}
        </button>
        <button
          style={{
            border: "none",
            backgroundColor: "#346db3",
            color: "white",
            fontSize: "14px",
            padding: "8px",
            fontWeight: "500",
            borderRadius: "5px",
            cursor: "pointer",
            minWidth: "130px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => showDrawer("ERS")}
        >
          <img src={ers} style={{ width: "18px", marginRight: "6px" }} alt="" />
          {langText[lang].ers}
        </button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <button
          style={{
            border: "none",
            backgroundColor: "transparent",
            color: "#346db3",
            fontSize: "14px",
            padding: "8px",
            fontWeight: "500",
            borderRadius: "5px",
            cursor: "pointer",
            minWidth: "130px",
          }}
          onClick={ReturnTicket}
        >
          <FontAwesomeIcon
            icon={faTrainSubway}
            style={{
              color: "#346db3",
              marginRight: "5px",
            }}
          />
          {langText[lang].returnTicket}
        </button>
        <button
          style={{
            border: "none",
            backgroundColor: "transparent",
            color: "#346db3",
            fontSize: "14px",
            padding: "8px",
            fontWeight: "500",
            borderRadius: "5px",
            cursor: "pointer",
            minWidth: "130px",
          }}
          onClick={goToHome}
        >
          <FontAwesomeIcon
            icon={faHouse}
            style={{
              color: "#346db3",
              marginRight: "5px",
            }}
          />
          {langText[lang].home}
        </button>
      </div>

      {drawer && (
        <Modal mode={drawer} handleClose={handleCloseDrawer} data={data} />
      )}
    </>
  );
};

export default Buttons;
