import { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { bookingFeedBack } from "../../../../Api/feedback";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
import "./styles.scss";

const langText = {
  hi: {
    how: "आपका टिकट बुकिंग का अनुभव कैसा रहा?",
    thanks: "आपकी प्रतिक्रिया के लिए धन्यवाद।",
    cancel: "रद्द करें",
    submit: "सबमिट करें",
    placeholder: "अपनी प्रतिक्रिया यहां दर्ज करें...",
  },
  en: {
    how: "How was your ticket booking experience ?",
    thanks: "Thank you for your feedback.",
    cancel: "Cancel",
    submit: "Submit",
    placeholder: "Enter your feedback here...",
  },
  gu: {
    how: "ટિકિટ બુક કરાવવાનો તમારો અનુભવ કેવો રહ્યો?",

    thanks: "તમારા પ્રતિભાવ બદલ આભાર.",

    cancel: "રદ કરો",

    submit: "સબમિટ કરો",

    placeholder: "તમારો પ્રતિસાદ અહીં દાખલ કરો...",
  },
};

const Feedback = ({ pnr }) => {
  const lang = useGetReduxState().behaviour.lang;
  const [comment, setComment] = useState("");
  const [feedDone, setFeedDone] = useState(false);
  const [rating, setRating] = useState(0); // initial rating value
  // const api = new Api();
  const [error, setError] = useState("");

  const [showFeedBack, setShowFeedBack] = useState(false);
  const [apiHitCount, setCount] = useState(0);
  // @ts-ignore
  const [txn, setTxn] = useState(Date.now().toString());

  useEffect(() => {
    setCount((prev) => prev + 1);
  }, [rating]);

  const handleRating = (rate: number) => {
    setRating(rate);
    if (apiHitCount < 5) {
      if (rate > 0) {
        bookingFeedBack({
          rating: rate,
          feedback: comment,
          pnr: pnr,
          txn: txn,
        });

        setShowFeedBack(true);
      } else setShowFeedBack(false);
    }
  };

  useEffect(() => {
    setError("");
  }, [comment]);

  const handleSubmit = () => {
    if (rating <= 4 && comment.length < 100) {
      setError(
        lang === "en"
          ? "Please enter a minimum of 100 characters"
          : "कृपया कम से कम 100 वर्ण दर्ज करें"
      );
      return;
    }
    bookingFeedBack({
      rating: rating,
      feedback: comment,
      pnr: pnr,
      txn: txn,
    });

    setFeedDone(true);
  };

  const handleCancel = () => {
    setShowFeedBack(false);
  };

  const translations = {
    hi: [
      "असंतोष",
      "उचित",
      "पर्याप्त",
      "उपयोगकर्ता-मित्र",
      "उत्कृष्ट",
      "श्रेष्ठ",
      "अद्भुत",
      "असाधारण",
      "अत्यंत उत्कृष्ट",
      "अत्यधिक",
    ],
    gu: [
      "અસંતોષ",
      "સરસ",
      "યોગ્ય",
      "યુઝર-ફ્રેન્ડલી",
      "શ્રેષ્ઠ",
      "શ્રેષ્ઠ",
      "ફાંક્તાસ્ટિક",
      "અસાધારણ",
      "અત્યંત ચમત્કારી",
      "બહુ ઉત્કૃષ્ટ",
    ],
    en: [
      "Dissatisfaction",
      "Fair",
      "Adequate",
      "User-Friendly",
      "Excellent ",
      "Superior",
      "Fantastic",
      "Exceptional",
      "Phenomenal",
      "Outstanding",
    ],
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // marginTop: "15px",
      }}
    >
      {feedDone ? (
        <p
          style={{
            marginBottom: "5px",
            fontSize: "16px",
            color: "darkslategray",
          }}
        >
          {langText[lang].thanks}
        </p>
      ) : (
        <p
          style={{
            marginBottom: "5px",
            fontSize: "16px",
            color: "darkslategray",
          }}
        >
          {langText[lang].how}
        </p>
      )}

      {!feedDone && (
        <>
        <a className="xy">
          <Rating
            onClick={handleRating}
            // @ts-ignore
            ratingValue={rating} /* Available Props */
            size={32}
            style={{
              marginLeft:"10%",
            }}
            fillColor={"#ffda0b"}
            allowHalfIcon={true}
            transition={true}
            tooltipArray={translations[lang]}
            showTooltip
            tooltipStyle={{
              backgroundColor: "white",
              fontSize: "18px",
              color: "rgb(52, 109, 179)",
              border: "1px solid rgb(52, 109, 179)",
            }}
          />
          </a>

          {showFeedBack && (
            <>
              {" "}
              <textarea
                id="feedback-text"
                name=""
                cols={30}
                placeholder={langText[lang].placeholder}
                rows={5}
                onChange={(e) => setComment(e.target.value)}
                style={{ borderRadius: "5px" }}
              ></textarea>
              {error && (
                <p
                  style={{
                    color: "tomato",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  {error}
                </p>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <button
                  style={{
                    border: "1px solid #0b74fa",
                    background: "white",
                    color: "#0b74fa",
                    borderRadius: "8px",
                    marginRight: "12px",
                    fontSize: "14px",
                    padding: "5px 8px",
                    cursor: "pointer",
                  }}
                  onClick={handleCancel}
                >
                  {langText[lang].cancel}
                </button>
                <button
                  disabled={comment == "" || (rating < 80 && error !== "")}
                  style={{}}
                  onClick={handleSubmit}
                  id="feedback-submit"
                >
                  {langText[lang].submit}
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Feedback;
