import styled from "styled-components";

const Container = styled.div`
  width: 100%;

  padding: 5px 10px;

  .acronyms {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    column-gap: 0.6in;
    width: 100%;
    font-size: 7pt;
    font-weight: normal;
    margin-top: 5pt;
  }

  .flex {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  img {
    width: 4.01in;
    height: 0.22in;
    margin-top: 10pt;
  }

  .recovers {
    font-size: 12pt;
    font-weight: normal;
    margin-bottom: 4pt;
  }

  .payment {
    h4 {
      font-weight: bold;
      font-size: 13pt;
      text-decoration: underline;
    }

    div {
      font-size: 11pt;
    }

    .flex {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      column-gap: 0.7in;
      padding-bottom: 6pt;
    }
  }

  .other {
    width: 100%;

    p {
      font-size: 9pt;
      font-weight: bold;

      &:nth-child(1) {
        margin-top: 12pt;
      }
    }
  }
`;

export default Container;
