import React from "react";
import useGetReduxState from "../../../../Hooks/useGetReduxState";

import PassengerFormButtons from "./PassengerFormButtons";
import Select from "./Select";
import TextAndMic from "./TextAndMic";

const Sender = ({ handleSendQueryPayload }) => {
  const current = useGetReduxState().behaviour.current;
  const { showMessageLoader } = useGetReduxState().behaviour;
  const isPending = useGetReduxState().utils.isBookingPending;

  if (showMessageLoader) return <React.Fragment></React.Fragment>;
  if (
    [
      "SELECT_PNR_FOR_CANCEL",
      "SELECT_PASSENGERS_FOR_CANCEL",
      "SELECT_PNR_FOR_TDR",
      "SELECT_PASSENGERS_FOR_TDR",
      "SELECT_PNR_FOR_BOARDING",
      "REFUND_OPTIONS",
    ].includes(current)
  )
    return <React.Fragment></React.Fragment>;
  if (
    current === "SELECT_SOURCE" ||
    current === "SELECT_DESTINATION" ||
    current === "SELECT_DATE" ||
    current === "SELECT_QUOTA" ||
    current === "SELECT_BOARDING_FOR_CHANGE" ||
    current === "SELECT_REASON_FOR_TDR"
  )
    return (
      <Select
        current={current}
        handleSendQueryPayload={handleSendQueryPayload}
      />
    );
  else if (current === "SELECT_PASSENGERS" || isPending)
    return <PassengerFormButtons />;
  else return <TextAndMic handleSendQueryPayload={handleSendQueryPayload} />;
};

export default Sender;
