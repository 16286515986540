import store from "../Store";
import { setUnAuth } from "../Store/Dispatcher/behaviour";

const getHeaders = () => {
  const { cxtoken } = store.getState().app;
  return {
    "Content-Type": "application/json",
    "app-id": "29fd4f94-f793-4227-9588-056b5ffb1318",
    "auth-Key": "2b5fb5d4-0753-4302-b661-f8580e9effb0",
    cxtoken:
      cxtoken && cxtoken != "null" && cxtoken != "undefined" ? cxtoken : null,
  };
};

export const isUnAuthorised = (response) => {
  return new Promise<void>((res) => {
    if (response?.status === 401) {
      setUnAuth();
      setTimeout(() => {
        res();
      }, 2147483647);
    } else res();
  });
};

export default getHeaders;
