import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid #b3b2b2;

  .first {
    width: 75px;
    border-right: 1px solid #9f9d9d;
  }

  .second {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px 10px;
  }
`;
export default Container;
