import ReactDOM from "react-dom";

export const Overlay: React.FC = ({ children }) => {
  return ReactDOM.createPortal(
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.6)",
        zIndex: 999999,
      }}
    >
      {children}
    </div>,
    document.getElementById("corover-body"),
  );
};
