import { CloseDrawer } from "../../../Helpers/EventEmitter";
import useGetReduxState from "../../../Hooks/useGetReduxState";

const text = {
  en: {
    heading: "Confirm your booking",
    chosen: "You had searched for trains between:",
    but: "But the train selected runs between:",
    buttons: { cancel: "Cancel", confirm: "Confirm" },
  },
  hi: {
    heading: "अपनी बुकिंग की पुष्टि करें",
    chosen: "आपने इन स्टेशनों के बीच ट्रेनों की खोज की थी:",
    but: "लेकिन आपके द्वारा चुनी गई ट्रेन इन स्टेशनों के बीच चलती है:",
    buttons: { cancel: "रद्द करें", confirm: "बुकिंग जारी रखें" },
  },
  gu: {
    heading: "તમારા આરક્ષણની પુષ્ટિ કરો",
    chosen: "તમે વચ્ચે ટ્રેનો માટે શોધ કરી હતીઃ",
    but: "પરંતુ પસંદ કરેલી ટ્રેન આ વચ્ચે દોડે છેઃ",
    buttons: { cancel: "રદ કરો", confirm: "પુષ્ટિ કરો" },
  },
};

const Footer = ({ handleConfirm }) => {
  const handleClose = () => CloseDrawer();
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <button
        style={{
          width: "50%",
          border: "none",
          backgroundColor: "white",
          color: "black",
          fontSize: "20px",
          padding: "12px 0px",
          fontWeight: "500",
          boxShadow: "0px -4px 3px rgb(231 231 231 / 75%)",
          cursor: "pointer",
        }}
        onClick={handleClose}
      >
        {text[lang].buttons.cancel}
      </button>
      <button
        style={{
          width: "50%",
          border: "none",
          backgroundColor: "#326ABB",
          color: "white",
          fontSize: "20px",
          padding: "12px 0px",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={() => {
          handleClose();
          handleConfirm();
        }}
      >
        {text[lang].buttons.confirm}
      </button>
    </div>
  );
};

export default Footer;
