import axios from "axios";
import moment from "moment";
import { getChannel } from "../Helpers/misc";
import store from "../Store";
import getHeaders from "./getHeaders";

export const bookingFeedBack = async ({ rating, feedback, txn, pnr }) => {
  const payload = { rating, feedback, txn, pnr };

  try {
    const response = await axios.post(
      `/dishaAPI/bot/ticketFeedback`,

      payload,
      {
        headers: getHeaders(),
      },
    );
    if (response.data.renderTemplate.data) {
      return response.data.renderTemplate.data.upcomingjourney;
    } else return [];
  } catch (e) {
    return [];
  }
};

export const thumbsFeedBack = async ({ answerId, feedback, comment }) => {
  const payload = { answerId, feedback, comment, channel: getChannel() };

  try {
    const response = await axios.post(
      `/dishaAPI/bot/feedback`,

      payload,
      {
        headers: getHeaders(),
      },
    );
    if (response.data.renderTemplate.data) {
      return response.data.renderTemplate.data.upcomingjourney;
    } else return [];
  } catch (e) {
    return [];
  }
};

export const sendUiErrorReport = async (error) => {
  const configuration = store.getState().app;
  const user = store.getState().user;

  let payload = {
    url: configuration.nextContext || "0",
    message: error,
    data: "UI Error",
    usertoken: configuration.userToken,
    dtm: moment().format("MM/DD/YYYY hh:mm:ss"),
    counter_in: "UI",
    isreported: false,
    status: null,
    type: "CRITICAL",
    ip: window.location.host,
    mobile: user.mobile || null,
    channel: getChannel(),
  };

  try {
    await axios.post(
      `/dishaAPI/bot/uiError`,

      payload,
      {
        headers: getHeaders(),
      },
    );

    return true;
  } catch (e) {
    return false;
  }
};
