import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  /* max-height: calc(100% - 40px); */

  height: 100%;
  overflow: auto;
`;

export default Container;
