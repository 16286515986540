import { useEffect, useState } from "react";
import { sendPhoneNumber, updateName, verifyOTP } from "../Api/login";
import { makeArray } from "../Helpers/misc";
import { setCxToken } from "../Store/Dispatcher/behaviour";
import { setPassengers } from "../Store/Dispatcher/passengerForm";
import { setUser } from "../Store/Dispatcher/user";
import { isAdult, isChild } from "../Templates/PassengerForm/utils";
import useGetReduxState from "./useGetReduxState";
import usePersistentStorage from "./usePersistentStorage";

const langText = {
  en: {
    name: "Please Enter a Valid Name.",
    phone: "Please Enter a Valid Mobile Number.",
    otp: "Please Enter a Valid OTP.",
    incorrect: "Incorrect OTP! Please Try Again.",
  },
  hi: {
    name: "कृपया एक मान्य नाम दर्ज करें।",
    phone: "कृपया एक मान्य मोबाइल नंबर दर्ज करें।",
    otp: "कृपया एक मान्य OTP दर्ज करें।",
    incorrect: "गलत OTP! कृपया पुन: प्रयास करें।",
  },
};

const useLogin = ({ handleLoginComplete }) => {
  const localData = useGetReduxState().app.local;

  const {
    saveCxToken,
    savePendingOTP,
    // getDataFromLocalStorage,
  } = usePersistentStorage();

  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const [component, setComponent] = useState("PHONE");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOTP] = useState("");
  const [otpuuid, setOTPuuid] = useState("");
  const [name, setName] = useState("");
  const irctcConfig = useGetReduxState().passengerform.irctcConfig;

  const handleOTPChange = (value) => {
    if (otp.length === 0) setError("");
    if (otp.length === 6) setError("");
    if (value.length === 6) {
      sendDirectOTP(value);
    }
    setOTP(value);
  };

  const isValidName = (name) => {
    const validRegex = /^[a-zA-Z ]{3,255}$/;

    if (validRegex.test(name)) return true;
    else return false;
  };

  const handleNameChange = (value) => {
    if (value === "") setError("");
    if (isValidName(value)) setError("");
    setName(value);
  };

  const handlePhoneNumberChange = (value) => {
    if (value === "") setError("");
    if (validMobile(value)) setError("");
    setPhoneNumber(value);
  };

  const handleBack = () => {
    // hideTemplate();
  };

  const validMobile = (phn) => {
    const validRegex = /^(\+?\d{1,4}[\s-])?(?!0+\s+,?$)\d{10}\s*,?$/;
    const validRegex2 = /^[6-9]\d{9}$/;
    if (validRegex.test(phn) && validRegex2.test(phn) && phn.length === 10)
      return true;
    else return false;
  };

  const isValidOTP = (otp) => {
    const validRegex = /^\d{6}$/;

    if (validRegex.test(otp)) return true;
    else return false;
  };

  const getOTP = async () => {
    if (phoneNumber === "" || !validMobile(phoneNumber)) {
      setError(langText[lang].phone);
      return;
    }
    setLoading(true);
    const data = await sendPhoneNumber(phoneNumber);
    if (data.renderTemplate.data.Details) {
      // saveUserToken(data.userToken);
      setOTPuuid(data.renderTemplate.data.Details);
      setComponent("OTP");
      savePendingOTP({
        uuid: data.renderTemplate.data.Details,
        mobile: phoneNumber,
      });
    } else {
    }
    setLoading(false);
  };

  const getInititialBerth = (age) => {
    const childBerthMandatory = irctcConfig.childBerthMandatory;

    if (isAdult(age)) return "NBC";

    if (isChild(age)) {
      return childBerthMandatory ? "" : "";
    }

    return "";
  };

  const constructPassenger = (passenger) => {
    return {
      name: passenger.passengerName || "",
      age: passenger.passengerAge.toString() || "",
      gender: passenger.passengerGender || "",
      food: irctcConfig.applicableFoodChoices.includes(
        passenger.passengerFoodChoice,
      )
        ? passenger.passengerFoodChoice
        : "",

      nationality: passenger.passengerNationality || "",
      passport: passenger.passengerCardNumber || "",
      dob: passenger.psgnConcDOB || "",
      berth: getInititialBerth(passenger.passengerAge.toString()),
      isSelected: false,
    };
  };

  const refactorForMatch = (passenger) => {
    return JSON.stringify(passenger);
  };

  const savePassengers = async (data) => {
    let actualData = data[0];

    // const passengers = makeArray(JSON.parse(actualData.passengerList)).map(
    //   (passenger) => constructPassenger(passenger)
    // );

    const savedPassengers = makeArray(JSON.parse(actualData.masterData)).map(
      (passenger) => constructPassenger(passenger),
    );

    let total = [...savedPassengers].map((passenger) =>
      refactorForMatch(passenger),
    );
    // let refactored = [];
    // for (let p of total) {
    //   if (!refactored.includes(p)) refactored.push(p);
    // }
    let refactored = [...new Set(total)];

    setPassengers(refactored.map((passenger) => JSON.parse(passenger)));
  };

  const sendOTP = async () => {
    if (otp.length !== 6 || !isValidOTP(otp)) {
      setError(langText[lang].otp);
      return;
    }
    setLoading(true);
    const data = await verifyOTP({ otp, otpuuid });
    if (data.cxtoken) {
      setUser(data.existingData);
      savePassengers(data.existingData);
      setCxToken(data.cxtoken);
      saveCxToken(data.cxtoken);

      if (data.newUser) {
        setComponent("NAME");
      } else {
        handleLoginComplete();
      }
    } else {
      setError(langText[lang].incorrect);
    }
    setLoading(false);
  };

  const sendDirectOTP = async (otp) => {
    if (otp.length !== 6 || !isValidOTP(otp)) {
      setError(langText[lang].otp);
      return;
    }
    setLoading(true);
    const data = await verifyOTP({ otp, otpuuid });
    if (data.cxtoken) {
      setUser(data.existingData);
      savePassengers(data.existingData);
      setCxToken(data.cxtoken);
      saveCxToken(data.cxtoken);

      if (data.newUser) {
        setComponent("NAME");
      } else {
        handleLoginComplete();
      }
    } else {
      setError(langText[lang].incorrect);
    }
    setLoading(false);
  };

  const sendName = async () => {
    if (!isValidName(name)) {
      setError(langText[lang].name);
      return;
    }
    setLoading(true);
    await updateName(name);

    setLoading(false);

    handleLoginComplete();
  };

  const reSendOTP = () => {
    setOTP("");
    getOTP();
  };

  const reEnterPhone = () => {
    // clearStorage();
    // setPhoneNumber("");
    setOTP("");
    setError("");
    setComponent("PHONE");
  };

  useEffect(() => {
    const data = JSON.parse(localData);

    if (data.mobile) {
      setOTPuuid(data.uuid);
      setPhoneNumber(data.mobile);
      setComponent("OTP");
    }
  }, []);

  return {
    getOTP,
    sendOTP,
    sendName,
    loading,
    component,
    error,
    values: {
      otp,
      phoneNumber,
      name,
    },
    handle: {
      handleOTPChange,
      handlePhoneNumberChange,
      handleBack,
      reEnterPhone,
      reSendOTP,
      handleNameChange,
    },
  };
};

export default useLogin;
