import { useEffect } from "react";
import { CloseDrawer } from "../../Helpers/EventEmitter";
import useGetReduxState from "../../Hooks/useGetReduxState";
import Drawer from "../../UI/UIComponents/Drawer";
import Speaker from "../../UI/UIComponents/Speaker";
import audios from "../../UI/UIComponents/Speaker/audios";
import Content from "./Content";
import translations from "./translations";

const Header = () => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        columnGap: "10px",
      }}
    >
      {translations[lang].header}
    </div>
  );
};

const Footer = () => {
  const lang: "hi" | "en" = useGetReduxState().behaviour.lang;
  return (
    <button
      style={{
        border: "none",
        background: "#326abb",
        color: "white",
        width: "100%",
        padding: "15px",
        fontSize: "18px",
        borderRadius: "6px",
        cursor: "pointer",
      }}
      onClick={() => CloseDrawer()}
    >
      {translations[lang].button}
    </button>
  );
};

const NextSteps = ({ handleClose }) => {
  useEffect(() => {
    setTimeout(() => {
      document.getElementById("disha-drawer-2")?.focus();
    }, 500);
  }, []);

  return (
    <Drawer
      header={<Header />}
      footer={<Footer />}
      content={<Content />}
      showCross={true}
      handleCloseDrawer={handleClose}
      showHeaderSeperator={true}
      tatkal={false}
      fullHeight={false}
      onEnter={handleClose}
    />
  );
};

export default NextSteps;
