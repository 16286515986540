import { faVolumeHigh, faVolumeMute } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import { playAudio, stopAudio } from "../../../Store/Dispatcher/behaviour";

const Speaker = ({ sizeBg, sizeIcon, audioUrl, disabled }) => {
  const [playing, setPlaying] = useState(false);
  const audio = useGetReduxState().behaviour.audio;
  const messages = useGetReduxState().messages;
  const loader = useGetReduxState().behaviour.showMessageLoader;
  const current = useGetReduxState().behaviour.fullScreenComponent.tyoe;
  const toggle = () => {
    if (!playing) {
      playAudio({ id: "0000", url: audioUrl });
    } else {
      stopAudio();
    }
    setPlaying((prev) => !prev);
  };

  useEffect(() => {
    setPlaying(audio.current === "0000");
  }, [audio.current]);

  const inputMode = useGetReduxState().behaviour.input_mode;

  useEffect(() => {
    if (
      inputMode === "VOICE" &&
      !disabled &&
      // messages[messages.length - 1] &&
      // (messages[messages.length - 1].type === "COMPONENT" || current?.type) &&
      !loader
    ) {
      toggle();
    }
  }, [audioUrl]);

  return (
    <div
      style={{
        width: sizeBg,
        height: sizeBg,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        background: "white",
        cursor: "pointer",
        flexShrink: 0,
        boxShadow: playing
          ? "rgba(20, 171, 236, 0.37) 0px 2px 4px 0px, rgba(16, 183, 255, 0.32) 0px 2px 16px 0px"
          : "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
      }}
      onClick={toggle}
    >
      <FontAwesomeIcon
        style={{ fontSize: sizeIcon, color: "#4a4a4a" }}
        icon={playing ? faVolumeMute : faVolumeHigh}
      />
    </div>
  );
};

export default Speaker;
