import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  .seperator {
    width: 100%;
    border: 3px solid #f2f2f2;
  }
`;

export default Container;
