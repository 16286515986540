import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  inset: 0;
  background-color: #0000009e;
  z-index: 9999999;

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    width: 90%;
    border-radius: 12px;

    .footer {
      position: static;
      padding: 0;
      transform: none;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 15px;
      width: 100%;
      border-radius: 0;

      .primary {
        width: 100%;
        font-size: 16px;
        background: #326abb;
        border: 1px solid #326abb;
        padding: 10px 0px;
        border-radius: 8px;
        cursor: pointer;
        color: white;
        box-shadow:
          rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
          rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      }

      .secondary {
        width: 100%;
        font-size: 16px;
        background: white;
        border: 1px solid transparent;
        padding: 10px 0px;
        border-radius: 8px;
        cursor: pointer;
        color: black;
        box-shadow:
          rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
          rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
      }
    }

    h1 {
      font-size: 22px;
      font-weight: 500;
      color: #2b2a2a;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 20px;
      font-size: 16px;
      color: #3f3d3d;
      font-weight: 400;
    }
  }
`;

export default Container;
