import { useEffect, useState } from "react";
import useGetReduxState from "../../Hooks/useGetReduxState";
import useSendQuery from "../../Hooks/useSendQuery/useSendQuery";
import { setIsFallback } from "../../Store/Dispatcher/behaviour";
import Disabled from "../Disabled";
import Container from "./styles";
import langText from "./translations";
const Options = () => {
  const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;

  const { sendQueryPayload } = useSendQuery(() => {});
  const [disabled, setDisabled] = useState(false);
  const handleClick = (query) => {
    setDisabled(true);
    sendQueryPayload({ data: null, query: query, inputType: "" });
  };
  const messages = useGetReduxState().messages;
  useEffect(() => {
    if (
      !disabled &&
      messages[messages.length - 1] &&
      messages[messages.length - 1].type !== "COMPONENT"
    )
      setDisabled(true);
  }, [messages]);

  return (
    <Container>
      {disabled && <Disabled />}
      <div className="header">
        <img
          src="https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/clickOption.svg"
          alt="click icon"
        />
        <div>
          <p>{langText[lang].header}</p>
          <span>{langText[lang].subtext}</span>
        </div>
      </div>
      <div className="refund-options">
        <button onClick={() => handleClick(langText[lang].query.cancelled)}>
          {langText[lang].button.cancelled}
        </button>
        <button
          onClick={() => {
            setIsFallback(false);
            handleClick(langText[lang].query.failed);
          }}
        >
          {langText[lang].button.failed}
        </button>
        <button onClick={() => handleClick(langText[lang].query.tdr)}>
          {langText[lang].button.tdr}
        </button>
      </div>
    </Container>
  );
};

export default Options;
