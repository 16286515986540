import useGetReduxState from "../../../Hooks/useGetReduxState";
import Spinner from "../../../UI/UIComponents/Spinner";
import langText from "../translations";
const Footer = ({ loading, component, getOTP, sendOTP, sendName }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  const get = () => {
    if (loading)
      return (
        <Spinner
          borderWidth={"2px"}
          activeColor={"#6495ed"}
          inactiveColor={"#f9f9f9"}
          speed={"1s"}
          size={"28px"}
        />
      );

    if (component === "PHONE")
      return <span>{langText[lang].footer.phone}</span>;
    if (component === "OTP") return <span>{langText[lang].footer.otp}</span>;
    if (component === "NAME") return <span>{langText[lang].footer.name}</span>;
  };

  return (
    <button
      style={{
        width: "100%",
        border: "none",
        background: "#326abb",
        color: "white",
        fontSize: "18px",
        padding: loading ? "10px" : "15px",
        borderRadius: "6px",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        if (loading) return;

        if (component === "PHONE") {
          getOTP();
          return;
        }

        if (component === "OTP") {
          sendOTP();
          return;
        }

        if (component === "NAME") {
          sendName();
          return;
        }
      }}
    >
      {get()}
    </button>
  );
};

export default Footer;
