import React from "react";
import Drawer from "../../../UI/UIComponents/Drawer";
import Content from "./Content";
import Header from "./Header";

const TrainSchedule = ({ train, handleClose }) => {
  return (
    <Drawer
      header={<Header />}
      content={<Content train={train} />}
      footer={<React.Fragment></React.Fragment>}
      handleCloseDrawer={handleClose}
      showCross={true}
      tatkal={false}
      fullHeight={false}
      showHeaderSeperator={true}
    />
  );
};

export default TrainSchedule;
