const translations = {
  en: {
    help: "How may I help you today?",
    greeting: {
      morning: "Good Morning",
      afternoon: "Good Afternoon",
      evening: "Good Evening",
    },
  },
  hi: {
    help: "मैं आपकी कैसे मदद कर सकती हूं?",
    greeting: {
      morning: "शुभ प्रभात",
      afternoon: "शुभ दोपहर",
      evening: "शुभ संध्या",
    },
  },
  gu: {
    help: "હું તમને કેવી રીતે મદદ કરી શકું?",
    greeting: {
      morning: "ગુડ મોર્નિંગ",
      afternoon: "શુભ બપોર.",
      evening: "શુભ સાંજ.",
    },
  },
};

export default translations;
