import styled from "styled-components";

const Container = styled.div`
  padding: 6px 15px;
  text-align: center;
  height: 100%;
  width: 100%;
  h4 {
    font-size: 20px;
    font-weight: 800;
    color: #605f5f;
    margin-bottom: 15px;
  }

  p {
    font-size: 12px;
    font-weight: 500;
    padding: 0px 12px;
    text-align: justify;

    color: #707f8f;
    margin: 0 auto;
    margin-bottom: 18px;
    line-height: 1.3;
    margin-top: 10px;
  }

  .secondary {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;

    p {
      margin: 0;
      padding: 0;
      font-size: 10px;
      color: #3c3c3c;
      text-align: left;
      margin-right: 5px;
    }

    a {
      text-decoration: none;
      font-size: 10px;
      background: white;
      border: none;
      cursor: pointer;
      color: cornflowerblue;

      font-weight: 700;
    }
  }
`;

export default Container;
