import { faGift } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useGetReduxState from "../../../../Hooks/useGetReduxState";
const train =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/book.svg";
const disha =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/thumbs-refactored.png";

const langText = {
  hi: {
    congrats: {
      isAllotted: "बधाई हो!",
      waiting: "कृपया प्रतीक्षा करें",
    },
    booked: {
      isAllotted: "आपकी टिकट बुक हो गई है।",
      waiting: "आपकी बुकिंग प्रक्रिया में है",
    },
    statusLabel: "बुकिंग स्टेटस",
    message: {
      isAllotted:
        "कोच, बर्थ और सीटें आवंटित की जा चुकी हैं। मैं आपकी सुखद और सुरक्षित यात्रा की कामना करती हूं।",
      isNotAllotted: "कोच/बर्थ नंबर चार्ट तैयार होने के बाद उपलब्ध होगा।",
      partial:
        "आंशिक रूप से कन्फर्म टिकट पर प्रतीक्षा सूची वाले यात्रियों को चार्ट तैयार होने के बाद स्वचालित रूप से रद्द नहीं किया जाता है। उपयोगकर्ता बोर्डिंग स्टेशन से प्रस्थान करने से 30 मिनट पहले तक TDR रद्द/फाइल कर सकता है।",
      waiting:
        "अगर चार्ट बनने के बाद भी आपका टिकट कन्फर्म नहीं होता है तो आपका टिकट अपने आप कैंसिल हो जाएगा।",
    },
    voucher: "अपना वाउचर प्राप्त करें",
  },
  en: {
    congrats: {
      isAllotted: "Congratulations!",
      waiting: "Please Wait",
    },
    booked: {
      isAllotted: "Your Ticket is booked.",
      waiting: "Your booking is in process",
    },
    statusLabel: "Booking Status",
    message: {
      isAllotted:
        "Coach, Berth and Seats have been alloted. I wish you a happy and safe journey.",
      isNotAllotted:
        "Coach/Berth Number will be available after chart preparation.",
      partial:
        "Wait Listed passengers on a partially confirmed ticket do not get cancelled automatically after chart preparation. User can cancel/file TDR up to 30 minutes before departure from boarding station.",
      waiting:
        "If your ticket is not confirmed after chart preparation, your ticket will be cancelled automatically.",
    },
    voucher: "Get your free voucher",
  },
  gu: {
    congrats: {
      isAllotted: "અભિનંદન!",
      waiting: "કૃપા કરીને રાહ જુઓ",
    },
    booked: {
      isAllotted: "તમારી ટિકિટ બુક થઈ ગઈ છે.",
      waiting: "તમારી બુકિંગ પ્રક્રિયામાં છે",
    },
    statusLabel: "આરક્ષણની સ્થિતિ",

    message: {
      isAllotted:
        "કોચ, બર્થ અને સીટોની ફાળવણી કરવામાં આવી છે. હું તમને સુખદ અને સુરક્ષિત યાત્રાની શુભેચ્છા પાઠવું છું.",

      isNotAllotted: "ચાર્ટ તૈયાર કર્યા પછી કોચ/બર્થ નંબર ઉપલબ્ધ થશે.",

      partial:
        "આંશિક રીતે પુષ્ટિ થયેલ ટિકિટ પર રાહ જોનારા મુસાફરો ચાર્ટ તૈયાર કર્યા પછી આપમેળે રદ થતા નથી. વપરાશકર્તા બોર્ડિંગ સ્ટેશનથી પ્રસ્થાનના 30 મિનિટ પહેલાં સુધી TDR રદ/ફાઇલ કરી શકે છે.",

      waiting:
        "જો ચાર્ટ તૈયાર થયા પછી તમારી ટિકિટની પુષ્ટિ ન થાય, તો તમારી ટિકિટ આપમેળે રદ થઈ જશે.",
    },

    voucher: "તમારું મફત વાઉચર મેળવો",
  },
};

const Header = ({ pnr, status, isAllotted }) => {
  const lang = useGetReduxState().behaviour.lang;
  const getStatus = () => {
    if (status === "CNF")
      return {
        main: "rgb(10, 206, 10)",
        status: "Confirmed",
        bg: "#e0f9e0",
        color: "green",
        message: isAllotted
          ? langText[lang].message.isAllotted
          : langText[lang].message.isNotAllotted,
        congrats: langText[lang].congrats.isAllotted,
        booked: langText[lang].booked.isAllotted,
      };
    if (status === "PARTIAL")
      return {
        main: "#ff9f00",
        status: "Partial Confirmed",
        bg: "#ffefdc",
        color: "orange",
        message: langText[lang].message.partial,
        congrats: langText[lang].congrats.waiting,
        booked: langText[lang].booked.waiting,
      };
    else
      return {
        main: "tomato",
        status: "In Waiting List",
        bg: "#ffe0e0",
        color: "tomato",
        message: langText[lang].message.waiting,
      };
  };

  const statusObj = getStatus();

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img style={{ width: "120px" }} src={train} alt="" />
          <img
            style={{ width: "90px", marginLeft: "-45px", marginTop: "70px" }}
            src={disha}
            alt=""
          />
        </div>
        <div style={{ textAlign: "center", marginTop: "-10px" }}>
          <p
            style={{
              margin: "0",
              fontSize: "20px",
              fontWeight: "600",
              color: "#0b74fa",
            }}
          >
            {statusObj.congrats}
          </p>
          <p
            style={{
              margin: "0",
              fontSize: "16px",
              fontWeight: "500",
              color: "darkslategray",
              marginTop: "3px",
            }}
          >
            {statusObj.booked}
          </p>
          <p
            style={{
              margin: "0",
              fontSize: "15px",
              fontWeight: "500",
              color: "#FA8072	",
              marginTop: "7px",
            }}
          >
            PNR: {pnr}
          </p>
          <p
            style={{
              margin: 0,
              marginTop: "10px",
              fontSize: "14px",
              color: "darkslategray",
            }}
          >
            {langText[lang].statusLabel}
          </p>
          <p
            style={{
              margin: "0px 0px 0px",
              fontSize: "16px",
              color: statusObj.main,
              fontWeight: "700",
              border: "1px solid",
              borderRadius: "5px",
              marginTop: "5px",
            }}
          >
            {statusObj.status}
          </p>
        </div>
      </div>
      <p
        style={{
          margin: "5px 0px 0px",
          lineHeight: "14px",
          fontSize: "14px",
          textAlign: "center",
          backgroundColor: statusObj.bg,
          color: statusObj.color,
          padding: "8px 16px",
          borderRadius: "5px",
        }}
      >
        {statusObj.message}
      </p>
      <a
        style={{
          textDecoration: "none",
          color: "#fd2d74",
          fontSize: "16px",
          borderRadius: "6px",
          fontWeight: "600",
          margin: "0 auto",
          display: "block",
          marginTop: "10px",
          textAlign: "center",
        }}
        href="http://bit.ly/3gBANx7"
        target="_blank"
      >
        {" "}
        <FontAwesomeIcon icon={faGift} /> {langText[lang].voucher}
      </a>
    </>
  );
};

export default Header;
