import { ReactChild, useEffect, useRef, useState } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";
import ReactDOM from "react-dom";
import EventEmitter from "../../../Helpers/EventEmitter";
import { setUpcomingBookings } from "../../../Store/Dispatcher/behaviour";

type propTypes = {
  header: ReactChild;
  content: ReactChild;
  footer: ReactChild;
  handleCloseDrawer(): void;
  showCross: boolean;
  fullHeight: boolean;
  tatkal: boolean;
  showHeaderSeperator: boolean;
  onEnter?(): void;
};
const Drawer = (props: propTypes) => {
  const [hidden, setHidden] = useState(false);
  const [hidden2, setHidden2] = useState(false);
  const [fixedHeight, setFixedHeight] = useState("0px");
  const [bottomHeight, setBottomHeight] = useState("0px");
  const ref = useRef();
  const [id, setId] = useState(1);

  const closDrawer = () => {
    setHidden(true);
    setUpcomingBookings(false);
    // if(!props.tatkal){
    setTimeout(() => {
      setHidden2(!hidden2);
      props.handleCloseDrawer();
    }, 500);
    // }
    // else{
    //   setTimeout(() => {
    //     setHidden2(!hidden2);
    //     props.handleCloseDrawer();
    //   }, 3500);
    // }
  };

  const closeListener = () => {
    let lastDrawer = Array.from(
      document.querySelectorAll(".drawer-overlay")
    ).slice(-1)[0] as HTMLDivElement;

    if (lastDrawer === ref.current) closDrawer();
  };

  const closeAllListener = () => {
    closDrawer();
  };

  useEffect(() => {
    let len = Array.from(document.querySelectorAll(".drawer-overlay")).length;

    setId(len);

    let h = parseInt(
      window
        .getComputedStyle(
          Array.from(document.querySelectorAll("#drawer-header")).slice(
            -1
          )[0] as HTMLDivElement
        )
        .height.split("px")[0]
    );

    let f = parseInt(
      window
        .getComputedStyle(
          Array.from(document.querySelectorAll("#drawer-footer")).slice(
            -1
          )[0] as HTMLDivElement
        )
        .height.split("px")[0]
    );
    setBottomHeight(f + "px");
    setFixedHeight(h + f + "px");

    const listener = EventEmitter.addListener("HIDE_DRAWER", closeListener);
    const listener2 = EventEmitter.addListener(
      "HIDE_ALL_DRAWER",
      closeAllListener
    );

    let iden = `disha-drawer-${id}`;

    document.getElementById(iden)?.focus();

    return () => {
      listener.remove();
      listener2.remove();
    };
  }, []);

  //   useEffect(() => {
  //     if (close) {
  //       closDrawer();
  //     }
  //   }, [close]);

  return ReactDOM.createPortal(
    <div
      id={`disha-drawer-${id}`}
      className="drawer-overlay"
      style={{ overflow: "hidden" }}
      ref={ref}
      tabIndex={1}
      onKeyDown={(e) => {
        if (e.key === "Enter") props.onEnter();
        e.stopPropagation();
      }}
    >
      <div className={`drawer-anim ${hidden ? "close" : "open"}`}>
        <div
          style={{
            position: "absolute",
            bottom: bottomHeight,
            width: "100%",
            height: "95%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            // overflowY: "h",
            // borderTopLeftRadius: "12px",
            // borderTopRightRadius: "12px",
            // borderTop: "50px solid white",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              padding: "12px 15px",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
              backgroundColor: "white",
              borderBottom: props.showHeaderSeperator
                ? "1px solid gainsboro"
                : "none",
              position: "relative",
            }}
            id="drawer-header"
          >
            {/* <p style={{ margin: "0", fontSize: "18px", fontWeight: "500" }}>
            Add Adult <span>(0 to 4 years)</span>
          </p> */}
            {props.header}

            {props.showCross && (
              <FontAwesomeIcon
                icon={faClose}
                style={{
                  color: "rgb(92, 91, 91)",
                  fontSize: "20px",
                  cursor: "pointer",
                  position: "absolute",
                  top: "10px",
                  right: "12px",
                }}
                onClick={closDrawer}
              />
            )}
          </div>
          <div
            className="drawer-scroll-container"
            style={{
              backgroundColor: "white",
              width: "100%",
              maxHeight: `calc(100% - ${fixedHeight})`,
              height: props.fullHeight ? `100%` : "fit-content",
              overflowY: "auto",
              overflowX: "hidden",

              // borderTop: "50px solid transparent",
            }}
          >
            {props.content}
          </div>
        </div>
        <div
          id="drawer-footer"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: "absolute",

            bottom: "0px",
            backgroundColor: "white",
            zIndex: 999999,
            padding: "4px 8px",
          }}
        >
          {props.footer}
        </div>
      </div>
    </div>,
    document.getElementById("corover-body")
  );
};

export default Drawer;
