export const setIrctcConfig = (value) => {
  const action = { type: "SET_IRCTC_CONFIG", value: value };
  return action;
};

export const setGstDetails = (value) => {
  const action = { type: "SET_GST_DETAILS", value: value };
  return action;
};

export const setAdditionalDetails = (value) => {
  const action = { type: "SET_ADDITIONAL_DETAILS", value: value };
  return action;
};

export const setJourneyPreferences = (value) => {
  const action = { type: "SET_JOURNEY_PREFERENCES", value: value };
  return action;
};

export const setPassengers = (value) => {
  const action = { type: "SET_PASSENGERS", value: value };
  return action;
};

export const setSavedPassengers = (value) => {
  const action = { type: "SET_SAVED_PASSENGERS", value: value };
  return action;
};

export const setPassengerFormStep = (value) => {
  const action = { type: "SET_CURRENT_PASSENGER_FORM_STEP", value: value };
  return action;
};

export const setInputOTP = (value) => {
  const action = { type: "SET_OTP", value: value };
  return action;
};

export const setVerifyOTPData = (value) => {
  const action = { type: "SET_OTP_DATA", value: value };
  return action;
};

export const setBookingData = (value) => {
  const action = { type: "SET_BOOOKING_DATA", value: value };
  return action;
};

export const setShowBookingSteps = (value) => {
  const action = { type: "SET_SHOWBOOKING_STEPS", value: value };
  return action;
};

export const setOtpView = (value) => {
  const action = { type: "SET_OTP_VIEW", value: value };
  return action;
};

export const setShowNextSteps = (value) => {
  const action = { type: "SET_SHOWNEXT_STEPS", value: value };
  return action;
};

export const setCurrentBookingStep = (value) => {
  const action = { type: "SET_CURRENT_BOOKING_STEP", value: value };
  return action;
};

export const setPaymentData = (value) => {
  const action = { type: "SET_PAYMENT_DATA", value: value };
  return action;
};
