const translations = {
  en: {
    header: "Ticket Cancelled",
    pnr: "PNR",
    cancelId: "Cancellation Id",
    date: "Date of Cancellation",
    time: "Time of Cancellation",
    paidAmount: "Total Amount Paid",
    deductedAmount: "Total Amount Deducted",
    refundAmount: "Refundable Amount",
    passengers: "Cancelled Passengers",
  },
  hi: {
    header: "टिकट रद्द",
    pnr: "PNR",
    cancelId: "कैंसलेशन Id",
    date: "रद्द करने की तिथि",
    time: "रद्द करने का समय",
    paidAmount: "भुगतान की गई राशि",
    deductedAmount: "काटी गई राशि",
    refundAmount: "वापसी योग्य राशि",
    passengers: "रद्द किए गए यात्री",
  },
  gu: {
    header: "ટિકિટ રદ કરવી",
    pnr: "PNR",
    cancelId: "રદ કરવાની ઓળખ",
    date: "રદ કરવાની તારીખ",
    time: "રદ કરવાનો સમય",
    paidAmount: "ચૂકવેલ રકમ",
    deductedAmount: "કાપવામાં આવેલી રકમ",
    refundAmount: "રિફંડપાત્ર રકમ",
    passengers: "રદ કરાયેલા મુસાફરો",
  },
};

export default translations;
