import { useEffect } from "react";
import { useSelector } from "react-redux";
import { stopAudio } from "../../../../Store/Dispatcher/behaviour";
import { RootState } from "../../../../Store/Reducers";
import HomeScreen from "./Components/Home";
import Messages from "./Components/Messages";
import Container from "./styles";
import QuickButtons from "./Components/Messages/QuickButtons";

const Content = () => {
  const messages = useSelector((state: RootState) => state.messages);

  useEffect(() => {
    let audioPlayer: any = document.getElementById(
      "myaudio",
    ) as HTMLAudioElement;

    audioPlayer.addEventListener(
      "ended",
      () => {
        stopAudio();
      },
      false,
    );
  }, []);
  return (
    <Container>
      {messages.length > 0 ? (
        <>
          {" "}
          <Messages messages={messages} />{" "}
        </>
      ) : (
        <HomeScreen />
      )}
    </Container>
  );
};

export default Content;
