import styled from "styled-components";

const HeaderContainer = styled.div`
  height: auto;
  background-color: orange;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  background-color: #032842;
  background-image: url(https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/header-refactor.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .bg {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #285c9d9c;
    padding: 0.625rem 0.875rem;
  }

  .first-column {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      display: block;
    }
  }

  .buttons {
    margin-right: 0.5rem;
  }

  .btn-menu {
    width: 1.25rem;
    cursor: pointer;
    margin-bottom: 0.625rem;
  }

  .btn-reset {
    width: 1.5rem;
    cursor: pointer;
  }

  .disha-logo {
    width: 3.625rem;
  }

  .header-text {
    p {
      font-size: 1rem;
      font-weight: 500;
      margin-left: 0.313rem;
    }
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-left: 0.25rem;
    }
    span {
      font-size: 0.875rem;
      font-weight: 500;
      display: block;
      margin-left: 0.313rem;
      line-height: 0.9;
      color: #fffa71;
    }
  }

  .beta {
    width: 3.75rem;
    display: none;
  }

  .second-column {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 0.625rem;
  }
`;

export default HeaderContainer;
