const translations = {
  en: {
    refund: {
      text: "Refund Status",
      subtext: "Check your refund status.",
      query: "Check Refund Status",
    },
    book: {
      text: "Book Ticket",
      subtext: "Book your e-ticket.",
      query: "Book Ticket",
    },
    pnr: {
      text: "PNR Status",
      subtext: "Check your PNR status.",
      query: "Check PNR Status",
    },
    cancel: {
      text: "Cancel Ticket",
      subtext: "Cancel your ticket.",
      query: "Cancel My Ticket",
    },
    history: {
      text: "Booking History",
      subtext: "Track your bookings.",
      query: "Booking History",
    },
    boarding: {
      text: "Change Boarding",
      subtext: "Change your boarding station.",
      query: "Change My Boarding Station",
    },
    failed: {
      text: "Failed Bookings",
      subtext: "Change your boarding station.",
      query: "My Failed Transactions",
    },
    faq: {
      text: "Ask Your Query",
      subtext: "Frequently Asked Questions",
      query: "",
    },
    tdr: {
      text: "File TDR",
      subtext: "Frequently Asked Questions",
      query: "File TDR",
    },
    feedback: {
      text: "Help Me Improve",
      subtext: "Frequently Asked Questions",
      query: "HMI",
    },
    passengers: {
      text: "My Passengers",
    },
    tourism: {
      text: "Tour Packages",
    },
    food: {
      text: "Order Food",
    },
  },
  hi: {
    refund: {
      text: "रिफंड की स्थिति",
      subtext: "अपनी धनवापसी स्थिति जांचें",
      query: "मुझे रिफंड स्तिथि की जांच करनी है",
    },
    book: {
      text: "टिकट बुक",
      subtext: "अपनी ई-टिकट बुक करें",
      query: "टिकट बुक करो",
    },
    pnr: {
      text: "PNR की स्थिति",
      subtext: "अपने PNR की स्थिति देखें",
      query: "PNR की स्थिति दिखाओ",
    },
    cancel: {
      text: "टिकट रद्द करें",
      subtext: "अपनी टिकट रद्द करें",
      query: "टिकट रद्द करो",
    },
    history: {
      text: "बुकिंग इतिहास",
      subtext: "अपनी सभी बुकिंग देखें",
      query: "बुकिंग इतिहास",
    },
    boarding: {
      text: "बोर्डिंग बदलें",
      subtext: "अपना बोर्डिंग स्टेशन बदलें",
      query: "मेरा बोर्डिंग बदलो",
    },
    failed: {
      text: "असफल ट्रांसक्शन्स",
      subtext: "Change your boarding station.",
      query: "मेरी असफल ट्रांसक्शन्स दिखाओ",
    },
    tdr: {
      text: "TDR फाइल करें",
      subtext: "Frequently Asked Questions",
      query: "TDR फाइल",
    },
    faq: {
      text: "अपना प्रश्न पूछें",
      subtext: "Frequently Asked Questions",
      query: "",
    },
    feedback: {
      text: "मुझे सुधारने में मदद करें",
      subtext: "Frequently Asked Questions",
      query: "HMI",
    },
    passengers: {
      text: "मेरी यात्री सूची",
    },
    tourism: {
      text: "टूर पैकेजेस",
    },
    food: {
      text: "खाना ऑर्डर करें",
    },
  },
  gu: {
    refund: {
      text: "રિફંડની સ્થિતિ",
      subtext: "તમારા રિફંડની સ્થિતિ તપાસો.",
      query: "મારે રિફંડની સ્થિતિ તપાસવાની છે.",
    },
    book: {
      text: "ટિકિટ બુક",
      subtext: "તમારી ઇ-ટિકિટ બુક કરો",
      query: "ટિકિટ બુક કરો",
    },
    pnr: {
      text: "પી. એન. આર. સ્થિતિ",
      subtext: "તમારા પી. એન. આર. ની સ્થિતિ તપાસો.",
      query: "પી. એન. આર. સ્થિતિ બતાવો",
    },
    cancel: {
      text: "ટિકિટ રદ કરો",
      subtext: "તમારી ટિકિટ રદ કરો",
      query: "ટિકિટ રદ કરો",
    },
    history: {
      text: "બુકિંગનો ઈતિહાસ",
      subtext: "તમારી તમામ બુકીંગ જુઓ.",
      query: "બુકિંગનો ઈતિહાસ",
    },
    boarding: {
      text: "બોર્ડિંગ બદલો",
      subtext: "તમારું બોર્ડિંગ સ્ટેશન બદલો",
      query: "મારું બોર્ડિંગ બદલો",
    },
    failed: {
      text: "નિષ્ફળ વ્યવહારો",
      subtext: "તમારું બોર્ડિંગ સ્ટેશન બદલો.",
      query: "મારા નિષ્ફળ વ્યવહારો બતાવો",
    },
    tdr: {
      text: "ફાઇલ TDR",
      subtext: "Frequently Asked Questions",
      query: "ટીડીઆર ફાઇલ",
    },
    faq: {
      text: "તમારા પ્રશ્ન પૂછો",
      subtext: "Frequently Asked Questions",
      query: "",
    },
    feedback: {
      text: "મને સુધારવામાં મદદ કરો.",
      subtext: "Frequently Asked Questions",
      query: "HMI",
    },
    passengers: {
      text: "મારી પ્રવાસી યાદી",
    },
    tourism: {
      text: "પ્રવાસ પેકેજો",
    },
    food: {
      text: "ખાવાનું મંગાવો",
    },
  },
};

export default translations;
