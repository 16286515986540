import { useEffect } from "react";
import Drawer from "../../../UI/UIComponents/Drawer";
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";

const Confirmation = ({ handleClose, handleConfirm, confirmation }) => {
  const handleBook = () => {
    handleConfirm({ train: confirmation.train, ticket: confirmation.ticket });
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("disha-drawer-2")?.focus();
    }, 500);
  }, []);

  return (
    <Drawer
      content={
        <Content train={confirmation.train} ticket={confirmation.ticket} />
      }
      header={<Header />}
      footer={<Footer handleConfirm={handleBook} />}
      showCross={true}
      showHeaderSeperator={true}
      tatkal={false}
      fullHeight={false}
      handleCloseDrawer={handleClose}
      onEnter={handleBook}
    />
  );
};

export default Confirmation;
