import useGetReduxState from "../../../Hooks/useGetReduxState";

const Header = () => {
  const lang = useGetReduxState().behaviour.lang;
  if (lang === "en") return <div>Edit your Journey</div>;
  if (lang === "hi") return <div>अपनी यात्रा एडिट करें</div>;
  if (lang === "gu") return <div>તમારી યાત્રાને સંપાદિત કરો</div>;
};

export default Header;
