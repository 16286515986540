import { useEffect } from "react";
import { useState } from "react";
import useGetReduxState from "../../Hooks/useGetReduxState";

import {
  setIrctcConfig,
  setJourneyPreferences,
} from "../../Store/Dispatcher/passengerForm";
import IrctcUserId from "./Steps/IrctcUserId";
import Passengers from "./Steps/Passengers";
import Preferences from "./Steps/Preferences";
import Container from "./styles";
import { useElementSize } from "@mantine/hooks";

import { parseIrctcConfig } from "./utils";
import Disabled from "../Disabled";
import langText from "./translations";
import React from "react";
import Speaker from "../../UI/UIComponents/Speaker";
import audios from "../../UI/UIComponents/Speaker/audios";
import { toggleLoader } from "../../Store/Dispatcher/behaviour";
const passengerHeader =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/luggage2-refactored.png";
const prefheader =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/pref-refactored.png";

const Passengerform = ({ data }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const { ref, height } = useElementSize();
  const messages = useGetReduxState().messages;
  const loader = useGetReduxState().behaviour.showMessageLoader;
  const scrollToBottom = () => {
    document
      .getElementById("corover-messages-box")
      ?.scrollIntoView({ behavior: "auto", block: "end" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [height]);

  const currentStep = useGetReduxState().passengerform.currentPassengerFormStep;
  const current = useGetReduxState().behaviour.current;
  const [disabled, setDisabled] = useState(false);
  const preferences = useGetReduxState().passengerform.journeyPreferences;
  const journey = useGetReduxState().journey;
  useEffect(() => {
    setIrctcConfig(parseIrctcConfig(data));
    setJourneyPreferences({
      ...preferences,
      boardingStation: journey.boardingStation,
    });
  }, []);

  useEffect(() => {
    if (loader) toggleLoader();
  }, [loader]);

  useEffect(() => {
    if (!disabled && current !== "SELECT_PASSENGERS") setDisabled(true);
  }, [current]);

  const getHeader = () => {
    if (currentStep === 1)
      return (
        <React.Fragment>
          <div className="first-column">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#eef0ff",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                padding: "4px",
                marginRight: "10px",
              }}
            >
              <img
                src="https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/irctc.png"
                alt=""
                style={{ width: "70%" }}
              />
            </div>
            <div>
              <p
                style={{
                  color: "#343434",
                  fontSize: "14px",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  columnGap: "12px",
                  marginTop: "4px",
                }}
              >
                {langText[lang].headers[1].head}
              </p>
              <span
                style={{
                  color: "#959595",
                  fontSize: "10px",
                  display: "block",
                  marginTop: "3px",
                }}
              >
                {langText[lang].headers[1].text}
              </span>
            </div>
          </div>

          <p className="step">
            {langText[lang].headers.step} ( 1 / 3 )
            {!disabled && (
              <Speaker
                sizeBg={"22px"}
                sizeIcon={"10px"}
                audioUrl={audios[lang].IRCTC_ID}
                disabled={disabled}
              />
            )}
          </p>
        </React.Fragment>
      );
    if (currentStep === 2)
      return (
        <React.Fragment>
          <div className="first-column">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#eef0ff",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                padding: "4px",
                marginRight: "10px",
              }}
            >
              <img src={passengerHeader} alt="" style={{ width: "90%" }} />
            </div>
            <div>
              <p
                style={{
                  color: "#343434",
                  fontSize: "14px",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  columnGap: "12px",
                }}
              >
                {langText[lang].headers[2].head}
              </p>
              <span
                style={{
                  color: "#959595",
                  fontSize: "10px",
                  display: "block",
                  marginTop: "3px",
                }}
              >
                {langText[lang].headers[2].text}
              </span>
            </div>
          </div>

          <p className="step">
            {langText[lang].headers.step} ( 2 / 3 )
            {!disabled && (
              <Speaker
                sizeBg={"22px"}
                sizeIcon={"10px"}
                audioUrl={audios[lang].SELECT_PASSENGERS}
                disabled={disabled}
              />
            )}
          </p>
        </React.Fragment>
      );
    if (currentStep === 3)
      return (
        <React.Fragment>
          <div className="first-column">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#eef0ff",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                padding: "4px",
                marginRight: "10px",
              }}
            >
              <img src={prefheader} alt="" style={{ width: "70%" }} />
            </div>
            <div>
              <p
                style={{
                  color: "#343434",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                {langText[lang].headers[3].head}
              </p>
              <span
                style={{
                  color: "#959595",
                  fontSize: "10px",
                  display: "block",
                  marginTop: "3px",
                }}
              >
                {langText[lang].headers[3].text}
              </span>
            </div>
          </div>

          <p className="step">
            {langText[lang].headers.step} ( 3 / 3 )
            {!disabled && (
              <Speaker
                sizeBg={"22px"}
                sizeIcon={"10px"}
                audioUrl={audios[lang].SELECT_PREFERNCES}
                disabled={disabled}
              />
            )}
          </p>
        </React.Fragment>
      );
  };

  useEffect(() => {
    if (
      !disabled &&
      messages[messages.length - 1] &&
      messages[messages.length - 1].type !== "COMPONENT"
    )
      setDisabled(true);
  }, [messages]);

  return (
    <Container ref={ref}>
      {disabled && <Disabled />}
      <div className="header">{getHeader()}</div>
      <div style={{ height: "calc(100% - 47px)" }}>
        {currentStep === 1 && <IrctcUserId />}

        {currentStep === 2 && <Passengers />}

        {currentStep === 3 && <Preferences />}
      </div>
    </Container>
  );
};

export default Passengerform;
