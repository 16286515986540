import { getRefundOfFailed } from "../Api/utils";
import { CloseDrawer } from "../Helpers/EventEmitter";
import {
  setIsRefund,
  setNextContext,
  setPrevCode,
  toggleLoader,
} from "../Store/Dispatcher/behaviour";
import { addCustomResponseMessage } from "../Store/Dispatcher/messages";
import RefundStatus from "../Templates/RefundStatus";
// import useGetReduxState from "./useGetReduxState";
import useSendQuery from "./useSendQuery/useSendQuery";
// import langText from "./useButtonQueries/translations";

const useDirectQueries = ({ booking }) => {
  // const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const inputType = "";
  const { sendQueryPayload } = useSendQuery(() => {});

  const sendQuery = () => {
    CloseDrawer();
    sendQueryPayload({
      query: booking.pnr,
      data: {
        pnr: booking.pnr,
        trainNumber: booking.trainNumber,
        journeyDate: booking.date,
        source: booking.fromStn,
        destination: booking.destStn,
        class: booking.journeyClass,
      },
      inputType: inputType,
    });
    setTimeout(() => {
      setIsRefund(false);
    }, 2000);
  };

  const sendQueryRefund = async () => {
    CloseDrawer();
    let data = await getRefundOfFailed(booking.orderId);

    data = { isForFailed: true, ...data };

    toggleLoader();
    setTimeout(() => {
      toggleLoader();
      addCustomResponseMessage({
        component: <RefundStatus data={data} />,
        showAvatar: false,
      });
    }, 1500);
  };

  const getCancel = () => {
    setNextContext("e02d4aa2-678c-4c83-a199-58e25adb8dc8,2");
    sendQuery();
  };

  const getPNR = () => {
    setNextContext("1918abb8-6b17-426e-a79e-990c3b194c9a,2");
    sendQuery();
  };

  const checkRefundCancelled = () => {
    setIsRefund(true);
    setPrevCode(
      "Az2hxb1Bj1c732gJY1SXA6kPN0wJvxREXepo8t+IHjn7k28ZXlqdTKPdAtE2AK9DIMZMBMhEcXKjoXV8+168xC0/4xYCIHvpD+cR3glGBKp4yRcAyWUuaJmiybSqlq+3D5IWfX7/7BhLXtOXM/nKSkWbbMFJb+0ziiEiR9Jh/fY=",
    );

    sendQuery();
  };

  const checkRefundFailed = () => {
    setNextContext("06d8305e-0a87-47eb-834d-a86734de5892,2");
    sendQueryRefund();
  };

  const changeBoarding = () => {
    setNextContext("68bce99a-844d-11ec-a8a3-0242ac120002,2");
    sendQuery();
  };

  const fileTDR = () => {
    setNextContext("a154264e-3335-4796-9e1d-37b91d303040,2");
    sendQuery();
  };

  return {
    getCancel,
    getPNR,
    changeBoarding,
    checkRefundCancelled,
    checkRefundFailed,
    fileTDR,
  };
};

export default useDirectQueries;
