import styled from "styled-components";

const Container = styled.div`
  width: 98%;
  margin: 0px auto;
  margin-bottom: 20px;
  /* padding: 12px 15px; */
  border: 1px solid gainsboro;
  border-radius: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  > * {
    line-height: 1;
    font-family: "Roboto", sans-serif;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .seperator {
    width: 100%;
    border: 2px solid #f2f2f2;
  }

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
    padding: 10px 16px;
    border-bottom: 1px solid gainsboro;

    img {
      width: 26px;
    }

    p {
      color: rgb(63, 68, 71);
      font-weight: 500;
      font-size: 16px;
      margin: 0;
    }
  }

  .first-details {
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;

    div {
      p {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    div:nth-child(1) {
      color: #3d3d3d;
      font-weight: 500;
      margin-bottom: 5px;
      font-size: 14px;
    }

    div:nth-child(2) {
      font-size: 14px;
      margin-bottom: 5px;
      color: darkslategray;
    }
  }

  .money {
    padding: 15px;

    p {
      font-size: 16px;
      color: #606d79;

      &:nth-child(1) {
        margin-bottom: 10px;
      }
      span {
        font-weight: 500;
      }
    }

    .hr {
      width: 100%;
      border-top: 1px solid #b7b7b7;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    h2 {
      font-size: 18px;
      font-weight: 400;
      color: #73a33d;

      span {
        font-weight: 500;
      }
    }
  }

  .passengers {
    padding: 15px;

    h4 {
      font-size: 16px;
      color: #444444;
      font-weight: 500;
    }

    p {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #6d6d6d;
    }
  }
`;

export default Container;
