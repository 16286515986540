import { faMicrophone, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Microphone } from "../../../Chatbot/Microphone";

import Container from "./styles";

const IconTextBox = ({
  value,
  onChange,
  placeholder,
  icon,
  handleVoice,
  content,
}) => {
  const [mic, setMic] = useState(false);

  return (
    <Container>
      <FontAwesomeIcon
        icon={faUser}
        style={{ fontSize: "18px", color: "dimgray" }}
      />
      <img src={icon} alt="" />
      <input
        value={value}
        onChange={onChange}
        type="text"
        placeholder={placeholder}
        autoFocus
      />

      <FontAwesomeIcon
        style={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          right: "10px",
          fontSize: "20px",
          color: "#2d69c0",
          cursor: "pointer",
        }}
        icon={faMicrophone}
        onClick={() => setMic(true)}
      />
      {mic && (
        <Microphone
          useEnglish={true}
          callback={(value) => {
            handleVoice(value);
            setMic(false);
          }}
          onClose={() => setMic(false)}
          content={content}
        />
      )}
    </Container>
  );
};

export default IconTextBox;
