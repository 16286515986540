import axios from "axios";

let cancelToken;

export const getPinDetails = async (pin, city) => {
  if (cancelToken) {
    cancelToken.cancel("Operation cancelled due to new request");
  }
  cancelToken = axios.CancelToken.source();
  const url = `/dishaAPI/bot/pin/${pin}${city ? `?city=${city}` : ""}`;

  return axios
    .get(url)
    .then((response) => response.data)
    .catch((_) => ({ error: true }));
};
