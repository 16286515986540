import store from "..";
import Fuse from "fuse.js";
import {
  HIDE_ALSO_TRY,
  PLAY_AUDIO,
  SET_APP_LANGUAGE,
  SET_CURRENT,
  SET_CXTOKEN,
  SET_DEVICE_ID,
  SET_FALLBACK,
  SET_IS_REFUND,
  SET_NEXT_CONTEXT,
  SET_PREV_CODE,
  SET_QUERY_LANGUAGE,
  SET_SESSION_ID,
  SET_STATUS,
  SET_USER_TOKEN,
  SHOW_ALSO_TRY,
  STOP_AUDIO,
  TOGGLE_MESSAGE_LOADER,
  SET_UPCOMING_BOOKINGS,
} from "./constants";
import { playAudioAction } from "./types";
import { ReactChild } from "react";

export const toggleMessageLoader = () => {
  const action = { type: TOGGLE_MESSAGE_LOADER };
  return action;
};

export const playMessageAudio = ({ id, url }: { id: string; url: string }) => {
  const action: playAudioAction = {
    type: PLAY_AUDIO,
    id: id,
    url: url,
  };
  return action;
};

export const stopMessageAudio = () => {
  const action = {
    type: STOP_AUDIO,
  };
  return action;
};

export const setAppLanguage = (lang) => {
  const action = {
    type: SET_APP_LANGUAGE,
    lang: lang,
  };
  return action;
};

export const setQueryLanguage = (lang) => {
  const action = {
    type: SET_QUERY_LANGUAGE,
    lang: lang,
  };
  return action;
};

export const showAlsoTry = (query) => {
  let faqs = store.getState().faqs;
  let lang = store.getState().behaviour.lang;

  let temp: any = [];
  faqs[lang].forEach((element) => {
    temp.push({
      label: element,
      value: element,
    });
  });
  let fuse = new Fuse(temp, {
    keys: ["label", "value"],
  });
  let data = fuse.search(query);
  let result: any = [];
  data.forEach((element) => {
    result.push(element.item);
  });

  const action = {
    type: SHOW_ALSO_TRY,
    questions: result.slice(1, 6).map((element) => element.label),
  };
  return action;
};

export const hideAlsoTry = () => {
  const action = { type: HIDE_ALSO_TRY };
  return action;
};

export const setUserToken = (value) => {
  const action = { type: SET_USER_TOKEN, value: value };
  return action;
};

export const setCxToken = (value) => {
  const action = { type: SET_CXTOKEN, value: value };
  return action;
};

export const setNextContext = (value) => {
  const action = { type: SET_NEXT_CONTEXT, value: value };
  return action;
};

export const setSessionId = (value) => {
  const action = { type: SET_SESSION_ID, value: value };
  return action;
};

export const setDeviceId = (value) => {
  const action = { type: SET_DEVICE_ID, value: value };
  return action;
};

export const setPrevCode = (value) => {
  const action = { type: SET_PREV_CODE, value: value };
  return action;
};

export const setStatus = (value) => {
  const action = { type: SET_STATUS, value: value };
  return action;
};
export const upcomingBookings = (value) => {
  const action = { type: SET_UPCOMING_BOOKINGS, value: value };
  return action;
};
// export const showLogin = () => {
//   const action = { type: SHOW_LOGIN };
//   return action;
// };

// export const showTrains = () => {
//   const action = { type: SHOW_TRAINS };
//   return action;
// };

// export const hideTemplate = () => {
//   const action = { type: HIDE_TEMPLATE };
//   return action;
// };

export const setDisabledSettings = ({ other, booking }) => {
  const action = {
    type: "SET_DISABLED_SETTINGS",
    other: other,
    booking: booking,
  };
  return action;
};

export const setFullScreenComponent = ({
  type,
  component,
}: {
  type: string;
  component: ReactChild;
}) => {
  const action = {
    type: "SET_FULLSCREEN_COMPONENT",
    value: { type: type, component: component },
  };
  return action;
};

export const setCurrent = (value) => {
  const action = { type: SET_CURRENT, value: value };
  return action;
};

export const setIsFallback = (value) => {
  const action = { type: SET_FALLBACK, value: value };
  return action;
};

export const setIsRefund = (value) => {
  const action = { type: SET_IS_REFUND, value: value };
  return action;
};

export const setRecentJourneys = (value) => {
  const action = { type: "SET_RECENT_JOURNEYS", value: value };
  return action;
};

export const setIrctcDetails = (value) => {
  const action = { type: "SET_IRCTC_DETAILS", value: value };
  return action;
};

export const setInputType = (value) => {
  const action = { type: "SET_INPUT_TYPE", value: value };
  return action;
};

export const setLocalData = (value) => {
  const action = { type: "SET_LOCAL_DATA", value: value };
  return action;
};

export const setUnAuth = () => {
  const action = { type: "SET_UNAUTH" };
  return action;
};



//
