import { useEffect } from "react";

import Buttons from "./components/Buttons";
import Feedback from "./components/Feedback";
import Header from "./components/Header";
import StatusDetails from "./components/StatusDetails";
import { getBookingStatus } from "./Logic";
// const langText = require("../langText/langText2.json");

const BookingSuccess = ({ ticketData }) => {
  useEffect(() => {}, []);

  const d = ticketData;

  const { bookingStatus, confirmed, waiting, isAllotted } = getBookingStatus(
    d.psgnDtlList
  );
  return (
    <div
      style={{
        width: "100%",
        borderRadius: "10px",
        padding: "20px 15px 10px",
        position: "relative",
        boxShadow:
          "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
      }}
    >
      <Header
        pnr={d.pnrNumber}
        status={bookingStatus}
        isAllotted={isAllotted}
      />
      <StatusDetails
        bookingStatus={bookingStatus}
        confirmed={confirmed}
        waiting={waiting}
        isAllotted={isAllotted}
      />
      <Feedback pnr={d.pnrNumber} />

      <div
        style={{
          width: "100%",
          borderTop: "2px solid #f0f0f0",
          marginTop: "5px",
        }}
      ></div>
      <Buttons data={d} />
    </div>
  );
};

export default BookingSuccess;
