import Container from "./styles";

const OtherDetails = ({ details }) => {
  return (
    <Container>
      <h3>Electronic Reservation Slip - Normal User</h3>
      <h4 style={{ marginTop: "14pt" }}>Indian Railways GST Details:</h4>
      <div>
        <p>Invoice Number: {details.invoice}</p>
        <p>Address: {details.indianRailAddress}</p>
      </div>
      <h4>Supplier Information:</h4>
      <div>
        <p>SAC Code: {details.sac}</p>
        <p>GSTIN: {details.supplierGstIn}</p>
      </div>
      <h4>Recipient Information:</h4>
      <div className="expand">
        <div>
          <p>GSTIN: {details.recipientGstIn}</p>
          <p>Name: {details.recipientName}</p>
          <p>Taxable Value: {details.taxable}</p>
        </div>
        <div>
          <p>Address: {details.recipientAddress}</p>
        </div>
      </div>

      <div className="expand">
        <div>
          <p>CGST Rate: {details.cgstRate}</p>
          <p>
            SGST/UGST Rate: {details.sgstRate}/{details.ugstRate}
          </p>
          <p>IGST Rate: {details.igstRate}</p>
          <p className="total">
            <span>Total Tax:</span> {details.taxable}
          </p>
        </div>
        <div>
          <p>CGST Amount: {details.cgstAmount}</p>
          <p>
            SGST/UGST Amount: {details.sgstAmount}/{details.ugstAmount}
          </p>
          <p>IGST Amount: {details.igstAmount}</p>
        </div>
      </div>

      <div className="supply">
        <h4>
          {/* Place of Supply: <span>{place}</span>{" "} */}
          Place of Supply: <span>{details.placeOfSupply}</span>{" "}
        </h4>
        <h4>
          State Code/Name of Supplier: <span>{details.statecodeName}</span>
        </h4>
      </div>
    </Container>
  );
};

export default OtherDetails;
