import store from "../Store";

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getStationName = (code) => {
  let state = store.getState();
  let lang = state.behaviour.lang;
  let Stations = state.utils.allStations;
  
  let foundArr = Stations.filter((station) => station.code === code);
  if (foundArr.length > 0) {
    if (lang === "en") return toTitleCase(foundArr[0].name);
    if (lang === "hi") return foundArr[0].name_hi;
    if (lang === "gu") return foundArr[0].name_gu;
  } else return "";
};
