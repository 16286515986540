const translations = {
  en: {
    source: "Select Your Source Station",
    destination: "Select Your Destiation Station",
    quota: "Select Your Journey Quota",
    date: "Select Your Journey Date",
    boarding: "Select New Boarding Station",
    tdr: "Select Reason for TDR",
  },
  hi: {
    source: "अपना सोर्स स्टेशन चुनें",
    destination: "अपना डेस्टिनेशन स्टेशन चुनें",
    quota: "अपना यात्रा कोटा चुनें",
    date: "अपनी यात्रा तिथि चुनें",
    boarding: "अपना नया बोर्डिंग स्टेशन चुनें",
    tdr: "TDR फाइल करने का कारण चुनें",
  },
  gu: {
    source: "તમારું સ્રોત સ્ટેશન પસંદ કરો",
    destination: "તમારું ગંતવ્ય સ્ટેશન પસંદ કરો",
    quota: "તમારો મુસાફરીનો ક્વોટા પસંદ કરો",
    date: "તમારી મુસાફરીની તારીખ પસંદ કરો.",
    boarding: "તમારું નવું બોર્ડિંગ સ્ટેશન પસંદ કરો",
    tdr: "ટી. ડી. આર. દાખલ કરવાનું કારણ પસંદ કરો",
  },
};

export default translations;
