import { useState } from "react";
import Radio from "./Radio";
import PropTypes from "prop-types";

export const RadioSelect = ({
  options,
  label,
  bordered,
  handleSelect,
  value,
  error,
}) => {
  const [selected, setSelected] = useState(value);
  const handleChange = (option) => {
    setSelected(option.value);
    handleSelect(option.value);
  };

  const groupedOptions = [];
  for (let i = 0; i < options.length; i += 2) {
    groupedOptions.push(options.slice(i, i + 2));
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        border: bordered ? "1px solid #d8d8d8" : "none",
        borderRadius: "6px",
        marginTop: "20px",
        marginBottom: "20px",
        position: "relative",
      }}
    >
      {label && (
        <span
          style={{
            position: "absolute",
            left: "10px",
            top: "-8px",
            fontSize: "12px",
            backgroundColor: "white",
            padding: "0px 4px",
            color: "#323232",
          }}
        >
          {label}
        </span>
      )}

      {groupedOptions.map((group, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            columnGap: "10px",
            marginBottom: "10px",
          }}
        >
          {group.map(
            (option) =>
              option.show && (
                <div
                  key={option.value}
                  style={{
                    flex: option.fullWidth ? "0 0 100%" : "1",
                    minWidth: "0",
                  }}
                >
                  <Radio
                    handleClick={handleChange}
                    option={option}
                    selected={option.value === selected}
                  />
                </div>
              )
          )}
        </div>
      ))}

      {error && (
        <p
          style={{
            width: "100%",
            fontSize: "12px",
            color: "tomato",
            fontWeight: "400",
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

RadioSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      show: PropTypes.bool,
      fullWidth: PropTypes.bool,
    })
  ).isRequired,
  label: PropTypes.string,
  bordered: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
};
