const translations = {
  en: {
    fields: {
      number: "Gst Number",
      company: "Company Name",
      flat: "Flat number",
      street: "Street Number",
      area: "Area",
      pincode: "Pincode",
      city: "City",
      state: "State",
    },
    placeholders: {
      number: "Enter Gst Number",
      company: "Enter Company Name",
      flat: "Enter Flat number",
      street: "Enter Street Number",
      area: "Enter Area",
      pincode: "Enter Pincode",
      city: "Enter City",
      state: "Enter State",
      popupState: "Please select state",
      popupCity: "Please select city",
    },
    validations: {
      valid: "Please enter a valid GST number",
      validFlat: "Please enter between 3 and 30 characters",
      validPin: "Please enter a valid pincode",
      number: "Please Fill Gst Number",
      company: "Please Fill Company Name",
      flat: "Please Fill Flat number",
      street: "Please Fill Street Number",
      area: "Please Fill Area",
      pincode: "Please Fill Pincode",
      city: "Please Fill City",
      state: "Please Fill State",
    },
  },
  hi: {
    fields: {
      number: "GST नंबर",
      company: "कंपनी का नाम",
      flat: "फ्लैट नंबर",
      street: "गली नंबर",
      area: "क्षेत्र",
      pincode: "पिन कोड",
      city: "शहर",
      state: "राज्य",
    },
    placeholders: {
      number: "GST नंबर दर्ज करें",
      company: "कंपनी का नाम दर्ज करें",
      flat: "फ्लैट नंबर दर्ज करें",
      street: "गली नंबर दर्ज करें",
      area: "क्षेत्र दर्ज करें",
      pincode: "पिन कोड दर्ज करें",
      city: "शहर दर्ज करें",
      state: "राज्य दर्ज करें",
      popupState: "कृपया राज्य का चयन करें",
      popupCity: "कृपया शहर चुनें",
    },
    validations: {
      valid: "कृपया एक मान्य GST नंबर दर्ज करें",
      validFlat: "कृपया 3 और 30 अक्षरों के बीच दर्ज करें",
      validPin: "कृपया एक वैध पिनकोड दर्ज करें",
      number: "कृपया GST नंबर भरें",
      company: "कृपया कंपनी का नाम भरें",
      flat: "कृपया फ्लैट नंबर भरें",
      street: "कृपया गली नंबर भरें",
      area: "कृपया क्षेत्र भरें",
      pincode: "कृपया पिन कोड भरें",
      city: "कृपया शहर भरें",
      state: "कृपया राज्य भरें",
    },
  },
  gu: {
    fields: {
      number: "જીએસટી નં.",
      company: "કંપનીનું નામ",
      flat: "ફ્લેટ નં.",
      street: "શેરી નં.",
      area: "વિસ્તાર",
      pincode: "પિન કોડ",
      city: "શહેર",
      state: "રાજ્ય",
    },
    placeholders: {
      number: "જીએસટી નંબર દાખલ કરો",
      company: "કંપનીનું નામ દાખલ કરો",
      flat: "ફ્લેટ નંબર દાખલ કરો",
      street: "શેરી નંબર દાખલ કરો",
      area: "વિસ્તાર દાખલ કરો",
      pincode: "પિન કોડ દાખલ કરો.",
      city: "શહેરમાં પ્રવેશ કરો",
      state: "રાજ્ય દાખલ કરો",
      popupState: "કૃપા કરીને રાજ્ય પસંદ કરો",
      popupCity: "કૃપા કરીને શહેર પસંદ કરો",
    },
    validations: {
      valid: "મહેરબાની કરીને માન્ય જીએસટી નંબર દાખલ કરો.",
      validFlat: "મહેરબાની કરીને 3 થી 30 અક્ષરો વચ્ચે દાખલ કરો.",
      validPin: "મહેરબાની કરીને માન્ય પિનકોડ દાખલ કરો",
      number: "મહેરબાની કરીને જીએસટી નંબર દાખલ કરો.",
      company: "મહેરબાની કરીને કંપનીનું નામ દાખલ કરો.",
      flat: "મહેરબાની કરીને ફ્લેટ નંબર દાખલ કરો.",
      street: "મહેરબાની કરીને શેરી નંબર દાખલ કરો",
      area: "મહેરબાની કરીને ક્ષેત્ર ભરો",
      pincode: "મહેરબાની કરીને પિન કોડ દાખલ કરો",
      city: "મહેરબાની કરીને શહેર ભરો",
      state: "મહેરબાની કરીને રાજ્ય ભરો",
    },
  },
};

export default translations;
