import moment from "moment";

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const parseDateTimeForBackendDumbFucks = (dateTime) => {
  if (
    !dateTime.includes("T") &&
    dateTime.includes(" ") &&
    dateTime.includes(".")
  )
    return moment(dateTime.split(".")[0], "YYYY-MM-DD hh:mm:ss");
  else if (dateTime.includes("T")) {
    return moment(dateTime.split(".")[0], "YYYY-MM-DDTh:m:s");
  } else return moment(dateTime, "MMM DD, YYYY, hh:mm:ss a");
};

const constructBooking = (data) => {
  const bookingData = JSON.parse(data.bookingData);
  return {
    pnr: bookingData.pnrNumber,
    trainName: toTitleCase(bookingData.trainName),
    trainNumber: bookingData.trainNumber,
    date: parseDateTimeForBackendDumbFucks(bookingData.boardingDate),
    source: bookingData.boardingStn,
    destination: bookingData.destStn,
    class: bookingData.journeyClass,
  };
};

export const parseBookings = (data) => {
  let isForTDR = Array.isArray(data);

  if (isForTDR) return data.map((booking) => constructBooking(booking));
  else return data.upcomingjourney.map((booking) => constructBooking(booking));
};
