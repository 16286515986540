import useGetReduxState from "../../../Hooks/useGetReduxState";
const loader =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/train2.gif";

const Loader = () => {
  const lang = useGetReduxState().behaviour.lang;

  const getText = () => {
    if (lang === "en") return "Finding the best train(s) for you...";
    if (lang === "hi") return "आपके लिए सबसे अच्छी ट्रेने ढूंढ रही हूँ...";
    if (lang === "gu") return "તમારા માટે શ્રેષ્ઠ ટ્રેનો શોધો";
  };

  return (
    <div
      style={{
        width: "95%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img src={loader} style={{ width: "100%", marginTop: "-30%" }} alt="" />
      <p
        style={{
          margin: "2px 0px 0px",
          fontSize: "18px",
          textAlign: "center",
          lineHeight: "22px",
          color: "rgb(43, 41, 41)",
          fontWeight: "400",
        }}
      >
        <span>{getText()} </span>
      </p>
    </div>
  );
};

export default Loader;
