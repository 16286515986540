import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IMAGE_MIC } from "../../../Assets";
import { Microphone } from "../../../Chatbot/Microphone";
import { isShowMic } from "../../../Helpers/misc";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import langText from "../translations";
import "../../styles.scss";
const Header = ({
  onChange,
  value,
  setMicOn,
  micOn,

  pickerType,
  handleVoice,
}) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const translations = {
    en: {
      content: "Station or City",
    },
    hi: {
      content: "स्टेशन/शहर",
    },

    gu: {
      content: "સ્ટેશન/શહેર",
    },
  };
  return (
    <div style={{ width: "100%" }}>
      {pickerType === "SOURCE" ? (
        <p
          style={{
            margin: "0",
            fontSize: "14px",
            fontWeight: "600",
            color: "#080808",
            textAlign: "center",
            width: "100%",
          }}
        >
          {langText[lang].heading.source}
        </p>
      ) : (
        <p
          style={{
            margin: "0",
            fontSize: "14px",
            fontWeight: "600",
            color: "#080808",
            textAlign: "center",
            width: "100%",
          }}
        >
          {langText[lang].heading.destination}
        </p>
      )}

      <div
        className="book-form-input"
        style={{
          marginTop: "12px",
          backgroundColor: "white",
          border: "1px solid lightgray",
          borderRadius: "6px",
          marginBottom: "0px",
          padding: "10px 14px",
        }}
      >
        <FontAwesomeIcon
          icon={faSearch}
          style={{
            color: "#858585",
            fontSize: "16px",
          }}
        />
        <input
          type="text"
          onChange={onChange}
          placeholder={langText[lang].search}
          value={value}
          style={{ fontSize: "14px" }}
          id="station-textbox"
        />
        {isShowMic() && (
          <img
            className="mic-btn"
            id="mic"
            src={IMAGE_MIC}
            alt=""
            onClick={() => setMicOn(true)}
            style={{ width: "22px", cursor: "pointer" }}
          />
        )}

        {micOn && (
          <Microphone
            callback={handleVoice}
            onClose={() => {
              setMicOn(false);
            }}
            useEnglish={true}
            content={translations[lang].content}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
