import { useEffect, useState } from "react";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import { setJourneyPreferences } from "../../../../../Store/Dispatcher/passengerForm";
import { handleChangeEmail } from "./utils";
import langText from "../translations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { Microphone } from "../../../../../Chatbot/Microphone";

const email =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/email1-refactored.png";
const info =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/info-refactored.png";

const Email = () => {
  const [text, setText] = useState(false);
  const userDataEmail = useGetReduxState().user.email;
  const preferences = useGetReduxState().passengerform.journeyPreferences;
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const [mic, setMic] = useState(false);

  const translations = {
    en: {
      content: "Passenger's Email id",
    },
    hi: {
      content: "यात्री ईमेल आईडी",
    },

    gu: {
      content: "પેસેન્જર ઈમેલ આઈડી",
    },
  };

  useEffect(() => {
    setJourneyPreferences({ ...preferences, email: userDataEmail });
  }, []);

  const handleChange = (e) => {
    let result = handleChangeEmail(e);

    setJourneyPreferences({
      ...preferences,
      email: result.value,
      isEmailError: result.error,
    });
  };

  const handleVoice = (value) => {
    if (!value) return;

    let email = value.replace("at the rate of", "@");
    email = email.replace("at the rate", "@");
    email = email.replace(" at ", " @ ");
    email = email.replace(" dot ", " . ");

    email = email.trim().split(" ").join("");

    let e = {
      target: {
        value: email,
      },
    };
    handleChange(e);
  };

  return (
    <div className="email" style={{ padding: "5px 15px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          columnGap: "5px",
        }}
      >
        <img src={email} style={{ width: "20px" }} alt="" />
        <p
          style={{
            fontSize: "14px",
            fontWeight: "600",
            color: "rgb(71 71 71)",
          }}
        >
          {langText[lang].email.text}{" "}
          <span style={{ fontSize: "12px", color: "gray" }}>
            ({langText[lang].optional})
          </span>
        </p>
        <img
          onClick={() => setText((prev) => !prev)}
          style={{ width: "16px", cursor: "pointer" }}
          src={info}
          alt=""
        />
      </div>
      <div style={{ width: "100%", position: "relative" }}>
        <input
          style={{
            fontSize: "14px",
            padding: "7px",
            border: "1px solid gray",
            width: "100%",
            borderRadius: "5px",
            marginTop: "4px",
            outline: "none",
          }}
          type="text"
          placeholder={langText[lang].email.placeholder}
          onChange={handleChange}
          value={preferences.email}
          id="disha-email-input"
        />
        <FontAwesomeIcon
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: "10px",
            fontSize: "17px",
            color: "#2d69c0",
            cursor: "pointer",
          }}
          icon={faMicrophone}
          onClick={() => setMic(true)}
        />
      </div>

      {preferences.isEmailError !== "" && (
        <p
          style={{
            fontSize: "12px",
            marginTop: "4px",
            color: "tomato",
            fontWeight: "500",
          }}
        >
          {preferences.isEmailError}
        </p>
      )}

      {text && (
        <span
          style={{
            fontSize: "12px",
            lineHeight: "1.1",
            display: "block",
            marginTop: "5px",
            color: "slategray",
          }}
        >
          {langText[lang].email.subtext}
        </span>
      )}
      {mic && (
        <Microphone
          useEnglish={true}
          callback={(value) => {
            handleVoice(value);
            setMic(false);
          }}
          onClose={() => setMic(false)}
          content={translations[lang].content}
        />
      )}
    </div>
  );
};

export default Email;
