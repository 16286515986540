const translations = {
  en: {
    left: "Select Passengers",
    right: "Select All",
    buttonCancel: "Cancel Ticket",
    buttonTDR: "File TDR",
    are: "Are you sure?",
    body: "Ticket will be permanently cancelled for ",
    yes: "Yes",
    no: "No",
  },
  hi: {
    left: "यात्रियों का चयन करें",
    right: "सबका चयन करें",
    buttonCancel: "टिकट रद्द करो",
    buttonTDR: "TDR फाइल करें",
    are: "क्या आप इसके बारे में निश्चित हैं?",
    body: " के लिए टिकट स्थायी रूप से रद्द कर दिया जाएगा",
    yes: "हाँ",
    no: "नहीं",
  },
  gu: {
    left: "મુસાફરો પસંદ કરો",
    right: "બધા પસંદ કરો",
    buttonCancel: "ટિકિટ રદ કરો",
    buttonTDR: "ફાઇલ TDR",
    are: "શું તમે તેની સાથે સંમત છો?",
    body: "ટિકિટ કાયમ માટે રદ કરવામાં આવશે",
    yes: "હા તે છે",
    no: "ના.",
  },
};

export default translations;
