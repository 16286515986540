import ReactDOM from "react-dom";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import useSendQuery from "../../../../../Hooks/useSendQuery/useSendQuery";
import "./styles.scss";

const bubble =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/Icon.svg";
const QuestionLabel = ({ label, index, handleClick }) => {
  return (
    <div
      key={index}
      style={{
        padding: "0px 22px",
        cursor: "pointer",
      }}
      // onClick={() => {
      //   if (!messageLoader)
      //     eventBus.dispatch("sendMessage", {
      //       message: {
      //         userInput: label,
      //         suggestion: true,
      //       },
      //     });
      //   setUserInputQuery(null);
      //   setInputValue("");
      //   setShowSuggestionsPopUp(false);
      // }}

      onClick={() => handleClick(label)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid rgba(217,217,217,0.32)",
          padding: "8px 0px",
        }}
      >
        <p
          style={{
            margin: "0px",
            fontSize: "14px",
            color: "#007df2",
            /* line-height: 14px; */
            fontWeight: 500,
            // textAlign: "center",
          }}
        >
          {label}
        </p>
      </div>
    </div>
  );
};

const getHeader = (lang) => {
  if (lang === "en") return "Auto Suggestions";

  if (lang === "hi") return "ऑटो सुझाव";

  if (lang === "gu") return "સ્વયંસંચાલિત સૂચનો";
};

const AutoSuggestions = ({ handleClose, suggestions }) => {
  const { sendQueryPayload } = useSendQuery(handleClose);
  const handleClick = (query) => {
    sendQueryPayload({ data: null, query: query, inputType: "" });
  };

  const lang = useGetReduxState().behaviour.lang;

  return ReactDOM.createPortal(
    <div
      style={{
        position: "absolute",
        bottom: "30px",
        left: "0px",
        right: "0px",
        backgroundColor: "white",
        borderTop: "1px solid rgba(128, 128, 128, 0.24)",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        paddingBottom: "15px",
        boxShadow: "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <img
          style={{
            width: "16px",
            marginLeft: "16px",
            marginRight: "5px",
          }}
          src={bubble}
          alt=""
        />

        <p
          style={{
            margin: 0,
            fontSize: "16px",

            color: "black",
            fontWeight: 500,
          }}
        >
          {getHeader(lang)}
        </p>
      </div>
      <div className="questions-scroll">
        {suggestions.map((element, index) => (
          <QuestionLabel
            label={element}
            index={index}
            handleClick={handleClick}
          />
        ))}
      </div>
    </div>,
    document.getElementById("corover-body"),
  );
};

export default AutoSuggestions;
