import styled from "styled-components";

const Container = styled.div`
  width: 85%;
  padding: 18px;
  border-radius: 8px;
  box-shadow:
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;

  .first {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;

    img {
      width: 40px;
      margin-right: 10px;
    }

    p {
      font-size: 14px;
    }
  }

  button {
    width: 60%;
    border: none;
    background-color: #326abb;
    color: white;
    display: block;
    padding: 10px;
    font-size: 14px;
    margin: 0 auto;
    margin-bottom: 10px;
    border-radius: 6px;
    cursor: pointer;
  }

  .helpline {
    text-align: center;

    h6 {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
      margin-top: 8px;
      color: #5b5b5b;
      text-decoration: underline;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      margin: 4px auto;
    }

    a {
      text-decoration: none;
      color: #1890ff;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

export default Container;
