/* eslint-disable import/first */

import { useEffect } from "react";

import { useTimer } from "react-timer-hook";

import moment from "moment";
import { createPortal } from "react-dom";
import { sendQuery } from "../../Api/sendQuery";
import useGetReduxState from "../../Hooks/useGetReduxState";
import { setBookingIsPending } from "../../Store/Dispatcher/utils";
import { setCurrentPassengerFormStep } from "../../Store/Dispatcher/passengerForm";
import { setIrctcDetails } from "../../Store/Dispatcher/behaviour";

const PendingBooking = ({ data, hide }) => {
  const time = new Date();
  const irctcId = useGetReduxState().user.irctcId;

  time.setSeconds(
    time.getSeconds() +
      (15 * 1000 * 60 - (Date.now() - data.updateTime)) / 1000 +
      60,
  );

  // time.setSeconds(time.getSeconds() + 120);

  useEffect(() => {}, []);

  // time.setSeconds(time.getSeconds() + 60);

  const { seconds, minutes } = useTimer({
    expiryTimestamp: time,
  });

  const getDetails = (str) => {
    let s = str.split(" and ");
    let m = s[0];
    let e = s[1];
    m = m.split(" number ")[1]?.trim();
    e = e.split("email")[1]?.trim();
    return {
      email: e,
      mobile: m,
    };
  };

  const proceed = async () => {
    let payload = {
      resend: "N",
      retry: true,
      loginId: irctcId,
    };

    const data = await sendQuery({
      query: "Payment success!",
      next_context: "ade4a7db-d819-417d-832a-259307fd94c7,12",
      cxpayload: payload,
      langCode: "en",
      isFallback: false,
    });
    if (data.data.status) {
      let { mobile, email } = getDetails(data.data.status);
      setIrctcDetails({ email: email, mobile: mobile, txnId: "" });
      setCurrentPassengerFormStep(3);
      setBookingIsPending(true);
      hide();
    }
  };

  return createPortal(
    <div
      style={{
        position: "absolute",
        inset: 0,
        zIndex: 99999,
        background: "#0000009e",
      }}
    >
      {!(minutes < 1 && seconds < 1) ? (
        <div
          style={{
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            padding: "16px 16px",
            borderRadius: "5px",
            width: "90%",
          }}
        >
          <p
            style={{
              margin: "0",
              textAlign: "center",
              fontSize: "24px",
              color: "#5e5e5e",
              fontWeight: "700",
              marginBottom: "10px",
            }}
          >
            Booking is Pending.
          </p>
          <p
            style={{
              margin: "0px",
              lineHeight: "20px",
              fontSize: "16px",
              color: "slategray",
              textAlign: "justify",
            }}
          >
            Payment of&nbsp;
            <span style={{ color: "cornflowerblue", fontWeight: "700" }}>
              ₹{data.paymentAmount}
            </span>
            &nbsp;for your previous booking was successful. But, you are yet to
            verify and confirm your ticket through OTP.
          </p>

          <p
            style={{
              textAlign: "center",
              fontSize: "14px",
              color: "#5e5e5e",
              fontWeight: "500",
              margin: "13px 0px",
            }}
          >
            Please verify OTP to book your ticket.
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "6px",
            }}
          >
            <button
              style={{
                backgroundColor: "rgb(11, 116, 250)",
                border: "none",
                color: "white",
                fontSize: "16px",
                padding: "10px 16px",
                borderRadius: "8px",
                fontWeight: "500",
                marginBottom: "2px",
                cursor: "pointer",
              }}
              onClick={() => {
                proceed();
              }}
            >
              Proceed to OTP Verification
            </button>

            <p
              style={{
                margin: "0",
                textAlign: "center",
                fontSize: "16px",
                color: "tomato",
                fontWeight: "600",
                marginTop: "10px",
              }}
            >
              Time Left:{" "}
              {moment(`${minutes}:${seconds}`, "m:s").format("mm:ss")}
            </p>
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            padding: "16px 16px",
            borderRadius: "5px",
            width: "90%",
          }}
        >
          <p
            style={{
              margin: "0",
              textAlign: "center",
              fontSize: "24px",
              color: "#5e5e5e",
              fontWeight: "700",
              marginBottom: "10px",
            }}
          >
            Time has expired.
          </p>
          <p
            style={{
              margin: "0px",
              lineHeight: "20px",
              fontSize: "16px",
              color: "slategray",
              textAlign: "justify",
            }}
          >
            Your time for booking the ticket has expired. Dont worry, your
            payment amount of ₹{data.paymentAmount} will be refunded in 1 to 7
            bank days.
          </p>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "16px",
            }}
          >
            <button
              style={{
                backgroundColor: "rgb(11, 116, 250)",
                border: "none",
                color: "white",
                fontSize: "16px",
                padding: "10px 16px",
                borderRadius: "8px",
                fontWeight: "500",
                marginBottom: "2px",
                cursor: "pointer",
              }}
              onClick={() => {
                hide();
              }}
            >
              Ok, Got It
            </button>
          </div>
        </div>
      )}
    </div>,
    document.getElementById("corover-body"),
  );
};

export default PendingBooking;
