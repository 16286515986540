import "./styles.scss";
const Icon = ({ img, imgWidth, type, handleClick, background, tab }) => {
  const onClicked = () => {
    handleClick(type.query);
  };

  return (
    <div
      className="icon-white"
      onClick={onClicked}
      style={{ backgroundColor: background }}
      tabIndex={tab}
      aria-label={type.text}
      onKeyDown={(e) => {
        if (e.key === "Enter") onClicked();
      }}
    >
      <img style={{ width: imgWidth }} src={img} alt="" />
      <p style={{ marginTop: "5px" }}>{type.text}</p>
    </div>
  );
};

export default Icon;
