import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* padding: 15px; */

  .no-passengers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: gray;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0px;

    img {
      width: 90px;
    }
    p {
      margin-top: 20px;
    }
  }

  button {
    width: fit-content;
    background-color: #326abb;
    color: white;
    padding: 10px 50px;
    border: none;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #326abb;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export default Container;
