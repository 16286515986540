import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;

  .passengers {
    padding: 15px;
    overflow-y: auto;
    max-height: calc(100% - 99px);

    button {
      font-size: 14px;
      background-color: rgb(50, 106, 187);
      color: white;
      cursor: pointer;
      border: none;
      display: block;
      flex-shrink: 0;
      width: fit-content;
      padding: 7px 16px;
      border-radius: 6px;
    }
  }

  h4 {
    color: #404040;
    font-weight: 500;
    margin-left: 15px;
    font-size: 16px;
    text-align: left;
  }

  button {
    width: 60%;
    background-color: rgb(50, 106, 187);
    color: white;
    padding: 12px 0px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid rgb(50, 106, 187);
    cursor: pointer;
    margin: 0px auto;
    display: block;
  }
`;

export default Container;
