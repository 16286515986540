const translations = {
  en: {
    header: "Sort Trains By",
    name: "Train Name",
    arrival: "Arrival Time",
    departure: "Departure Time",
    fastest: "Fastest",
    button: "Sort Trains",
  },
  hi: {
    header: "ट्रेनों को क्रमबद्ध करें",
    name: "ट्रेन का नाम",
    arrival: "आगमन का समय",
    departure: "प्रस्थान का समय",
    fastest: "Fastest",
    button: "क्रमबद्ध करें",
  },
  gu: {
    header: "ટ્રેનોને ક્રમબદ્ધ કરો",
    name: "ટ્રેનનું નામ",
    arrival: "આગમનનો સમય",
    departure: "પ્રસ્થાનનો સમય",
    fastest: "Fastest",
    button: "સૉર્ટ કરો",
  },
};

export default translations;
