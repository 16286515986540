import styled from "styled-components";

const Container = styled.div`
  width: 98%;
  margin: 0px auto;
  margin-bottom: 20px;
  /* padding: 12px 15px; */
  border: 1px solid gainsboro;
  border-radius: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 20px 15px;
  > * {
    line-height: 1;
    font-family: "Roboto", sans-serif;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  h1 {
    font-size: 14px;
    font-weight: 500;
    color: dimgray;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h2 {
    font-size: 14px;
    color: dimgray;
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 18px;
  }

  h4 {
    text-align: center;
    font-weight: 600;
    color: #555555;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid gainsboro;
    padding-top: 15px;
    font-size: 14px;

    img {
      width: 22px;
      margin-right: 5px;
    }
  }

  h5 {
    text-align: center;
    margin-top: 14px;
    font-weight: 500;
    color: darkslategray;
  }

  .station {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #bcbcbc;
    width: fit-content;
    padding: 8px 12px;
    border-radius: 6px;
    margin-top: 10px;

    p {
      font-size: 16px;
      font-weight: 600;
      color: #4f4e4e;
    }

    img {
      width: 22px;
      margin-right: 5px;
    }
  }

  .date-time {
    p {
      text-align: center;
      margin-top: 32px;
      font-weight: 500;
      color: darkslategray;
      font-size: 14px;
    }

    h5 {
      font-size: 18px;
      color: lightslategray;
      font-weight: 600;
      margin-top: 10px;
    }
  }
`;

export default Container;
