import moment from "moment";
import { useEffect, useState } from "react";
import { getQuotaName } from "../../Helpers/getQuotaName";
import { getStationName } from "../../Helpers/getStationName";
import useGetReduxState from "../../Hooks/useGetReduxState";
import { setFullScreen } from "../../Store/Dispatcher/behaviour";
import { setJourney } from "../../Store/Dispatcher/journey";
import { deleteLastNmessages } from "../../Store/Dispatcher/messages";
import Trains from "../Trains";
import EditTrains from "../Trains/EditTrains";
import Container from "./styles";
import langText from "./translations";

const icon =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/check-mark.svg";
const trainIcon =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/train-icon-list.png";
const SelectedTrain = () => {
  const [edit, setEdit] = useState(false);
  const train = useGetReduxState().train;
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const findTrains = ({ source, destination, date, quota }) => {
    setJourney({ source, destination, quota, date, boardingStation: "" });
    setFullScreen({
      type: "TRAINS",
      component: <Trains />,
    });
    deleteLastNmessages(3);
  };

  const getDuration = ({
    departureTime,
    departureDate,
    arrivalTime,
    arrivalDate,
  }) => {
    const start = departureTime + ", " + departureDate;
    const end = arrivalTime + ", " + arrivalDate;

    const date1 = moment(start, "hh:mm, YYYYMMDD");
    const date2 = moment(end, "hh:mm, YYYYMMDD");

    const diff = date2.diff(date1);

    let duration = moment.duration(diff);

    var hours = parseInt(duration.asHours().toString());

    var minutes = parseInt(duration.asMinutes().toString());

    minutes = minutes - hours * 60;
    return `${hours}hrs ${minutes}min`;
  };

  // const [durationString, setDurationString] = useState("");
  useEffect(() => {
    // let duration = ;
    // setDurationString(duration.hours + "hrs " + duration.minutes + "min");
  }, []);

  return (
    <Container>
      <div className="train-selected">
        <div className="header">
          <div>
            <img src={icon} alt="" />
            <p>{langText[lang].selected}</p>
          </div>
          <button onClick={() => setEdit(true)}>{langText[lang].edit}</button>
        </div>
        <div className="details">
          <div className="train">
            <img src={trainIcon} alt="" />
            {train.trainName} <span>({train.trainnumber})</span>
          </div>
          <div className="second-row">
            <span>{train.class}</span>
            <span>|</span>
            <span>
              {getQuotaName(train.quota)} ({train.quota})
            </span>
          </div>
          <div className="stations">
            <div className="from">
              <p>{train.source}</p>
              <span>{getStationName(train.source)}</span>
            </div>
            <div className="hr"></div>
            <h5>
              {getDuration({
                departureDate: train.departureDate,
                arrivalDate: train.arrivalDate,
                departureTime: train.departureTime,
                arrivalTime: train.arrivalTime,
              })}
            </h5>
            <div className="hr"></div>
            <div className="to">
              <p>{train.destination}</p>
              <span>{getStationName(train.destination)}</span>
            </div>
          </div>
          <div className="date-time">
            <p>
              {moment(train.departureTime, "HH:mm").format("HH:mm")}, 
              {moment(train.departureDate, "YYYYMMDD").format(" DD MMM")}
            </p>
            <p>
              {moment(train.arrivalTime, "HH:mm").format("HH:mm")}, 
              {moment(train.arrivalDate, "YYYYMMDD").format(" DD MMM")}
            </p>
          </div>
        </div>
      </div>
      {edit && (
        <EditTrains
          handleClose={() => setEdit(false)}
          findTrains={findTrains}
        />
      )}
    </Container>
  );
};

export default SelectedTrain;
