import { User } from "../Reducers/types";

export const setUser = (user: User) => {
  const action = { type: "SET_USER", user: user };
  return action;
};

export const setPassengers = (passengers) => {
  const action = { type: "SET_PASSENGERS_USER", value: passengers };
  return action;
};

export const setSavedPassengers = (passengers) => {
  const action = { type: "SET_SAVED_PASSENGERS_USER", value: passengers };
  return action;
};
