import moment from "moment";
import { useState } from "react";
import { Microphone } from "../../Chatbot/Microphone";
import { Quotas } from "../../Helpers/getQuotaName";
import Drawer from "../../UI/UIComponents/Drawer";
import Content from "./Content/Content";
import Header from "./Header/Header";
import Fuse from "fuse.js";
import { CloseDrawer } from "../../Helpers/EventEmitter";
import { isShowMic } from "../../Helpers/misc";
import { IMAGE_MIC } from "../../Assets";
import useGetReduxState from "../../Hooks/useGetReduxState";

const Footer = ({ callBack, current }) => {
  const [micOn, setMicOn] = useState(false);
  const lang = useGetReduxState().behaviour.lang;

  const translations = {
    en: {
      content: "General",
    },
    hi: {
      content: "सामान्य",
    },

    gu: {
      content: "  સામાન્ય",
    },
  };

  const disableQuota = () => {
    let currentDate = moment();
    if (moment(current, "YYYYMMDD").diff(currentDate, "days") + 1 < 6) {
      return false;
    }
    return true;
  };
  const handleVoice = (value) => {
    if (value) {
      let searchData: any = [];
      searchData = disableQuota()
        ? Quotas.filter((element) => element.value !== "TQ")
        : Quotas;

      const fuse = new Fuse(searchData, {
        keys: ["label", "value"],
      });
      let searchValue: any = fuse.search(value);
      callBack(searchValue[0].item.value);
      setMicOn(false);
      CloseDrawer();
    }
  };

  return (
    <div style={{ width: "100%", padding: "10px 0px" }}>
      <div
        style={{
          margin: "0 auto",
          padding: "20px",
          height: "35px",
          width: "35px",
          borderRadius: "50%",
          backgroundColor: "aliceblue",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {isShowMic() && (
          <img
            className="mic-btn"
            id="mic"
            src={IMAGE_MIC}
            alt=""
            style={{ width: "28px" }}
            onClick={() => setMicOn(true)}
          />
        )}
      </div>
      {micOn && (
        <Microphone
          callback={handleVoice}
          onClose={() => {
            setMicOn(false);
          }}
          useEnglish={true}
          content={translations[lang].content}
        />
      )}
    </div>
  );
};

const QuotaPicker = ({ handleClose, callBack, currentDate }) => {
  return (
    <Drawer
      header={<Header />}
      content={
        <Content
          onClick={(value) => {
            callBack(value);
            CloseDrawer();
            // handleClose();
          }}
          current={currentDate}
        />
      }
      footer={
        <Footer
          callBack={(value) => {
            callBack(value);
            handleClose();
          }}
          current={currentDate}
        />
      }
      handleCloseDrawer={handleClose}
      showCross={true}
      tatkal={false}
      fullHeight={false}
      showHeaderSeperator={true}
    />
  );
};

export default QuotaPicker;
