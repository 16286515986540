import store from "../../Store";
import langText from "./translations";
const parse = (string) => {
  if (string) return JSON.parse(string);
  return false;
};

const isWaiting = () => {
  const train = store.getState().train;
  let check = train.status.toLowerCase();

  if (check.includes("/wl")) return true;

  return false;
};

const showInsurance = (config) => {
  const train = store.getState().train;
  const enabled = parse(config?.travelInsuranceEnabled);
  const isWaitingStatus = isWaiting();
  const isTejas = train.trainName.toLowerCase().includes("tejas");

  return enabled && !isWaitingStatus && !isTejas;
};

export const isAdult = (age) => {
  return parseInt(age) > 11;
};

export const isChild = (age) => {
  return parseInt(age) > 4 && parseInt(age) < 12;
};

export const isInfant = (age) => {
  return parseInt(age) < 5;
};

export const getGender = (gender) => {
  const lang: "en" | "gu" | "hi" = store.getState().behaviour.lang;
  if (gender === "M") return langText[lang].utils.male;
  if (gender === "F") return langText[lang].utils.female;
  return langText[lang].utils.transgender;
};

export const getAge = (age) => {
  const lang: "en" | "gu" | "hi" = store.getState().behaviour.lang;
  return age + " " + langText[lang].utils.years;
};

export const getEntity = (passengerage) => {
  const age = parseInt(passengerage);
  const lang: "en" | "gu" | "hi" = store.getState().behaviour.lang;
  if (age > 11) return langText[lang].utils.adult;
  if (age > 4) return langText[lang].utils.child;
  return langText[lang].utils.infant;
};

export const getEntityYearSpan = (passengerage) => {
  const age = parseInt(passengerage);
  const lang: "en" | "gu" | "hi" = store.getState().behaviour.lang;
  if (age > 11) return langText[lang].utils.span.adult;
  if (age > 4) return langText[lang].utils.span.child;
  return langText[lang].utils.span.infant;
};

export const getFoodChoice = (food) => {
  const lang: "en" | "gu" | "hi" = store.getState().behaviour.lang;
  if (food === "V") return langText[lang].utils.food.short.veg;
  if (food === "N") return langText[lang].utils.food.short.nonveg;
  if (food === "E") return langText[lang].utils.food.short.snacks;
  if (food === "T") return langText[lang].utils.food.short.tea;
  if (food === "J") return langText[lang].utils.food.short.jain;
  if (food === "F") return langText[lang].utils.food.short.vegd;
  if (food === "G") return langText[lang].utils.food.short.nonvegd;
  if (food === "D") return langText[lang].utils.food.short.none;
};

export const parseIrctcConfig = (data) => {
  const config = data.bkgCfg;

  return {
    // showConcession: parse(config?.seniorCitizenApplicable),
    childBerthMandatory: parse(config?.childBerthMandatory),
    showFoodChoice: parse(config?.foodChoiceEnabled),
    applicableFoodChoices: config?.foodDetails || [],
    showTravelInsurance: showInsurance(config),
    quota: data.quota,
    //check if array and even can come empty
    berths: Array.isArray(config?.applicableBerthTypes)
      ? config?.applicableBerthTypes
      : [config?.applicableBerthTypes],
    boardingStations: Array.isArray(data.boardingStations)
      ? data.boardingStations.map((stn) => stn.value)
      : [data.boardingStations.value],
    isGSTEnable: parse(config?.gstDetailInputFlag),
    trainClass: data.enqClass,
    isWaiting: isWaiting(),
    maxInfants: parseInt(config?.maxInfants),
    maxPassengers: parseInt(config?.maxPassengers),
  };
};
