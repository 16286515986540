import BookingForm from "../../../Chatbot/Body/Components/Content/Components/Home/BookingForm";
import { CloseDrawer } from "../../../Helpers/EventEmitter";

const Content = ({ findTrains }) => {
  return (
    <div style={{ width: "100%", padding: "0px 15px", overflow: "hidden" }}>
      <BookingForm
        forEdit={true}
        findTrains={({ source, destination, date, quota }) => {
          if (source && destination) {
            CloseDrawer();
            findTrains({ source, destination, date, quota });
          }
        }}
      />
    </div>
  );
};

export default Content;
