import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  column-gap: 5px;
  align-items: center;
  border: 1px solid gray;
  padding: 10px 12px;
  border-radius: 8px;
  position: relative;

  input {
    border: none;
    width: 100%;
    outline: none;
    font-size: 18px;
    font-weight: 600;
    color: dimgray;

    &::-webkit-input-placeholder {
      font-weight: 400;
      color: #aaaaaa;
    }
  }
`;

export default Container;
