import styled from "styled-components";

const Container = styled.div`
  width: 280px;
  border: solid 1px #eaf1fb;
  padding: 10px 15px;
  border-radius: 15px;
  display: inline-block;

  margin-right: 10px;

  .top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
  }

  .top p {
    font-size: 14px;
    font-weight: 600;
    color: #3f4c5f;
  }

  .details {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }

  .details .to {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 45%;
    text-align: right;
  }
  .details img {
    width: 30px;
  }
  .details .from {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 45%;
    text-align: left;
  }

  .hr {
    width: 100%;
    border-top: solid 1px #eaf1fb;
  }

  p {
    font-size: 18px;
    font-weight: normal;
    color: #1f1f1f;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }

  .details .code {
    font-size: 15px;
    font-weight: 500;
    color: #3f4c5f;
  }

  .details .station {
    font-size: 9px;
    margin-top: 5px;
    color: #3f4352;
  }

  .details .time {
    font-size: 14px;
    font-weight: 500;
    color: #607d8b;
    margin-top: 8px;
  }
`;
export default Container;
