import useGetReduxState from "../../../../../../../Hooks/useGetReduxState";
const station =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/station-icon.png";
const cal =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/calendar-icon-new.png";
const text = {
  gu: {
    search: "ટ્રેનની શોધ",
    from: "પ્રસ્થાન",
    to: "આગમન",
    date: "મુસાફરીની તારીખ",
    quota: "મુસાફરીનો ક્વોટા",
    source: "તમારું પ્રસ્થાન સ્ટેશન પસંદ કરો",
    destination: "તમારું આગમન સ્ટેશન પસંદ કરો",
    bookings: "મારું બુકિંગ",
  },
  hi: {
    search: "ट्रेन खोजें",
    from: "प्रस्थान",
    to: "आगमन",
    date: "यात्रा तिथि",
    quota: "यात्रा कोटा",

    source: "अपना प्रस्थान स्टेशन चुनें",
    destination: "अपना आगमन स्टेशन चुनें",
    bookings: "मेरी बुकिंग",
  },
  en: {
    search: "Search Train",
    from: "From",
    to: "To",
    date: "Journey Date",
    quota: "Journey Quota",
    source: "Select Your Source Station",
    destination: "Select Your Destination Station",
    bookings: "My Bookings",
  },
};

const TextBox = ({ type, onTap, value }) => {
  const lang = useGetReduxState().behaviour.lang;

  const getLabel = () => {
    const text2 = text[lang];

    if (type === "source") return text2.from;

    if (type === "destination") return text2.to;

    if (type === "date") return text2.date;

    if (type === "quota") return text2.quota;
  };

  const getPlaceholder = () => {
    const text2 = text[lang];
    if (type === "source") return text2.source;

    if (type === "destination") return text2.destination;

    if (type === "date") return "Select Your Journey Date";

    if (type === "quota") return "Select Your Quota";
  };
  // type === "quota" || type === "date"
  return (
    <div
      style={
        type === "source" || type === "destination"
          ? {
              backgroundColor: "white",
              padding: "5px 15px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: type === "destination" ? "5px" : "10px",
              cursor: "pointer",
              width: "98%",
              borderBottom: "1px solid #CCCBCB",
              paddingLeft: "5px",
              paddingBottom: "10px",
            }
          : {
              backgroundColor: "white",
              padding: "5px 15px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "10px",
              cursor: "pointer",
              width: "48%",
              borderBottom: "1px solid #CCCBCB",
              paddingLeft: "5px",
              paddingBottom: "10px",
            }
      }
      onClick={onTap}
      tabIndex={0}
      aria-label={getPlaceholder()}
      onKeyDown={(e) => {
        if (e.key === "Enter") onTap();
      }}
    >
      {(type === "source" || type === "destination") && (
        <img
          style={{ marginRight: "8px", width: "24px" }}
          src={station}
          alt=""
        />
      )}

      {type === "date" && (
        <img style={{ marginRight: "8px", width: "22px" }} src={cal} alt="" />
      )}

      <div>
        <span
          style={{
            fontSize: "12px",
            color: "#346db3",
            fontWeight: "700",
            marginTop: "0px",
            display: "block",
          }}
        >
          {getLabel()}
        </span>
        <div
          style={{
            fontSize: "14px",
            marginTop: "6px",
            color: value ? "#4B4B4C" : "#969696",
            fontWeight: "600",
            // whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
            {value ? value : getPlaceholder()}
        </div>
      </div>
    </div>
  );
};

export default TextBox;
