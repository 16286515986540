import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { checkDate } from "../../Api/utils";
import { IMAGE_MIC } from "../../Assets";
import { Microphone } from "../../Chatbot/Microphone";
import { CloseDrawer } from "../../Helpers/EventEmitter";
import { isShowMic } from "../../Helpers/misc";
import { RootState } from "../../Store/Reducers";
import Drawer from "../../UI/UIComponents/Drawer";
import Content from "./Content/Content";
import Header from "./Header/Header";
import useGetReduxState from "../../Hooks/useGetReduxState";
import { StringifyOptions } from "querystring";

const Footer = ({ callBack }) => {
  const [micOn, setMicOn] = useState(false);
  const lang = useGetReduxState().behaviour.lang;

  const disableDate = (value) => {
    let date = moment(value, "YYYYMMDD");
    let currentDate = moment(new Date(), "YYYYMMDD");
    if (date.diff(currentDate, "days") + 1 < 6) {
      return false;
    }
    return true;
  };

  const translations = {
    en: {
      content: "Journey's date",
    },
    hi: {
      content: "यात्रा की तारीख",
    },

    gu: {
      content: "મુસાફરીની તારીખ",
    },
  };
  // const handleVoice = async (value) => {
  // if (value) {
  // const data = await checkDate(value, "en");
  // if (data && data.isTrue) {
  //   if (data.date) {
  //     if ((quota === "TQ" && !disableDate(data.date)) || quota !== "TQ") {
  //       callBack(data.date);
  //       setMicOn(false);
  //       CloseDrawer();
  //     }
  //   }
  // }
  // }
  //   const currentYear = new Date().getFullYear();

  //   const date = moment(`${value} ${currentYear}`, "Do MMMM YYYY");
  //   console.log("date-", date);

  //   const formattedDate = date.format("YYYYMMDD");
  //   console.log("formattedDate", formattedDate);
  // };

  const filterDate = (data) => {
    // console.log("data", data);
    switch (data.replace(/^\s+/, "")) {
      case "first":
        return 1;
      case "second":
        return 2;
      case "third":
        return 3;
      default:
        return /^\d+$/.test(data) 
          ? parseInt(data.replace(/\b(?:rd|th|st|nd|)\b/g, ""))
          : data.replace(/\b(?:rd|th|st|nd|)\b/g, "");
    }
  };

  
  const convertDate = (inputDate) => {
    const parts = inputDate.split(" ");
    let value = filterDate(parts[0]);

    if (typeof value === "number") {
      const part = inputDate.split(" ");
      const dayPart = filterDate(part[0]);
      const monthPart = part[1];
      const formattedDate = `${dayPart} ${monthPart}`;
      return formattedDate;
    } else {
      const dayPart = parts[1].replace(/\D/g, "");
      const monthPart = parts[0];

      const formattedDate = `${dayPart}${parts[1].replace(
        /\d/g,
        ""
      )} ${monthPart}`;
      return formattedDate; //28 February
    }
  };

  const handleVoice = async (value) => {
    //working for mm dd/dd mm
    const data = value;
    if (data) {
      let finalValue = convertDate(data);
      const modifiedValue = finalValue.replace(/\b(?:rd|th|st|nd)\b/g, "");
      const currentYear = new Date().getFullYear();
      try {
        const date = moment(`${modifiedValue} ${currentYear}`, "Do MMMM YYYY");
        if (date.isValid()) {
          const formattedDate = date.format("YYYYMMDD");
          callBack(formattedDate);
          setMicOn(false);
          CloseDrawer();
        } else {
          setMicOn(false);
          CloseDrawer();
          callBack(moment().format("YYYYMMDD"));
          console.error("Invalid date input");
        }
      } catch (error) {
        console.error("Error parsing date", error);
      }
    }
  };

  return (
    <div style={{ width: "100%", padding: "10px 0px" }}>
      <div
        style={{
          margin: "0 auto",
          padding: "20px",
          height: "35px",
          width: "35px",
          borderRadius: "50%",
          backgroundColor: "aliceblue",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {isShowMic() && (
          <img
            className="mic-btn"
            id="mic"
            src={IMAGE_MIC}
            alt=""
            style={{ width: "28px" }}
            onClick={() => setMicOn(true)}
          />
        )}
      </div>

      {micOn && (
        <Microphone
          callback={handleVoice}
          onClose={() => {
            setMicOn(false);
          }}
          useEnglish={true}
          content={
            translations[lang].content + " " + moment().format("DD-MM-YYYY")
          }
        />
      )}
    </div>
  );
};

const DatePicker = ({ handleClose, callBack }) => {
  return (
    <Drawer
      header={<Header />}
      content={
        <Content
          onClick={(d) => {
            callBack(moment(d).format("YYYYMMDD"));
            CloseDrawer();
          }}
        />
      }
      footer={<Footer callBack={callBack} />}
      handleCloseDrawer={handleClose}
      showCross={true}
      tatkal={false}
      fullHeight={false}
      showHeaderSeperator={true}
    />
  );
};

export default DatePicker;
