import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect, useState } from "react";
import { savePassengerList } from "../../../../../Api/utils";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import { setPassengers } from "../../../../../Store/Dispatcher/passengerForm";
import Drawer from "../../../../../UI/UIComponents/Drawer";
import { getAge, getEntity, getGender } from "../../../utils";
import AddPassenger from "../AddPassenger/AddPassenger";
import Container from "./styles";

const Passenger = ({ passenger }) => {
  const passengers = useGetReduxState().passengerform.passengers;
  const [isOpenEdit, setOpenEdit] = useState(false);
  // const [saveToggle, setSaveToggle] = useState(0);

  const handleDelete = () => {
    let temp = passengers;
    const ind = passengers.indexOf(passenger);
    temp.splice(ind, 1);
    setPassengers([...temp]);
    // setSavedPassengers([...temp]);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        margin: "0px auto 8px",
        padding: "10px",
        borderRadius: "10px",
        position: "relative",
        boxShadow:
          "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
      }}
    >
      <div>
        <p style={{ margin: "0", fontSize: "16px", fontWeight: "400" }}>
          {passenger.name}
        </p>
        <div style={{ marginTop: "4px", color: "slategray", fontSize: "12px" }}>
          <span>{getEntity(passenger.age)}</span> <span>|</span>
          <span>{getGender(passenger.gender)}</span> <span>|</span>
          <span>{getAge(passenger.age)}</span>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "end", alignItems: "center" }}
      >
        <FontAwesomeIcon
          icon={faPen}
          style={{
            color: "#5c5b5b",
            marginRight: "10px",
            fontSize: "14px",
            cursor: "pointer",
          }}
          onClick={() => setOpenEdit(true)}
        />
        <FontAwesomeIcon
          icon={faTrash}
          style={{
            color: "#5c5b5b",
            marginRight: "10px",
            fontSize: "14px",
            cursor: "pointer",
          }}
          onClick={handleDelete}
        />
      </div>

      {isOpenEdit && (
        <AddPassenger
          handleCloseDrawer={() => setOpenEdit(false)}
          isInMasterData={false}
          passengerProp={passenger}
          isAlloted={false}
        />
      )}
    </div>
  );
};

const Content = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const passengers = useGetReduxState().passengerform.passengers;
  const [saveToggle, setSaveToggle] = useState(0);

  const refactorMasterData = (masterData) => {
    let d = masterData.map((passenger) => {
      return {
        passengerName: passenger.name,
        passengerAge: parseInt(passenger.age),
        passengerGender: passenger.gender,
        passengerNationality: passenger.nationality,
        passengerFoodChoice: passenger.food,
        passengerCardNumber: passenger.passport,
        psgnConcDOB: passenger.dob,
      };
    });
    return d;
  };

  const saveData = async () => {
    await savePassengerList(refactorMasterData(passengers));
  };

  useEffect(() => {
    if (saveToggle > 0) {
      saveData();
    }
    setSaveToggle(1);
  }, [JSON.stringify(passengers)]);

  const lang = useGetReduxState().behaviour.lang;

  return (
    <Container>
      <button
        onClick={() => {
          setOpenDrawer(true);
        }}
      >
        <FontAwesomeIcon icon={faPlus} style={{ marginRight: "10px" }} />
        {lang === "en" ? (
          <span>Add New Passenger</span>
        ) : (
          <span>नया यात्री जोड़ें</span>
        )}
      </button>
      <div
        style={{
          width: "95%",
          borderTop: "1px solid gainsboro",
          margin: "20px auto",
        }}
      ></div>

      {lang === "en" ? <h4>Passenger List</h4> : <h4>यात्री सूची</h4>}

      <div className="passengers">
        {passengers.map((passenger) => {
          return <Passenger passenger={passenger} />;
        })}
      </div>
      {openDrawer && (
        <AddPassenger
          passengerProp={null}
          isInMasterData={false}
          handleCloseDrawer={() => setOpenDrawer(false)}
          isAlloted={false}
        />
      )}
    </Container>
  );
};

const AddPassengersDialog = ({ handleClose }) => {
  return (
    <Drawer
      showCross={true}
      showHeaderSeperator={false}
      tatkal={false}
      fullHeight={false}
      footer={<React.Fragment></React.Fragment>}
      header={<React.Fragment></React.Fragment>}
      content={<Content />}
      handleCloseDrawer={handleClose}
    />
  );
};

export default AddPassengersDialog;
