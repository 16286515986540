import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .trains-found {
    font-size: 16px;
    font-weight: 500;
    color: #444444;
    text-align: left;
    flex-shrink: 0;
    margin-right: 8px;
  }
`;
export default Container;
