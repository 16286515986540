const translations = {
  en: {
    header: "Payment successful!",
    text1: "You have completed your payment.",
    text2: "AMOUNT PAID",
    text3: "TXN",
    text4: "Please wait...",
  },
  hi: {
    header: "भुगतान सफल!",
    text1: "आपने अपना भुगतान पूरा कर लिया है।",
    text2: "भुगतान की गई राशि",
    text3: "TXN",
    text4: "कृपया प्रतीक्षा करें...",
  },
  gu: {
    header: "ચુકવણી સફળ રહી હતી!",
    text1: "તમે તમારી ચુકવણી પૂર્ણ કરી લીધી છે.",
    text2: "ચૂકવેલ રકમ",
    text3: "TXN",
    text4: "રાહ જુઓ...",
  },
};

export default translations;
