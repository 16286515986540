import FooterContainer from "./Footer.styles";
const img1 = require("../../Assets/Local/corover.png");
const img2 = require("../../Assets/Local/Bharat.png");
const Footer = () => {
  return (
    <FooterContainer>
      <span>Powered by </span>
      <a
        href="https://corover.ai/"
        target="_blank"
        title="CoRover® Pvt. Ltd."
        tabIndex={0}
        aria-label="Powered by CoRover"
      >
        <img src={img1} alt="" />
        {/* <img style={{width:'45px'}} src={img1} alt="" /> */}
        <sup>®</sup>
        </a>
        {/* <h3 >|</h3>
        <img style={{width:'70px'}} src={img2} alt="" /> */}
      
    </FooterContainer>
  );
};

export default Footer;
