import moment from "moment";
import { ENTER_PNR } from "./constants";

const getCxpayload = (flowStep, query, data) => {
  if (!flowStep) return null;

  switch (flowStep) {
    case ENTER_PNR:
      return {
        pnr: query,
        enquiryType: "ALL",
      };

    case "SELECT_PNR_FOR_CANCEL":
      return {
        pnr: data.pnr,
      };
    case "SELECT_PASSENGERS_FOR_CANCEL":
      return {
        passengerToken: data,
      };

    case "SELECT_PNR_FOR_BOARDING":
      return {
        pnr: data.pnr,
        trainNo: data.trainNumber,
        journeyDate: moment(data.date).format("YYYYMMDD"),
        source: data.source,
        destination: data.destination,
        jClass: data.class,
      };

    case "SELECT_BOARDING_FOR_CHANGE":
      return {
        boardingStation: data,
      };

    case "SELECT_PNR_FOR_TDR":
      return {
        PNR: data.pnr,
      };

    case "SELECT_PASSENGERS_FOR_TDR":
      return {
        passengerToken: data,
      };

    case "SELECT_REASON_FOR_TDR":
      return {
        reasonIndex: data,
      };

    case "SELECT_SOURCE":
      return {
        source: data,
      };
    case "SELECT_DESTINATION":
      return {
        destination: data,
      };

    case "SELECT_QUOTA":
      return {
        jQuota: data,
      };

    case "SELECT_DATE":
      return {
        journeyDate: data,
      };

    case "SELECT_TRAIN":
      return {
        jClass: data.class,
        trainNo: data.trainNumber,
        journeyDate: data.date, //YYYYMMDD
        source: data.source,
        destination: data.destination,
        jQuota: data.quota,
        trainName: data.trainName,
      };

    default:
      return null;
  }
};

export default getCxpayload;
