import styled from "styled-components";

const Component = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  padding: 12px 0px;

  div {
    text-align: center;
    font-size: 12px;
    color: #333333;
    font-weight: 500;
    width: 80px;
    padding: 10px;
    /* border: 1px solid gray; */
    cursor: pointer;
    border-radius: 5px;

    p {
      line-height: 1.2;
    }

    img {
      width: 24px;
      margin-bottom: 10px;
    }
  }
`;
export default Component;
