import moment from "moment";

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const makeArray = (array) => {
  if (Array.isArray(array)) return array;
  else return [array];
};

const parseDateTimeForBackendDumbFucks = (dateTime) => {
  if (
    !dateTime.includes("T") &&
    dateTime.includes(" ") &&
    dateTime.includes(".")
  )
    return moment(dateTime.split(".")[0], "YYYY-MM-DD hh:mm:ss");
  else if (dateTime.includes("T")) {
    return moment(dateTime.split(".")[0], "YYYY-MM-DDTh:m:s");
  } else return moment(dateTime, "MMM DD, YYYY, hh:mm:ss a");
};

const getBerthDetail = (detail) => {
  if (!detail || detail === "" || detail === "0") return "N/A";

  return detail;
};

const constructassengers = (passengers) => {
  return makeArray(passengers).map((passenger) => {
    return {
      age: passenger.passengerAge,
      atBooking: {
        status: passenger.bookingStatus,
        seat: getBerthDetail(passenger.bookingBerthNo),

        coach: getBerthDetail(passenger.bookingCoachId),
      },
      atCurrent: {
        status: passenger.currentStatus,
        seat: getBerthDetail(passenger.currentBerthNo),

        coach: getBerthDetail(passenger.currentCoachId),
      },
    };
  });
};

const getChartStatusColor = (status) => {
  let text = status.toLowerCase();
  if (text.includes("not") && text.includes("prepared")) return "red";
  if (text.includes("prepared")) return "green";

  return "orange";
};

const parseData = (data) => {
  return {
    pnr: data.pnrNumber,
    passengers: constructassengers(data.passengerList),
    bookingDate: moment(data.bookingDate, "YYYYMMDD").format("DD MMMM, YYYY"),
    source: data.boardingPoint,
    destination: data.destinationStation,
    dateOfJourney: parseDateTimeForBackendDumbFucks(data.dateOfJourney).format(
      "DD MMMM, YYYY",
    ),
    chartStatus: data.chartStatus,
    chartStatusColor: getChartStatusColor(data.chartStatus),
    journeyClass: data.journeyClass,
    quota: data.quota,
    trainName: toTitleCase(data.trainName),
    trainNumber: data.trainNumber,
  };
};

export default parseData;
