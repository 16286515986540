const translations = {
  en: {
    label: {
      food: "Select Catering Service Option",
      berth: "Select Berth Choice",
    },
    mandatory: "Selecting Child Berth is Mandatory for this journey",
    fullFare: "Full Fare will be applicable for children with berth",
    footer: "Continue",
    select: {
      food: "Select Catering Service Option for ",
      berth: "Select Berth Choice for",
      both: "Select Preferences for ",
    },
  },
  hi: {
    label: {
      food: "फ़ूड चॉइस का चयन करें",
      berth: "बर्थ चॉइस का चयन क",
    },
    mandatory: "इस यात्रा के लिए चाइल्ड बर्थ का चयन करना अनिवार्य है",
    fullFare: "बर्थ वाले बच्चों के लिए पूरा किराया लागू होगा",
    footer: "जारी रखें",
    select: {
      food: "फ़ूड चॉइस का चयन",
      berth: "बर्थ चॉइस का चयन",
      both: "अपनी प्राथमिकताएं चुनें",
    },
  },
  gu: {
    label: {
      food: "ખોરાકની પસંદગી પસંદ કરો",
      berth: "જન્મ પસંદગી પસંદ કરો",
    },
    mandatory: "આ સફર માટે બાળજન્મની પસંદગી કરવી ફરજિયાત છે.",
    fullFare: "બર્થ ધરાવતા બાળકો માટે સંપૂર્ણ ભાડું લાગુ થશે.",
    footer: "ચાલુ રાખો",
    select: {
      food: "ખોરાકની પસંદગી",
      berth: "જન્મ પસંદગીની પસંદગી",
      both: "તમારી પસંદગીઓ પસંદ કરો",
    },
  },
};

export default translations;
