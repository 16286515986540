import styled from "styled-components";

const Container = styled.div`
  position: relative;

  > * {
    line-height: 1;
    font-family: "Montserrat", sans-serif;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;
export default Container;
