import { PNR_FLOW } from "./constants";
import getFlowName from "./getFlowName";
import {
  bookingSteps,
  cancelTicketSteps,
  changeBoardingSteps,
  pnrSteps,
  tdrSteps,
} from "./getSteps";

export const getFlowStep = ({ nextContext, options, context, isRefund }) => {
  const flowName = getFlowName({ nextContext, options, context, isRefund });
  const stepNumber = parseInt(nextContext.split(",")[1]) - 1 || 0;

  if (options && context) return "REFUND_OPTIONS";

  if (isRefund) return "ENTER_PNR";

  if (nextContext === "06d8305e-0a87-47eb-834d-a86734de5892,5")
    return "RENDER_REFUND_STATUS";

  switch (flowName) {
    case "BOOKING_FLOW":
      return bookingSteps(stepNumber);
    case PNR_FLOW:
      return pnrSteps(stepNumber);
    case "CANCEL_FLOW":
      return cancelTicketSteps(stepNumber);
    case "BOARDING_FLOW":
      return changeBoardingSteps(stepNumber);
    case "TDR_FLOW":
      return tdrSteps(stepNumber);

    default:
      return "";
  }
};
