const translations = {
  en: {
    header: "Book Your Ticket",
    text1: "Complete your booking by following 2 steps",
    text2: "Payment",
    text3: "OTP Verification",
    continue: "Continue to Booking",
  },
  hi: {
    header: "अपना टिकट बुक करें",
    text1: "2 चरणों का पालन करके अपनी बुकिंग पूरी करें",
    text2: "भुगतान",
    text3: "OTP सत्यापन",
    continue: "बुकिंग जारी रखें",
  },
  gu: {
    header: "તમારી ટિકિટ બુક કરો",
    text1: "2 પગલાંઓ અનુસરીને તમારું આરક્ષણ પૂર્ણ કરો.",
    text2: "ચુકવણી",
    text3: "ઓટિપી પ્રમાણીકરણ",
    continue: "બુકિંગ ચાલુ રાખો",
  },
};

export default translations;
