const translations = {
  en: {
    ticket: "E-ticket",
    ers: "ERS",
    boarding: "Change Boarding",
    cancel: "Cancel Ticket",
    refund1: "Refund Status",
    refund2: "Check Refund Status",
    tdr: "File TDR",
  },
  hi: {
    ticket: "E-टिकट",
    ers: "ERS",
    boarding: "बोर्डिंग बदलें",
    cancel: "टिकट रद्द करें",
    refund1: "धनवापसी की स्थिति",
    refund2: "धनवापसी की स्थिति",
    tdr: "TDR फाइल करें",
  },
  gu: {
    ticket: "ઇ-ટિકિટ",
    ers: "ERS",
    boarding: "બોર્ડિંગ બદલો",
    cancel: "ટિકિટ રદ કરો",
    refund1: "રિફંડની સ્થિતિ",
    refund2: "રિફંડની સ્થિતિ",
    tdr: "ફાઇલ TDR",
  },
};

export default translations;
