import { User } from "./types";

const intialUser: User = {
  name: "",
  irctcId: "",
  email: "",
  mobile: "",
  passengers: [],
  savedPassengers: [],
};

const reducer = (user: User = intialUser, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        name: action.user.name,
        irctcId: action.user.irctcId,
        email: action.user.email,
        mobile: action.user.mobile,
        passengers: action.user.passengers,
        savedPassengers: action.user.savedPassengers,
      };

    case "SET_PASSENGERS_USER":
      return {
        ...user,
        passengers: action.value,
      };

    case "SET_SAVED_PASSENGERS_USER":
      return {
        ...user,
        savedPassengers: action.value,
      };

    default:
      return user;
  }
};

export default reducer;
