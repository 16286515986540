import ScrollContainer from "react-indiana-drag-scroll";
import useButtonQueries from "../../../../../../../Hooks/useButtonQueries/useButtonQueries";
import useGetReduxState from "../../../../../../../Hooks/useGetReduxState";
import Container from "./styles";
import langText from "./translations";
import { ReactNode, useRef } from "react";

const arrow = require("../../../../../../../Assets/Local/quick-button.png");
const refundIcon =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/refund.svg";
const pnrIcon = "https://d3upbvvdvllr10.cloudfront.net/dishav3/pnr.svg";
const trainBlue =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/train-blue.svg";
const boarding =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/bording-station.svg";
const history =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/history.svg";
const cancel =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/cancel-ticket-refactored.png";

const QuickButtons = () => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const ref = useRef<HTMLDivElement>();
  const {
    useBook,
    useCancel,
    useChangeBoarding,
    usePNR,
    useRefund,
    useBookingHistory,
  } = useButtonQueries();

  const scrollLeft = () => {
    ref.current.scrollLeft -= 150;
  };

  const scrollRight = () => {
    ref.current.scrollLeft += 150;
  };

  return (
    <Container>
      <img
        src={arrow}
        alt=""
        onClick={scrollLeft}
        style={{
          width: "18px",
          transform: "rotate(180deg)",
          marginRight: "8px",
          cursor: "pointer",
        }}
      />
      <ScrollContainer style={{ scrollBehavior: "smooth" }} innerRef={ref}>
        <div className="icon" onClick={useBook}>
          <img style={{ width: "16px" }} src={trainBlue} alt="" />
          <p>{langText[lang].book}</p>
        </div>
        <div className="icon" onClick={useRefund}>
          <img style={{ width: "19px" }} src={refundIcon} alt="" />
          <p>{langText[lang].refund}</p>
        </div>
        <div className="icon" onClick={usePNR}>
          <img style={{ width: "26px" }} src={pnrIcon} alt="" />
          <p>{langText[lang].pnr}</p>
        </div>
        <div className="icon" onClick={useCancel}>
          <img style={{ width: "24px" }} src={cancel} alt="" />
          <p>{langText[lang].cancel}</p>
        </div>
        <div className="icon" onClick={useBookingHistory}>
          <img style={{ width: "26px" }} src={history} alt="" />
          <p>{langText[lang].history}</p>
        </div>
        <div className="icon" onClick={useChangeBoarding}>
          <img style={{ width: "21px" }} src={boarding} alt="" />
          <p>{langText[lang].boarding}</p>
        </div>
      </ScrollContainer>
      <img
        style={{ width: "18px", marginLeft: "8px", cursor: "pointer" }}
        src={arrow}
        alt=""
        onClick={scrollRight}
      />
    </Container>
  );
};

export default QuickButtons;
