import { Message } from "../Reducers/types";
import { ADD_NEW_MESSAGE, DROP_MESSAGES } from "./constants";

export const addNewMessage = (message: Message) => {
  const action = { type: ADD_NEW_MESSAGE, message: message };
  return action;
};

export const dropMessages = () => {
  const action = { type: DROP_MESSAGES };
  return action;
};

export const dropNmessages = (n) => {
  const action = { type: "DROP_N_MESSAGES", value: n };
  return action;
};
