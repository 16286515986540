import styled from "styled-components";

const Container = styled.div`
  font-family: "Montserrat", sans-serif;
  padding: 15px;

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 72px;
  }

  .quota-expanded {
    display: inline-block;
    text-align: center;
    border: 1px solid black;
    margin-right: 10px;
    color: black;

    cursor: pointer;

    font-weight: 600;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 0px;
  }

  .selected-quota {
    display: inline-block;
    text-align: center;
    border: 1px solid #326abb;
    margin-right: 10px;
    color: #0b74fa;
    cursor: pointer;
    background-color: #326abb;
    color: white;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 0px;
  }
`;

export default Container;
