import { irctc } from "../../../Assets/Remote/images";
import "./styles.css";
const IrctcLogo = () => {
  return (
    <div className="logo-container">
      <img src={irctc} alt="" />
    </div>
  );
};

export default IrctcLogo;
