import { getStationName } from "../../../Helpers/getStationName";
import Container from "./styles";
const ir = require("../../../Assets/Local/indian-railway.png");
const irctc = require("../../../Assets/Local/irctc.png");
const disha = require("../../../Assets/Local/disha-pass.png");
const arrow = require("../../../Assets/Local/ers-arrow.png");

const azadi = require("../../../Assets/Local/azadi.png");
const g20 = require("../../../Assets/Local/g20.png");

const Header = ({
  source,
  destination,
  departureTime,
  departureDate,
  arrivalTime,
  arrivalDate,
  boarding,
  boardingDate,
}) => {
  return (
    <Container>
      <h4>Electronic Reservation Slip - Normal User</h4>

      <div className="logos">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          <img src={ir} alt="" className="ir" />
        </div>

        <img src={disha} alt="" className="disha" />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          <img src={irctc} alt="" className="irctc" />
        </div>
      </div>

      <div className="journey-ers">
        <div>
          <span style={{ fontWeight: "bold" }}>Booked From</span>
          <p>
            {getStationName(source).toUpperCase()} ({source})
          </p>
          <h5>Start Date {departureDate}</h5>
        </div>
        <div>
          <img src={arrow} alt="" />
          <p style={{ fontWeight: "bold" }}>
            {getStationName(boarding)} ({boarding})
          </p>
          <p style={{ marginTop: "-2pt", fontWeight: "bold" }}>
            Departure* {departureTime} {boardingDate}
          </p>
        </div>

        <div>
          <span style={{ fontWeight: "bold" }}>To</span>
          <p>
            {getStationName(destination).toUpperCase()} ({destination})
          </p>
          <h5>
            Arrival* {arrivalTime} {arrivalDate}
          </h5>
        </div>
      </div>
    </Container>
  );
};

export default Header;
