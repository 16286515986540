import useGetReduxState from "../../../../Hooks/useGetReduxState";
const train =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/book.svg";
const disha =
  "https://cdn.jsdelivr.net/gh/corover/assets@18June/askdisha-bucket/Disha-400.svg";

const Header = () => {
  const lang = useGetReduxState().behaviour.lang;

  const langText = {
    en: {
      not: "Your ticket is not booked!",
      safe: "Dont Worry. Your money is safe.",
      deducted:
        "If money was debited from your account, it would be automatically refunded in 1 to 7 working days.",
    },
    hi: {
      not: "आपका टिकट बुक नहीं हुआ है!",
      safe: "चिंता मत करो। आपका पैसा सुरक्षित है।",
      deducted:
        "यदि आपके खाते से पैसा डेबिट हो गया था, तो यह 1 से 7 कार्य दिवसों में स्वचालित रूप से वापस कर दिया जाएगा।",
    },
    gu: {
      not: "તમારી ટિકિટ બુક નથી થઈ!",

      safe: "ચિંતા ન કરો. તમારા પૈસા સુરક્ષિત છે.",

      deducted:
        "જો તમારા ખાતામાંથી નાણાં ડેબિટ કરવામાં આવ્યા હોય, તો તે 1 થી 7 કાર્યકારી દિવસોમાં આપમેળે પરત કરવામાં આવશે.",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: "80px" }} src={train} alt="" />
        <img
          style={{ width: "50px", marginLeft: "-45px", marginTop: "65px" }}
          src={disha}
          alt=""
        />
      </div>
      <div
        style={{ textAlign: "center", marginTop: "-10px", maxWidth: "210px" }}
      >
        <p
          style={{
            margin: "0",
            fontSize: "18px",
            fontWeight: "600",
            color: "tomato",
            marginTop: "20px",
          }}
        >
          {langText[lang].not}
        </p>
        <p
          style={{
            margin: "0",
            fontSize: "14px",
            fontWeight: "500",
            color: "darkslategray",
            marginTop: "2px",
          }}
        >
          {langText[lang].safe}
        </p>

        <p
          style={{
            fontSize: "12px",
            fontWeight: "500",
            color: "#8a8a8a",
            lineHeight: "12px",
            textAlign: "center",
            marginTop: "8px",
          }}
        >
          {langText[lang].deducted}
        </p>
      </div>
    </div>
  );
};

export default Header;
