import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  font-size: 11pt;
  border-top: 2px solid;

  h4 {
    font-size: 13pt;
    text-decoration: underline;
  }

  div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .container {
    padding: 4px 10px;
    display: block;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    width: 100%;
  }

  .note {
    padding: 4px 10px;
    line-height: 1.3;
    span {
      display: inline-block;
      margin-right: 6px;
      font-weight: bold;
    }
  }
`;

export default Container;
