import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import useGetReduxState from "../../../../../../Hooks/useGetReduxState";
import Drawer from "../../../../../../UI/UIComponents/Drawer";
import { RadioSelect } from "../../../../../../UI/UIComponents/RadioSelect/RadioSelect";
import { TextBox } from "../../../../../../UI/UIComponents/TextBox/TextBox";
import langText from "./translations";
const Header = () => {
  const lang = useGetReduxState().behaviour.lang;
  if (lang === "en")
    return (
      <p style={{ margin: "0", fontSize: "18px", fontWeight: "400" }}>
        Additional Preferences <span>(optional)</span>
      </p>
    );

  if (lang === "gu")
    return (
      <p style={{ margin: "0", fontSize: "18px", fontWeight: "400" }}>
        વધારાની પસંદગીઓ <span>(વૈકલ્પિક)</span>
      </p>
    );

  return (
    <p style={{ margin: "0", fontSize: "18px", fontWeight: "400" }}>
      अतिरिक्त प्राथमिकताएं <span>(वैकल्पिक)</span>
    </p>
  );
};

const Content = ({ formvalues, setFormValues }) => {
  // const [isChecked, setIsChecked] = useState(formvalues.values.autoUpgradation);
  // const response = useRecoilValue(responseData);
  // const selected = useRecoilValue(selectedPassengers);
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const irctConfig = useGetReduxState().passengerform.irctcConfig;
  const passengers = useGetReduxState().passengerform.passengers;
  const getSelectedPassengers = () => {
    const temp = passengers.filter(
      (passenger) => passenger.isSelected === true,
    );

    return temp;
  };

  const numberOfPassengers = getSelectedPassengers().length;
  const getShowTrue = (x) => {
    switch (x) {
      case 0:
      case 4:
        return true;

      case 1:
        if (
          !irctConfig.isWaiting &&
          (irctConfig.trainClass == "2S" ||
            irctConfig.trainClass == "SL" ||
            irctConfig.trainClass.includes("A") ||
            irctConfig.trainClass == "CC" ||
            irctConfig.trainClass == "EC")
        )
          return true;
        else return false;

      case 2:
      case 3:
        if (
          !irctConfig.isWaiting &&
          (irctConfig.trainClass == "SL" ||
            irctConfig.trainClass == "2A" ||
            irctConfig.trainClass == "3A") &&
          numberOfPassengers >= 2
        )
          return true;
        else return false;

      case 5:
      case 6:
        if (!irctConfig.isWaiting) return true;
        return false;

      case 7:
        if (!irctConfig.isWaiting && numberOfPassengers >= 2) return true;
        else return false;
    }
  };

  const options = [
    {
      label: langText[lang].choices[0],
      value: "99",
      show: getShowTrue(0),
    },
    {
      label: langText[lang].choices[1],
      value: "1",
      show: getShowTrue(1),
    },
    {
      label: langText[lang].choices[2],
      value: "2",
      show: getShowTrue(2),
    },
    {
      label: langText[lang].choices[3],
      value: "3",
      show: getShowTrue(3),
    },

    {
      label: langText[lang].choices[4],
      value: "4",
      show: getShowTrue(4),
    },

    {
      label: langText[lang].choices[5],
      value: "5",
      show: getShowTrue(5),
    },

    {
      label: langText[lang].choices[6],
      value: "6",
      show: getShowTrue(6),
    },

    {
      label: langText[lang].choices[7],
      value: "7",
      show: getShowTrue(7),
    },
  ];

  const onChange = (e) => {
    let value = e.target.value;
    let values = formvalues.values;
    values.coach = value;
    let errors = formvalues.errors;
    if (value === "") {
      errors.coach = "";
    } else if (!/^[A-Za-z][0-9]{1,2}/.test(value)) {
      errors.coach = langText[lang].coachValid;
    } else {
      errors.coach = "";
    }
    setFormValues({ values: values, errors: errors });
  };

  const handleSelect = (value) => {
    let values = formvalues.values;
    let errors = formvalues.errors;
    values.reservationChoice = value;
    setFormValues({ values, errors });
  };

  const handleVoice = (value, id) => {
    let e = {
      target: {
        name: id,
        value: value,
      },
    };
    onChange(e);
  };

  return (
    <div style={{ width: "100%", padding: "8px 15px" }}>
      {!irctConfig.isWaiting && (
        <TextBox
          label={langText[lang].coachField}
          placeholder={langText[lang].coachPlaceholder}
          id={"journey-coach"}
          onChange={onChange}
          error={formvalues.errors.coach}
          value={formvalues.values.coach}
          type={"text"}
          handleVoice={handleVoice}
        />
      )}

      {/* <CheckBox
        label={langText[lang].auto}
        boxSize="18px"
        boxRadius={"2px"}
        fontColor={"#5c5c5c"}
        fontSize={"16px"}
        fontWeight={"500px"}
        lineHeight={"16px"}
        margin={"20px 0px 25px 2px"}
        space={"8px"}
        onCheck={handleCheck}
        isChecked={formvalues.values.autoUpgradation}
      /> */}
      {/* <TextBox label={"Company Name"} placeholder={"Enter Company Name"} /> */}

      {irctConfig.quota !== "TQ" && (
        <RadioSelect
          options={options}
          label={langText[lang].reservation}
          bordered={true}
          handleSelect={handleSelect}
          value={formvalues.values.reservationChoice}
          error={""}
        />
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          marginTop: "10px",
          color: "gray",
        }}
      >
        <FontAwesomeIcon
          icon={faExclamationCircle}
          style={{
            fontSize: "18px",
            marginRight: "5px",
          }}
        />
        <span style={{ fontSize: "16px", lineHeight: "16px" }}>
          {langText[lang].warning}
        </span>
      </div>

      <div style={{ marginBottom: "15px" }}></div>
    </div>
  );
};

const Footer = ({ handleClose, isDisabled }) => {
  const lang = useGetReduxState().behaviour.lang;

  return (
    <button
      style={{
        width: "100%",
        border: "none",
        backgroundColor: "#326abb",
        color: "white",
        fontSize: "18px",
        padding: "12px",
        borderRadius: "6px",
        cursor: "pointer",
        display: "block",
      }}
      disabled={isDisabled}
      onClick={handleClose}
    >
      {lang === "en" ? (
        <span>Add Preferences</span>
      ) : lang === "hi" ? (
        <span>आवेदन करें</span>
      ) : (
        <span>પસંદગીઓ ઉમેરો</span>
      )}
    </button>
  );
};

const AdditionalDrawer = ({ handleSubmit, handleCloseDrawer }) => {
  const additionalData = useGetReduxState().passengerform.additionalDetails;

  const initialFormValues = {
    values: additionalData,
    errors: {
      coach: "",
      autoUpgradation: "",
      reservationChoice: "",
    },
  };
  const [formValues, setFormValues] = useState(initialFormValues);

  const isDisabled = () => {
    if (
      formValues.values.autoUpgradation === false &&
      formValues.values.coach === "" &&
      formValues.values.reservationChoice === ""
    )
      return true;

    return false;
  };

  return (
    <Drawer
      header={<Header />}
      content={
        <Content formvalues={formValues} setFormValues={setFormValues} />
      }
      footer={
        <Footer
          handleClose={() => handleSubmit(formValues.values)}
          isDisabled={isDisabled()}
        />
      }
      handleCloseDrawer={handleCloseDrawer}
      showCross={true}
      tatkal={false}
      fullHeight={false}
      showHeaderSeperator={true}
    />
  );
};

export default AdditionalDrawer;
