const find =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/search.svg";
const compare =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/list.svg";
const enter =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/password.svg";
const booked =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/success.svg";

const Row = ({ img, text, subtext }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginLeft: "10px",
        marginTop: "10px",
      }}
    >
      <div
        style={{
          width: "25px",
          height: "25px",
          borderRadius: "50%",
          backgroundColor: "rgb(235, 238, 253)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "5px",
        }}
      >
        <img
          src={img}
          style={{
            width: "94%",
          }}
          // onClick={handleSwap}
          alt=""
        />
      </div>

      <p
        style={{
          margin: "0px 0px 0px 5px",
          color: "rgb(8, 8, 8)",
          fontSize: "12px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {text}
        <span
          style={{
            color: "gray",
            fontSize: "10px",
            fontWeight: "400",
            display: "block",
            marginLeft: "4px",
          }}
        >
          {subtext}
        </span>
      </p>
    </div>
  );
};

const HowWorks = ({ text, expanded }) => {
  return (
    <div
      style={{
        marginTop: "0px",
        maxHeight: expanded ? "500px" : "0px",
        overflow: "hidden",
        transition: "all 0.5s",
      }}
    >
      <Row img={find} text={text.find} subtext />
      <Row img={compare} text={text.compare} subtext />
      <Row img={enter} text={text.otp} subtext={text.otp2} />
      <Row img={booked} text={text.booked} subtext />
      <iframe
        width="100%"
        tabIndex={-1}
        height="300px"
        src="https://www.youtube.com/embed/gQcqoZvIieg"
        title="YouTube video player"
        style={{ border: "none", margin: "15px 0px", borderRadius: "10px" }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </div>
  );
};

export default HowWorks;
