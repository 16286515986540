const translations = {
  en: {
    listening: "Listening...",
    try: "Try Saying",
    suggestions: {
      book: "I want to book a Ticket",
    },
  },
  hi: {
    listening: "आपको सुन रही हूँ...",
    try: "यह कहने का प्रयास करें",
    suggestions: {
      book: "मुझे टिकट बुक करनी है",
    },
  },
  gu: {
    listening: "હું તમને સાંભળું છું...",
    try: "તે કહેવાનો પ્રયાસ કરો",
    suggestions: {
      book: "મારે ટિકિટ બુક કરાવવાની છે",
    },
  },
};

export default translations;
