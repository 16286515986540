import { useState } from "react";
import { CloseDrawer } from "../../../Helpers/EventEmitter";
import Drawer from "../../../UI/UIComponents/Drawer";
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";

const Sort = ({ sortMode, handleSelect, handleClose }) => {
  const [mode, setMode] = useState(sortMode);

  const onClick = (value) => {
    // CloseDrawer();
    // handleSelect(value);
    setMode(value);
  };

  const handleSubmit = () => {
    CloseDrawer();
    handleSelect(mode);
  };

  return (
    <Drawer
      content={<Content handleSelect={onClick} sortMode={mode} />}
      header={<Header />}
      footer={<Footer submit={handleSubmit} />}
      showCross={true}
      showHeaderSeperator={true}
      tatkal={false}
      fullHeight={false}
      handleCloseDrawer={handleClose}
    />
  );
};

export default Sort;
