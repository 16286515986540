import moment from "moment";
import AgentDetails from "./AgentDetails";
import Header from "./Header";
import Instructions from "./Instructions";
import OtherDetails from "./OtherDetails";
import Passengers from "./Passengers";
import PaymentDetails from "./PaymentDetails";
import Container from "./styles";
import Train from "./Train";
import { parseBooking } from "./utils";

const ERSTemplate = ({ ticketData }) => {
  const {
    pnr,
    trainNumber,
    trainName,
    jClass,
    quota,
    distance,
    source,
    boarding,
    boardingDate,
    destination,
    departureDate,
    departureTime,
    arrivalDate,
    arrivalTime,
    passengers,
    fares,
    bookingDate,
    orderId,
    QR,
    otherDetails,
  } = parseBooking(ticketData);

  return (
    <Container>
      <div className="border">
        <Header
          source={source}
          destination={destination}
          departureDate={moment(departureDate).format("DD-MMM-YYYY")}
          departureTime={departureTime}
          arrivalDate={moment(arrivalDate).format("DD-MMM-YYYY")}
          arrivalTime={arrivalTime}
          boarding={boarding}
          boardingDate={moment(boardingDate).format("DD-MMM-YYYY")}
        />
        <Train
          pnr={pnr}
          trainName={trainName}
          trainNumber={trainNumber}
          // printTime={moment().format("DD-MMM-YYYY hh:mm:ss") + " Hrs"}
          jClass={jClass}
          quota={quota}
          distance={distance}
          bookingDate={
            moment(bookingDate).format("DD-MMM-YYYY hh:mm:ss") + "HRS"
          }
        />
        <Passengers passengers={passengers} />
        <PaymentDetails fares={fares} orderId={orderId} qr={QR} />
        <AgentDetails />
      </div>
      <div className="border" style={{ marginTop: "0.7in" }}>
        <OtherDetails details={otherDetails} />
        <Instructions />
      </div>
    </Container>
  );
};

export default ERSTemplate;
