import styled from "styled-components";

const Container = styled.div`
  width: 100%;

  box-shadow:
    rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
  border-radius: 8px;
  margin-top: 15px;
  padding: 10px;
  position: relative;

  &:first-child {
    margin-top: 0px;
  }

  .info {
    width: 100%;
  }

  .second {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  .first-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    p {
      font-size: 14px;
      font-weight: 600;

      &:nth-child(1) {
        color: #009688;
      }
      &:nth-child(2) {
        font-size: 13px;
        color: #535353;
        border: 1px solid #86918f;
        padding: 2px 4px;
        border-radius: 2px;
        border-top-right-radius: 5px;
        background: white;
        position: absolute;
        top: 0px;
        right: 0px;
      }
    }
  }

  .train {
    font-size: 13px;
    color: #474646;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .third-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
      font-size: 12px;
      color: #2f5050;
      font-weight: 400;
    }
  }

  button {
    border: none;
    font-size: 14px;
    padding: 0px 15px;
    background: #326abb;
    color: white;
    border-radius: 8px;
    margin-left: 4px;
    cursor: pointer;
  }
`;

export default Container;
