import useGetReduxState from "../../../Hooks/useGetReduxState";
import Spinner from "../../../UI/UIComponents/Spinner";

const disha = "https://d3upbvvdvllr10.cloudfront.net/eticket/search.svg";

const text = {
  en: {
    wait: "Please Wait...",
    finding: "Finding Your Nearby Stations",
  },
  hi: {
    wait: "कृपया प्रतीक्षा करें...",
    finding: "मुझे आपके आस-पास के स्टेशन मिल रहे हैं",
  },
  gu: {
    wait: "રાહ જુઓ...",
    finding: "તમારા નજીકના સ્ટેશનો શોધો",
  },
};

const Loader = ({ showImg }) => {
  const lang = useGetReduxState().behaviour.lang;
  return (
    <div
      style={{
        position: "absolute",
        inset: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {showImg && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img src={disha} style={{ width: "50px" }} alt="" />
            <p
              style={{
                margin: "0",
                marginLeft: "10px",
                fontSize: "20px",
                color: "#5d5d5d",
                fontWeight: "500",
              }}
            >
              {text[lang].finding}
            </p>
          </div>
          <p
            style={{ fontSize: "16px", color: "dimgray", marginBottom: "20px" }}
          >
            {text[lang].wait}
          </p>
        </>
      )}

      <Spinner
        borderWidth={"5px"}
        activeColor={"#6495ed"}
        inactiveColor={"#f9f9f9"}
        speed={"1s"}
        size={"40px"}
      />
    </div>
  );
};

export default Loader;
