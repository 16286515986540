import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { thumbsFeedBack } from "../../Api/feedback";
import useGetReduxState from "../../Hooks/useGetReduxState";
import { Overlay } from "../../UI/UIComponents/Overlay";
import langText from "./translations";
const Like = ({ answerId }) => {
  const lang = useGetReduxState().behaviour.lang;
  useEffect(() => {
    thumbsFeedBack({
      answerId: answerId,
      feedback: "Like",
      comment: "",
    });
  }, []);
  return (
    <Overlay>
      <div
        style={{
          backgroundColor: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          padding: "30px 20px",
          borderRadius: "15px",
          width: "70%",
          textAlign: "center",
        }}
      >
        <h1 style={{ marginBottom: "18px", fontSize: "16px", fontWeight: 400 }}>
          {langText[lang].like}
        </h1>
        <FontAwesomeIcon
          icon={faThumbsUp}
          style={{
            color: "0b74fa",
            fontSize: "50px",
            animationName: "floating",
            animationDuration: "1.5s",
            animationIterationCount: "infinite",
            animationTimingFunction: "ease-in-out",
            transition: "0.5s",
          }}
        />
      </div>
    </Overlay>
  );
};

export default Like;
