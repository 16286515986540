import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  position: relative;
  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;

    &:last-child {
      margin-top: 0px;
    }

    button {
      width: 80%;
      flex-shrink: 1;
      font-size: 14px;
      background: white;
      cursor: pointer;

      font-weight: 500;
      /* padding: 5px 10px; */
      height: 34px;
      border-radius: 7px;
      border: none;
      box-shadow:
        rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        rgb(209, 213, 219) 0px 0px 0px 1px inset;
      margin: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 25px;
        margin-right: 5px;
      }
      div {
        margin-right: 10px;
      }
    }
  }
`;

export default Container;
