import { getClassName } from "../../../Helpers/getClassName";
import { getQuotaName } from "../../../Helpers/getQuotaName";

import Container from "./styles";

const Train = ({
  pnr,
  trainNumber,
  trainName,
  jClass,
  quota,
  distance,

  bookingDate,
}) => {
  return (
    <Container>
      <div className="first-row">
        <div>
          <span>PNR</span>
          <p>{pnr}</p>
        </div>

        <div>
          <span>Train No./Name</span>
          <p>
            {trainNumber}/{trainName}
          </p>
        </div>

        <div>
          <span>Class</span>
          <p>
            {getClassName(jClass)} ({jClass})
          </p>
        </div>
      </div>
      <div className="second-row">
        <div>
          <span>Quota</span>
          <p>
            {getQuotaName(quota)} ({quota})
          </p>
        </div>

        <div>
          <span>Distance</span>
          <p>{distance} KM</p>
        </div>

        <div>
          <span>Booking Date</span>
          <p>{bookingDate}</p>
        </div>
      </div>
    </Container>
  );
};

export default Train;
