const translations = {
  hi: {
    oops: "क्षमा करना! कुछ गलत हो गया है।",
    developers:
      "मेरे डेवलपर्स को इस मुद्दे के बारे में स्वचालित रूप से अधिसूचित किया गया है। आप कृपया कुछ समय बाद पुनः प्रयास कर सकते हैं।",
    home: "Home पर जाएं",
    again: "पुनः प्रयास करें",
  },
  en: {
    oops: "Oops! Something went wrong.",
    developers:
      "My developers have been notified automatically regarding this issue. You may please try again after some time.",
    home: "Back to Home",
    again: "Try Again",
  },
  gu: {
    oops: "માફ કરશો! કંઇક ખોટું થયું છે.",
    developers:
      "મારા વિકાસકર્તાઓને આ સમસ્યા વિશે આપમેળે સૂચિત કરવામાં આવ્યા છે. તમે કૃપા કરીને થોડા સમય પછી ફરીથી પ્રયાસ કરી શકો છો.",
    home: "ઘેર જાઓ.",
    again: "ફરી પ્રયાસ કરો",
  },
};

export default translations;
