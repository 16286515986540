const translations = {
  en: {
    insurance: {
      text: "Travel Insurance",
      subtext:
        "Do you want to opt for travel insurance worth Rs 0.45/person (Incl. of GST )?",
      yes: "Yes",
      no: "No",
    },
    gst: {
      text: "GST Details",
      subtext:
        "If you are booking ticket for official or commercial travel: By filling GST details of the company, the amount of tax paid on the ticket can be adjusted in respective company tax.",
    },
    email: {
      text: "Email Id",
      subtext:
        "After booking, you will recieve an email with your ticket on this email Id",
      placeholder: "Enter your Email ID",
    },
    boarding: {
      text: "Boarding Station",
      subtext:
        "While you may choose any station on the route of the train as your boarding point, you would have to pay the fare as applicable for Reservation From - Reservation To stations.",
    },
    additional: {
      text: "Additional Preferences",
      subtext:
        "The berths will be alloted based on system logic, depending upon availability at that point of time. If you need assured Lower Berths or assured accomodation in the same coach, please select this option.",
    },
    optional: "optional",
  },
  hi: {
    insurance: {
      text: "यात्रा बीमा",
      subtext:
        "क्या आप रु. 0.45/व्यक्ति (GST सहित) के यात्रा बीमा का विकल्प चुनना चाहते हैं?",
      yes: "हां",
      no: "नहीं",
    },
    gst: {
      text: "GST विवरण",
      subtext:
        "यदि आप आधिकारिक या व्यावसायिक यात्रा के लिए टिकट बुक कर रहे हैं: कंपनी के GST विवरण भरकर, टिकट पर चुकाए गए कर की राशि को संबंधित कंपनी कर में समायोजित किया जा सकता है।",
    },
    email: {
      text: "ईमेल Id",
      subtext:
        "बुकिंग के बाद, आप इस ईमेल Id पर अपने टिकट के साथ एक ईमेल प्राप्त करेंगे",
      placeholder: "अपनी ईमेल Id दर्ज करें",
    },
    boarding: {
      text: "बोर्डिंग स्टेशन ",
      subtext:
        "जबकि आप ट्रेन के मार्ग पर किसी भी स्टेशन को अपने बोर्डिंग बिंदु के रूप में चुन सकते हैं, आपको Reservation From - Reservation To स्टेशनों के लिए लागू किराए का भुगतान करना होगा।",
    },
    additional: {
      text: "अतिरिक्त प्राथमिकताएं",
      subtext:
        "बर्थ का आवंटन उस समय उपलब्धता के आधार पर सिस्टम लॉजिक के आधार पर किया जाएगा। यदि आपको सुनिश्चित लोअर बर्थ या एक ही कोच में सुनिश्चित आवास की आवश्यकता है, तो कृपया इस विकल्प का चयन करें।",
    },
    optional: "वैकल्पिक",
  },
  gu: {
    insurance: {
      text: "મુસાફરી વીમો",
      subtext:
        "શું તમારી પાસે રૂ. કોઈ વ્યક્તિ માટે મુસાફરી વીમો (જીએસટી સહિત) પસંદ કરવા માંગો છો?",
      yes: "હા.",
      no: "ના.",
    },
    gst: {
      text: "જીએસટીની વિગતો",
      subtext:
        "જો તમે સત્તાવાર અથવા વ્યવસાયિક સફર માટે ટિકિટ બુક કરાવી રહ્યાં હોવ તોઃ કંપનીની જીએસટી વિગતો ભરીને, ટિકિટ પર ચૂકવવામાં આવેલા કરની રકમને સંબંધિત કંપની કર સામે સમાયોજિત કરી શકાય છે.",
    },
    email: {
      text: "ઈ-મેલ આઈડી",
      subtext:
        "બુકિંગ કર્યા પછી, તમને આ ઇમેઇલ આઈડી પર તમારી ટિકિટ સાથે એક ઇમેઇલ પ્રાપ્ત થશે.",
      placeholder: "તમારું ઇમેઇલ આઈડી દાખલ કરો.",
    },
    boarding: {
      text: "બોર્ડિંગ સ્ટેશન",
      subtext:
        "જ્યારે તમે ટ્રેનના માર્ગ પર કોઈપણ સ્ટેશનને તમારા બોર્ડિંગ પોઇન્ટ તરીકે પસંદ કરી શકો છો, ત્યારે તમારે સ્ટેશનો માટે આરક્ષણ માટે લાગુ ભાડું ચૂકવવું પડશે.",
    },
    additional: {
      text: "વધારાની પસંદગીઓ",
      subtext:
        "બર્થની ફાળવણી તે સમયે ઉપલબ્ધતાના આધારે સિસ્ટમ લોજિકના આધારે કરવામાં આવશે. જો તમને એ જ કોચમાં બાંયધરીકૃત લોઅર બર્થ અથવા બાંયધરીકૃત રહેઠાણની જરૂર હોય, તો કૃપા કરીને આ વિકલ્પ પસંદ કરો.",
    },
    optional: "વૈકલ્પિક",
  },
};

export default translations;
