import axios from "axios";
import { getDSession } from "../Helpers/misc";
import store from "../Store";
import getHeaders, { isUnAuthorised } from "./getHeaders";
let cancelToken;

export const sendPhoneNumber = async (phoneNumber) => {
  let lang = "en";
  if (cancelToken) {
    cancelToken.cancel("Operation cancelled due to new request");
  }
  cancelToken = axios.CancelToken.source();

  const configuration = store.getState().app;
  const inputMode = store.getState().behaviour.input_mode;

  let payload: any = {
    source: window.navigator.userAgent,
    mobileNumber: phoneNumber,
    userToken: configuration.userToken,
    useOTP: true,
    audioUrl: null,
    inputType: inputMode,
    dSession: getDSession(),
    sessionId: configuration.sessionId,
    deviceId: configuration.deviceId,
    status: 1,
  };

  try {
    const response = await axios.post(
      `/dishaAPI/bot/login/${lang}`,

      payload,
    );

    return response.data;
  } catch (e) {
    await isUnAuthorised(e.response);
    return -1;
  }
};

export const verifyOTP = async ({ otp, otpuuid }) => {
  let lang = "en";
  if (cancelToken) {
    cancelToken.cancel("Operation cancelled due to new request");
  }
  cancelToken = axios.CancelToken.source();

  const configuration = store.getState().app;
  const inputMode = store.getState().behaviour.input_mode;
  let payload: any = {
    source: window.navigator.userAgent,
    otp: otp,
    otpuuid: otpuuid,
    userToken: configuration.userToken,
    audioUrl: null,
    inputType: inputMode,
    dSession: getDSession(),
    sessionId: configuration.sessionId,
    deviceId: configuration.deviceId,
    status: configuration.status,
  };

  try {
    const response = await axios.post(
      `/dishaAPI/bot/verifyLogin/${lang}`,
      payload,
    );

    return response.data;
  } catch (e) {
    await isUnAuthorised(e.response);
    return -1;
  }
};

export const updateName = async (name) => {
  if (cancelToken) {
    cancelToken.cancel("Operation cancelled due to new request");
  }
  cancelToken = axios.CancelToken.source();

  const configuration = store.getState().app;

  let payload: any = {
    name: name,
    userToken: configuration.userToken,
    deviceId: configuration.deviceId,
  };

  try {
    const response = await axios.post(`/dishaAPI/bot/addUserName`, payload, {
      headers: getHeaders(),
    });

    return response.data;
  } catch (e) {
    await isUnAuthorised(e.response);
    return -1;
  }
};
