import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { getBerthName } from "../../../../Helpers/getBerthNames";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
import {
  getAge,
  getEntity,
  getFoodChoice,
  getGender,
  isInfant,
} from "../../../PassengerForm/utils";

const Passenger = ({ passenger }) => {
  const irctcConfig = useGetReduxState().passengerform.irctcConfig;
  return (
    <div
      style={{
        boxShadow:
          "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
        padding: "8px 12px",
        borderRadius: "7px",
        marginBottom: "8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <div style={{ color: "#3f3e3e", fontSize: "15px", fontWeight: "500" }}>
          {passenger.name}
        </div>
        <div style={{ fontSize: "12px", marginTop: "4px", color: "slategray" }}>
          <span style={{ display: "inline-block", marginRight: "4px" }}>
            {getEntity(passenger.age)}
          </span>
          <span style={{ display: "inline-block", marginRight: "4px" }}>|</span>
          <span style={{ display: "inline-block", marginRight: "4px" }}>
            {getGender(passenger.gender)}
          </span>
          <span style={{ display: "inline-block", marginRight: "4px" }}>|</span>
          <span style={{ display: "inline-block", marginRight: "4px" }}>
            {getAge(passenger.age)}
          </span>
          {irctcConfig.showFoodChoice &&
            // !isInfant(passenger.age) &&
            passenger.food && (
              <React.Fragment>
                <span>|</span>&nbsp;
                <span>{getFoodChoice(passenger.food)}</span>
              </React.Fragment>
            )}
        </div>
      </div>

      {passenger.berth && (
        // !isInfant(passenger.age) &&
        <div
          style={{
            fontSize: "12px",
            padding: "8px",
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
            borderRadius: "6px",
            color: "slategray",
            fontWeight: "500",
          }}
        >
          {getBerthName(passenger.berth)}
        </div>
      )}
    </div>
  );
};

const Passengers = ({ passengers, edit }) => {
  const lang = useGetReduxState().behaviour.lang;

  return (
    <div style={{ padding: "15px" }}>
      <div
        style={{
          marginBottom: "15px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4
          style={{
            color: "#474545",
            fontWeight: "500",
            fontSize: "14px",
          }}
        >
          {lang === "en" ? <span>Passengers</span> : <span>यात्रीगण</span>}(
          {passengers.length})
        </h4>
        <button
          style={{
            fontSize: "14px",
            background: "white",
            border: "none",
            color: "rgb(64, 64, 64)",
            padding: "6px 12px",
            borderRadius: "8px",
            cursor: "pointer",
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          }}
          onClick={edit}
        >
          {lang === "en" ? (
            <span>Edit Passengers</span>
          ) : (
            <span>यात्रियों को एडिट करें</span>
          )}{" "}
          <FontAwesomeIcon
            style={{ fontSize: "12px", marginLeft: "5px" }}
            icon={faPen}
          />{" "}
        </button>
      </div>
      {passengers.map((passenger) => (
        <Passenger passenger={passenger} />
      ))}
    </div>
  );
};

export default Passengers;
