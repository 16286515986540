import { useEffect } from "react";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
import Drawer from "../../../../UI/UIComponents/Drawer";

import Content from "./Content";
import ContentForFailed from "./ContentForFailed";
import Footer from "./Footer";
import { setUpcomingBookings } from "../../../../Store/Dispatcher/behaviour";

const Header = () => {
  const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;
  if (lang === "en") return <span>Booking Details</span>;

  return <span>बुकिंग विवरण</span>;
};

const BookingDetails = ({ tabNumber, data, handleCloseDrawer }) => {
  useEffect(() => {
    setUpcomingBookings(true);
  }, []);
  return (
    <Drawer
      header={<Header />}
      footer={
        <Footer
          ticketData={data.ticketData}
          bookingStatus={data.bookingStatus}
          handleCloseDrawer={handleCloseDrawer}
        />
      }
      content={
        tabNumber === 3 ? (
          <ContentForFailed data={data} />
        ) : (
          <Content data={data} />
        )
      }
      handleCloseDrawer={handleCloseDrawer}
      showCross={true}
      tatkal={false}
      fullHeight={false}
      showHeaderSeperator={true}
    />
  );
};

export default BookingDetails;
