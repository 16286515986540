import styled from "styled-components";

const getBorder = (props) => {
  let borderWidth = props.borderWidth;
  let borderColor = props.inactiveColor;

  return `${borderWidth} solid ${borderColor}`;
};

const getBorderTop = (props) => {
  let borderWidth = props.borderWidth;
  let borderColor = props.activeColor;

  return `${borderWidth} solid ${borderColor}`;
};

const getAnimation = (props) => {
  return `spin ${props.speed} linear infinite`;
};

const Container = styled.div`
  border: ${(props) => getBorder(props)};
  border-radius: 50%;
  border-top: ${(props) => getBorderTop(props)};
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  animation: ${(props) => getAnimation(props)};

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Container;
