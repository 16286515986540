import { useEffect, useState } from "react";
import { sendQuery as api } from "../../Api/sendQuery";
import {
  hideAlsoTry,
  showAlsoTry,
  playAudio,
  toggleLoader,
  setNextContext,
  setCurrent,
  setPrevCode,
  setIsFallback,
  setIsRefund,
  setFullScreen,
  stopAudio,
  setInputType,
} from "../../Store/Dispatcher/behaviour";
import {
  setJourneyDate,
  setJourneyDestination,
  setJourneyQuota,
  setJourneySource,
} from "../../Store/Dispatcher/journey";
import {
  addCustomResponseMessage,
  addResponseMessage,
  addUserMessage,
  deleteAllMessages,
  deleteLastNmessages,
} from "../../Store/Dispatcher/messages";
import BookingHistory from "../../Templates/BookingHistory";
import Login from "../../Templates/Login2";
import Passengerform from "../../Templates/PassengerForm";
import PNRStatus from "../../Templates/PNRStatus";
import Options from "../../Templates/Options";
import RefundStatus from "../../Templates/RefundStatus";
import SelectPassengers from "../../Templates/SelectPassengers";
import SelectPNR from "../../Templates/SelectPNR";
import useGetReduxState from "../useGetReduxState";
import { getFlowStep } from "./utils";
import getCxpayload from "./utils/getCxPayload";
import getTemplate from "./utils/getTemplate";
import BoardingChange from "../../Templates/BoardingChange";
import Trains from "../../Templates/Trains";
import SelectedTrain from "../../Templates/SelectedTrain";
import Maintainance from "../../Templates/Maintainance";
import getFlowName from "./utils/getFlowName";
import { isBlockTime } from "../../Helpers/misc";
import Fallback from "../../Templates/Fallback400";
import Fallback500 from "../../Templates/Fallback500";
import Eticket from "../../PDF/Eticket";
import BookingSuccess from "../../Templates/BookingResponse/Success/BookingSuccess";
import BookingFailure from "../../Templates/BookingResponse/Failure/BookingFailure";
import {
  setAdditionalDetails,
  setCurrentPassengerFormStep,
  setGstDetails,
  setIrctcConfig,
  setJourneyPreferences,
  setPassengers,
} from "../../Store/Dispatcher/passengerForm";
import { CloseDrawer } from "../../Helpers/EventEmitter";
import Maintainance_Server from "../../Templates/Trains/Maintainance";
import CancelTicket from "../../Templates/CancelTicket";
import Alert from "../../UI/UIComponents/ErrorAlert/Alert";
import { isAdult } from "../../Templates/PassengerForm/utils";
interface QueryPayload {
  inputType: "TEXT" | "VOICE" | "";
  query: string;
  data: any;
  isDirect?: boolean;
}

const useSendQuery = (changeCallback) => {
  const inputTypeInRedux = useGetReduxState().behaviour.input_mode;

  let input_mode: "TEXT" | "VOICE" = "TEXT";
  const empty: QueryPayload = {
    query: "",
    inputType: inputTypeInRedux,
    data: null,
  };
  const [queryPayload, sendQueryPayload] = useState<QueryPayload>(empty);
  const next_context = useGetReduxState().app.nextContext;
  const langCode = useGetReduxState().behaviour.queryLang;
  const lang = useGetReduxState().behaviour.lang;

  const passengers = useGetReduxState().passengerform.passengers;

  //in case user sends same query again
  const refreshQuery = () => {
    sendQueryPayload((prev) => ({ ...prev, query: "" }));
  };

  const recursiveSearch = (obj, results = []) => {
    const r = results;

    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (
        (key === "errorMessage" || key === "error") &&
        typeof value !== "object" &&
        typeof value === "string" &&
        value !== ""
      ) {
        r.push(value);
      } else if (typeof value === "object" && value) {
        recursiveSearch(value, r);
      }
    });
    return r;
  };

  const isValidPNR = (input) => {
    const validRegex = /^[0-9]*$/;
    if (validRegex.test(input) && input.length === 10) return true;
    else return false;
  };

  const isForPNR = (current) => {
    if (current === "ENTER_PNR") return true;
    return false;
  };

  const conf = useGetReduxState().app;
  const disabledSettings = useGetReduxState().app.disabledSettings;
  const current = useGetReduxState().behaviour.current;
  const getComponent = (CustomComponent, data) => {
    switch (CustomComponent) {
      case "BookingHistory":
        return <BookingHistory data={data} activeBookingTab={1} />;
      case "FailedBookings":
        return <BookingHistory data={data} activeBookingTab={3} />;
      case "PNRStatus":
        return <PNRStatus data={data} />;
      case "RefundOptions":
        return <Options />;
      case "RefundStatus":
        return <RefundStatus data={data} />;
      case "SelectBooking":
        return <SelectPNR data={data} />;
      case "SelectPassengersForCancel":
        return <SelectPassengers data={data} isFor={"CANCEL"} />;
      case "SelectPassengersForTDR":
        return <SelectPassengers data={data} isFor={"TDR"} />;
      case "PassengerForm":
        return <Passengerform data={data} />;
      case "CancelTicket":
        return <CancelTicket data={data} />;
    }
  };

  const isEndOfFlow = (nextContext) => {
    const ends = [
      "1918abb8-6b17-426e-a79e-990c3b194c9a,3",
      "06d8305e-0a87-47eb-834d-a86734de5892,5",
    ];
    return ends.includes(nextContext);
  };

  const send = async ({ showUserMessage }) => {
    if (queryPayload.inputType) {
      input_mode = queryPayload.inputType;
    } else input_mode = inputTypeInRedux;
    setInputType(input_mode);
    let flowStep = getFlowStep({
      nextContext: next_context || "",
      options: undefined,
      context: undefined,
      isRefund: false,
    });

    setFullScreen({ type: null, component: null });

    if (queryPayload.data === "SHOW_TRAINS") {
      setFullScreen({
        type: "TRAINS",
        component: <Trains isDirect={queryPayload.isDirect} />,
      });
      return;
    }

    if (queryPayload.query === "BOARDING_PASS") {
      addCustomResponseMessage({
        component: (
          <Eticket
            ticketData={queryPayload.data}
            isForCard={true}
            lang={"en"}
          />
        ),
        showAvatar: false,
      });
      refresh();
      return;
    }

    if (queryPayload.query === "BOOKING_PENDING") {
      addResponseMessage({
        text:
          langCode === "hi"
            ? "क्षमा करें, इस समय आपका टिकट कन्फर्म नहीं हो सकती. कृपया कुछ समय बाद अपना बुकिंग इतिहास जांचें।"
            : "Sorry, your ticket couldn't be confirmed at the moment. Please check your booking history after some time.",
        audioUrl: "",
        showAvatar: true,
        id: Date().toString(),
      });

      refresh();
      return;
    }

    if (
      queryPayload.query === "BOOKING_FAILURE" ||
      queryPayload.query === "BOOKING_SUCCESS"
    ) {
      let isSuccess = queryPayload.query === "BOOKING_SUCCESS";

      setCurrent("BOOKING_END");
      let msg = langCode === "hi" ? "मेरा टिकट बुक करें" : "Book My Ticket";
      let respMsg = "";
      let audio = "";
      if (isSuccess) {
        respMsg =
          langCode === "hi"
            ? "बधाई हो! आपका टिकट बुक हो गया है।"
            : "Congratulations! Your ticket is booked.";
        audio =
          langCode === "hi"
            ? "https://uiresource.blob.core.windows.net/selfonboard-res/69236ad5-4dea-4e23-894b-3d9938386a8b.wav"
            : "https://storage.corover.ai/chatbot-audio-bucket-aws/252d4d38-e3e5-47ac-9774-01a504ffef2a_en.mp3";
      } else {
        respMsg =
          langCode === "hi"
            ? "क्षमा करें, आपका टिकट बुक नहीं किया जा सका।"
            : "Sorry, your ticket could not be booked.";
        audio =
          langCode === "hi"
            ? "https://uiresource.blob.core.windows.net/selfonboard-res/f222370c-2542-4c09-a7da-62977d06071a.wav"
            : "https://storage.corover.ai/chatbot-audio-bucket-aws/9ea1cec5-720b-4376-9c2b-d3a804210e4d_en.mp3";
      }
      let id = "10124367" + Date.now().toString();
      addUserMessage({ text: msg });

      addResponseMessage({
        text: respMsg,
        audioUrl: audio,
        showAvatar: true,
        id: id,
      });
      if (input_mode === "VOICE") {
        playAudio({
          id: id,
          url: audio,
        });
      }
      toggleLoader();

      setTimeout(() => {
        toggleLoader();

        if (isSuccess) {
          addCustomResponseMessage({
            component: <BookingSuccess ticketData={queryPayload.data} />,
            showAvatar: false,
          });
        } else {
          addCustomResponseMessage({
            component: <BookingFailure msg={queryPayload.data.message} />,
            showAvatar: false,
          });
        }
      }, 1500);

      refresh();
      return;
    }
    if (showUserMessage) {
      if (flowStep === "SELECT_TRAIN")
        addCustomResponseMessage({
          component: <SelectedTrain />,
          showAvatar: false,
        });
      else addUserMessage({ text: queryPayload.query });
    }

    toggleLoader();

    if (isForPNR(current) && !isValidPNR(queryPayload.query)) {
      setTimeout(() => {
        toggleLoader();
        let msg =
          langCode === "hi"
            ? "कृपया एक मान्य PNR नंबर दर्ज करें"
            : "Please enter a valid PNR Number";
        addResponseMessage({
          text: msg,
          id: Date.now().toString(),
          showAvatar: true,
        });
      }, 1000);
      return;
    }

    setCurrent(flowStep);

    let cxpayload = getCxpayload(
      flowStep,
      queryPayload.query,
      queryPayload.data
    );

    const data = await api({
      query: queryPayload.query,
      next_context: next_context,
      langCode: langCode,
      cxpayload: cxpayload,
      isFallback: conf.isFallback,
    });

    // response err
    if (data.error && data.status) {
      if (data.status === 404) {
        addCustomResponseMessage({
          component: <Fallback />,
          showAvatar: false,
        });
      } else if (data.status === 401) {
        addCustomResponseMessage({
          component: (
            <Alert
              error={"Session Time Out!"}
              suberror={
                "It looks like your session has been timed out. Click OK to start a new session."
              }
              handleClose={() => window.location.reload()}
            />
          ),
          showAvatar: false,
        });
      } else {
        addCustomResponseMessage({
          component: (
            <Fallback500
              handleTry={() => {
                deleteLastNmessages(2);
                send({ showUserMessage: true });
                changeCallback();
              }}
              handleHome={() => {
                goToHome();
              }}
            />
          ),
          showAvatar: false,
        });
      }

      toggleLoader();

      return;
    }

    const flowName2 = getFlowName({
      nextContext: data.next_context || "",
      options: data.options,
      context: data.context,
      isRefund: data.isRefund,
    });

    if (
      [
        "PNR_FLOW",
        "CANCEL_FLOW",
        "BOOKING_FLOW",
        "BOARDING_FLOW",
        "TDR_FLOW",
        "BOOKING_HISTORY_FLOW",
      ].includes(flowName2)
    ) {
      if (
        disabledSettings.other &&
        [
          "PNR_FLOW",
          "CANCEL_FLOW",
          "BOARDING_FLOW",
          "TDR_FLOW",
          "BOOKING_HISTORY_FLOW",
        ].includes(flowName2)
      ) {
        toggleLoader();
        addCustomResponseMessage({
          component: <Maintainance isFromIRCTC={false} message={""} />,
          showAvatar: false,
        });
        return;
      }

      if (!disabledSettings.booking && "BOOKING_FLOW" === flowName2) {
        toggleLoader();
        addCustomResponseMessage({
          component: <Maintainance isFromIRCTC={false} message={""} />,
          showAvatar: false,
        });
        return;
      }

      if (isBlockTime().isBlock) {
        toggleLoader();
        addCustomResponseMessage({
          component: (
            <Maintainance isFromIRCTC={true} message={isBlockTime().message} />
          ),
          showAvatar: false,
        });

        return;
      }
    }

    if (data.apiReference && data.apiReference === "otp-send") {
      // showLogin();

      setFullScreen({
        type: "LOGIN",
        component: (
          <Login
            handleLogin={() => {
              send({ showUserMessage: false });
              changeCallback();
            }}
            handleCloseDrawer={() => {
              deleteLastNmessages(1);
            }}
          />
        ),
      });

      toggleLoader();
      return;
    }

    setIsFallback(Boolean(data.isFallback));
    setIsRefund(Boolean(data.isRefund));
    if (data.context) setPrevCode(data.context);
    else setPrevCode(null);

    if (data.next_context === "ade4a7db-d819-417d-832a-259307fd94c7,9")
      setNextContext("ade4a7db-d819-417d-832a-259307fd94c7,10");
    else setNextContext(data.next_context || "");

    if (data.cxStructure) {
      let values = data.cxStructure.entity;
      let source =
        values.filter((entity) => entity.type === "src")[0]?.value || "";
      if (source) setJourneySource(source);
      let destination =
        values.filter((entity) => entity.type === "des")[0]?.value || "";
      if (destination) setJourneyDestination(destination);
      let date =
        values.filter((entity) => entity.type === "journeyDate")[0]?.value ||
        "";
      if (date) setJourneyDate(date);
      let quota =
        values.filter((entity) => entity.type === "quota")[0]?.value || "GN";
      setJourneyQuota(quota);
      if (source && destination && date) {
        setFullScreen({
          type: "TRAINS",
          component: <Trains />,
        });

        toggleLoader();
        return;
      }
    }

    flowStep = getFlowStep({
      nextContext: data.next_context || "",
      options: data.options,
      context: data.context,
      isRefund: data.isRefund,
    });

    if (flowStep === "SELECT_BOARDING_FOR_CHANGE" && data.userInput?.data) {
      let bStations = data.userInput?.data.map((boarding) => boarding.value);

      const irctcConfig = {
        applicableFoodChoices: [],
        childBerthMandatory: false,
        showFoodChoice: false,
        showTravelInsurance: false,
        quota: "",
        berths: [],
        boardingStations: bStations,
        isGSTEnable: false,
        trainClass: "",
        isWaiting: false,
        maxInfants: 2,
        maxPassengers: 6,
      };
      setIrctcConfig({ ...irctcConfig });

      if (bStations.length < 2) {
        toggleLoader();
        let respMsg =
          langCode === "hi"
            ? "क्षमा करें, इस यात्रा के लिए कोई बीच का स्टेशन उपलब्ध नहीं है"
            : "Sorry, there are no intermediate stations available for this journey";
        let audio =
          langCode === "hi"
            ? "https://uiresource.blob.core.windows.net/selfonboard-res/1b0dcb0e-e645-46fc-b2af-f87c1a8905e1.wav"
            : "https://coroverbackendstorage.blob.core.windows.net/chatbot-audio-bucket/3cbce17c-2c1a-46a1-8bfa-12c5f9939f66_en.mp3";
        let id = Date.now().toString();
        addResponseMessage({
          text: respMsg,
          audioUrl: audio,
          showAvatar: true,
          id: id,
        });
        if (input_mode === "VOICE") {
          playAudio({
            id: id,
            url: audio,
          });
        }
        return;
      }
    }

    setCurrent(flowStep);

    let error: string = recursiveSearch(data)[0] || "";

    if (error) {
      toggleLoader();
      addResponseMessage({
        text: error,
        audioUrl: "",
        showAvatar: true,
        id: Date.now().toString(),
      });

      return;
    }

    if (data.renderTemplate || data.options || data.data) {
      toggleLoader();
      //recursively check
      if (data.options) {
        addResponseMessage({
          text: data.answer,
          audioUrl: data.audio,
          showAvatar: true,
          id: data.answerId,
        });

        toggleLoader();

        setTimeout(() => {
          toggleLoader();
          const CustomComponent = getTemplate("refundOptions");

          addCustomResponseMessage({
            component: getComponent(CustomComponent, null),
            showAvatar: false,
          });
        }, 1500);
      } else if (data.data?.newBoardingPoint) {
        addResponseMessage({
          text: data.answer,
          audioUrl: data.audio,
          showAvatar: true,
          id: data.answerId,
        });

        toggleLoader();

        setTimeout(() => {
          toggleLoader();

          addCustomResponseMessage({
            component: <BoardingChange data={data.data} />,
            showAvatar: false,
          });
        }, 1500);
      } else if (
        data.renderTemplate.data &&
        !data.renderTemplate.data.errorMessage
      ) {
        addResponseMessage({
          text: data.answer,
          audioUrl: data.audio,
          showAvatar: true,
          id: data.answerId,
          render: data.render || "",
        });

        toggleLoader();

        //match renderTemplate

        setTimeout(() => {
          toggleLoader();
          const CustomComponent = getTemplate(data.renderTemplate.templateName);

          addCustomResponseMessage({
            component: getComponent(CustomComponent, data.renderTemplate.data),
            showAvatar: false,
          });
        }, 1500);
      } else if (data.renderTemplate.showTrain) {
        addResponseMessage({
          text: data.answer,
          audioUrl: data.audio,
          showAvatar: true,
          id: data.answerId,
          render: data.render || "",
        });

        setFullScreen({
          type: "TRAINS",
          component: <Trains />,
        });
      } else {
        addResponseMessage({
          text: data.renderTemplate?.data?.errorMessage,
          audioUrl: data.audio,
          showAvatar: true,
          id: data.answerId,
        });
      }
    } else {
      toggleLoader();
      addResponseMessage({
        text: data.answer,
        audioUrl: data.audio,
        showAvatar: true,
        id: data.answerId,
      });
      if (data.alsoTry) {
        showAlsoTry(queryPayload.query);
      } else {
        hideAlsoTry();
      }
    }

    if (
      input_mode === "VOICE" &&
      data.audio &&
      data.render !== "trains" &&
      data.render !== "passengerDetails"
    ) {
      playAudio({
        id: data.answerId,
        url: data.audio,
      });
    }

    if (isEndOfFlow(data.next_context || "")) {
      refresh();
    }
  };

  const refresh = () => {
    CloseDrawer();
    setNextContext("");
    setIsFallback(false);
    setIsRefund(false);
    setFullScreen({ type: null, component: null });
    setCurrent("");
    setCurrentPassengerFormStep(1);
    refreshPassengerFormData();
  };

  const getInititialBerth = (age) => {
    if (isAdult(age)) return "NBC";

    return "";
  };

  const refreshPassengerFormData = () => {
    const resetPassengers = () => {
      return passengers.map((passenger) => {
        return {
          ...passenger,
          isSelected: false,
          berth: getInititialBerth(passenger.age),
        };
      });
    };

    const irctcConfig = {
      applicableFoodChoices: [],
      childBerthMandatory: false,
      showFoodChoice: false,
      showTravelInsurance: false,
      quota: "",
      berths: [],
      boardingStations: [],
      isGSTEnable: false,
      trainClass: "",
      isWaiting: false,
      maxInfants: 2,
      maxPassengers: 6,
    };

    const journeyPreferences = {
      irctcUserId: "",
      insuranceSelected: true,
      boardingStation: "",
      email: "",
      isEmailError: false,
      gstSelected: false,
      additionalSelected: false,
    };

    const gstDetails = {
      gstnumber: "",
      gstcompany: "",
      gstflat: "",
      gststreet: "",
      gstarea: "",
      gstpincode: "",
      gststate: "",
      gstcity: "",
    };

    const p = resetPassengers();

    setPassengers(p);
    setCurrentPassengerFormStep(1);
    setIrctcConfig(irctcConfig);
    setJourneyPreferences(journeyPreferences);
    setGstDetails(gstDetails);
    setAdditionalDetails(gstDetails);
  };

  const goToHome = () => {
    refresh();
    deleteAllMessages();
    hideAlsoTry();
    stopAudio();
  };

  useEffect(() => {
    if (queryPayload.query) {
      send({ showUserMessage: true });
      changeCallback();
      refreshQuery();
    }
  }, [queryPayload.query]);

  useEffect(() => {}, []);

  return { sendQueryPayload, refresh, goToHome, refreshPassengerFormData };
};

export default useSendQuery;
