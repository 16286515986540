import { getFAQs } from "../../Api/utils";
import { SET_FAQS } from "./constants";

export const setFaqsForLang = (lang) => async (dispatch) => {
  try {
    const data = await getFAQs(lang);
    dispatch({ type: SET_FAQS, lang: lang, data: data });
  } catch (error) {
    console.log(error.message);
  }
};
