const translations = {
  en: {
    text: "In order to complete booking, you will be asked for verification through an OTP sent to the mobile number and email registered with your IRCTC user id.",
    placeholder: "Enter IRCTC User ID Here...",
    button: "Verify and Proceed",
    dont: "Dont have an IRCTC User ID?",
    create: "Create an IRCTC Account",
  },
  hi: {
    text: "बुकिंग पूरी करने के लिए, आपसे IRCTC User Id के साथ पंजीकृत मोबाइल नंबर और ईमेल पर भेजे गए OTP के माध्यम से सत्यापन के लिए कहा जाएगा।",
    placeholder: "कृपया IRCTC User Id दर्ज करें...",
    button: "सबमिट करें और आगे बढ़ें",
    dont: "आपके पास IRCTC User Id नहीं है?",
    create: "यहाँ क्लिक करें",
  },
  gu: {
    text: "બુકિંગ પૂર્ણ કરવા માટે, તમને નોંધાયેલા મોબાઇલ નંબર પર મોકલવામાં આવેલા ઓ. ટી. પી. દ્વારા અને આઈ. આર. સી. ટી. સી. યુઝર આઈ. ડી. સાથે ઈમેલ દ્વારા પ્રમાણિત કરવાનું કહેવામાં આવશે.",
    placeholder: "મહેરબાની કરીને આઈઆરસીટીસી વપરાશકર્તા આઈડી દાખલ કરો...",
    button: "સબમિટ કરો અને આગળ વધો",
    dont: "શું તમારી પાસે આઈ. આર. સી. ટી. સી. વપરાશકર્તા ઓળખપત્ર નથી?",
    create: "અહીં ક્લિક કરો",
  },
};

export default translations;
