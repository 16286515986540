import useGetReduxState from "../../../Hooks/useGetReduxState";
import langText from "../translations";
import "../../styles.scss";

const Header = ({}) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  return <span>{langText[lang].heading}</span>;
};

export default Header;
