import { faLongArrowRight, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState } from "react";
import useGetReduxState from "../../Hooks/useGetReduxState";
import EditTrains from "../Trains/EditTrains";
import Container from "./styles";
const train =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/train-icon-list.png";
const TrainDetails = ({ findTrains }) => {
  const { source, destination, departureDate, trainName, trainnumber } =
    useGetReduxState().train;

  const [edit, setEdit] = useState(false);

  return (
    <Container>
      <div className="first">
        <p
          style={{
            color: "#000000",
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          {moment(departureDate, "YYYYMMDD").format("DD MMM")}
        </p>
        <span
          style={{
            fontSize: "9px",
            fontWeight: "500",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "black",
          }}
        >
          {moment(departureDate, "YYYYMMDD").format("dddd")}
        </span>
      </div>

      <div className="second">
        <div>
          <img src={train} alt="" />
          <p>
            {trainName} <span>({trainnumber})</span>
          </p>
        </div>
        <div>
          <p
            style={{
              fontSize: "12px",
              color: "#000000",
              fontWeight: "500",
            }}
          >
            {source}
          </p>

          <FontAwesomeIcon
            icon={faLongArrowRight}
            style={{
              marginRight: "20px",
              marginLeft: "20px",
              fontSize: "18px",
              color: "#717171",
            }}
          />

          <p
            style={{
              fontSize: "12px",
              color: "#000000",
              fontWeight: "500",
            }}
          >
            {destination}
          </p>
        </div>
      </div>
      <div className="third">
        <FontAwesomeIcon
          icon={faPen}
          style={{
            fontSize: "13px",
            color: "#424242",
            cursor: "pointer",
          }}
          onClick={() => {
            setEdit(true);
          }}
        />
      </div>
      {edit && (
        <EditTrains
          handleClose={() => setEdit(false)}
          findTrains={findTrains}
        />
      )}
    </Container>
  );
};

export default TrainDetails;
