import { useEffect } from "react";

import langText from "./translations";
import "./styles.scss";
import useGetReduxState from "../../../Hooks/useGetReduxState";

const success = "https://d3upbvvdvllr10.cloudfront.net/eticket/success.svg";
const PaymentSuccess = ({ amount, txnId }) => {
  const lang = useGetReduxState().behaviour.lang;
  var startAudio = null;
  let url =
    lang === "en"
      ? "https://coroverbackendstorage.blob.core.windows.net/chatbot-audio-bucket/d3f27d77-346f-4b12-8253-4b82f54b74f0_en.mp3"
      : "https://coroverbackendstorage.blob.core.windows.net/chatbot-audio-bucket/c1ee20f6-3af7-44a5-8cb5-11fc1034e708_hi.wav";

  useEffect(() => {
    startAudio = new Audio(url);
    startAudio.loop = false;
    if (false) startAudio.play();
  }, []);

  return (
    <div className="final-success2">
      <img src={success} alt="" />
      <h4>{langText[lang].header}</h4>
      <p>{langText[lang].text1}</p>
      <span>
        {langText[lang].text2} ₹{amount}
      </span>
      <h5>
        {langText[lang].text3} : {txnId}
      </h5>

      <h1>{langText[lang].text4}</h1>
    </div>
  );
};

export default PaymentSuccess;
