import styled from "styled-components";

const Container = styled.div`
  height: 280px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: white;
  z-index: 999999;
  border-top: 1px solid rgba(128, 128, 128, 0.31);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  .close {
    width: 15px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 999;
  }

  .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .speech-text {
    padding: 20px 22px;
    height: 50%;
    width: 100%;
    position: relative;

    .listening {
      width: 100%;
      font-size: 20px;
      text-align: center;
      color: #131313;
      margin: 0;
      margin-top: 25px;
    }

    .error {
      width: 100%;
      font-size: 20px;
      text-align: center;
      color: #131313;
      margin: 0;
      margin-top: 25px;
    }

    .caught-speech {
      margin: 0;
      font-size: 20px;
      text-align: center;
      position: absolute;
      top: 70%;
      transform: translateY(-50%);
      width: 100%;
      left: 0;
      padding: 0px 20px;
      transition: all 0.4s ease-in-out;
      font-weight: 500;
    }
  }

  .bottom-container {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .try-saying {
    position: absolute;
    width: 100%;
    left: 50%;
    top: -25px;
    transform: translateX(-50%);

    p:nth-child(1) {
      margin: 0;
      color: #606060;
      font-style: italic;
      margin-bottom: 2px;
      text-align: center;
    }

    p:nth-child(2) {
      margin: 0;
      color: #606060;
      text-align: center;
      font-size: 16px;
    }
  }

  .microphone-new-inactive {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #346db3;
    cursor: pointer;
  }

  .microphone-new-inactive img {
    width: 50px;
    cursor: pointer;
  }

  .microphone-new-active {
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00a1ff;
    cursor: pointer;
  }

  .microphone-new-active img {
    width: 50px;
    cursor: pointer;
  }

  .microphone-new-active::before {
    content: "";
    position: absolute;
    width: 60px;
    height: 60px;
    border: 1px solid #346db3;
    border-radius: 50%;
    animation: line-pulse-effects 1.5s linear infinite;
  }

  .microphone-new-active::after {
    content: "";
    position: absolute;
    width: 60px;
    height: 60px;
    border: 1px solid #346db3;
    border-radius: 50%;
    animation: line-pulse-effects 1.5s linear infinite;
    animation-delay: 0.4s;
  }

  @keyframes line-pulse-effects {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }

    50% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(1.4);
      opacity: 0;
    }
  }
`;

export default Container;
