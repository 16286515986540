import moment from "moment";
import store from "..";
import {
  addNewMessage,
  dropMessages,
  dropNmessages,
} from "../Actions/messages";
import {
  ResponseCustomMessage,
  ResponseTextMessage,
  UserMessage,
} from "./types";

export const addUserMessage = (message: UserMessage) => {
  store.dispatch(
    addNewMessage({
      text: message.text,
      type: "TEXT",
      sender: "USER",
      timeStamp: moment().fromNow(),
    }),
  );
};

export const addResponseMessage = (message: ResponseTextMessage) => {
  //Remove error codes

  let text = message.text.split("- (")[0];

  store.dispatch(
    addNewMessage({
      text: text,
      type: "TEXT",
      sender: "BOT",
      audioUrl: message.audioUrl,
      timeStamp: moment().fromNow(),
      showAvatar: true,
      id: message.id,
      render: message.render || "",
    }),
  );
};

export const addCustomResponseMessage = (message: ResponseCustomMessage) => {
  store.dispatch(
    addNewMessage({
      type: "COMPONENT",
      sender: "BOT",
      component: message.component,
      timeStamp: moment().fromNow(),
      showAvatar: message.showAvatar,
    }),
  );
};

export const deleteAllMessages = () => {
  store.dispatch(dropMessages());
};

export const deleteLastNmessages = (n) => {
  store.dispatch(dropNmessages(n));
};
