import { getAvailabilityColor } from "../../../Helpers/trains";
import styled from "styled-components";
import { CloseDrawer } from "../../../Helpers/EventEmitter";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import { useState } from "react";
import Breakup from "./Breakup";
import NotAllowed from "../../NotAllowed";
import moment from "moment";
import { table } from "console";
const disha =
  "https://cdn.jsdelivr.net/gh/corover/assets@18June/askdisha-bucket/Disha-400.svg";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;
  box-shadow: rgb(231 231 231 / 75%) 0px -4px 3px;
  font-family: Montserrat, sans-serif;

  .book-submit {
    width: 50%;
    padding: 16px 0px;
    border: none;
    background-color: rgb(50, 106, 187);
    color: white;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }

  .book-submit:disabled,
  .book-submit[disabled] {
    background-color: #d7e9ff;
    color: #bcbcbc;
    cursor: not-allowed;
  }
`;

const langText = {
  hi: {
    not: "चयनित विकल्प के लिए बुकिंग की अनुमति नहीं है",
    per: "प्रति सीट",
    book: "टिकट बुक करें",
    noTicket: "कोई टिकट उपलब्ध नहीं",
  },
  en: {
    not: "Booking is not allowed for selected option",
    per: "per Seat",
    book: "BOOK TICKET",
    noTicket: "No Tickets Available",
  },
  gu: {
    not: "પસંદ કરેલ વિકલ્પ માટે આરક્ષણની મંજૂરી નથી",
    per: "બેઠક દીઠ",
    book: "બુક ટિકિટ",
    noTicket: "કોઈ ટિકિટ ઉપલબ્ધ નથી",
  },
};

const Footer = ({
  selectedStatus,
  error,
  selectedTicket,
  tickets,
  onBook,
  selectedDay,
  classObj,
  showAvailabilityPopUp,
  setShowAvailabilityPopUp,
  selectedQuota,
}) => {
  const [tatkal, setTatkal] = useState(false);
  const [arp1, setArp] = useState(false);
  const handleSubmit = () => {
    if (selectedQuota === "GN" && selectedStatus.includes("#")) {
      setArp(true);
      // onBook();
    } else {
      setArp(false);
    }
    if (selectedQuota === "TQ" && selectedStatus.includes("#")) {
      setTatkal(true);
      // onBook();
    } else {
      setTatkal(false);
      CloseDrawer();
      onBook();
    }
    if (selectedTicket ==="2S" || selectedTicket ==="SL"){
      setShowAvailabilityPopUp("SLEEPER");
    } else {
      setShowAvailabilityPopUp("AC")
    }
  };

  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const [show, setShow] = useState(false);

  return (
    <Container>
      <div
        style={{
          width: "50%",
          height: "100%",
          padding: "0px 5px",
        }}
      >
        {selectedDay !== "" && (
          <>
            {getAvailabilityColor(selectedStatus) === "#A5A4A6" &&
              error === "" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src={disha}
                    style={{ width: "20px", marginRight: "8px" }}
                    alt=""
                  />
                  <p
                    style={{
                      margin: "0px",
                      lineHeight: "15px",
                      fontSize: "14px",
                    }}
                  >
                    {langText[lang].not}
                  </p>
                </div>
              )}
            {error !== "" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "18px",
                  color: "#505050",
                }}
              >
                <p style={{ margin: 0 }}>{langText[lang].noTicket}</p>
              </div>
            )}

            {getAvailabilityColor(selectedStatus) !== "#A5A4A6" &&
              error === "" && (
                <div style={{ padding: "5px 0px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <p
                      style={{
                        margin: "0px",
                        fontSize: "24px",
                        fontWeight: "600",
                        color: "#080808",
                        marginRight: "6px",
                      }}
                    >
                      {tickets.filter(
                        (ticket) => ticket.class === selectedTicket
                      )[0]?.fare &&
                        "₹" +
                          tickets.filter(
                            (ticket) => ticket.class === selectedTicket
                          )[0].fare}{" "}
                    </p>
                    <span
                      style={{
                        fontSize: "10px",
                        color: "#4B4B4B",
                        fontWeight: "500",
                      }}
                    >
                      {langText[lang].per}
                    </span>
                  </div>

                  <button
                    style={{
                      display: "block",
                      fontSize: "10px",
                      background: "none",
                      border: "none",
                      color: "rgb(50,106,187)",
                      fontWeight: "600",
                      textAlign: "center",
                      width: "100%",
                      marginTop: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShow(true)}
                  >
                    View Fare Breakup
                  </button>
                </div>
              )}
          </>
        )}
      </div>
      <button
        disabled={
          !(getAvailabilityColor(selectedStatus) !== "#A5A4A6" && error === "")
        }
        className="book-submit"
        onClick={handleSubmit}
      >
        {langText[lang].book}
      </button>
      {show && <Breakup onClose={() => setShow(false)} classObj={classObj} />}
      {(tatkal || arp1 )&& (
        <NotAllowed
          For={showAvailabilityPopUp}
          date={moment().format("YYYYMMDD")}
          arp1={arp1}
          handleClose={() => {
            setArp(false);
            setTatkal(false);
            setShowAvailabilityPopUp("");
          }}
        />
      )}
    </Container>
  );
};

export default Footer;
