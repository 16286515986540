import React from "react";
import Container from "./styles";
import Content from "./Components/Content";

import Sender from "./Components/Sender";
import useSendQuery from "../../Hooks/useSendQuery/useSendQuery";
import useGetReduxState from "../../Hooks/useGetReduxState";

import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../ErrorFallback";
import QuickButtons from "./Components/Content/Components/Messages/QuickButtons";

const Body = () => {
  const { sendQueryPayload } = useSendQuery(() => {});
  const handleSendQueryPayload = ({ query, inputType, data }) => {
    sendQueryPayload({ data: data, query: query, inputType: inputType });
  };
  const currentFullScreen = useGetReduxState().behaviour.fullScreenComponent;

  const { goToHome } = useSendQuery(() => {});
  const messages = useGetReduxState().messages;
  //
  const getRender = () => {
    if (currentFullScreen.component && currentFullScreen.type !== "LOGIN")
      return currentFullScreen.component;
    else
      return (
        <React.Fragment>
          {messages.length > 0 && <QuickButtons />}
          <Content />
          {/* <PassengerFormButtons /> */}
          {<Sender handleSendQueryPayload={handleSendQueryPayload} />}
          {/* <Select
            current={"tdr"}
            handleSendQueryPayload={handleSendQueryPayload}
          /> */}
        </React.Fragment>
      );
  };
  return (
    <Container id="corover-body">
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={goToHome}>
        {getRender()}
        {currentFullScreen.component &&
          currentFullScreen.type === "LOGIN" &&
          currentFullScreen.component}
      </ErrorBoundary>
      
    </Container>
  );
};

export default Body;
