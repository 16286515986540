export const IMAGE_DISHA_MESSAGE_AVATAR = require("./Local/disha-message-avatar.ico");
export const IMAGE_MESSAGE_SPEAKER =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/play.svg";
export const IMAGE_MESSAGE_MUTE =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/mute.svg";

export const IMAGE_MIC_OFF =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/mic-grey.png";
export const IMAGE_CROSS =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/close.png";

export const IMAGE_MIC =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/mic-refactored.png";
export const IMAGE_SEND =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/send-refactored.png";
export const IMAGE_LANG =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/language-refactored.png";
export const IMAGE_SWAP =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/arrows-refactored.png";
export const IMAGE_MIC_ON =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/speech-refactored.png";
