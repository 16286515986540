import { getStationName } from "../../../Helpers/getStationName";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import Container from "./styles";
import langText from "./translations";

const earlyMorning =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/early-morning.png";
const morning =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/morning.svg";
const afternoon =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/afternoon.svg";
const night =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/night.svg";

const Content = ({ mode, onClick }) => {
  const { source, destination } = useGetReduxState().journey;
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  return (
    <Container>
      <h1>
        {langText[lang].departure} <span>{getStationName(source)}</span>
      </h1>
      <div className="container">
        <div
          style={{
            border: mode === 11 ? "1px solid #0b74fa" : "1px solid transparent",
            background: mode === 11 ? "#f0f8ff" : "white",
          }}
          onClick={() => onClick(11)}
        >
          <img
            src={earlyMorning}
            alt=""
            style={{ width: "45px", marginBottom: "5px" }}
          />
          <p>{langText[lang].early}</p>
          <span>12 AM - 6AM</span>
        </div>
        <div
          style={{
            border: mode === 12 ? "1px solid #0b74fa" : "1px solid transparent",
            background: mode === 12 ? "#f0f8ff" : "white",
          }}
          onClick={() => onClick(12)}
        >
          <img src={morning} style={{ width: "36px" }} alt="" />
          <p>{langText[lang].morning}</p>
          <span>6 AM - 12PM</span>
        </div>
        <div
          style={{
            border: mode === 13 ? "1px solid #0b74fa" : "1px solid transparent",
            background: mode === 13 ? "#f0f8ff" : "white",
          }}
          onClick={() => onClick(13)}
        >
          <img
            src={afternoon}
            style={{ width: "36px", marginBottom: "4px" }}
            alt=""
          />
          <p>{langText[lang].afternoon}</p>
          <span>12 PM - 6PM</span>
        </div>
        <div
          style={{
            border: mode === 14 ? "1px solid #0b74fa" : "1px solid transparent",
            background: mode === 14 ? "#f0f8ff" : "white",
          }}
          onClick={() => onClick(14)}
        >
          <img
            src={night}
            alt=""
            style={{ width: "36px", marginBottom: "13px" }}
          />
          <p>{langText[lang].night}</p>
          <span>6 PM - 12AM</span>
        </div>
      </div>
      <div className="hr"></div>
      <h1>
        {langText[lang].arrival} <span>{getStationName(destination)}</span>
      </h1>
      <div className="container">
        <div
          style={{
            border: mode === 21 ? "1px solid #0b74fa" : "1px solid transparent",
            background: mode === 21 ? "#f0f8ff" : "white",
          }}
          onClick={() => onClick(21)}
        >
          <img
            src={earlyMorning}
            alt=""
            style={{ width: "45px", marginBottom: "5px" }}
          />
          <p>{langText[lang].early}</p>
          <span>12 AM - 6AM</span>
        </div>
        <div
          style={{
            border: mode === 22 ? "1px solid #0b74fa" : "1px solid transparent",
            background: mode === 22 ? "#f0f8ff" : "white",
          }}
          onClick={() => onClick(22)}
        >
          <img src={morning} style={{ width: "36px" }} alt="" />
          <p>{langText[lang].morning}</p>
          <span>6 AM - 12PM</span>
        </div>
        <div
          style={{
            border: mode === 23 ? "1px solid #0b74fa" : "1px solid transparent",
            background: mode === 23 ? "#f0f8ff" : "white",
          }}
          onClick={() => onClick(23)}
        >
          <img
            src={afternoon}
            style={{ width: "36px", marginBottom: "4px" }}
            alt=""
          />
          <p>{langText[lang].afternoon}</p>
          <span>12 PM - 6PM</span>
        </div>
        <div
          style={{
            border: mode === 24 ? "1px solid #0b74fa" : "1px solid transparent",
            background: mode === 24 ? "#f0f8ff" : "white",
          }}
          onClick={() => onClick(24)}
        >
          <img
            src={night}
            alt=""
            style={{ width: "36px", marginBottom: "13px" }}
          />
          <p>{langText[lang].night}</p>
          <span>6 PM - 12AM</span>
        </div>
      </div>
    </Container>
  );
};

export default Content;
