import store from "..";
import * as actions from "../Actions/utils";

export const setBookingIsPending = (value) => {
  store.dispatch(actions.setIsBookingPending(value));
};

export const setOtpIsPending = (value) => {
  store.dispatch(actions.setIsOtpPending(value));
};

export const setCountires = (value) => {
  store.dispatch(actions.setCountires(value));
};

export const setPopularStations = (value) => {
  store.dispatch(actions.setPopularStations(value));
};

export const setAllStations = (value) => {
  store.dispatch(actions.setAllStations(value));
};
