import { CloseDrawer } from "../../Helpers/EventEmitter";
import { setCurrentPassengerFormStep } from "../../Store/Dispatcher/passengerForm";
import Drawer from "../../UI/UIComponents/Drawer";
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";

const ReviewJourney = ({ data, handleClose, submit, loading }) => {
  return (
    <Drawer
      showCross={false}
      showHeaderSeperator={true}
      header={<Header status={data.status} />}
      content={
        <Content
          data={data}
          edit={() => {
            CloseDrawer();
            setCurrentPassengerFormStep(2);
          }}
        />
      }
      footer={
        <Footer price={data.fares.total} submit={submit} loading={loading} />
      }
      tatkal={false}
      fullHeight={false}
      handleCloseDrawer={handleClose}
      onEnter={submit}
    />
  );
};

export default ReviewJourney;
