import moment from "moment";
import store from "../../../../../../../Store";

import langTextPassengers from "../../../../../../../Templates/BookingHistory/Components/BookingDetails/Content/translations";

const parseDateTimeForBackendDumbFucks = (dateTime) => {
  if (
    !dateTime.includes("T") &&
    dateTime.includes(" ") &&
    dateTime.includes(".")
  )
    return moment(dateTime.split(".")[0], "YYYY-MM-DD hh:mm:ss");
  else if (dateTime.includes("T")) {
    return moment(dateTime.split(".")[0], "YYYY-MM-DDTh:m:s");
  } else return moment(dateTime, "MMM DD, YYYY, hh:mm:ss a");
};

const makeArray = (array) => {
  if (Array.isArray(array)) return array;
  else return [array];
};

const getGender = (code) => {
  const lang = store.getState().behaviour.lang;
  if (code === "M") return langTextPassengers[lang].male;
  if (code === "F") return langTextPassengers[lang].female;
  else return langTextPassengers[lang].transgender;
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const getBerthDetail = (detail) => {
  if (detail === "" || detail === "0" || !detail) return "N/A";

  return detail;
};

const getStatus = (status) => {
  const lang = store.getState().behaviour.lang;
  if (status.toLowerCase() === "can")
    return {
      label: langTextPassengers[lang].can,
      color: "#ff4c4c",
    };

  if (status.toLowerCase().includes("wl"))
    return {
      label: `${langTextPassengers[lang].wl} - ${status}`,
      color: "#ff9800",
    };

  return {
    label: langTextPassengers[lang].cnf,
    color: "#56c05a",
  };
};

const constructassengers = (passengers) => {
  const lang = store.getState().behaviour.lang;
  return makeArray(passengers).map((passenger) => {
    return {
      name: toTitleCase(passenger.passengerName),
      gender: getGender(passenger.passengerGender),
      age: passenger.passengerAge + langTextPassengers[lang].years,
      atBooking: {
        status: getStatus(passenger.bookingStatus),
        seat: getBerthDetail(passenger.bookingBerthNo),
        berth: getBerthDetail(passenger.bookingBerthCode),
        coach: getBerthDetail(passenger.bookingCoachId),
      },
      atCurrent: {
        status: getStatus(passenger.currentStatus),
        seat: getBerthDetail(passenger.currentBerthNo),
        berth: getBerthDetail(passenger.currentBerthCode),
        coach: getBerthDetail(passenger.currentCoachId),
      },
    };
  });
};

// const PayTmResultCodesForNoTxn;

// const getPayMode = (mode) => {
//   if (!mode) return mode;
//   if (mode.toUpperCase() == "DC") return "Debit Card";
//   if (mode.toUpperCase() == "CC") return "Credit Card";

//   return mode;
// };

const constructBooking = (data, bookingStatus) => {
  // const lang = store.getState().behaviour.lang;
  const bookingData = JSON.parse(data.bookingData);
  return {
    pnr: bookingData.pnrNumber,
    bookingStatus: bookingStatus,
    trainName: toTitleCase(bookingData.trainName),
    trainNumber: bookingData.trainNumber,
    source: bookingData.boardingStn,
    destination: bookingData.destStn,
    bookingDate: parseDateTimeForBackendDumbFucks(bookingData.bookingDate),
    orderId: bookingData.requestedClientTransactionId,
    class: bookingData.journeyClass,
    quota: bookingData.journeyQuota,
    departureDate: parseDateTimeForBackendDumbFucks(bookingData.boardingDate),
    departureDateTime: moment(
      parseDateTimeForBackendDumbFucks(bookingData.boardingDate).format(
        "YYYYMMDD"
      ) + bookingData.departureTime,
      "YYYYMMDDh:m"
    ),
    departureTime: bookingData.departureTime,
    arrivalDateTime: parseDateTimeForBackendDumbFucks(bookingData.destArrvDate),
    arrivalTime: bookingData.arrivalTime,
    distance: bookingData.distance,
    arrivalDate: parseDateTimeForBackendDumbFucks(bookingData.destArrvDate),
    amount:
      "₹" + (parseFloat(bookingData.totalCollectibleAmount) + 11.8).toFixed(2),
    passengers: constructassengers(bookingData.psgnDtlList),
    ticketData: constructBookingForETicket(data),
  };
};

export const constructBookingForETicket = (data) => {
  const bookingData = JSON.parse(data.bookingData);

  return {
    pnr: bookingData.pnrNumber,
    ...bookingData,
  };
};

const sortBookings = (isFailed, array) => {
  if (isFailed) {
    return array.sort((a, b) => a.date - b.date).reverse();
  } else {
    return array
      .sort((a, b) => a.departureDateTime - b.departureDateTime)
      .reverse();
  }
};

export const parseBookings = (bookings) => {
  let upcoming = bookings.map((booking) => constructBooking(booking, 1));
    return sortBookings(false, upcoming);
};
