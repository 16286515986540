import axios from "axios";
import store from "../Store";
import getHeaders from "./getHeaders";

export const generateCaptcha = async () => {
  const userToken = store.getState().app.userToken;

  try {
    const response = await axios.get(
      `/dishaAPI/bot/generateCaptcha/booking/${userToken}`,
      {
        headers: getHeaders(),
      },
    );

    return response.data;
  } catch (error) {
    return "";
  }
};

export const verifyCaptcha = async (text) => {
  const userToken = store.getState().app.userToken;

  try {
    const response = await axios.get(
      `/dishaAPI/bot/verifyCaptcha/booking/${userToken}/${text}`,
      {
        headers: getHeaders(),
      },
    );

    return response.data ? true : false;
  } catch (error) {
    return false;
  }
};
