const translations = {
  en: {
    berth: "Berth Choice",
    allot: "Allot Berth",
    addButton: "Add New Passenger",
    no: "No passengers have been added yet",
    error:
      "In Ladies Quota, only ladies travelling alone or with a child less than 12 years of age are eligible for journey.",
  },
  hi: {
    berth: "बर्थ चॉइस",
    allot: "बर्थ अलॉट करें",
    addButton: "नया यात्री जोड़ें",
    no: "अभी तक कोई यात्री नहीं जोड़ा गया है",
    error:
      "लेडीज़ कोटे में अकेले यात्रा करने वाली महिलाएं या 12 वर्ष से कम उम्र के बच्चे के साथ यात्रा करने की पात्रता होती है।",
  },
  gu: {
    berth: "જન્મ પસંદગી",
    allot: "બર્થ ફાળવો",
    addButton: "નવા મુસાફરને ઉમેરો",
    no: "હજુ સુધી કોઈ મુસાફરોનો ઉમેરો કરવામાં આવ્યો નથી.",
    error:
      "મહિલાઓનો ક્વોટા મહિલાઓને એકલા અથવા 12 વર્ષથી ઓછી ઉંમરના બાળક સાથે મુસાફરી કરવાનો અધિકાર આપે છે.",
  },
};

export default translations;
