import React from "react";
import useGetReduxState from "../../Hooks/useGetReduxState";
import Drawer from "../../UI/UIComponents/Drawer";
import Content from "./Content";

const TdrReasonPicker = ({ handleClose }) => {
  const lang = useGetReduxState().behaviour.lang;
  const head =
    lang === "en"
      ? "Select Reason for Filing TDR"
      : "TDR फाइल करने का कारण चुनें";
  return (
    <Drawer
      header={<span>{head}</span>}
      content={<Content />}
      footer={<React.Fragment></React.Fragment>}
      handleCloseDrawer={handleClose}
      showCross={true}
      showHeaderSeperator={true}
      tatkal={false}
      fullHeight={false}
    />
  );
};

export default TdrReasonPicker;
