import moment from "moment";

const makeArray = (array) => {
  if (Array.isArray(array)) return array;
  else return [array];
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const constructPassengers = (names) => {
  return makeArray(names).map((name) => toTitleCase(name));
};

const parseDateTimeForBackendDumbFucks = (dateTime) => {
  if (
    !dateTime.includes("T") &&
    dateTime.includes(" ") &&
    dateTime.includes(".")
  )
    return moment(dateTime.split(".")[0], "YYYY-MM-DD hh:mm:ss");
  else if (dateTime.includes("T")) {
    return moment(dateTime.split(".")[0], "YYYY-MM-DDTh:m:s");
  } else return moment(dateTime, "MMM DD, YYYY, hh:mm:ss a");
};

export const parseData = (data) => {
  return {
    cancellationId: data.cancellationId,
    pnr: data.pnrNo,
    date: parseDateTimeForBackendDumbFucks(data.cancelledDate),
    paidAmount: data.amountCollected,
    deductedAmount: data.cashDeducted,
    refundAmount: data.refundAmount,
    passengers: data.name ? constructPassengers(data.name) : [],
    message: data.message,
  };
};
