import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  border-bottom: 2px solid black;
  padding: 8px 10px;
  text-align: center;

  h5 {
    font-size: 13pt;
    font-weight: bold;
    text-decoration: underline;
    width: 100%;
    text-align: left;
  }

  .table {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2px;
    font-size: 11pt;

    div {
    }
  }

  .header {
    font-weight: bold;
  }
`;

export default Container;
