const translations = {
  en: {
    select: "Select",
    heading: "Select Boarding Station",
    current: "Your Current Boarding Station is:",
    following: "",
  },
  hi: {
    select: "चयन करें",
    heading: "बोर्डिंग स्टेशन का चयन करें",
    current: "आपका वर्तमान बोर्डिंग स्टेशन है:",
    following: "ट्रेन मार्ग पर कुछ अन्य स्टेशन निम्नलिखित हैं",
  },
  gu: {
    select: "પસંદ કરો",
    heading: "બોર્ડિંગ સ્ટેશન પસંદ કરો.",
    current: "તમારું વર્તમાન બોર્ડિંગ સ્ટેશન છેઃ",
    following: "ટ્રેન માર્ગ પરના કેટલાક અન્ય સ્ટેશનો નીચે મુજબ છેઃ",
  },
};

export default translations;
