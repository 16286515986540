import styled from "styled-components";

const Container = styled.div`
  background-color: #fff;
  position: relative;
  height: ${(props) => (props.mode === 0 ? "200px" : "fit-content")};

  .tabs {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    width: 100%;
    background: white;

    div {
      position: relative;
      width: 50%;
      text-align: center;

      span {
        display: inline-block;

        border-top: 2px solid #4782eb;
        position: absolute;
        top: 27px;

        left: 50%;
        width: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .new-speaker {
    width: 32px;
    height: 32px;
    background: white;
    padding: 1px;
    display: flex;
    justify-content: center;
    padding: 6px;
    border-radius: 50%;
    position: absolute;
    top: 72px;
    left: 18px;
    cursor: pointer;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .availability-container {
    border: solid 1px #dee2ea;
    padding: 5px 12px;
    margin-top: 3px;
    margin-bottom: 8px;
    border-radius: 18px;
    background-color: white;
    position: absolute;
    top: 60px;
    right: 0px;
    // width: 234px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .availability-container .top {
    position: static;
    margin: 0;
    color: #323e50;
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
  }

  .availability-container .details-wrap-availability {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
    margin-top: 5px;
  }

  .availability-container .details-wrap-availability .left {
  }

  .availability-container .details-wrap-availability .left .code {
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    color: #323e50;
    margin-bottom: 4px;
    // text-align: center;
  }

  .availability-container .details-wrap-availability .left .station {
    margin: 0;
    font-size: 10px;
    font-weight: 500;
    line-height: 9px;
    color: #323e50;
    //text-align: center;
  }

  .availability-container .details-wrap-availability .left .time {
    margin: 0;
    font-size: 11px;
    font-weight: 500;
    color: #a3a3a3;
    display: none;
  }

  .availability-container .details-wrap-availability .right {
  }

  .availability-container .details-wrap-availability .right .code {
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    text-align: right;
    color: #323e50;
    margin-bottom: 4px;
  }

  .availability-container .details-wrap-availability .right .station {
    margin: 0;
    font-size: 10px;
    font-weight: 500;
    line-height: 9px;
    text-align: right;
    color: #323e50;
  }

  .availability-container .details-wrap-availability .right .time {
    margin: 0;
    font-size: 11px;
    font-weight: 500;
    text-align: right;
    color: #a3a3a3;
    display: none;
  }

  .train-container {
    position: absolute;
    bottom: 11px;
    right: 0px;
  }

  .train {
    width: 100%;
    display: flex;
    // overflow: scroll;
    justify-content: space-between;
    transform: translateX(100%);
    cursor: pointer;
    // overflow: hidden !important;
    animation: move-train var(--animTime) linear 1s infinite forwards;
  }

  .train-anim {
  }
  .train:hover {
    animation-play-state: paused;
  }

  .train-car {
    margin: 5px;
    flex-shrink: 0;
    margin: 0 1px;
    position: relative;
    /* display: inline-block; */
    vertical-align: top;
    width: 190px;
    height: 52px;
    background-color: #2a2a72;
    background-image: linear-gradient(356deg, #2a2a72 0%, #009ffd 74%);
    padding-top: 1.8rem;
    padding-left: 1.3rem;
    border: 1px solid #00a1ff;
    border-top: 2px solid #00a1ff;
    border-radius: 7px;
  }
  /* .train-head,
.train-end {
  padding-left: 1.8rem;
  border-radius: 20rem 1.1rem 1.1rem;
  border-left: 2px solid #978a8e;
} */

  .train-end {
    background-color: #ae3013;
    background-image: linear-gradient(356deg, #5a1201 0%, #f63d13 74%);
    border: 1px solid #8e2710;
    border-top: 2px solid #ae3013;
    /* width: 14em; */
    padding-left: 0.8rem;
    border-radius: 20rem 1.1rem 1.1rem;
    border-left: 2px solid #978a8e;
  }

  /* .train-head .train-windows,
.train-end .train-windows {
  width: 17rem;
} */
  .train-head-window,
  .train-end-window {
    width: 2.5rem !important;
    border-top-left-radius: 2.5rem !important;
    margin-right: 2rem !important;
  }
  .train-end {
    // transform: rotateY(180deg);
  }
  .train-windows,
  .train-door {
    display: inline-block;
  }
  // .train-windows {
  //   width: 9rem;
  //   height: 5rem;
  // }
  .train-windows .w-left,
  .train-windows .w-right,
  .train-windows .train-head-window,
  .train-windows .train-end-window {
    display: inline-block;
    width: 29px;
    height: 18px;
    margin-right: 0.3rem;
    border-radius: 0.3rem;
    background: #9797a5;
  }
  .train-door {
    vertical-align: top;
    width: 24px;
    height: 34px;
    opacity: 0.2;
    padding: 0.4rem;
    margin-right: 0.5rem;
    background: #d0c9cc;
    position: relative;
    margin-top: -12px;
  }
  .train-door::before {
    content: "";
    width: 100%;
    height: 13px;
    background: #50464b;
    display: block;
  }

  .train-track {
    width: 100%;
    height: 45rem;
    background: #43383e;
  }

  .ledge {
    width: 100%;
    height: 3rem;
    margin-bottom: 4rem;
    border-bottom: 3px solid #392e34;
  }

  .pillar div {
    height: 38rem;
    background: #534a4f;
    display: inline-block;
    border-radius: 30rem 30rem 0 0;
  }
  .pillar-left {
    width: 12rem;
    margin-left: -6rem;
    margin-right: 5rem;
  }
  .pillar-middle,
  .pillar-right {
    border-right: 5px solid #968b8e;
  }
  .pillar-middle {
    width: 16rem;
    margin-right: 8rem;
  }
  .pillar-right {
    width: 18rem;
    margin-right: 0.8rem;
  }

  .train-end .schedule {
    right: 4px;
    width: 50%;
    top: 5px;
    font-size: 9px;
    border-radius: 5px;
    color: #333;
    background: #ffda4c;
  }
  .train-end .train-door {
    opacity: 1;
  }
  .schedule {
    font-weight: bold;
    right: 0;
    z-index: 99999;
    padding: 3px;
    font-size: 11px;
    width: 100%;
    background: #d2f4f4;
    top: 21px;
    color: #333;
    /* background: content-box; */
    position: absolute;
    text-align: center;
  }
  .info {
    right: 11%;
    background: #2c3b87;
    top: 4px;
    border-radius: 4px;
    padding: 1px 5px;
    font-size: 9px;
    color: #ececef;
    position: absolute;
  }
  .engine {
    width: 3rem !important;
  }
  .back {
    width: 117px;
  }
  .back .train-windows {
    width: 38px;
  }
  .back .train-door {
    opacity: 1;
  }
  .pnr-error {
    text-align: center;
    margin-top: 24px;
    margin: 0 auto;
    padding: 27px 3px;
    width: 246px;
    background-color: white;
    position: relative;
    overflow: hidden;
    p {
      color: #e66e04;
      font-size: 12px;
      margin: 0;
      font-weight: 700;
    }
  }
  .viewShedule {
    right: 18px;
    font-size: 9px;
    position: absolute;
    cursor: pointer;
  }

  @keyframes move-train {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(var(--animLeft));
    }
  }

  .list {
    width: 100%;
    border-collapse: collapse;

    td,
    th {
      border: 1px solid #dddddd;
      text-align: center;
      padding: 8px;
    }

    th {
      font-size: 12px;
    }

    td {
      font-size: 12px;
    }
  }
`;

export default Container;
