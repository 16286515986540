import moment from "moment";

import { isInfant } from "../../Templates/PassengerForm/utils";

const refactorPassengerForPayload = ({
  showFoodChoice,

  passenger,
  isAdult,
  index,
}) => {
  // let p = { ...passenger };

  if (isAdult) {
    let l = {
      passengerGender: passenger.gender,
      passengerName: passenger.name.trim(),
      passengerAge: parseInt(passenger.age),
      passengerNationality: passenger.nationality,
      adult: true,
      passengerBerthChoice: passenger.berth === "NBC" ? "" : passenger.berth,
      isAlloted: passenger.berth !== "",
      childBerthFlag: passenger.berth !== "",
      passengerSerialNumber: index + 1,
    };

    if (passenger.nationality !== "IN") {
      l["passengerCardNumber"] = passenger.passport;
      l["psgnConcDOB"] = moment(passenger.dob, "DD-MM-YYYY").format("YYYYMMDD");
      l["passengerIcardFlag"] = true;
      l["passengerCardType"] = "PASSPORT";
    }

    if (showFoodChoice) l["passengerFoodChoice"] = passenger.food;

    return l;
  } else {
    let l = {
      passengerGender: passenger.gender,
      passengerName: passenger.name.trim(),
      passengerAge: parseInt(passenger.age),
      adult: false,
    };

    return l;
  }
};

const getPassengerList = ({
  showFoodChoice,

  passengers,
}) => {
  const selectedPassengers = passengers.filter(
    (passenger) =>
      (passenger.isSelected && !isInfant(passenger.age)) ||
      (passenger.isSelected && passenger.berth !== "")
  );

  const passengerList = selectedPassengers.map((passenger, index) => {
    return refactorPassengerForPayload({
      showFoodChoice,

      passenger,
      isAdult: true,
      index,
    });
  });
  return passengerList;
};

const getInfantList = ({ showFoodChoice, passengers }) => {
  const selectedInfants = passengers.filter(
    (passenger) =>
      passenger.isSelected && isInfant(passenger.age) && passenger.berth === ""
  );

  const passengerList = selectedInfants.map((passenger, index) =>
    refactorPassengerForPayload({
      index,
      showFoodChoice,

      passenger,
      isAdult: false,
    }),
  );

  return passengerList;
};

const refactorGst = (gst) => {
  return {
    gstIn: gst.gstnumber,
    nameOnGst: gst.gstcompany,
    flat: gst.gstflat,
    street: gst.gststreet,
    area: gst.gstarea,
    pin: parseInt(gst.gstpincode),
    state: gst.gststate,
    city: gst.gstcity,
  };
};

const refactorMasterData = (masterData) => {
  let d = masterData.map((passenger) => {
    return {
      passengerName: passenger.name,
      passengerAge: parseInt(passenger.age),
      passengerGender: passenger.gender,
      passengerNationality: passenger.nationality,
      passengerFoodChoice: passenger.food,
      passengerCardNumber: passenger.passport,
      psgnConcDOB: passenger.dob,
    };
  });

  return d;
};

export const makeCxPayload = ({
  flags,
  preferences,
  passengers,
  gstDetails,
  additionalDetails,
}) => {
  let payload = {
    travelInsuranceOpted: preferences.insuranceSelected,
    loginId: preferences.irctcUserId.trim(),
    boardingStation: preferences.boardingStation,
    masterData: refactorMasterData(passengers),
    passengerList: getPassengerList({
      showFoodChoice: flags.showFoodChoice,
      passengers: passengers,
    }),
    infantList: getInfantList({
      showFoodChoice: flags.showFoodChoice,

      passengers: passengers,
    }),
    destAddress: {
      email: preferences.email,
    },
    saveList: true,
  };

  if (preferences.gstSelected) {
    payload["GST"] = refactorGst(gstDetails);
  } else {
    payload["GST"] = {};
  }

  payload["autoUpgradationSelected"] = additionalDetails.autoUpgradation;
  if (flags.quota === "TQ")
    payload["reservationChoice"] = additionalDetails.reservationChoice;
  if (preferences.additionalSelected) {
    if (flags.quota !== "TQ")
      payload["reservationChoice"] = additionalDetails.reservationChoice;
    payload["coachId"] = additionalDetails.coach;
  }

  return payload;
};
