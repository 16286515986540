const translations = {
  en: {
    show: "See Fare Details",
    hide: "Hide Fare Details",
    total: "Total Fare",
    ticket: "Ticket Fare",
    irctc: "IRCTC Convenience Fee",
    ctc: "CTC (Chatbot Ticket Charges incl. GST)",
    travel: "Travel Insurance (incl. of GST)",
  },
  hi: {
    show: "भुगतान विवरण देखें",
    hide: "भुगतान विवरण छुपाएं",
    total: "कुल शुल्क",
    ticket: "टिकट का शुल्क",
    irctc: "IRCTC सुविधा शुल्क",
    ctc: "CTC (चैटबॉट टिकट शुल्क - GST सहित)",
    travel: "यात्रा बीमा (GST सहित)",
  },
  gu: {
    show: "ચુકવણીની વિગતો જુઓ",
    hide: "ચુકવણીની વિગતો છુપાવો",
    total: "કુલ ખર્ચ",
    ticket: "ટિકિટ ફી",
    irctc: "આઈઆરસીટીસી ફેસિલિટી ચાર્જ",
    ctc: "સીટીસી (ચેટબોટ ટિકિટ ફી-જીએસટી સહિત)",
    travel: "મુસાફરી વીમો (જીએસટી સહિત)",
  },
};

export default translations;
