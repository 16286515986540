import { faLongArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseDrawer } from "../../../Helpers/EventEmitter";
import { getStationName } from "../../../Helpers/getStationName";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import {
  setJourneyDestination,
  setJourneySource,
} from "../../../Store/Dispatcher/journey";
import Loader from "./Loader";
import Station from "./Station";
import langText from "../translations";

const iconPopular =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/popural.svg";
const train =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/train-icon-list.png";
const buttonGps =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/gps-blue-refactored.svg";
const iconRecent =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/history-search.svg";
const iconNearby =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/gps-orange-refactored.svg";

const Content = ({
  stations,
  viewPopular,

  findingLoader,
  mode,
  findNearbyStations,
  searchLoader,
  showLabel,
  showNoStations,

  handleClick,
}) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  let recents = useGetReduxState().app.journeys;

  const onClickRecent = (journey) => {
    setJourneySource(journey.source);
    setJourneyDestination(journey.destination);
    CloseDrawer();
  };
  return (
    <div style={{ height: "100%", position: "relative", padding: "0px 15px" }}>
      {!findingLoader && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: "17px",

            backgroundColor: "#EBEEFD",
            width: "fit-content",
            padding: "6px 10px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          className="button"
          onClick={() => {
            if (mode !== "NEARBY") findNearbyStations();
            else viewPopular();
          }}
        >
          <div
            style={{
              width: "18px",
              height: "18px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "2px",
              background: "white",
            }}
            className="button-icon-container"
          >
            {mode !== "NEARBY" && (
              <img src={buttonGps} alt="" style={{ width: "70%" }} />
            )}
            {mode === "NEARBY" && (
              <img src={train} alt="" style={{ width: "70%" }} />
            )}
          </div>
          <p
            style={{
              margin: "0px 0px 0px 5px",
              fontSize: "11px",
              color: "#3F63F5",
              fontWeight: "500",
            }}
          >
            {mode !== "NEARBY" && <span>{langText[lang].find}</span>}
            {mode === "NEARBY" && <span>{langText[lang].viewAll}</span>}
          </p>
        </div>
      )}

      {showNoStations && (
        <div
          style={{
            position: "absolute",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            fontSize: "18px",
            color: "gray",
          }}
          className="no-station"
        >
          {langText[lang].no}
        </div>
      )}

      {mode === "POPULAR" &&
        !findingLoader &&
        showLabel &&
        recents &&
        recents.length > 0 && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginBottom: "3px",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  width: "18px",
                  height: "18px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2px",
                  background: "#FCF2D7",
                }}
              >
                <img src={iconRecent} alt="" style={{ width: "70%" }} />
              </div>
              <p
                style={{
                  margin: "0",
                  marginLeft: "5px",
                  fontSize: "15px",
                  color: "#080808",
                  fontWeight: "600",
                }}
              >
                {langText[lang].recent}
              </p>
            </div>

            <div style={{}}>
              {recents.map((recent) => (
                <p
                  style={{
                    margin: "0",

                    padding: "3px",
                    cursor: "pointer",
                    fontSize: "14px",
                    color: "#0B74FA",
                    fontWeight: "500",
                  }}
                  onClick={() => onClickRecent(recent)}
                >
                  <span>{getStationName(recent.source)}</span>{" "}
                  <FontAwesomeIcon
                    icon={faLongArrowRight}
                    style={{
                      marginRight: "6px",
                      marginLeft: "4px",
                      fontSize: "12px",
                      color: "#424242",
                    }}
                  />
                  <span>{getStationName(recent.destination)}</span>
                </p>
              ))}
            </div>
            <div
              style={{ marginTop: "10px", borderTop: "1px solid gainsboro" }}
            ></div>
          </div>
        )}

      {!findingLoader && !searchLoader && !showNoStations && showLabel && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            marginBottom: "2px",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              width: "18px",
              height: "18px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "2px",
              background: "#FCF2D7",
            }}
          >
            {mode === "POPULAR" && (
              <img src={iconPopular} alt="" style={{ width: "70%" }} />
            )}
            {mode === "NEARBY" && (
              <img src={iconNearby} alt="" style={{ width: "70%" }} />
            )}
          </div>
          <p
            style={{
              margin: "0",
              marginLeft: "5px",
              fontSize: "15px",
              color: "#080808",
              fontWeight: "600",
            }}
          >
            {!showNoStations &&
              !findingLoader &&
              showLabel &&
              mode === "POPULAR" && <span>{langText[lang].popular}</span>}
            {!showNoStations && !findingLoader && mode === "NEARBY" && (
              <span>{langText[lang].near}</span>
            )}
          </p>
        </div>
      )}

      {!showNoStations && !searchLoader && !findingLoader && (
        <div
          className="stations"
          style={{
            minHeight: "100px",
            display: "flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          {stations.map((station) => (
            <Station station={station} OnClick={handleClick} />
          ))}
        </div>
      )}

      {(findingLoader || searchLoader) && <Loader showImg={findingLoader} />}
    </div>
  );
};

export default Content;
