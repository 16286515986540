import { faCaretDown, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./TextBox.module.css";

export const SelectBox = ({
  isForSelect,
  label,
  value,
  handleClick,
  showIcon,
}) => {
  return (
    <div
      className={styles.textbox}
      style={{
        // backgroundColor: filled ? "#eeeeee" : "white",
        cursor: showIcon ? "pointer" : "auto",
      }}
      onClick={handleClick}
    >
      {label && (
        <label
          style={{
            cursor: "pointer",
          }}
          htmlFor="name"
        >
          {label}
        </label>
      )}

      <>
        <p>{value}</p>

        {showIcon && (
          <>
            {isForSelect ? (
              <FontAwesomeIcon
                icon={faCaretDown}
                style={{
                  color: "rgb(92, 91, 91)",
                  position: "absolute",
                  fontSize: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: "10px",
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faPen}
                style={{
                  color: "rgb(92, 91, 91)",
                  position: "absolute",
                  fontSize: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  right: "10px",
                }}
              />
            )}
          </>
        )}

        {/* <FontAwesomeIcon
          icon={faCaretDown}
          style={{
            color: "rgb(92, 91, 91)",
            position: "absolute",
            fontSize: "15px",
            top: "50%",
            transform: "translateY(-50%)",
            right: "10px",
          }}
        /> */}
      </>
    </div>
  );
};
