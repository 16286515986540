import useGetReduxState from "../../../Hooks/useGetReduxState";
import useSendQuery from "../../../Hooks/useSendQuery/useSendQuery";
import langText from "./translations";
const Maintainance = ({ message }) => {
  const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;

  const { goToHome } = useSendQuery(() => {});

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        flexDirection: "column",
        alignItems: "center",
        border: "2px solid #d6d6d6",
        borderRadius: "25px",
        display: "flex",

        justifyContent: "center",
        padding: "20px 12px",
        width: "90%",
      }}
    >
      <img
        style={{ width: "80px" }}
        src={
          "https://cdn.jsdelivr.net/gh/corover/assets@18June/askdisha-bucket/disha-sleeping.gif"
        }
        alt="Server Under Maintainance"
      />

      <div>
        <p
          style={{
            fontSize: "20px",
            marginTop: "16px",
            color: "#d75859",
            fontWeight: "600",
            marginBottom: "3px",
            textAlign: "center",
          }}
        >
          {langText[lang].header}
        </p>
        <p
          style={{
            fontSize: "16px",
            lineHeight: "20px",
            color: "#545454",
            margin: "0",
            paddingLeft: "7px",
            paddingRight: "7px",
            textAlign: "justify",
          }}
        >
          {message}
        </p>
      </div>
      <button
        style={{
          border: "none",
          background: "rgb(38, 90, 166)",
          color: "white",
          padding: "12px 15px",
          borderRadius: "5px",
          fontSize: "16px",
          cursor: "pointer",
          marginTop: "20px",
          width: "100%",
        }}
        onClick={goToHome}
      >
        {langText[lang].home}
      </button>
    </div>
  );
};

export default Maintainance;
