import Fare from "./Fare";
import Journey from "./Journey";
import Passengers from "./Passengers";
import Container from "./styles";

const Content = ({ data, edit }) => {
  return (
    <Container>
      <Journey train={data.train} />
      <div className="seperator"></div>
      <Passengers passengers={data.passengers} edit={edit} />
      <div className="seperator"></div>
      <Fare fares={data.fares} />
    </Container>
  );
};

export default Content;
