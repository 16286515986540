export const menu =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/menu.png";
export const cancel =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/cancel.png";
export const reset =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/home.png";
export const irctc =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/irctc.png";
export const beta =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/beta.png";
export const disha =
  "https://cdn.jsdelivr.net/gh/corover/assets@18June/askdisha-bucket/disha.svg";
