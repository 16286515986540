import store from "../../../../../Store";

const isValidEmail = (email) => {
  let lang = store.getState().behaviour.lang;
  if (email === "")
    return {
      isError: false,
      errorMsg: "",
    };
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false)
    return {
      isError: true,
      errorMsg:
        lang === "en"
          ? "Please enter a valid email"
          : "कृपया एक वैध ईमेल सबमिट करें",
    };

  return {
    isError: false,
    errorMsg: "",
  };
};

export const handleChangeEmail = (e) => {
  let value = e.target.value;
  let error = "";
  let v = isValidEmail(e.target.value);
  if (v.isError) error = v.errorMsg;
  else error = "";
  return { value, error };
};
