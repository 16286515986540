import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: 0px 10px;
  padding-bottom: 10px;
  /* height: 100%; */
`;

export default Container;
