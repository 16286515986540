import moment from "moment";
import { getBookingDetails } from "../../Api/irctc";
import { toTitleCase } from "../../Helpers/misc";

const parseDateTimeForBackendDumbFucks = (dateTime) => {
  if (
    !dateTime.includes("T") &&
    dateTime.includes(" ") &&
    dateTime.includes(".")
  )
    return moment(dateTime.split(".")[0], "YYYY-MM-DD hh:mm:ss");
  else if (dateTime.includes("T")) {
    return moment(dateTime.split(".")[0], "YYYY-MM-DDTh:m:s");
  } else return moment(dateTime, "MMM DD, YYYY, hh:mm:ss a");
};

export const parse = async (data) => {
  const d = await getBookingDetails(data.pnr);

  return {
    trainName: toTitleCase(d.trainName),
    trainNo: d.trainNumber,
    jClass: d.journeyClass,
    boarding: data.newBoardingPoint || "",
    dateTime: parseDateTimeForBackendDumbFucks(data.newBoardingDate),
    pnr: data.pnr,
  };
};
