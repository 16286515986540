import moment from "moment";
import { useState } from "react";
import { CloseDrawer } from "../../../../../Helpers/EventEmitter";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import { setJourneyBoarding } from "../../../../../Store/Dispatcher/journey";
import { setTrain } from "../../../../../Store/Dispatcher/train";
import BookDate from "../../../BookDate";
import Confirmation from "../../../Confirmation";
import Train from "../../../Train";
import Container from "./styles";

const Trains = ({ trains, updateCache, sendTrain }) => {
  const [expanded, setExpanded] = useState({ train: null, jClass: null });
  const journey = useGetReduxState().journey;
  const [confirmation, setConfirmation] = useState(null);
  const handleExpand = ({ jClass, train }) => {
    setExpanded({ jClass, train });
  };

  const getClassFiltered = (tickets) => {
    if (!tickets) {
      return [];
    }
    let ticketsNew = tickets.filter((element) => element.error === false);

    return ticketsNew;
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const getDestinationDate = (startDate, startTime, duration) => {
    let hoursStart = parseInt(startTime.split(":")[0]);
    let minutesStart = parseInt(startTime.split(":")[1]);
    let hoursDur = parseInt(duration.split(":")[0]);
    let minutesDur = parseInt(duration.split(":")[1]);
    let totalMinutes =
      (hoursDur + hoursStart) * 60 + (minutesDur + minutesStart);

    return moment(startDate, "YYYYMMDD")
      .add(totalMinutes, "minutes")
      .format("YYYYMMDD");
  };

  const book = ({ train, ticket }) => {
    let chosenTrain = {
      trainName: toTitleCase(train.trainName),
      trainnumber: train.trainNumber,
      source: train.source,
      destination: train.destination,
      departureTime: train.departureTime,
      departureDate: train.departureDate,
      duration: train.duration,
      arrivalTime: train.arrivalTime,
      arrivalDate: getDestinationDate(
        train.departureDate,
        train.departureTime,
        train.duration,
      ),
      quota: train.quota,
      class: ticket.class,
      status: ticket.availablityStatus,
    };

    setTrain(chosenTrain);
    setJourneyBoarding(train.source);
    sendTrain({
      jclass: ticket.class,
      trainNumber: train.trainNumber,
      date: train.departureDate,
      source: train.source,
      destination: train.destination,
      quota: train.quota,
      trainName: train.trainName,
    });
  };

  const confirm = ({ train, ticket }) => {
    // CloseDrawer();
    // setTimeout(() => {
    //   setExpanded({ train: null, jClass: null });
    // }, 1000);

    let obj = {
      train: train,
      ticket: ticket,
    };

    setConfirmation(obj);
  };

  const handleBookTicket = ({ train, ticket }) => {
    if (
      ticket.msg !== "" ||
      train.source !== journey.source ||
      train.destination !== journey.destination
    ) {
      confirm({ train, ticket });
    } else book({ train, ticket });
  };

  return (
    <Container>
      {trains.map((train) => (
        <Train
          train={train}
          getClassFiltered={getClassFiltered}
          handleExpand={handleExpand}
        />
      ))}
      {expanded.train && (
        <BookDate
          train={expanded.train}
          trainClass={expanded.jClass}
          getClassFiltered={getClassFiltered}
          handleBookTicket={handleBookTicket}
          handleClose={() => setExpanded({ train: null, jClass: null })}
          updateCache={updateCache}
        />
      )}

      {confirmation !== null && (
        <Confirmation
          handleClose={() => setConfirmation(null)}
          handleConfirm={book}
          confirmation={confirmation}
        />
      )}
    </Container>
  );
};

export default Trains;
