import { useState } from "react";
import { CloseDrawer } from "../../../../../Helpers/EventEmitter";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import {
  setAdditionalDetails,
  setJourneyPreferences,
} from "../../../../../Store/Dispatcher/passengerForm";
import AdditionalDrawer from "./AdditonalDrawer/AdditionalDrawer";
import langText from "../translations";
import { CheckBox } from "../../../../../UI/UIComponents/Checkbox";
import langText2 from "./AdditonalDrawer/translations";
import React from "react";

const img =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/additional1-refactored.png";
const info =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/info-refactored.png";

const Additional = () => {
  const [isOpen, setIsOpen] = useState(false);
  const pref = useGetReduxState().passengerform.journeyPreferences;
  const addPref = useGetReduxState().passengerform.additionalDetails;
  const additionalSelected = pref.additionalSelected;
  const irctConfig = useGetReduxState().passengerform.irctcConfig;
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const handleSubmit = (data) => {
    setAdditionalDetails(data);
    setJourneyPreferences({ ...pref, additionalSelected: true });
    CloseDrawer();
  };
  const [text, setText] = useState(false);

  const handleOpen = () => {
    if (!additionalSelected) {
      setIsOpen(true);
    } else setJourneyPreferences({ ...pref, additionalSelected: false });
  };

  const handleCheck = () => {
    let a = !addPref.autoUpgradation;
    setAdditionalDetails({ ...addPref, autoUpgradation: a });
  };

  const handleCheck2 = () => {
    let a = addPref.reservationChoice ? undefined : "4";
    setAdditionalDetails({ ...addPref, reservationChoice: a });
  };

  return (
    <React.Fragment>
      <div className="additional" style={{ padding: "10px 15px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              columnGap: "5px",
            }}
          >
            <img src={img} style={{ width: "22px" }} alt="" />
            <div>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "rgb(71 71 71)",
                }}
              >
                {langText[lang].additional.text}{" "}
              </p>
              <span
                style={{ fontSize: "12px", color: "gray", fontWeight: "600" }}
              >
                ({langText[lang].optional})
              </span>
            </div>

            <img
              onClick={() => setText((prev) => !prev)}
              style={{ width: "16px", cursor: "pointer" }}
              src={info}
              alt=""
            />
          </div>
          <div
            style={{
              width: "50px",
              height: "18px",
              backgroundColor: additionalSelected ? "#b9daf4" : "#ddd8d8",
              borderRadius: "15px",
              position: "relative",
              cursor: "pointer",
              transition: "0.3s all ease-in-out",
            }}
            onClick={handleOpen}
          >
            <span
              style={{
                display: "block",
                backgroundColor: "rgb(50, 106, 187)",
                height: "18px",
                width: "18px",
                borderRadius: "50%",
                position: "absolute",
                left: !additionalSelected ? "0%" : "100%",
                transform: additionalSelected && "translateX(-100%)",
                transition: "0.3s all ease-in-out",
              }}
            ></span>
          </div>
        </div>

        {text && (
          <span
            style={{
              fontSize: "12px",
              lineHeight: "1.1",
              display: "block",
              marginTop: "5px",

              color: "slategray",
            }}
          >
            {langText[lang].additional.subtext}
          </span>
        )}

        {isOpen && (
          <AdditionalDrawer
            handleSubmit={handleSubmit}
            handleCloseDrawer={() => setIsOpen(false)}
          />
        )}
      </div>
      <CheckBox
        label={langText2[lang].auto}
        boxSize="16px"
        boxRadius={"2px"}
        fontColor={"rgb(71, 71, 71)"}
        fontSize={"14px"}
        fontWeight={"600"}
        lineHeight={"16px"}
        margin={"5px 0px 12px 16px"}
        space={"8px"}
        onCheck={handleCheck}
        isChecked={addPref.autoUpgradation}
      />

      {irctConfig.quota === "TQ" && (
        <CheckBox
          label={langText2[lang].choices[4]}
          boxSize="16px"
          boxRadius={"2px"}
          fontColor={"rgb(71, 71, 71)"}
          fontSize={"14px"}
          fontWeight={"600"}
          lineHeight={"16px"}
          margin={"5px 0px 12px 16px"}
          space={"8px"}
          onCheck={handleCheck2}
          isChecked={addPref.reservationChoice}
        />
      )}
    </React.Fragment>
  );
};

export default Additional;
