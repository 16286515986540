import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { getQuotaName } from "../../../../../Helpers/getQuotaName";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import QuotaPicker from "../../../../../Pickers/QuotaPicker";
import Filter from "../../../Filter";
import Sort from "../../../Sort";
import Container from "./styles";
const filter =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/filter.svg";
const sort =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/sort.svg";
const Settings = ({
  sortMode,
  filterMode,
  handleSortSelect,
  handleFilterSelect,
  handleQuotaSelect,
}) => {
  // {handleQuotaChange, handleFilterChange, handleSortChange}
  const { quota, date } = useGetReduxState().journey;
  const [openDrawer, setOpenDrawer] = useState("");
  const lang = useGetReduxState().behaviour.lang;
  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          backgroundColor: "white",
          borderTop: "1px solid #e0e0e0",
          borderBottom: "1px solid #b1b1b1",
        }}
      >
        <span
          style={{
            fontSize: "11px",
            fontWeight: 600,
            color: "#111111",
            cursor: "pointer",
            width: "100%",
            textAlign: "center",

            padding: "3px 0",

            // backgroundColor: sortData.on ? "#d9f3ff" : "white",
            borderRight: "1px solid gainsboro",
          }}
          onClick={() => {
            setOpenDrawer("QUOTA");
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              margin: "0 auto",
              padding: "0px 5px",
            }}
          >
            <span
              style={{
                width: "95%",
                textAlign: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {getQuotaName(quota)} ({quota})
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCaretDown}
                style={{
                  color: "#111111",
                }}
              />
            </span>
          </div>
        </span>

        <span
          style={{
            fontSize: "11px",
            fontWeight: 600,
            color: "#111111",
            cursor: "pointer",
            width: "100%",
            textAlign: "center",

            padding: "3px 0",
            backgroundColor: sortMode !== 0 ? "#d9f3ff" : "white",
            borderRight: "1px solid gainsboro",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "5px",
          }}
          onClick={() => {
            if (sortMode === 0) setOpenDrawer("SORT");
            else handleSortSelect(0);
          }}
        >
          <img
            style={{ width: "20px", marginRight: "3px" }}
            src={sort}
            alt=""
          />{" "}
          {lang === "en"
            ? "Sort"
            : lang === "hi"
            ? "क्रमबद्ध करें"
            : "સૉર્ટ કરો"}
        </span>
        <span
          style={{
            fontSize: "11px",
            fontWeight: 600,
            color: "#111111",
            cursor: "pointer",
            width: "100%",
            textAlign: "center",
            height: "100%",
            padding: "6px 0",
            borderRight: "2px solid white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "5px",
            backgroundColor: filterMode !== 0 ? "#d9f3ff" : "white",
          }}
          onClick={() => {
            if (filterMode === 0) setOpenDrawer("FILTER");
            else handleFilterSelect(0);
          }}
        >
          {" "}
          <img
            style={{ width: "15px", marginRight: "3px" }}
            src={filter}
            alt=""
          />{" "}
          {lang === "en"
            ? "Filter"
            : lang === "hi"
            ? "फिल्टर करें"
            : "ફિલ્ટર કરો"}
        </span>
      </div>
      {openDrawer === "SORT" && (
        <Sort
          sortMode={sortMode}
          handleSelect={handleSortSelect}
          handleClose={() => setOpenDrawer("")}
        />
      )}
      {openDrawer === "FILTER" && (
        <Filter
          filterMode={filterMode}
          handleSelect={handleFilterSelect}
          handleClose={() => setOpenDrawer("")}
        />
      )}
      {openDrawer === "QUOTA" && (
        <QuotaPicker
          handleClose={() => setOpenDrawer("")}
          callBack={(value) => {
            if (value !== quota) handleQuotaSelect(value);
          }}
          currentDate={date}
        />
      )}
    </Container>
  );
};

export default Settings;
