import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { IMAGE_SWAP } from "../../../../../../../Assets";
import { getQuotaName } from "../../../../../../../Helpers/getQuotaName";
import { getStationName } from "../../../../../../../Helpers/getStationName";
import useGetReduxState from "../../../../../../../Hooks/useGetReduxState";
import DatePicker from "../../../../../../../Pickers/DatePicker";
import QuotaPicker from "../../../../../../../Pickers/QuotaPicker";
import StationPicker from "../../../../../../../Pickers/StationPicker";
import {
  setJourneyDestination,
  setJourneyQuota,
  setJourneySource,
} from "../../../../../../../Store/Dispatcher/journey";
import AlertWarning from "../../../../../../../UI/UIComponents/Warning/AlertWarning";
import Alert from "../../../../../../../UI/UIComponents/Alert";
import { addCustomResponseMessage } from "../../../../../../../Store/Dispatcher/messages";
// import Destination from "../../Selects/Destination";
// import JourneyDate from "../../Selects/JourneyDate";
// import Quotas from "../../Selects/Quotas";
// import Source from "../../Selects/Source";
import AlertError from "../../../../../../../UI/UIComponents/ErrorAlert/Alert";
import TextBox from "./TextBox";
import { escape } from "querystring";
import Maintainance from "../../../../../../../Templates/Trains/Maintainance";
import { transl_IRCTC_Maintainance as translation } from "../../../../../../../Templates/Trains/Maintainance/translations";
import { faHourglass1 } from "@fortawesome/free-solid-svg-icons";
import { text, warningText, errorText } from "./translation";
import ClearFilter from "../../../../../../../Templates/Trains/NoTrains/ClearFilter";

const BookingForm = ({ forEdit, findTrains }) => {
  const [openDrawer, setOpenDrawer] = useState("");
  const journey = useGetReduxState().journey;
  // const Journey = useRecoilValue(journeyAtom);
  // const JourneySetter = useSetRecoilState(setJourneyData);
  const lang = useGetReduxState().behaviour.lang;
  const disabledSettings = useGetReduxState().app.disabledSettings;
  const [source, setSource] = useState(journey.source);
  const [destination, setDestination] = useState(journey.destination);
  const [date, setDate] = useState(journey.date);
  const [quota, setQuota] = useState(journey.quota);
  const [quotaWarning, setQuotaWarning] = useState(false);
  const [AlertErr, setAlertErr] = useState(false);

  useEffect(() => {
    setSource(journey.source);
    setDestination(journey.destination);
    setDate(journey.date);
    setQuota(journey.quota);
  }, [journey]);

  const handleChoose = ({ type, value }) => {
    switch (type) {
      case "SOURCE":
        setSource(value);
        return;
      case "DESTINATION":
        setDestination(value);
        return;
      case "DATE":
        setDate(moment(value).format("YYYYMMDD"));
        return;
      case "QUOTA":
        setQuota(value);
        // setJourneyQuota(value);
        return;
    }
  };

  const handleSwap = () => {
    let temp = source;
    setJourneySource(destination);
    setJourneyDestination(temp);
  };

  const [showConfirm, setShowConfirm] = useState(false);
  const nextContext = useGetReduxState().app.nextContext;

  const find = () => {
    if (disabledSettings.booking) {
      if (nextContext && parseInt(nextContext.split(",")[1]) > 8)
        setShowConfirm(true);
      else findTrains({ source, destination, quota, date, isDirect: true });
    } else {
      addCustomResponseMessage({
        component: <Maintainance message={translation[lang].text} />,
        showAvatar: false,
      });
    }
  };

  const getButton = () => {
    if (forEdit) {
      if (lang === "en") return "Find Trains";
      if (lang === "hi") return "ट्रेनें खोजें";
      if (lang === "gu") return "ટ્રેનો શોધો";
    } else {
      if (lang === "en") return "Book Ticket";
      if (lang === "hi") return "टिकट बुक करें";
      if (lang === "gu") return "ટિકિટ બુક કરો";
    }
  };

  // useEffect(() => {
  //   document.getElementById("book-form")?.focus();
  // }, [openDrawer]);

  useEffect(() => {
    if (
      source.trim() !== "" &&
      destination.trim() !== "" &&
      source.trim() === destination.trim()
    ) {
      setAlertErr(true);
    } else {
      setAlertErr(false);
    }
  }, [source, destination]);

  return (
    <div
      style={{ fontFamily: "'Montserrat',sans-serif", outline: "none" }}
      tabIndex={0}
      aria-label="Booking Form: you can choose your source, destination, journey date and journey quota here and press Book Ticket button to search trains"
      // onKeyDown={(e) => {
      //   if (e.key === "Enter") {
      //     if (source && destination && quota && date)
      //       findTrains({ source, destination, quota, date });
      //   }
      // }}
      id="book-form"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <TextBox
          type={"source"}
          onTap={() => setOpenDrawer("SOURCE")}
          value={
            getStationName(source)
              ? `${getStationName(source)} - ${source}`
              : ""
          }
        />

        <div
          style={{
            width: "36px",
            height: "36px",
            borderRadius: "50%",
            backgroundColor: "#EBEEFD",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "5px",
            cursor: "pointer",
            position: "absolute",
            top: "53%",
            transform: "translateY(-50%)",
            right: "10px",
          }}
          tabIndex={0}
          aria-label="Button for Swap stations for Source and Destination"
        >
          <img
            src={IMAGE_SWAP}
            style={{
              width: "80%",
            }}
            onClick={handleSwap}
            alt=""
          />
        </div>

        <TextBox
          type={"destination"}
          onTap={() => setOpenDrawer("DESTINATION")}
          value={
            getStationName(destination)
              ? `${getStationName(destination)} - ${destination}`
              : ""
          }
        />
      </div>

      {/* <TextBox type={"date"} />
      <TextBox type={"quota"} /> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextBox
          type={"date"}
          onTap={() => setOpenDrawer("DATE")}
          value={
            date ? `${moment(date, "YYYYMMDD").format("DD MMM, YYYY")}` : ""
          }
        />

        <TextBox
          type={"quota"}
          onTap={() => setOpenDrawer("QUOTA")}
          value={quota ? `${getQuotaName(quota)} - ${quota}` : ""}
        />
      </div>

      <button
        style={{
          display: "block",
          margin: "0 auto",
          width: "100%",
          border: "none",

          backgroundColor: "#346db3",
          color: "white",
          fontSize: "16px",
          fontWeight: 500,
          padding: "12px",
          borderRadius: "8px",
          marginTop: "15px",
          cursor: "pointer",
        }}
        onClick={find}
        tabIndex={0}
      >
        {getButton()}
      </button>

      {openDrawer === "SOURCE" && (
        <StationPicker
          pickerType="SOURCE"
          handleClose={() => setOpenDrawer("")}
          source={source}
          destination={destination}
          callBack={(value) => handleChoose({ type: "SOURCE", value: value })}
        />
      )}

      {openDrawer === "DESTINATION" && (
        <StationPicker
          pickerType="DESTINATION"
          source={source}
          destination={destination}
          handleClose={() => setOpenDrawer("")}
          callBack={(value) =>
            handleChoose({ type: "DESTINATION", value: value })
          }
        />
      )}

      {openDrawer === "DATE" && (
        <DatePicker
          handleClose={() => setOpenDrawer("")}
          callBack={(value) => handleChoose({ type: "DATE", value: value })}
        />
      )}

      {openDrawer === "QUOTA" && (
        <QuotaPicker
          handleClose={() => setOpenDrawer("")}
          callBack={(value) => {
            handleChoose({ type: "QUOTA", value: value });
            value === "SS" && setQuotaWarning(true);
          }}
          currentDate={date}
        />
      )}

      {quotaWarning && (
        <AlertWarning
          error={warningText[lang].header}
          suberror={warningText[lang].content}
          handleClose={() => {
            setQuotaWarning(false);
          }}
        />
      )}

      {AlertErr && (
        <AlertError
          error={errorText[lang].header}
          suberror={errorText[lang].content}
          handleClose={() => {
            setAlertErr(false);
            setSource(journey.source);
            setDestination(journey.destination);
          }}
        />
      )}
      {showConfirm && (
        <Alert
          header={text[lang].header}
          content={text[lang].content}
          secondaryText={text[lang].seconDaryText}
          primaryText={text[lang].primaryText}
          handleSecondaryClick={() => {
            setShowConfirm(false);
          }}
          handlePrimaryClick={() => {
            setShowConfirm(false);
            findTrains({ source, destination, quota, date, isDirect: true });
          }}
        />
      )}
    </div>
  );
};

export default BookingForm;
