import { useState } from "react";

import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";

import "./styles.scss";
import { isAgeMatch, missingFields } from "./Logic/Validations/PassengerForm";
import Drawer from "../../../../../UI/UIComponents/Drawer";
import { isAdult, isChild, isInfant } from "../../../utils";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import {
  setPassengers,
  setSavedPassengers,
} from "../../../../../Store/Dispatcher/passengerForm";
import { CloseDrawer } from "../../../../../Helpers/EventEmitter";

const AddPassenger = ({
  handleCloseDrawer,
  passengerProp,
  isInMasterData,
  isAlloted,
}) => {
  const config = useGetReduxState().passengerform.irctcConfig;
  const passengers = useGetReduxState().passengerform.passengers;
  const savedPassengers = useGetReduxState().passengerform.savedPassengers;
  const isSaveInMasterData = true;
  const irctcConfig = useGetReduxState().passengerform.irctcConfig;

  const getInititialBerth = (age) => {
    const childBerthMandatory = irctcConfig.childBerthMandatory;

    if (isAdult(age)) return "NBC";

    if (isChild(age)) {
      return childBerthMandatory ? "" : "";
    }

    return "";
  };
  const initialPassenger =
    passengerProp !== null
      ? {
          values: {
            ...passengerProp,
          },
          errors: {
            name: "",
            gender: "",
            age: "",
            berth: "",
            nationality: "",
            food: "",
            passport: "",
            dob: "",
            isSelected: "",
          },
        }
      : {
          values: {
            name: "",
            gender: "",
            age: "",
            berth: "",
            nationality: "IN",
            food: "",
            passport: "",
            dob: "",
            isSelected: true,
          },
          errors: {
            name: "",
            gender: "",
            age: "",
            berth: "",
            nationality: "",
            food: "",
            passport: "",
            dob: "",
            isSelected: "",
          },
        };

  const [passenger, setPassenger] = useState(initialPassenger);
  const isDisbaled = () => {
    if (
      passenger.errors.age !== "" ||
      passenger.errors.name !== "" ||
      passenger.errors.gender !== "" ||
      passenger.errors.nationality !== ""
    ) {
      return true;
    }

    if (passenger.errors.food !== "") {
      return true;
    }

    if (passenger.values.nationality !== "IN") {
      if (passenger.errors.passport !== "" || passenger.errors.dob !== "") {
        return true;
      }
    }

    return false;
  };

  const refactorForMatch = (passenger) => {
    const p = {
      age: passenger.age,
      name: passenger.name,
      gender: passenger.gender,
    };
    return JSON.stringify(p);
  };

  const handleAdd = () => {
    const { isMissing, passengerSet } = missingFields(passenger, config);
    setPassenger(passengerSet);

    if (isMissing) return;
    const { isError, errorMsg } = isAgeMatch(passengerSet);
    if (isError) {
      passengerSet.errors.dob = errorMsg;
      setPassenger(passengerSet);
      return;
    }

    let newPassenger = passenger.values;
    newPassenger.berth = getInititialBerth(newPassenger.age);
    let newPassengers = [...passengers, newPassenger];
    setPassengers(newPassengers);
    if (isSaveInMasterData) {
      let newSaved = [...savedPassengers, newPassenger];
      setSavedPassengers(newSaved);
    }
    CloseDrawer();
  };

  const handleEdit = () => {
    const { isMissing, passengerSet } = missingFields(passenger, config);

    if (
      !isInfant(passengerSet.values.age) &&
      passengerSet.values.berth === ""
    ) {
      passengerSet.values.berth = getInititialBerth(passengerSet.values.age);
    } else if (isInfant(passengerSet.values.age)) {
      passengerSet.values.berth = "";
      passengerSet.values.food = "";
    }

    setPassenger(passengerSet);
    if (isMissing) return;

    const { isError, errorMsg } = isAgeMatch(passengerSet);
    if (isError) {
      passengerSet.errors.dob = errorMsg;
      setPassenger(passengerSet);
      return;
    }

    if (isInMasterData) {
      let temp = savedPassengers;
      const ind = savedPassengers.indexOf(passengerProp);
      let updatedPassenger = passenger.values;
      temp[ind] = updatedPassenger;
      setSavedPassengers([...temp]);
    } else {
      let temp = passengers;
      const stringArray = passengers.map((passeng) =>
        refactorForMatch(passeng),
      );
      const ind = stringArray.indexOf(refactorForMatch(passengerProp));
      let updatedPassenger = passenger.values;
      temp[ind] = updatedPassenger;
      setPassengers([...temp]);
      //Save changes in Master Data
      let temp2 = savedPassengers;
      const stringArray2 = savedPassengers.map((passeng) =>
        refactorForMatch(passeng),
      );
      const ind2 = stringArray2.indexOf(refactorForMatch(passengerProp));
      let updatedPassenger2 = passenger.values;

      temp2[ind2] = updatedPassenger2;

      setSavedPassengers([...temp2]);
    }
    CloseDrawer();
  };

  return (
    <Drawer
      header={<Header passengerProp={passengerProp} />}
      content={
        <Content
          passengerState={passenger}
          isAlloted={isAlloted}
          setPassenger={setPassenger}
        />
      }
      footer={
        <Footer
          handleClose={passengerProp !== null ? handleEdit : handleAdd}
          isEdit={passengerProp !== null}
          isDisabled={isDisbaled()}
        />
      }
      handleCloseDrawer={handleCloseDrawer}
      showCross={true}
      tatkal={false}
      fullHeight={false}
      showHeaderSeperator={true}
    />
  );
};

export default AddPassenger;
