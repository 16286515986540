/* eslint-disable import/first */

import { useEffect, useRef, useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import { IMAGE_MIC_OFF, IMAGE_MIC_ON } from "../../Assets";
import useGetReduxState from "../../Hooks/useGetReduxState";
import Spinner from "../../UI/UIComponents/Spinner";
import { stopAudio as stopMessageVoice } from "../../Store/Dispatcher/behaviour";
import ReactDOM from "react-dom";
import Container from "./styles";
import langText from "./translations";

const close =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/close.png";

interface mic {
  useEnglish: boolean;
  callback: any;
  onClose: any;
  content: any;
}
export const Microphone: React.FC<mic> = (props: any) => {
  const { transcript, listening } = useSpeechRecognition();

  // const [lang2, setlang] = useState(SocketHandler.getLanguage()!);
  const [micOn, setMicOn] = useState(false);
  const [gotFinalSpeech, setGotFinalSpeech] = useState(false);
  const queryLang = useGetReduxState().behaviour.queryLang;

  // const getStream = async () => {
  //   let mediaStream = null;
  //   if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
  //     await navigator.mediaDevices
  //       .getUserMedia(
  //         // constraints - only audio needed for this app
  //         {
  //           audio: true,
  //         }
  //       )

  //       // Success callback
  //       .then((stream) => {
  //         mediaStream = stream;
  //       })

  //       // Error callback
  //       .catch((err) => {
  //         mediaStream = null;
  //       });
  //   } else {
  //     mediaStream = null;
  //   }

  //   return mediaStream;
  // };

  const getLanguage = () => {
    switch (queryLang) {
      case "hi":
        return "hi-IN";
      case "en":
        return "en-IN";
      case "us":
        return "en-US";
      case "gb":
        return "en-UK";

      case "gu":
        return "gu-IN";

      default:
        return "en-IN";
    }
  };

  let lang = "en-IN";
  if (props.useEnglish) lang = "en-IN";
  else lang = getLanguage();

  const uiLang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;

  const hogya = useRef(false);

  const isMobile = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      )
    ) {
      // true for mobile device
      return true;
    } else {
      // false for not mobile device
      return false;
    }
  };

  const startRecognition = () => {
    setIsLoaded(true);
    setMicOn(true);
    if (!isMobile()) {
      let startAudio = new Audio(
        "https://coroverbackendstorage.blob.core.windows.net/chatbot-audio-bucket/start.mp3"
      );
      startAudio.play();
    }

    // startAudioRecording();
    SpeechRecognition.startListening({
      continuous: true,
      language: lang,
    });
  };

  const stopRecognition = () => {
    if (!isMobile()) {
      let audio = new Audio(
        "https://coroverbackendstorage.blob.core.windows.net/chatbot-audio-bucket/caught.mp3"
      );
      audio.play();
    }

    SpeechRecognition.stopListening();
    setGotFinalSpeech(true);
    setToggle(false);
    setTimeout(() => {
      if (!hogya.current) {
        props.callback(transcript);
        hogya.current = true;
        // stopAudioRecording();
      }
    }, 1000);
  };

  const [isloaded, setIsLoaded] = useState(false);
  useEffect(() => {
    stopMessageVoice();
    if (!listening && !isloaded) {
      startRecognition();
    }
  }, []);

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (transcript) {
      setToggle((prev) => !prev);
    }

    const delayDebounceFn = setTimeout(() => {
      if (transcript) stopRecognition();
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [transcript]);

  return ReactDOM.createPortal(
    <Container>
      <img
        src={close}
        onClick={() => {
          SpeechRecognition.stopListening();
          props.onClose();
        }}
        style={{
          width: "15px",
          position: "absolute",
          right: "15px",
          top: "15px",
          cursor: "pointer",
          zIndex: 999,
        }}
        alt="Close Microphone Button"
        tabIndex={1}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            SpeechRecognition.stopListening();
            props.onClose();
          }
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          height: "100%",
        }}
      >
        <div
          style={{
            padding: "20px 22px",
            height: "50%",
            width: "100%",
            position: "relative",
          }}
        >
          {transcript === "" ? (
            <p
              style={{
                width: "100%",
                fontSize: "20px",
                textAlign: "center",
                color: "#131313",
                margin: 0,
                marginTop: "25px",
              }}
            >
              {langText[uiLang].listening}
            </p>
          ) : (
            <p
              style={{
                margin: 0,
                fontSize: "20px",
                textAlign: "center",
                position: "absolute",
                top: "70%",
                transform: "translateY(-50%)",
                width: "100%",
                left: 0,
                padding: "0px 20px",
                transition: "all 0.4s ease-in-out",
                color: toggle ? "#0b74fa" : "#131313",
                fontWeight: 500,
              }}
            >
              {transcript}
            </p>
          )}
        </div>
        <div
          style={{
            height: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            width: "100%",
          }}
        >
          {micOn && transcript === "" && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                left: "50%",
                top: "-25px",
                transform: "translateX(-50%)",
              }}
            >
              {" "}
              <p
                style={{
                  margin: 0,
                  color: "#606060",
                  fontStyle: "italic",
                  marginBottom: "2px",
                  textAlign: "center",
                }}
              >
                {langText[uiLang].try}
              </p>
              <p
                style={{
                  margin: 0,
                  color: "#606060",
                  textAlign: "center",
                  fontSize: "16px",
                }}
              >
                {props.content}
              </p>
            </div>
          )}

          {!gotFinalSpeech ? (
            <div className={toggle ? "outer-pulse-active" : "outer-pulse"}>
              <div
                className={
                  transcript.length > 1 && micOn
                    ? "microphone-new-inactive"
                    : transcript.length === 0 && !micOn
                    ? "microphone-new-inactive"
                    : "microphone-new-active"
                }
              >
                <img src={micOn ? IMAGE_MIC_ON : IMAGE_MIC_OFF} alt="" />
              </div>
            </div>
          ) : (
            <div className="microphone-new-inactive">
              <Spinner
                borderWidth={"3px"}
                activeColor={"#f9f9f9"}
                inactiveColor={"#346db3"}
                speed={"0.8s"}
                size={"30px"}
              />
            </div>
          )}
        </div>
      </div>
    </Container>,
    document.getElementById("corover-body")
  );
};
