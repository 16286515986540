import styled from "styled-components";

const BookingDetailsContainer = styled.div`
  width: 100%;

  p,
  h2,
  h3,
  h4,
  h5,
  h6,
  span {
    margin: 0;
    line-height: 1;
  }

  .first {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
    column-gap: 10px;
    font-size: 14px;
    .headers {
      p {
        font-weight: 600;
        color: #373737;
        margin-top: 5px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .values {
      p {
        font-weight: 500;
        color: #2f4f4f;
        margin-top: 5px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .payment-details {
    padding: 15px;
    font-size: 12px;

    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 5px;
      margin-top: 5px;

      &:first-child {
        margin-top: 0;
      }

      p {
        font-weight: 700;
        color: #656565;
      }

      span {
        color: lightslategray;
        font-weight: 500;
      }
    }
  }
  .seperator {
    width: 100%;
    border: 2px solid #f2f2f2;
  }
  .amount-details {
    padding: 15px;

    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 8px;

      &:last-child {
        margin-top: 15px;
      }

      p {
        color: #636363;
        font-weight: 600;
        font-size: 18px;
      }

      span {
        font-size: 16px;
        font-weight: 500;
        border: 1px solid;
        padding: 3px 12px;
        border-radius: 5px;
      }
    }
  }

  .paid {
    color: #ff8506;
  }

  .refund {
    color: #4caf50;
  }

  .refund-status {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    padding: 15px;
    padding-top: 5px;
    p {
      font-size: 20px;
    }
    span {
      font-size: 18px;
      font-weight: 500;
      border: none;
      padding: 5px 30px;
      border-radius: 20px;
      background-color: ${(props) => (props.refunded ? "#4e9251" : "#f6ab3c")};
      color: white;
    }
  }

  .message {
    padding: 8px 15px;
    border-radius: 6px;
    line-height: 1.2;
    background-color: ${(props) => (props.refunded ? "#e1efd2" : "#fff3dd")};
    margin: 0 15px 15px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
    color: ${(props) => (props.refunded ? "green" : "#e9a83a")};
    font-size: 14px;
    font-weight: 500;

    .icon {
      font-size: 18px;
    }
  }
`;

export default BookingDetailsContainer;
