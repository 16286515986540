import { PassengerFormData } from "./types";

const intialPassengerFormData: PassengerFormData = {
  currentPassengerFormStep: 1,
  irctcConfig: {
    applicableFoodChoices: [],
    childBerthMandatory: false,
    showFoodChoice: false,
    showTravelInsurance: false,
    quota: "",
    berths: [],
    boardingStations: [],
    isGSTEnable: false,
    trainClass: "",
    isWaiting: false,
    maxInfants: 2,
    maxPassengers: 6,
  },

  journeyPreferences: {
    irctcUserId: "",
    insuranceSelected: true,
    boardingStation: "",
    email: "",
    isEmailError: false,
    gstSelected: false,
    additionalSelected: false,
  },

  gstDetails: {
    gstnumber: "",
    gstcompany: "",
    gstflat: "",
    gststreet: "",
    gstarea: "",
    gstpincode: "",
    gststate: "",
    gstcity: "",
  },

  additionalDetails: {
    coach: "",
    autoUpgradation: false,
    reservationChoice: "",
  },

  passengers: [],
  savedPassengers: [],
  otpValue: "",
  otpData: [],
  bookingData: [],
  showBookingSteps: false,
  currentBookingStep: null,
  paymentData: "",
  showNextSteps: false,
  otpView: false,
};

const reducer = (
  passengerFormData: PassengerFormData = intialPassengerFormData,
  action
) => {
  switch (action.type) {
    case "SET_CURRENT_PASSENGER_FORM_STEP":
      return { ...passengerFormData, currentPassengerFormStep: action.value };
    case "SET_IRCTC_CONFIG":
      return { ...passengerFormData, irctcConfig: action.value };
    case "SET_SAVED_PASSENGERS":
      return { ...passengerFormData, savedPassengers: action.value };
    case "SET_PASSENGERS":
      return { ...passengerFormData, passengers: action.value };
    case "SET_GST_DETAILS":
      return { ...passengerFormData, gstDetails: action.value };
    case "SET_ADDITIONAL_DETAILS":
      return { ...passengerFormData, additionalDetails: action.value };
    case "SET_JOURNEY_PREFERENCES":
      return { ...passengerFormData, journeyPreferences: action.value };
    case "SET_OTP":
      return { ...passengerFormData, otpValue: action.value };

    case "SET_OTP_DATA":
      return { ...passengerFormData, otpData: action.value };

    case "SET_BOOOKING_DATA":
      return { ...passengerFormData, bookingData: action.value };

    case "SET_SHOWNEXT_STEPS":
      return { ...passengerFormData, showNextSteps: action.value };

    case "SET_SHOWBOOKING_STEPS":
      return { ...passengerFormData, showBookingSteps: action.value };

    case "SET_CURRENT_BOOKING_STEP":
      return { ...passengerFormData, currentBookingStep: action.value };

    case "SET_PAYMENT_DATA":
      return { ...passengerFormData, paymentData: action.value };

    case "SET_OTP_VIEW":
      return { ...passengerFormData, otpView: action.value };

    default:
      return passengerFormData;
  }
};

export default reducer;
