import moment from "moment";
import { useEffect } from "react";
import { CloseDrawer } from "../../../Helpers/EventEmitter";
import useGetReduxState from "../../../Hooks/useGetReduxState";
const general =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/gn.svg";
const ladies =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/ld.svg";
const senior =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/ss.svg";
const tatkal =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/hourglass.png";

const text = {
  en: {
    heading: "Select Journey Quota",
    gen: "General",
    ld: "Ladies",
    ss: "Lower Berth / Senior Citizen",
    tat: "Tatkal",
  },
  hi: {
    heading: "यात्रा कोटा चुने",
    gen: "जनरल",
    ld: "लेडीज",
    ss: "लोअर बर्थ/सीनियर सिटी",
    tat: "ततकाल",
  },
  gu: {
    heading: "મુસાફરીનો ક્વોટા પસંદ કરો",
    gen: "જનરલ",
    ld: "મહિલાઓ",
    ss: "લોઅર બર્થ/સિનિયર સિટીઝન",
    tat: "તત્કાલ",
  },
};
const Content = ({ onClick, current }) => {
  const lang = useGetReduxState().behaviour.lang;

  useEffect(() => {}, []);

  const handleClick = (value) => {
    CloseDrawer();
    onClick(value);
  };

  const disableQuota = () => {
    let currentDate = moment();
    if (moment(current, "YYYYMMDD").diff(currentDate, "days") + 1 < 6) {
      return false;
    }
    return true;
  };

  return (
    <div className="selects-quota">
      <div className="quota" id="general" onClick={() => handleClick("GN")}>
        <div className="icon">
          <img src={general} alt="" />
        </div>

        <p>
          {" "}
          <span>GN</span> - {text[lang].gen}
        </p>
      </div>
      <div
        style={{
          marginTop: "10px",
          borderTop: "1px solid gainsboro",
          marginBottom: "10px",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ></div>
      <div className="quota" id="ladies" onClick={() => handleClick("LD")}>
        <div className="icon">
          <img src={ladies} alt="" />
        </div>

        <p>
          {" "}
          <span>LD</span> - {text[lang].ld}
        </p>
      </div>
      <div
        style={{
          marginTop: "10px",
          borderTop: "1px solid gainsboro",
          marginBottom: "10px",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ></div>
      <div className="quota" id="senior" onClick={() => handleClick("SS")}>
        <div className="icon">
          <img src={senior} alt="" />
        </div>

        <p>
          {" "}
          <span>SS</span> - {text[lang].ss}
        </p>
      </div>

      {!disableQuota() && (
        <>
          <div
            style={{
              marginTop: "10px",
              borderTop: "1px solid gainsboro",
              marginBottom: "10px",
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          ></div>

          <div className="quota" id="tatkal" onClick={() => handleClick("TQ")}>
            <div className="icon">
              <img src={tatkal} alt="" />
            </div>

            <p>
              {" "}
              <span>TQ</span> - {text[lang].tat}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default Content;
