const translations = {
  en: {
    order: "Order Id:",
    amount: "Amount:",
    refund: "Refund:",
    initiated: "Initiated",
    pending: "Pending",
  },
  hi: {
    order: "आर्डर Id:",
    amount: "रकम:",
    refund: "धनवापसी:",
    initiated: "शुरू हो चूका",
    pending: "लंबित",
  },
  gu: {
    order: "ઓર્ડર આઈડીઃ",
    amount: "રકમઃ",
    refund: "રિફંડઃ",
    initiated: "તેની શરૂઆત થઈ ગઈ છે.",
    pending: "બાકી છે",
  },
};

export default translations;
