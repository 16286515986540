import { useState } from "react";
import useButtonQueries from "../../../../../../../Hooks/useButtonQueries/useButtonQueries";
import useGetReduxState from "../../../../../../../Hooks/useGetReduxState";
import { setFullScreen } from "../../../../../../../Store/Dispatcher/behaviour";
import HelpMeImprove from "../../../../../../../Templates/HelpMeImprove";
import Login from "../../../../../../../Templates/Login2";
import AddPassengersDialog from "../../../../../../../Templates/PassengerForm/Steps/Passengers/AddPassengersDialog";
import Icon from "./Icon";
import translations from "./translations";
import { addCustomResponseMessage } from "../../../../../../../Store/Dispatcher/messages";
import Maintainance from "../../../../../../../Templates/Trains/Maintainance";
import { transl_IRCTC_Maintainance } from "../../../../../../../Templates/Trains/Maintainance/translations";
const cancel =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/cancel-ticket-refactored.png";

const refund =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/refund.svg";
const history =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/history.svg";
const pnr = "https://d3upbvvdvllr10.cloudfront.net/dishav3/pnr.svg";
const failed =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/failed-transation.svg";
const boarding =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/bording-station.svg";

const feedback =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/positive-vote.png";

const tdr =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/tdr.svg";

const passengers =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/luggage2-refactored.png";
const tourism =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/tourism-logo.png";
const FoT =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/FoT-Icon.png";

const Menu = ({ tab }) => {
  const lang = useGetReduxState().behaviour.lang;
  const cxtoken = useGetReduxState().app.cxtoken;
  const [showDrawer, setShowDrawer] = useState(false);
  const disabledSettings = useGetReduxState().app.disabledSettings;

  const handleFeedBack = () => {
    setShowDrawer(true);
  };
  const {
    usePNR,
    useBookingHistory,
    useCancel,
    useChangeBoarding,
    useCheckFailed,
    useFileTDR,
    useRefund,
  } = useButtonQueries();

  const maintainceTime = () => {
    addCustomResponseMessage({
      component: (
        <Maintainance message={transl_IRCTC_Maintainance[lang].text} />
      ),
      showAvatar: false,
    });
  };
  const [showPassengers, setShowPassengers] = useState(false);

  const handlePassengerList = () => {
    if (cxtoken) setShowPassengers(true);
    else
      setFullScreen({
        type: "LOGIN",
        component: (
          <Login
            handleLogin={() => {
              setFullScreen({ type: null, component: null });
              setShowPassengers(true);
            }}
            handleCloseDrawer={() =>
              setFullScreen({ type: null, component: null })
            }
          />
        ),
      });
  };

  return (
    <div className="icons-container">
      {/* <Icon img={book} imgWidth={"30px"} label={"Book a Ticket"} /> */}

      <Icon
        img={failed}
        imgWidth={"34px"}
        type={translations[lang].failed}
        handleClick={useCheckFailed}
        background="#FCEDEC"
        tab={tab}
      />

      <a
        href="https://www.ecatering.irctc.co.in/?utm_source=irctc&utm_medium=link&utm_campaign=ask_disha"
        target="_blank"
        rel="noopener noreferrer"
        style={{ position: "relative", textDecoration: "none" }}
        tabIndex={98}
      >
        <Icon
          img={FoT}
          imgWidth={"34px"}
          type={translations[lang].food}
          handleClick={() => {}}
          background="#fde4d9"
          tab={tab}
        />
      </a>
      <a
        href="https://app.affiliatics.com/redirect/af637ee0b14d0189.48957849"
        target="_blank"
        rel="noopener noreferrer"
        style={{ position: "relative", textDecoration: "none" }}
        tabIndex={98}
      >
        <Icon
          img={tourism}
          imgWidth={"34px"}
          type={translations[lang].tourism}
          handleClick={() => {}}
          background="#fcf7ec"
          tab={tab}
        />
      </a>

      <Icon
        img={tdr}
        imgWidth={"26px"}
        type={translations[lang].tdr}
        handleClick={disabledSettings.booking ? useFileTDR : maintainceTime}
        background="#EFF8FD"
        tab={tab}
      />
      <Icon
        img={cancel}
        imgWidth={"28px"}
        type={translations[lang].cancel}
        handleClick={disabledSettings.booking ? useCancel : maintainceTime}
        background="#ffeeee"
        tab={tab}
      />
      <Icon
        img={pnr}
        imgWidth={"40px"}
        type={translations[lang].pnr}
        handleClick={disabledSettings.booking ? usePNR : maintainceTime}
        background="#EBEEFD"
        tab={tab}
      />

      <Icon
        img={passengers}
        imgWidth={"32px"}
        type={translations[lang].passengers}
        handleClick={handlePassengerList}
        background="#f0effd"
        tab={tab}
      />

      <Icon
        img={boarding}
        imgWidth={"30px"}
        type={translations[lang].boarding}
        handleClick={
          disabledSettings.booking ? useChangeBoarding : maintainceTime
        }
        background="#E3FFF0"
        tab={tab}
      />
      <Icon
        img={refund}
        imgWidth={"22px"}
        type={translations[lang].refund}
        handleClick={disabledSettings.booking ? useRefund : maintainceTime}
        background="#ebfae6"
        tab={tab}
      />
      <Icon
        img={history}
        imgWidth={"32px"}
        type={translations[lang].history}
        handleClick={useBookingHistory}
        background="#EFF8FD"
        tab={tab}
      />

      <Icon
        img={feedback}
        imgWidth={"32px"}
        type={translations[lang].feedback}
        handleClick={handleFeedBack}
        background="#FDF7E7"
        tab={tab}
      />
      {showDrawer && <HelpMeImprove handleClose={() => setShowDrawer(false)} />}
      {showPassengers && (
        <AddPassengersDialog handleClose={() => setShowPassengers(false)} />
      )}
    </div>
  );
};

export default Menu;
