export const text = {
  en: {
    header: "Are you sure?",
    content: "The traveller and other information will not be saved.",
    primaryText: "Confirm",
    seconDaryText: "Cancel",
  },
  hi: {
    header: "क्या आप इसके बारे में निश्चित हैं?",
    content: "यात्री और अन्य जानकारी सहेजी नहीं जाएगी!",
    primaryText: "जारी रखें",
    seconDaryText: "रद्द करें",
  },
  gu: {
    header: "શું તમને ખાતરી છે?",
    content: "પ્રવાસી અને અન્ય માહિતી સાચવવામાં આવશે નહીં.",
    primaryText: "પુષ્ટિ કરો",
    seconDaryText: "રદ કરો",
  },
};

export const warningText = {
  en: {
    header: "What is LOWER BERTH/SENIOR CITIZEN Quota?",
    content:
      "LOWER BERTH/SENIOR CITIZEN Quota berths are lower berths earmarked only for male of age 60 years and above/female of age 45 years and above, when traveling alone or two passengers (under mentioned criteria) traveling on one ticket. For reservation of pregnant women traveling alone under LOWER BERTH/SENIOR CITIZEN Quota, booking facility is available only at Indian Railways Booking counters/Reservation Offices.",
  },
  hi: {
    header: "निचली बर्थ/वरिष्ठ नागरिक कोटा क्या है?",
    content:
      "निचली बर्थ/वरिष्ठ नागरिक कोटा बर्थ निचली बर्थ केवल 60 वर्ष और उससे अधिक आयु के पुरुष/45 वर्ष और उससे अधिक आयु की महिला के लिए आरक्षित हैं, जब अकेले यात्रा कर रहे हों या एक टिकट पर दो यात्री हों (उल्लिखित मानदंडों के अधीन)। यात्रा कर रहे हैं. लोअर बर्थ/वरिष्ठ नागरिक कोटा के तहत अकेले यात्रा करने वाली गर्भवती महिलाओं के आरक्षण के लिए, बुकिंग सुविधा केवल भारतीय रेलवे बुकिंग काउंटरों/आरक्षण कार्यालयों पर उपलब्ध है।",
  },
  gu: {
    header: "લોઅર બર્થ/વરિષ્ઠ નાગરિક ક્વોટા શું છે?",
    content:
      "લોઅર બર્થ/વરિષ્ઠ નાગરિક ક્વોટા બર્થ એ નીચી બર્થ છે જે ફક્ત 60 વર્ષની વયના પુરૂષો અને 45 વર્ષ અને તેથી વધુ વયની સ્ત્રીઓ માટે આરક્ષિત છે, જ્યારે એકલા મુસાફરી કરતા હોય અથવા બે મુસાફરો (નિર્ધારિત માપદંડ હેઠળ) એક ટિકિટ પર મુસાફરી કરતા હોય. લોઅર બર્થ/વરિષ્ઠ નાગરિક ક્વોટા હેઠળ એકલી મુસાફરી કરતી સગર્ભા સ્ત્રીઓના આરક્ષણ માટે, બુકિંગ સુવિધા ફક્ત ભારતીય રેલ્વેના બુકિંગ કાઉન્ટર/રિઝર્વેશન ઑફિસ પર ઉપલબ્ધ છે.",
  },
};

export const errorText = {
  en: {
    header: "The source and  destination cannot be  same.",
    content:
      " Please select a different source and destination. To proceed, click Ok.",
  },
  hi: {
    header: "स्रोत और गंतव्य समान नहीं हो सकते।",
    content:
      " कृपया एक अलग स्रोत और गंतव्य चुनें। आगे बढ़ने के लिए, ठीक क्लिक करें।",
  },
  gu: {
    header: "સ્રોત અને ગંતવ્ય સમાન હોઈ શકતા નથી.",
    content:
      "કૃપા કરીને બીજું સ્ત્રોત અને ગંતવ્ય પસંદ કરો. આગળ વધવા માટે, ઑકે ક્લિક કરો.",
  },
};
