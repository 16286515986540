export const setIsBookingPending = (value) => {
  const action = { type: "IS_BOOKING_PENDING", value: value };
  return action;
};

export const setIsOtpPending = (value) => {
  const action = {
    type: "IS_OTP_PENDING",
    value: value,
  };
  return action;
};

export const setPopularStations = (value) => {
  const action = {
    type: "SET_POPULAR_STATIONS",
    value: value,
  };
  return action;
};

export const setAllStations = (value) => {
  const action = {
    type: "SET_ALL_STATIONS",
    value: value,
  };
  return action;
};

export const setCountires = (value) => {
  const action = {
    type: "SET_COUNTRIES",
    value: value,
  };
  return action;
};
