import {
  isValidName,
  isValidAge,
  isValidPassport,
  isValidDob,
} from "./Validations/PassengerForm";

const PassengerValues = {
  name: "name",
  gender: "gender",
  age: "age",
  berth: "berth",
  nationality: "nationality",
  food: "food",
  passport: "passport",
  dob: "dob",
};

export const handleChange = (e, passenger) => {
  let values = passenger.values;
  let errors = passenger.errors;
  let value = e.target.value;
  if (e.target.name === PassengerValues.name) {
    let name = value.replace(/^\s+|\s+$/g, "");
    let { isError, errorMsg } = isValidName(name);
    if (isError) errors.name = errorMsg;
    else errors.name = "";
  }
  if (e.target.name === PassengerValues.age) {
    let { isError, errorMsg } = isValidAge(value);
    if (isError) errors.age = errorMsg;
    else errors.age = "";
  }

  if (e.target.name === PassengerValues.passport) {
    let { isError, errorMsg } = isValidPassport(value);
    if (isError) errors.passport = errorMsg;
    else errors.passport = "";
  }

  if (e.target.name === PassengerValues.dob) {
    let { isError, errorMsg } = isValidDob(value);
    if (isError) errors.dob = errorMsg;
    else errors.dob = "";
  }

  values = { ...values, [e.target.name]: value };

  return { values, errors };
};
