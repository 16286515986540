import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: auto;

  .seperator {
    width: 100%;
    border-top: 6px solid #f7f7f7;
    margin: 8px 0px;
  }
`;

export default Container;
