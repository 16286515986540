import { ADD_NEW_MESSAGE, DROP_MESSAGES } from "../Actions/constants";
import { Message } from "./types";

const intialMessages = [];

const reducer = (messages: Message[] = intialMessages, action) => {
  switch (action.type) {
    case ADD_NEW_MESSAGE:
      return [
        ...messages,
        {
          ...action.message,
          id: action.message.id
            ? action.message.id
            : (messages.length + 1).toString(),
        },
      ];

    case DROP_MESSAGES:
      return [];

    case "DROP_N_MESSAGES":
      return messages.slice(0, -action.value);

    default:
      return messages;
  }
};

export default reducer;
