/* eslint-disable import/first */

import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useTimer } from "react-timer-hook";
import { isProdEnv } from "../../../Helpers/getEnvironment";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import Alert from "../../../UI/UIComponents/Alert";

import Loader from "./Loader";
import TimeOver from "./TimeOver";
import langText from "./translations";
const disha =
  "https://cdn.jsdelivr.net/gh/corover/assets@18June/askdisha-bucket/favi.svg";

const text = {
  en: {
    header: "Back to Review Booking",
    content:
      "Doing so will cancel your current payment. Are you sure about it?",
    primaryText: "Yes",
    seconDaryText: "No",
  },
  hi: {
    header: "बुकिंग की समीक्षा पर वापस जाएं",
    content:
      "ऐसा करने से आपका वर्तमान भुगतान रद्द हो जाएगा। क्या आप इसके बारे में निश्चित हैं?",
    primaryText: "हां",
    seconDaryText: "नहीं",
  },
  gu: {
    header: "બુકીંગની સમીક્ષા પર પાછા જાઓ",

    content: "આમ કરવાથી તમારી હાલની ચુકવણી રદ થઈ જશે. શું તમને પુરી ખાતરી છે?",

    primaryText: "હા.",

    seconDaryText: "ના.",
  },
};

export const PayTm = ({
  clientTransactionId,
  txnToken,
  paymentAmount,
  handlePaymentDone,
  goBack,
}) => {
  /*** dev paytm merchantId**/

  const merchantId = isProdEnv
    ? "IRCTCC84510399265141"
    : "IRCTCC86226828912803";

  const lang = useGetReduxState().behaviour.lang;
  // const [showBtn, setShowBtn] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [showPaytm, setShowPaytm] = useState(false);
  // const [isComplete, setIsComplete] = useState(false);

  // const [showModal, setShowModal] = useState(false);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 600);
  const { seconds, minutes } = useTimer({
    expiryTimestamp: time,
  });

  useEffect(() => {
    let gID = "G-X4XL7DB3RW";
    // if (window.location.host.includes("staging")) {
    //   gID = "G-L5BLC79E9S";
    // } else if (window.location.host.includes("assistant")) {
    //   gID = "G-G6T02NG34W";
    // }
    // gID = "G-G6T02NG34W";
    ReactGA.initialize(gID);
    ReactGA.send({ hitType: "pageview", page: "/payment" });
    proceed();
  }, []);

  const proceed = () => {
    ReactGA.event({
      category: "Payment",
      action: "PAYMENT",
      label: `Amount: INR${paymentAmount}`,
    });
    // let txnToken = getTxnToken();
    const script = document.createElement("script");

    /*** dev paytm URL**/
   // script.src = `https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/${merchantId}.js`;

    script.src = isProdEnv
      ? `https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/${merchantId}.js`
      : `https://securegw-stage.paytm.in/merchantpgpui/checkoutjs/merchants/${merchantId}.js`;

    script.async = true;
    script.onload = () => {
      var config = {
        root: "#PaytmDiv",

        flow: "DEFAULT",

        merchant: {
          name: "DISHA E-Ticketing",
          logo: disha,
          redirect: false,
        },
        data: {
          orderId: clientTransactionId /* update order id */,
          token: txnToken /* update token value */,
          tokenType: "TXN_TOKEN",
          amount: paymentAmount /* update amount */,
        },
        handler: {
          //@ts-ignore
          notifyMerchant: function (eventName, data) {},
          transactionStatus: (response) => {
            if (response.STATUS === "TXN_SUCCESS") {
              handlePaymentDone();
            }
          },
        },
      };
      if (window["Paytm"] && window["Paytm"].CheckoutJS) {
        window["Paytm"].CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
          // initialze configuration using init method
          window["Paytm"].CheckoutJS.init(config)
            .then(function onSuccess() {
              // setShowBtn(true);
              setShowPaytm(true);
              setShowLoader(false);

              // after successfully updating configuration, invoke JS Checkout
              window["Paytm"].CheckoutJS.invoke();
            })
            .catch(function onError(error) {
              console.log("error => ", error);
            });
        });
      } else {
        //todo
      }
    };

    document.body.appendChild(script);
  };
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <React.Fragment>
      {showLoader && <Loader />}
      <div
        style={{
          height: "100%",
          display:
            showPaytm && !(minutes < 1 && seconds < 1) ? "block" : "none",
          maxHeight: "calc(100% - 40px)",
        }}
        id="PaytmDiv"
      ></div>
      <div
        style={{
          display: showPaytm && !(minutes < 1 && seconds < 1) ? "flex" : "none",

          justifyContent: "space-around",
          alignItems: "center",
          zIndex: 5,
          position: "absolute",
          bottom: "0px",
          textAlign: "center",
          width: "100%",
          padding: "8px 0px",
        }}
      >
        <p
          style={{
            margin: 0,
            color: "#32b1fc",
            fontWeight: 500,
            cursor: "pointer",
          }}
          onClick={() => setShowConfirm(true)}
        >
          {langText[lang].back}
        </p>
        <p style={{ margin: 0, color: "#d79414", fontWeight: 500 }}>
          {langText[lang].time}:{" "}
          {moment(`${minutes}:${seconds}`, "m:s").format("mm:ss")}
        </p>
      </div>

      {showPaytm && minutes < 1 && seconds < 1 && <TimeOver />}

      {showConfirm && (
        <Alert
          header={text[lang].header}
          content={text[lang].content}
          secondaryText={text[lang].seconDaryText}
          primaryText={text[lang].primaryText}
          handleSecondaryClick={() => {
            setShowConfirm(false);
          }}
          handlePrimaryClick={() => {
            setShowConfirm(false);
            goBack();
          }}
        />
      )}
    </React.Fragment>
  );
};
