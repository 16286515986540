const translations = {
  en: {
    header: "Unexpected error occurred",
    subtext: "Your last session closed unexpectedly.",
    button1: "Send Report",
    button2: "Go to HOME",
    sent: "Crash report sent succesfully!",
    send: "Send a crash report?",
  },
  hi: {
    header: "अप्रत्याशित तकनीकी बाधा",
    subtext: "आपका पिछला सत्र अप्रत्याशित रूप से बंद हो गया।",
    button1: "रिपोर्ट भेजें",
    button2: "HOME पर जाएँ",
    sent: "क्रैश रिपोर्ट सफलतापूर्वक भेजी गई!",
    send: "क्रैश रिपोर्ट भेजना चाहेंगे?",
  },
  gu: {
    header: "અનપેક્ષિત ટેકનિકલ અવરોધો",
    subtext: "તમારું છેલ્લું સત્ર અણધારી રીતે બંધ થઈ ગયું.",
    button1: "અહેવાલ મોકલો",
    button2: "ઘરે જાઓ",
    sent: "ક્રેશ રિપોર્ટ સફળતાપૂર્વક મોકલવામાં આવ્યો!",
    send: "ક્રેશ રિપોર્ટ મોકલવા માંગો છો?",
  },
};

export default translations;
