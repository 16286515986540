import store from "../Store";

export const berthNames = [
  {
    value: "NBC",

    label: "Any Berth",

    label_hi: "कोई भी बर्थ",

    label_gu: "કોઈપણ બર્થ",
  },

  {
    value: "LB",

    label: "Lower Berth",

    label_hi: "लोअर बर्थ",

    label_gu: "નીચલા બર્થ",
  },

  {
    value: "MB",

    label: "Middle Berth",

    label_hi: "मिडिल बर्थ",

    label_gu: "મધ્ય બર્થ",
  },

  {
    value: "UB",

    label: "Upper Berth",

    label_hi: "उप्पर बर्थ",

    label_gu: "ઉપલા બર્થ",
  },

  {
    value: "CB",

    label: "Cabin",

    label_hi: "केबिन",

    label_gu: "કેબિન",
  },

  {
    value: "CP",

    label: "Coupe",

    label_hi: "कूप",

    label_gu: "કુપે",
  },

  {
    value: "SL",

    label: "Side Lower",

    label_hi: "साइड लोअर",

    label_gu: "નીચલી બાજુ",
  },

  {
    value: "SM",

    label: "Side Middle",

    label_hi: "साइड मिडिल",

    label_gu: "બાજુ મધ્ય",
  },

  {
    value: "SU",

    label: "Side Upper",

    label_hi: "साइड उप्पर",

    label_gu: "બાજુ ઉપર",
  },

  {
    value: "WS",

    label: "Window Side",

    label_hi: "विंडो साइड",

    label_gu: "વિન્ડો બાજુ",
  },

  {
    value: "A",

    label: "Aisle",

    label_hi: "आयिल",

    label_gu: "પાંખડી",
  },

  {
    value: "M",

    label: "Middle",

    label_hi: "मिडिल",

    label_gu: "મધ્યમાં",
  },
];
const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getBerthName = (value) => {
  let lang = store.getState().behaviour.lang;
  let foundArr = berthNames.filter((berth) => berth.value === value);
  if (foundArr.length > 0) {
    if (lang === "en") return toTitleCase(foundArr[0].label);
    if (lang === "hi") return foundArr[0].label_hi;
    if (lang === "gu") return foundArr[0].label_gu;
  } else return value;
};
