import styled from "styled-components";

const Container = styled.div`
  width: 98%;
  margin: 0px auto;
  margin-bottom: 20px;
  /* padding: 12px 15px; */
  border: 1px solid gainsboro;
  border-radius: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px;
  position: relative;

  .first {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    .date {
      color: #05b290;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }

    .pnr {
      color: #4b4b4c;
      font-size: 12px;
      font-weight: 700;
      margin: 0;
      border: 1px solid;
      padding: 3px 6px;
      border-radius: 4px;
    }
  }

  .second {
    display: flex;
    justify-content: space-between;

    .source {
      font-size: 12px;
      font-weight: 500;
      margin: 0;
    }

    .train {
      color: #626262;
      font-size: 10px;

      font-weight: 700;
      margin: 0;
    }

    .destination {
      font-size: 12px;
      font-weight: 500;
      margin: 0;
    }
  }

  .third {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 4px;

    .source,
    .destination {
      color: #858790;
      font-size: 11px;
      font-weight: 400;
      margin: 0;
    }

    .destination {
      text-align: right;
    }
  }

  .hr {
    border-top: 1px solid #eaf0f9;
    margin-bottom: 10px;
    margin-top: 8px;
  }

  .passengers {
    .header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      p {
      }
    }
  }

  button {
    width: 100%;
    border: none;
    color: white;
    background: #326abb;
    cursor: pointer;
    padding: 12px;
    border-radius: 7px;
    font-size: 16px;

    &:disabled,
    &[disabled] {
      background-color: #e0e0e0;
      color: #bcbcbc;
      cursor: not-allowed;
    }
  }
`;
export default Container;
