import styled from "styled-components";

const Container = styled.div`
  display: block;
  width: 100%;

  background-color: white;

  /* padding-bottom: 6px; */
  overflow: hidden;
  position: relative;
  // z-index: 1000;
  border-bottom: 10px solid #f4f4f4;
  padding: 10px 14px;
  font-family: "Montserrat", sans-serif;

  .week {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .week span {
    display: block;
    margin-right: 5px;
  }

  .tickets {
    padding: 4px 0px;
    white-space: nowrap;

    margin-bottom: 0px;
  }

  .ticket-new {
    display: inline-block;
    cursor: pointer;
    padding: 4px 10px;
    border-radius: 5px;
    min-width: 106px;
    border: 1px solid #b4b3b3;
    border-radius: 5px;
  }

  .ticket-new:hover {
    transform: scale(1.05);
  }
`;
export default Container;
