const getFlowName = ({ nextContext, options, context, isRefund }) => {
  if (!nextContext) return "FAQ";

  if ((options && context) || isRefund) {
    return "REFUND_FLOW";
  }

  const flowType = nextContext.split(",")[0];
  switch (flowType) {
    case "1918abb8-6b17-426e-a79e-990c3b194c9a":
      return "PNR_FLOW";
    case "e02d4aa2-678c-4c83-a199-58e25adb8dc8":
      return "CANCEL_FLOW";
    case "ade4a7db-d819-417d-832a-259307fd94c7":
      return "BOOKING_FLOW";
    case "68bce99a-844d-11ec-a8a3-0242ac120002":
      return "BOARDING_FLOW";
    case "a154264e-3335-4796-9e1d-37b91d303040":
      return "TDR_FLOW";
    case "2b35a9d4-59af-443e-8dc6-95b0acf94466":
      return "BOOKING_HISTORY_FLOW";
    case "06d8305e-0a87-47eb-834d-a86734de5892":
      return "REFUND_FLOW";
    default:
      return "FAQ";
  }
};

export default getFlowName;
