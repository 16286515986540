import { useEffect } from "react";
import Message from "./Message";
import Container from "./styles";
import { Message as MessageBody } from "../../../../../../Store/Reducers/types";
import Loader from "./Loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../Store/Reducers";
import { stopAudio } from "../../../../../../Store/Dispatcher/behaviour";
import AlsoTry from "../../../../../../AlsoTry";
import useGetReduxState from "../../../../../../Hooks/useGetReduxState";
import QuickButtons from "./QuickButtons";
import { useElementSize } from "@mantine/hooks";

const Messages: React.FC<{ messages: MessageBody[] }> = ({ messages }) => {
  const { ref, height } = useElementSize();
  const scrollToBottom = () => {
    document
      .getElementById("corover-messages-box")
      ?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [height]);

  const { showMessageLoader } = useSelector(
    (state: RootState) => state.behaviour,
  );

  const alsoTryQuestions = useGetReduxState().behaviour.alsoTry;

  return (
    <Container id="corover-messages-box" ref={ref}>
      {messages.map((message, index) => {
        if (message.type === "TEXT")
          return (
            <Message
              id={message.id}
              key={index}
              text={message.text}
              audioUrl={message.audioUrl}
              sender={message.sender}
              render={message.render || ""}
            />
          );
        else {
          return (
            <div
              style={{
                width: "100%",
                marginTop: "15px",
              }}
            >
              {message.component}
            </div>
          );
        }
      })}

      {showMessageLoader && <Loader />}
      {!showMessageLoader && alsoTryQuestions.length > 0 && (
        <AlsoTry questions={alsoTryQuestions} />
      )}
    </Container>
  );
};

export default Messages;
