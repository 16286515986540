import { faLongArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import useGetReduxState from "../../../Hooks/useGetReduxState";
import Container from "./styles";
import langText from "../translations";
const Booking = ({ booking, handleClick }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  return (
    <Container>
      <div className="first-row">
        <p>{moment(booking.date).format("DD MMMM, YYYY")}</p>
        <p>PNR: {booking.pnr}</p>
      </div>
      <div className="second">
        <div className="info">
          <p className="train">
            {booking.trainName} - <span>{booking.trainNumber}</span>
          </p>
          <div className="third-row">
            <p>{booking.source}</p>
            <FontAwesomeIcon
              icon={faLongArrowRight}
              style={{ fontSize: "14px", color: "gray", margin: "0px 10px" }}
            />
            <p>{booking.destination}</p>
          </div>
        </div>
        <button onClick={() => handleClick(booking.pnr)}>
          {langText[lang].select}
        </button>
      </div>
    </Container>
  );
};

export default Booking;
