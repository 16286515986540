import moment from "moment";
import { useEffect, useState } from "react";
import { getStationName } from "../../Helpers/getStationName";
import { CheckBox } from "../../UI/UIComponents/Checkbox";
import Container from "./styles";
import { parseBooking } from "./utils";
import langText from "./translations";
import useGetReduxState from "../../Hooks/useGetReduxState";
import Disabled from "../Disabled";
import Alert from "../../UI/UIComponents/Alert";
import useSendQuery from "../../Hooks/useSendQuery/useSendQuery";
const SelectPassengers = ({ data, isFor }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const getQuery = () => {
    if (isFor === "TDR")
      return lang === "en"
        ? "File TDR Request Sent"
        : "फ़ाइल टीडीआर के लिए अनुरोध भेजा गया";

    return lang === "en"
      ? "Cancellation Request Sent"
      : "रद्द करने के लिए अनुरोध भेजा गया";
  };
  const [disabled, setDisabled] = useState(false);

  const [tokens, setTokens] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const { source, destination, date, trainName, trainNumber, passengers, pnr } =
    parseBooking(data, isFor);

  const onPassengerClick = (index) => {
    let toks = tokens;
    toks[index] = !toks[index];

    setTokens([...tokens]);
  };

  const isAllSelected = () => {
    let actualPasengers = tokens.slice(0, passengers.length);
    return actualPasengers.every((value) => value);
  };

  const selectAll = () => {
    let flag = isAllSelected() ? false : true;
    let toks = tokens;
    for (let i = 0; i < passengers.length; i++) toks[i] = flag;

    setTokens([...toks]);
  };
  const { sendQueryPayload } = useSendQuery(() => {});

  const handleSend = () => {
    setShow(false);
    setDisabled(true);
    const passengerToken = tokens.map((token) => (token ? "Y" : "N")).join("");
    sendQueryPayload({
      query: getQuery(),
      data: passengerToken,
      inputType: "",
    });
  };

  const [show, setShow] = useState(false);

  const isDisabled = () => tokens.every((token) => !token);

  const handleClick = () => {
    if (isFor === "TDR") handleSend();
    else setShow(true);
  };

  const getPassengers = () => {
    let selectedPassengers = [];

    for (var i = 0; i < passengers.length; i++) {
      if (tokens[i]) selectedPassengers.push(passengers[i]);
    }
    let str = "";

    if (selectedPassengers.length == 1) return selectedPassengers[0].name;

    for (var i = 0; i < selectedPassengers.length - 1; i++) {
      str += "" + selectedPassengers[i].name + " ";
    }
    let x = lang == "en" ? "and " : "और ";
    str = str + x + selectedPassengers[selectedPassengers.length - 1].name;

    return str;
  };

  const getMsg = () => {
    if (lang === "en") {
      return langText[lang].body + getPassengers();
    } else {
      return getPassengers() + langText[lang].body;
    }
  };

  const messages = useGetReduxState().messages;
  useEffect(() => {
    if (
      !disabled &&
      messages[messages.length - 1] &&
      messages[messages.length - 1].type !== "COMPONENT"
    )
      setDisabled(true);
  }, [messages]);

  return (
    <Container>
      {disabled && <Disabled />}
      <div className="first">
        <p className="date">{moment(date).format("MMMM DD, YYYY")}</p>
        <p className="pnr">PNR: {pnr}</p>
      </div>
      <div className="second">
        <p className="source">{source}</p>
        <p className="train">
          {trainName} ({trainNumber})
        </p>
        <p className="destination">{destination}</p>
      </div>
      <div className="third">
        <p className="source">{getStationName(source)}</p>
        <p className="destination">{getStationName(destination)}</p>{" "}
      </div>
      <div className="hr"></div>

      <div className="passengers">
        <div className="header">
          <p>{langText[lang].left}</p>
          <CheckBox
            label={langText[lang].right}
            boxSize="16px"
            boxRadius={"3px"}
            fontColor={"#424242"}
            fontSize={"14px"}
            fontWeight={"400px"}
            lineHeight={"16px"}
            margin={"0px"}
            space={"5px"}
            isChecked={isAllSelected()}
            onCheck={selectAll}
          />
        </div>

        {passengers.map((passenger, index) => (
          <CheckBox
            label={`${passenger.name} - ${passenger.status}`}
            boxSize="18px"
            boxRadius={"3px"}
            fontColor={"#424242"}
            fontSize={"15px"}
            fontWeight={"400px"}
            lineHeight={"16px"}
            margin={"10px 0px"}
            space={"8px"}
            isChecked={tokens[index]}
            onCheck={() => onPassengerClick(index)}
          />
        ))}
      </div>

      <div className="hr"></div>

      <button onClick={handleClick} disabled={isDisabled()}>
        {isFor === "TDR"
          ? langText[lang].buttonTDR
          : langText[lang].buttonCancel}
      </button>

      {show && (
        <Alert
          header={langText[lang].are}
          content={getMsg()}
          secondaryText={"No"}
          primaryText={"Yes"}
          handleSecondaryClick={() => setShow(false)}
          handlePrimaryClick={handleSend}
        />
      )}
    </Container>
  );
};

export default SelectPassengers;
