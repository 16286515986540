const translations = {
  en: {
    header: "Filter Trains By",
    arrival: "Arrival Time at",
    departure: "Departure Time from",
    early: "Early Morning",
    morning: "Morning",
    afternoon: "Afternoon",
    night: "Night",
    button: "Filter Trains",
  },
  hi: {
    header: "ट्रेनों को फ़िल्टर करें",
    arrival: "आगमन का समय",
    departure: "प्रस्थान का समय",
    early: "बहुत सवेरे",
    morning: "सुबह",
    afternoon: "दोपहर",
    night: "रात्रि",
    button: "फ़िल्टर करें",
  },
  gu: {
    header: "ટ્રેનોને ફિલ્ટર કરો",
    arrival: "આગમનનો સમય",
    departure: "પ્રસ્થાનનો સમય",
    early: "ખૂબ વહેલી સવારે",
    morning: "સવારે તા.",
    afternoon: "બપોર પછી",
    night: "રાત.",
    button: "ફિલ્ટર કરો",
  },
};

export default translations;
