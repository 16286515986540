import { Journey } from "../Reducers/types";
import {
  SET_BOARDING,
  SET_DATE,
  SET_DESTINATION,
  SET_JOURNEY,
  SET_QUOTA,
  SET_SOURCE,
} from "./constants";

export const setJourneyData = (journey: Journey) => {
  const action = { type: SET_JOURNEY, journey: journey };
  return action;
};

export const setChosenSource = (value: string) => {
  const action = { type: SET_SOURCE, value: value };
  return action;
};

export const setChosenDestination = (value: string) => {
  const action = { type: SET_DESTINATION, value: value };
  return action;
};

export const setChosenQuota = (value: string) => {
  const action = { type: SET_QUOTA, value: value };
  return action;
};

export const setChosenDate = (value: string) => {
  const action = { type: SET_DATE, value: value };
  return action;
};

export const setChosenBoarding = (value: string) => {
  const action = { type: SET_BOARDING, value: value };
  return action;
};
