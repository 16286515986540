import {
  SET_BOARDING,
  SET_DATE,
  SET_DESTINATION,
  SET_JOURNEY,
  SET_QUOTA,
  SET_SOURCE,
} from "../Actions/constants";
import { Journey } from "./types";

const intialJourney: Journey = {
  source: "",
  destination: "",
  quota: "",
  date: "",
  boardingStation: "",
};

const reducer = (journey: Journey = intialJourney, action) => {
  switch (action.type) {
    case SET_JOURNEY:
      return {
        source: action.journey.source,
        destination: action.journey.destination,
        quota: action.journey.quota,
        date: action.journey.date,
        boardingStation: action.journey.boardingStation,
      };

    case SET_SOURCE:
      return {
        ...journey,
        source: action.value,
      };

    case SET_DESTINATION:
      return {
        ...journey,
        destination: action.value,
      };

    case SET_DATE:
      return {
        ...journey,
        date: action.value,
      };

    case SET_QUOTA:
      return {
        ...journey,
        quota: action.value,
      };

    case SET_BOARDING:
      return {
        ...journey,
        boardingStation: action.value,
      };

    default:
      return journey;
  }
};

export default reducer;
