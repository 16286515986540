import store from "../Store";

export let Classes = [
  {
    label: "First AC",

    label_hi: "फर्स्ट आ.स.",

    value: "1A",

    label_gu: "પ્રથમ એસી",
  },

  {
    label: "AC 2 Tier",

    label_hi: "आ.स. 2 टियर",

    value: "2A",

    label_gu: "એસી 2 સ્તર",
  },

  {
    label: "First Class",

    label_hi: "फर्स्ट क्लास",

    value: "FC",

    label_gu: "પ્રથમ વર્ગ",
  },

  {
    label: "AC 3 Tier",

    label_hi: "आ.स. 3 टियर",

    value: "3A",

    label_gu: "એસી 3 સ્તર",
  },

  {
    label: "AC 3 Tier Economy",

    label_hi: "आ.स. 3 टियर इकॉनमी",

    value: "3E",

    label_gu: "એસી 3 સ્તરનું અર્થતંત્ર",
  },

  {
    label: "AC Chair Car",

    label_hi: "आ.स. चेयर कार",

    value: "CC",

    label_gu: "એસી ચેર કાર",
  },

  {
    label: "Sleeper",

    label_hi: "स्लीपर क्लास",

    value: "SL",

    label_gu: "સ્લીપર",
  },

  {
    label: "Second Sitting",

    label_hi: "सेकंड सिट्टिन्ग्",

    value: "2S",

    label_gu: "બીજી બેઠક",
  },

  {
    label: "Executive Class",

    label_hi: "एग्जीक्यूटिव क्लास",

    value: "EC",

    label_gu: "કાર્યકારી વર્ગ",
  },

  {
    label: "Anubhuti",

    label_hi: "अनुभूति",

    value: "EA",

    label_gu: "અનુભૂતિ",
  },

  {
    label: "Vistadome AC",

    label_hi: "विस्टाडोम आ.स.",

    value: "EV",

    label_gu: "વિસ્ટાડોમ એસી",
  },
];
const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getClassName = (code) => {
  let lang = store.getState().behaviour.lang;
  let foundArr = Classes.filter((classs) => classs.value === code);
  if (foundArr.length > 0) {
    if (lang === "en") return toTitleCase(foundArr[0].label);
    if (lang === "hi") return foundArr[0].label_hi;
    if (lang === "gu") return foundArr[0].label_gu;
  } else return "";
};
