import styled from "styled-components";

const Container = styled.div`
  width: 8.3in;
  background: white;
  padding: 0.3in;

  font-family: "Helvetica", sans-serif;

  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    margin: 0;
  }

  p,
  h5,
  h4 {
    line-height: 1.4;
  }

  .border {
    border: 2px solid black;
    height: 11in;
    overflow: hidden;
  }
`;

export default Container;
