import useGetReduxState from "../../Hooks/useGetReduxState";

const Header = () => {
  const lang = useGetReduxState().behaviour.lang;
  if (lang === "en") return <div>What are you looking for?</div>;

  if (lang === "hi") return <div>आप क्या खोज रहे हैं?</div>;

  if (lang === "gu") return <div>તમે શું શોધી રહ્યાં છો?</div>;
};

export default Header;
