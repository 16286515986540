const translations = {
  en: {
    bookForm: {
      search: "Search Train",
      from: "From",
      to: "To",
      date: "Journey Date",
      quota: "Journey Quota",
      source: "Source Station",
      destination: "Destination Station",
      bookings: "My Bookings",
    },
    howWorks: {
      heading: "How to book train ticket on AskDISHA?",
      sub: "Easy, Fast and Secure!",
      find: "Search trains",
      compare: "Select the best train",
      otp: "Enter IRCTC OTP",
      otp2: "",
      booked: "Ticket is booked",
    },
    coming: "Under Maintainance",
    today: "Today",
    looking: "What are you looking for?",
    else: "Looking for something else?",
    bookButton: {
      text: "Book ticket",
      query: "Book Ticket",
    },
    quickButtons: {
      refund: {
        text: "Refund Status",
        subtext: "Check your refund status.",
        query: "Check Refund Status",
      },
      book: {
        text: "Book Ticket",
        subtext: "Book your e-ticket.",
        query: "Book Ticket",
      },
      pnr: {
        text: "PNR Status",
        subtext: "Check your PNR status.",
        query: "Check PNR Status",
      },
      cancel: {
        text: "Cancel Ticket",
        subtext: "Cancel your ticket.",
        query: "Cancel My Ticket",
      },
      history: {
        text: "Booking History",
        subtext: "Track your bookings.",
        query: "Booking History",
      },
      boarding: {
        text: "Change Boarding",
        subtext: "Change your boarding station.",
        query: "Change My Boarding Station",
      },
      failed: {
        text: "Failed Bookings",
        subtext: "Change your boarding station.",
        query: "My Failed Transactions",
      },
      faq: {
        text: "Ask Your Query",
        subtext: "Frequently Asked Questions",
        query: "",
      },
      tdr: {
        text: "File TDR",
        subtext: "Frequently Asked Questions",
        query: "File TDR",
      },
      feedback: {
        text: "Help Me Improve",
        subtext: "Frequently Asked Questions",
        query: "HMI",
      },
    },
    upcomingBookings: {                          
      button: "View upcoming Bookings",
      header: "My Upcoming Bookings",
      wait: "Please wait... ",
      verify: {
        text: "Please verify your phone number to see your upcoming bookings",
        button: "Verify Phone Number",
      },
      noBooking: "You have no upcoming bookings",
      see: "See All",
      seeQuery: "See All Bookings",
      seats: "Seats",
    },
  },
  hi: {
    bookForm: {
      search: "ट्रेन खोजें",
      from: "प्रस्थान",
      to: "आगमन",
      date: "यात्रा तिथि",
      quota: "यात्रा कोटा",
      source: "प्रस्थान स्टेशन चुनें",
      destination: "आगमन स्टेशन चुनें",
      bookings: "मेरी बुकिंग",
    },
    howWorks: {
      heading: "AskDISHA पर ट्रेन की टिकट कैसे बुक करें?",
      sub: "आसान, तेज और सुरक्षित!",
      find: "ट्रेन खोजें",
      compare: "सबसे अच्छी ट्रेन चुनें",
      otp: "IRCTC OTP दर्ज करें",
      otp2: "",
      booked: "टिकट बुक हो जाएगा",
    },
    coming: "रखरखाव के तहत",
    today: "आज",
    looking: "मैं आपकी कैसे मदद कर सकती हूं?",
    else: "मैं इनमें भी आपकी मदद कर सकती हूं",
    bookButton: {
      text: "टिकट बुक करें",
      query: "टिकट बुक करो",
    },
    quickButtons: {
      refund: {
        text: "रिफंड की स्थिति",
        subtext: "अपनी धनवापसी स्थिति जांचें",
        query: "मुझे रिफंड स्तिथि की जांच करनी है",
      },
      book: {
        text: "टिकट बुक",
        subtext: "अपनी ई-टिकट बुक करें",
        query: "टिकट बुक करो",
      },
      pnr: {
        text: "PNR की स्थिति",
        subtext: "अपने PNR की स्थिति देखें",
        query: "PNR की स्थिति दिखाओ",
      },
      cancel: {
        text: "टिकट रद्द करें",
        subtext: "अपनी टिकट रद्द करें",
        query: "टिकट रद्द करो",
      },
      history: {
        text: "बुकिंग इतिहास",
        subtext: "अपनी सभी बुकिंग देखें",
        query: "बुकिंग इतिहास",
      },
      boarding: {
        text: "बोर्डिंग बदलें",
        subtext: "अपना बोर्डिंग स्टेशन बदलें",
        query: "मेरा बोर्डिंग बदलो",
      },
      failed: {
        text: "असफल ट्रांसक्शन्स",
        subtext: "Change your boarding station.",
        query: "मेरी असफल ट्रांसक्शन्स दिखाओ",
      },
      tdr: {
        text: "TDR फाइल करें",
        subtext: "Frequently Asked Questions",
        query: "TDR फाइल",
      },
      faq: {
        text: "अपना प्रश्न पूछें",
        subtext: "Frequently Asked Questions",
        query: "",
      },
      feedback: {
        text: "मुझे सुधारने में मदद करें",
        subtext: "Frequently Asked Questions",
        query: "HMI",
      },
    },
    upcomingBookings: {
      button: "आने वाली बुकिंग देखें",
      header: "मेरी आने वाली बुकिंग",
      wait: "कृपया प्रतीक्षा करें...",
      verify: {
        text: "अपनी आने वाली बुकिंग देखने के लिए कृपया अपने फ़ोन नंबर की पुष्टि करें",
        button: "फ़ोन नंबर की पुष्टि करें",
      },
      noBooking: "आपकी कोई आने वाली बुकिंग नहीं है",
      see: "सभी बुकिंग देखें",
      seeQuery: "मेरा बुकिंग इतिहास दिखाओ",
      seats: "सीटें",
    },
  },
  gu: {
    bookForm: {
      search: "ટ્રેનની શોધ",
      from: "પ્રસ્થાન",
      to: "આગમન",
      date: "મુસાફરીની તારીખ",
      quota: "મુસાફરીનો ક્વોટા",
      source: "પ્રસ્થાન સ્ટેશન પસંદ કરો",
      destination: "આગમન સ્ટેશન પસંદ કરો",
      bookings: "મારું બુકિંગ",
    },
    howWorks: {
      heading: "AskDISHA પર ટ્રેન ટિકિટ કેવી રીતે બુક કરવી?",
      sub: "ઝડપી, સરળ અને સલામત!",
      find: "ટ્રેનની શોધ",
      compare: "શ્રેષ્ઠ ટ્રેન પસંદ કરો",
      otp: "આઈઆરસીટીસીનો ઓ. ટી. પી. દાખલ કરો.",
      otp2: "",
      booked: "ટિકિટ બુક કરવામાં આવશે.",
    },
    coming: "જાળવણી હેઠળ",
    today: "આજની",
    looking: "હું તમને કેવી રીતે મદદ કરી શકું?",
    else: "હું તમને આમાં પણ મદદ કરી શકું છું.",
    bookButton: {
      text: "તમારી ટિકિટ બુક કરો",
      query: "ટિકિટ બુક કરો",
    },
    quickButtons: {
      refund: {
        text: "રિફંડની સ્થિતિ",
        subtext: "તમારા રિફંડની સ્થિતિ તપાસો.",
        query: "મારે રિફંડની સ્થિતિ તપાસવાની છે.",
      },
      book: {
        text: "ટિકિટ બુક",
        subtext: "તમારી ઇ-ટિકિટ બુક કરો",
        query: "ટિકિટ બુક કરો",
      },
      pnr: {
        text: "પી. એન. આર. સ્થિતિ",
        subtext: "તમારા પી. એન. આર. ની સ્થિતિ તપાસો.",
        query: "પી. એન. આર. સ્થિતિ બતાવો",
      },
      cancel: {
        text: "ટિકિટ રદ કરો",
        subtext: "તમારી ટિકિટ રદ કરો",
        query: "ટિકિટ રદ કરો",
      },
      history: {
        text: "બુકિંગનો ઈતિહાસ",
        subtext: "તમારી તમામ બુકીંગ જુઓ.",
        query: "બુકિંગનો ઈતિહાસ",
      },
      boarding: {
        text: "બોર્ડિંગ બદલો",
        subtext: "તમારું બોર્ડિંગ સ્ટેશન બદલો",
        query: "મારું બોર્ડિંગ બદલો",
      },
      failed: {
        text: "નિષ્ફળ વ્યવહારો",
        subtext: "તમારું બોર્ડિંગ સ્ટેશન બદલો.",
        query: "મારા નિષ્ફળ વ્યવહારો બતાવો",
      },
      tdr: {
        text: "ફાઇલ TDR",
        subtext: "Frequently Asked Questions",
        query: "ટીડીઆર ફાઇલ",
      },
      faq: {
        text: "તમારા પ્રશ્ન પૂછો",
        subtext: "Frequently Asked Questions",
        query: "",
      },
      feedback: {
        text: "મને સુધારવામાં મદદ કરો.",
        subtext: "Frequently Asked Questions",
        query: "HMI",
      },
    },
    upcomingBookings: {
      button: "ઇનકમિંગ બુકિંગ્સ જુઓ",
      header: "મારું આગામી બુકિંગ",
      wait: "રાહ જુઓ...",
      verify: {
        text: "તમારી આગામી બુકિંગ જોવા માટે કૃપા કરીને તમારા ફોન નંબરની પુષ્ટિ કરો.",
        button: "ફોન નંબર ચકાસો",
      },
      noBooking: "તમારી પાસે કોઈ ઇનકમિંગ બુકિંગ નથી",
      see: "તમામ આરક્ષણો જુઓ.",
      seeQuery: "મારી બુકિંગ હિસ્ટ્રી બતાવો",
      seats: "બેઠકો",
    },
  },
};

export default translations;
