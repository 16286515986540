const translations = {
  hi: {
    selected: "ट्रेन चयनित",
    edit: "एडिट",
  },
  en: {
    selected: "Train Selected",
    edit: "EDIT",
  },
  gu: {
    selected: "પસંદ કરેલી ટ્રેન",
    edit: "સંપાદિત કરો",
  },
};

export default translations;
