import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
import PassengerWithBerthDetails from "./PassengerWithBerthDetails";
import PassengerWithoutBerthDetails from "./PassengerWithoutBerthDetails";

const langText = {
  hi: {
    view: "विस्तृत बुकिंग स्थिति देखें",
    hide: "विवरण छुपाएं",
    confirmed: "Confirmed यात्रि",
    waiting: "Waiting List यात्रि",
  },
  en: {
    view: "View Detailed Booking Status",
    hide: "Hide Details",
    confirmed: "Confirmed Passengers",
    waiting: "In Waiting List",
  },
  gu: {
    view: "વિગતવાર આરક્ષણની સ્થિતિ જુઓ",

    hide: "વિગતો છુપાવો",

    confirmed: "પુષ્ટિ પામેલા મુસાફરો",

    waiting: "રાહ યાદીમાં",
  },
};

const StatusDetails = ({ bookingStatus, confirmed, waiting, isAllotted }) => {
  const [details, showDetails] = useState(true);
  const lang = useGetReduxState().behaviour.lang;
  return (
    <div style={{ margin: "10px auto" }}>
      <button
        style={{
          border: "none",
          backgroundColor: "transparent",
          color: "rgb(11, 116, 250)",
          fontSize: "14px",
          padding: "8px",
          fontWeight: "500",
          borderRadius: "5px",
          cursor: "pointer",
          minWidth: "130px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0px auto",
        }}
        onClick={() => showDetails((prev) => !prev)}
      >
        {details ? (
          <>
            {langText[lang].hide}{" "}
            <FontAwesomeIcon
              icon={faCaretUp}
              style={{
                color: "0b74fa",
                marginLeft: "5px",
                fontSize: "16px",
              }}
            />
          </>
        ) : (
          <>
            {langText[lang].view}{" "}
            <FontAwesomeIcon
              icon={faCaretDown}
              style={{
                color: "0b74fa",
                marginLeft: "5px",
                fontSize: "16px",
              }}
            />
          </>
        )}
      </button>
      <div
        style={{
          maxHeight: details ? 2 * 70 + "px" : "0px",
          transition: "max-height 0.5s ease-in-out",
          overflow: "hidden",
        }}
      >
        {bookingStatus === "PARTIAL" && (
          <p
            style={{
              margin: "0px",
              fontSize: "12px",
              color: "dimgray",
            }}
          >
            {langText[lang].confirmed} ({confirmed.length})
          </p>
        )}

        <div>
          {isAllotted &&
            confirmed.map((passenger) => (
              <PassengerWithBerthDetails passenger={passenger} />
            ))}
          {!isAllotted &&
            confirmed.map((passenger) => (
              <PassengerWithoutBerthDetails passenger={passenger} />
            ))}
        </div>
        {bookingStatus === "PARTIAL" && (
          <p
            style={{
              margin: "0px",
              fontSize: "12px",
              color: "dimgray",
              marginTop: "10px",
            }}
          >
            {langText[lang].waiting} ({waiting.length})
          </p>
        )}
        <div>
          {waiting.map((passenger) => (
            <PassengerWithoutBerthDetails passenger={passenger} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatusDetails;
