import moment from "moment";
import store from "../../Store";

import langTextPassengers from "./Components/BookingDetails/Content/translations";
import langTextFailed from "./Components/BookingDetails/ContentForFailed/translations";

const parseDateTimeForBackendDumbFucks = (dateTime) => {
  if (
    !dateTime.includes("T") &&
    dateTime.includes(" ") &&
    dateTime.includes(".")
  )
    return moment(dateTime.split(".")[0], "YYYY-MM-DD hh:mm:ss");
  else if (dateTime.includes("T")) {
    return moment(dateTime.split(".")[0], "YYYY-MM-DDTh:m:s");
  } else return moment(dateTime, "MMM DD, YYYY, hh:mm:ss a");
};

const makeArray = (array) => {
  if (Array.isArray(array)) return array;
  else return [array];
};

const getGender = (code) => {
  const lang = store.getState().behaviour.lang;
  if (code === "M") return langTextPassengers[lang].male;
  if (code === "F") return langTextPassengers[lang].female;
  else return langTextPassengers[lang].transgender;
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const getBerthDetail = (detail) => {
  if (detail === "" || detail === "0" || !detail) return "N/A";

  return detail;
};

const getStatus = (status) => {
  const lang = store.getState().behaviour.lang;
  if (status.toLowerCase() === "can")
    return {
      label: langTextPassengers[lang].can,
      color: "#ff4c4c",
    };

  if (status.toLowerCase().includes("wl"))
    return {
      label: `${langTextPassengers[lang].wl} - ${status}`,
      color: "#ff9800",
    };

  return {
    label: langTextPassengers[lang].cnf,
    color: "#56c05a",
  };
};

const constructassengers = (passengers) => {
  const lang = store.getState().behaviour.lang;
  return makeArray(passengers).map((passenger) => {
    return {
      name: toTitleCase(passenger.passengerName),
      gender: getGender(passenger.passengerGender),
      age: passenger.passengerAge + langTextPassengers[lang].years,
      atBooking: {
        status: getStatus(passenger.bookingStatus),
        seat: getBerthDetail(passenger.bookingBerthNo),
        berth: getBerthDetail(passenger.bookingBerthCode),
        coach: getBerthDetail(passenger.bookingCoachId),
      },
      atCurrent: {
        status: getStatus(passenger.currentStatus),
        seat: getBerthDetail(passenger.currentBerthNo),
        berth: getBerthDetail(passenger.currentBerthCode),
        coach: getBerthDetail(passenger.currentCoachId),
      },
    };
  });
};

// const PayTmResultCodesForNoTxn;

const constructRefundMsg = (data) => {
  let status = "";
  let message = "";

  let txn = parseDateTimeForBackendDumbFucks(data.txnDate);

  let current = moment(new Date());
  // Current - txn <= 59
  let result = current.diff(txn, "minutes");

  if (
    data.gatewayStage === "1" &&
    result < 90 &&
    !(data.resultStatus === "TXN_FAILURE")
  ) {
    status = "PENDING";
    message =
      "Refund will be initiated! Payment amount will be credited to your bank account in 3 to 7 working days";
  } else if (
    data.gatewayStage === "1" &&
    result > 90 &&
    !(data.resultStatus === "TXN_FAILURE")
  ) {
    status = "PENDING";
    message = "Refund will be initiated!";
  } else if (data.gatewayStage === "3" && data.resultStatus === "TXN_SUCCESS") {
    status = "DONE";
    message = "Refund is initiated! Check Refund Status";
  } else {
    status = data.resultStatus;
    message = data.resultMsg;
  }
  return { status, message };
};

const getPayMode = (mode) => {
  if (!mode) return mode;
  if (mode.toUpperCase() == "DC") return "Debit Card";
  if (mode.toUpperCase() == "CC") return "Credit Card";

  return mode;
};

const getFailedMDK = (bookings, isForFailed) => {
  let data = bookings.filter(
    (booking) => booking.resultStatus === "TXN_SUCCESS"
  );

  data = data.filter((booking) => {
    let txnDate = parseDateTimeForBackendDumbFucks(booking.txnDate);
    let duration = moment.duration(moment().diff(txnDate));
    var minutes = parseInt(duration.asMinutes().toString());
    if (isForFailed) {
      if (Math.abs(minutes) > 90) return booking;
    } else {
      if (Math.abs(minutes) <= 90) return booking;
    }
  });

  return data;
};

const constructBooking = (isFailed, data, bookingStatus) => {
  const lang = store.getState().behaviour.lang;

  if (bookingStatus === 5) {
    return {
      orderId: data.orderId || "",
      txnAmount: data.txnAmount,
      date: parseDateTimeForBackendDumbFucks(data.txnDate),
      // code: data.resultCode,
      // dStatus: data.resultStatus,
      // dMsg: data.resultMsg,
      bookingStatus: bookingStatus,
    };
  }

  if (isFailed) {
    let { status, message } = constructRefundMsg(data);
    return {
      orderId: data.orderId || "",
      refundAmount: data.refundAmt,
      txnId: data.txnId,
      txnAmount: data.txnAmount,
      date: parseDateTimeForBackendDumbFucks(data.txnDate),
      status: status,
      message: message,
      bankTxnId: data.bankTxnId || langTextFailed[lang].not,
      paymentMode: getPayMode(data.paymentMode) || langTextFailed[lang].not,
      bankName: data.bankName || langTextFailed[lang].not,
      // code: data.resultCode,
      // dStatus: data.resultStatus,
      // dMsg: data.resultMsg,
      bookingStatus: bookingStatus,
      ticketData: { orderId: data.orderId },
    };
  } else {
    const bookingData = JSON.parse(data.bookingData);

    

    return {
      pnr: bookingData.pnrNumber,
      bookingStatus: bookingStatus,
      trainName: toTitleCase(bookingData.trainName),
      trainNumber: bookingData.trainNumber,
      source: bookingData.boardingStn,
      destination: bookingData.destStn,
      bookingDate: parseDateTimeForBackendDumbFucks(bookingData.bookingDate),
      orderId: bookingData.requestedClientTransactionId,
      class: bookingData.journeyClass,
      quota: bookingData.journeyQuota,
      departureDate: parseDateTimeForBackendDumbFucks(bookingData.boardingDate),
      departureDateTime: moment(
        parseDateTimeForBackendDumbFucks(bookingData.boardingDate).format(
          "YYYYMMDD"
        ) + bookingData.departureTime,
        "YYYYMMDDh:m"
      ),
      arrivalDate: parseDateTimeForBackendDumbFucks(bookingData.destArrvDate),
      departureTime: bookingData.departureTime,
      arrivalTime: bookingData.arrivalTime,
      distance: bookingData.distance,

      amount:
        "₹" +
        (parseFloat(bookingData.totalCollectibleAmount) + 11.8).toFixed(2),
      passengers: constructassengers(bookingData.psgnDtlList),
      ticketData: constructBookingForETicket(data),
    };
  }
};

const sortBookings = (isFailed, array) => {
  if (isFailed) {
    return array.sort((a, b) => a.date - b.date).reverse();
  } else {
    return array
      .sort((a, b) => a.departureDateTime - b.departureDateTime)
      .reverse();
  }
};

export const parseBookings = (bookings) => {
  let upcoming = bookings.upcomingjourney.map((booking) =>
    constructBooking(false, booking, 1)
  );

  let cancelled = bookings.cancelled.map((booking) =>
    constructBooking(false, booking, 2)
  );
  let completed = bookings.completed.map((booking) =>
    constructBooking(false, booking, 4)
  );
  let failed = getFailedMDK(bookings.failed, true).map((booking) =>
    constructBooking(true, booking, 3)
  );

  let pending = getFailedMDK(bookings.failed, false).map((booking) =>
    constructBooking(true, booking, 5)
  );

  return {
    upcoming: sortBookings(false, upcoming),
    cancelled: sortBookings(false, cancelled),
    completed: sortBookings(false, completed),
    failed: sortBookings(true, failed),
    pending: sortBookings(true, pending),
  };
};

export const constructBookingForETicket = (data) => {
  const bookingData = JSON.parse(data.bookingData);

  return {
    pnr: bookingData.pnrNumber,
    ...bookingData,
  };
};
