const audios = {
  en: {
    SELECT_TRAIN:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/778210de-128b-47e9-b8ca-df0bd1cbac21_en.mp3",
    CHOOSE_DATE:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/8ba7609e-6663-4e6b-a310-a9e535ab1caf_en.mp3",
    CONFIRM_BOOKING:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/aecf79ae-0954-4bf2-8a8b-46b5a496333b_en.mp3",
    ENTER_MOBILE:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/e4a072f3-1c36-48b2-a224-5a2a07344b4a_en.mp3",
    ENTER_OTP:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/e08f2f11-07f5-404a-920a-a41e81a999bd_en.mp3",
    IRCTC_ID:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/31ac9ef5-4f4c-42ce-895c-b73843328a7a_en.mp3",
    SELECT_PASSENGERS:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/8744f9f6-8a75-4869-a75f-3e37ce1b9f97_en.mp3",
    SELECT_PREFERNCES:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/415631c3-91b9-4989-b8fe-358a754c275c_en.mp3",
    REVIEW_JOURNEY:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/9d8ae269-572f-45fc-b5af-28babb54f110_en.mp3",
    NEXT_STEPS:
    "https://coroverbackendstorage.blob.core.windows.net/chatbot-audio-bucket/52dc30cf-807a-4b6d-9dfc-a8e79d7cb6ad_en.mp3",
    PROCEED_PAYMENT:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/da0ffec5-9092-4328-8cb0-eef6a6a39a70_en.mp3",
    PAYMENT_SUCCESS:
      "https://coroverbackendstorage.blob.core.windows.net/chatbot-audio-bucket/d3f27d77-346f-4b12-8253-4b82f54b74f0_en.mp3",
    BOOKING_OTP:
      "https://coroverbackendstorage.blob.core.windows.net/chatbot-audio-bucket/7c2b53d4-d80a-46fc-b053-bf30ff2024b3_en.mp3",
  },
  hi: {
    SELECT_TRAIN:
      "https://uiresource.blob.core.windows.net/selfonboard-res/20d1f457-156c-4094-a9a8-ee1ab859cd32.wav",
    CHOOSE_DATE:
      "https://uiresource.blob.core.windows.net/selfonboard-res/a7c099c5-2321-4f69-851d-7333ab275371.wav",
    CONFIRM_BOOKING:
      "https://uiresource.blob.core.windows.net/selfonboard-res/a2c345a6-18c0-4663-ae21-25dac58a2baf.wav",
    ENTER_MOBILE:
      "https://uiresource.blob.core.windows.net/selfonboard-res/b95d7501-0a0b-485c-8421-e4c03e167f4d.wav",
    ENTER_OTP:
      "https://uiresource.blob.core.windows.net/selfonboard-res/8f0ece5f-eb2c-435d-939a-629998ec2023.wav",
    IRCTC_ID:
      "https://uiresource.blob.core.windows.net/selfonboard-res/2b8dcccf-bcc9-4a97-ac0a-90279c18a310.wav",
    SELECT_PASSENGERS:
      "https://uiresource.blob.core.windows.net/selfonboard-res/ba39af6d-d2b4-4edb-99b4-69ab433e6e4f.wav",
    SELECT_PREFERNCES:
      "https://uiresource.blob.core.windows.net/selfonboard-res/e77d9a80-2d9f-4c9c-a5a0-ca29a1e33eb5.wav",
    REVIEW_JOURNEY:
      "https://uiresource.blob.core.windows.net/selfonboard-res/4e8a467d-d023-46f4-b9b7-d4657c829ee7.wav",
    NEXT_STEPS:
    "https://coroverbackendstorage.blob.core.windows.net/chatbot-audio-bucket/55a42c16-4a1a-4c20-a498-9411bfabe7c8_hi.mp3",
    PROCEED_PAYMENT:
      "https://uiresource.blob.core.windows.net/selfonboard-res/dbdbaa2f-2509-407e-915a-e51c458e6619.wav",
    PAYMENT_SUCCESS:
      "https://uiresource.blob.core.windows.net/selfonboard-res/c3c91797-9190-42d2-bf17-f19410e19ff6.wav",
    BOOKING_OTP:
      "https://uiresource.blob.core.windows.net/selfonboard-res/5e2d0045-3f95-4177-8ae6-a220b0fe58d4.wav",
  },
  gu: {
    SELECT_TRAIN:
      "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/289f244d-d1d9-4205-a790-c12a9de7f23d.wav",

    CHOOSE_DATE:
      "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/e86cf83f-c5f6-4714-bd57-e67c6c4a7f0e.wav",

    CONFIRM_BOOKING:
      "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/03bbe78d-b5d3-4401-8990-624979eb7bdb.wav",

    ENTER_MOBILE:
      "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/3f7fd275-83e3-4c74-9c76-25693710844e.wav",

    ENTER_OTP:
      "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/fec96140-383a-4a79-88c2-21cc52738076.wav",

    IRCTC_ID:
      "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/8e474cce-9d08-41aa-8e5a-97f9c3f2f356.wav",

    SELECT_PASSENGERS:
      "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/a1554f0f-d888-4199-ae9f-0021fa1688bd.wav",

    SELECT_PREFERNCES:
      "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/6c48d5e1-4d95-4f79-8246-ca8b70bd2492.wav",

    REVIEW_JOURNEY:
      "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/abd0c1b0-1094-40a7-b694-a961d9478925.wav",

    NEXT_STEPS:
    "https://coroverbackendstorage.blob.core.windows.net/chatbot-audio-bucket/97c84ef4-f43a-4408-af36-7561b4c2e6ef_gu.mp3",

    PROCEED_PAYMENT:
      "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/e0e94806-669a-4b95-856d-0f10d1330825.wav",

    PAYMENT_SUCCESS:
      "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/c091331e-372b-4c2a-b1fc-0d56d2566b80.wav",

    BOOKING_OTP:
      "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/57d23769-c1a0-4067-9a38-f64d09bca368.wav",
  },
};

export default audios;
