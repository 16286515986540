import React, { useEffect, useState } from "react";
import useGetReduxState from "../../Hooks/useGetReduxState";
import useSendQuery from "../../Hooks/useSendQuery/useSendQuery";
import Disabled from "../Disabled";
import Booking from "./Booking/Booking";
import Container from "./styles";
import { parseBookings } from "./utils";
import langText from "./translations";
const SelectPNR = ({ data }) => {
  const bookings = parseBookings(data);
  const [disabled, setDisabled] = useState(false);
  const { sendQueryPayload } = useSendQuery(() => {});

  const handleClick = (booking) => {
    setDisabled(true);
    sendQueryPayload({
      query: booking.pnr,
      data: {
        pnr: booking.pnr,
        trainNumber: booking.trainNumber,
        journeyDate: booking.date,
        source: booking.source,
        destination: booking.destination,
        class: booking.class,
      },
      inputType: "",
    });
  };
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  const messages = useGetReduxState().messages;
  useEffect(() => {
    if (
      !disabled &&
      messages[messages.length - 1] &&
      messages[messages.length - 1].type !== "COMPONENT"
    )
      setDisabled(true);
  }, [messages]);

  return (
    <React.Fragment>
      {bookings.length >0 && (
        <Container>
          {disabled && <Disabled />}
          <h2>{langText[lang].header}</h2>
          <div className="bookings-container">
            {bookings.map((booking) => (
              <Booking
                booking={booking}
                handleClick={() => handleClick(booking)}
              />
            ))}
            {/* <h4>{langText[lang].no}</h4> */}
          </div>
        </Container>
      )}
    </React.Fragment>
  );
};

export default SelectPNR;
