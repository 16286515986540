import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import { getClassName } from "../../Helpers/getClassName";
import { getStationName } from "../../Helpers/getStationName";
import useGetReduxState from "../../Hooks/useGetReduxState";
import useSendQuery from "../../Hooks/useSendQuery/useSendQuery";
import Container from "./styles";
import langText from "./translations";
import { parse } from "./utils";

const train =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/train-icon-list.png";

const check =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/check-mark-green.svg" as string;

const BoardingChange = ({ data }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const { refresh } = useSendQuery(() => {});
  const [boardingData, setBoardingData] = useState({
    trainName: "",
    trainNo: "",
    jClass: "",
    pnr: "",
    boarding: "",
    dateTime: moment(),
  });
  const get = async () => {
    const { boarding, dateTime, jClass, pnr, trainName, trainNo } =
      await parse(data);

    setBoardingData({ boarding, jClass, dateTime, pnr, trainName, trainNo });
  };

  useEffect(() => {
    get();
    refresh();
  }, []);

  if (boardingData.trainName === "") return <React.Fragment></React.Fragment>;

  return (
    <Container>
      <h1>
        <p style={{ color: "#3770d7", fontWeight: "700" }}>
          PNR - {boardingData.pnr}
        </p>
        <p style={{ color: "lightseagreen", fontWeight: "600" }}>
          {boardingData.jClass} - {getClassName(boardingData.jClass)}
        </p>
      </h1>
      <h2
        style={{
          fontSize: "16px",
          color: "#353535",
          fontWeight: "700",
          marginTop: "8px",
          marginBottom: "14px",
        }}
      >
        {boardingData.trainName} - ({boardingData.trainNo})
      </h2>
      <h4>
        <img src={check} alt="" />
        {langText[lang].changed}
      </h4>
      <h5> {langText[lang].boarding}</h5>
      <div className="station">
        <img src={train} alt="" />
        <p>
          {" "}
          {getStationName(boardingData.boarding)} - {boardingData.boarding}
        </p>
      </div>
      <div className="date-time">
        <p>{langText[lang].time}</p>

        <h5>
          {moment(boardingData.dateTime).format("hh:mm a, DD MMMM, YYYY")}
        </h5>
      </div>
    </Container>
  );
};

export default BoardingChange;
