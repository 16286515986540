const translations = {
  en: {
    book: "Book Ticket",
    refund: "Refund Status",
    cancel: "Cancel Ticket",
    pnr: "PNR Status",
    boarding: "Change Boarding",
    history: "Booking History",
  },
  hi: {
    book: "टिकट बुक",
    refund: "रिफंड की स्थिति",
    cancel: "टिकट रद्द करें",
    pnr: "PNR की स्थिति",
    boarding: "बोर्डिंग बदलें",
    history: "बुकिंग इतिहास",
  },
  gu: {
    book: "ટિકિટ બુક",
    refund: "રિફંડની સ્થિતિ",
    cancel: "ટિકિટ રદ કરો",
    pnr: "પી. એન. આર. સ્થિતિ",
    boarding: "બોર્ડિંગ બદલો",
    history: "બુકિંગનો ઈતિહાસ",
  },
};

export default translations;
