const translations = {
  en: {
    warning:
      "The Booking will fail if selected reservation choice is not available.",
    auto: "Consider for Auto Upgrade",
    coachField: "Preferred Coach ID",
    coachPlaceholder: "Enter Preferred Coach ID (e.g S1)",
    coachValid: "Please enter a valid coach id",
    reservation: "Reservation Choice",
    choices: {
      "0": "No Choice",
      "1": "Book only if all the berths are allotted in same coach",
      "2": "Book only if atleast 1 lower berth is allotted",
      "3": "Book only if 2 lower berths are allotted",
      "4": "Book only if confirm",
      "5": "Book only if confirm in same coach",
      "6": "Book only if confirm and 1 LB",
      "7": "Book only if confirm and 2 LB",
    },
  },
  hi: {
    warning: "चयनित आरक्षण विकल्प उपलब्ध नहीं होने पर बुकिंग विफल हो जाएगी",
    auto: "ऑटो अपग्रेडेशन का विकल्प चुनें",
    coachField: "पसंदीदा कोच नंबर",
    coachPlaceholder: "पसंदीदा कोच नंबर दर्ज करें (उदाहरण S1)",
    coachValid: "कृपया एक मान्य कोच नंबर दर्ज करें",
    reservation: "आरक्षण विकल्प",
    choices: {
      "0": "कोई विकल्प नहीं",
      "1": "तभी बुक करें जब सभी बर्थ एक ही कोच में अलॉट हों",
      "2": "कम से कम 1 निचली बर्थ अलॉट होने पर ही बुक करें",
      "3": "नीचे की 2 बर्थ अलॉट होने पर ही बुक करें",
      "4": "कन्फर्म होने पर ही बुक करें",
      "5": "एक ही कोच में कन्फर्म होने पर ही बुक करें",
      "6": "कन्फर्म होने और कम से कम 1 निचली बर्थ अलॉट होने पर ही बुक करें",
      "7": "कन्फर्म होने और नीचे की 2 बर्थ अलॉट होने पर ही बुक करें",
    },
  },
  gu: {
    warning: "જો પસંદ કરેલ આરક્ષણ વિકલ્પ ઉપલબ્ધ ન હોય તો આરક્ષણ નિષ્ફળ જશે.",
    auto: "આપમેળે સુધારો પસંદ કરો",
    coachField: "પસંદગીનો કોચ નંબર",
    coachPlaceholder: "પસંદગીનો કોચ નંબર દાખલ કરો (ઉદાહરણ તરીકે S1)",
    coachValid: "મહેરબાની કરીને માન્ય કોચ નંબર દાખલ કરો.",
    reservation: "આરક્ષણ વિકલ્પો",
    choices: {
      "0": "કોઈ વિકલ્પ નથી",
      "1": "જ્યારે એક જ કોચમાં તમામ બર્થ ફાળવવામાં આવે ત્યારે જ બુક કરો",
      "2": "જો ઓછામાં ઓછી 1 નીચલી બર્થ ફાળવવામાં આવે તો જ બુક કરો",
      "3": "જો નીચેની 2 બર્થ ફાળવવામાં આવે તો જ બુક કરો",
      "4": "પુષ્ટિ થાય ત્યારે જ બુક કરો.",
      "5": "જો પુષ્ટિ થાય તો જ એક જ કોચમાં બુક કરો",
      "6": "જો પુષ્ટિ થઈ હોય અને ઓછામાં ઓછી 1 નીચલી બર્થ ફાળવવામાં આવે તો જ બુક કરો",
      "7": "જો પુષ્ટિ થઈ હોય અને 2 બર્થ ફાળવવામાં આવે તો જ બુક કરો",
    },
  },
};

export default translations;
