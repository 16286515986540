const translations = {
  en: {
    upcoming: "Upcoming",
    cancelled: "Cancelled",
    failed: "Failed",
    completed: "Completed",
    pending: "Pending",
  },
  hi: {
    upcoming: "आने वाली",
    cancelled: "रद्द",
    failed: "असफल",
    completed: "संपूर्ण",
    pending: "लंबित",
  },
  gu: {
    upcoming: "આગામી",
    cancelled: "રદ કરાયેલ",
    failed: "નિષ્ફળતા મળી",
    completed: "પૂર્ણ કરો",
    pending: "બાકી છે",
  },
};

export default translations;
