import { useState } from "react";
import Container from "./styles";
import moment from "moment";
import { getStationName } from "../../../../../../../../Helpers/getStationName";
import BookingDetails from "../../../../../../../../Templates/BookingHistory/Components/BookingDetails";
const arrow =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/arrow_right.svg";

const Booking = ({ booking }) => {

  const [show, setShow] = useState(false);
  return (
    <Container onClick={() => setShow(true)}>
      <div className="top">
        <p>{moment(booking.departureDateTime).format("DD MMM, YYYY")}</p>
        <p>{booking.numberOfpassenger} Seats</p>
      </div>
      <div className="hr"></div>
      <div className="details">
        <div className="from">
          <p className="code">{booking.source}</p>
          <p className="station">{getStationName(booking.source)}</p>
          <p className="time">
            {moment(booking.departureDateTime).format("hh:mm A")}
          </p>
        </div>
        <img src={arrow} alt="" />
        <div className="to">
          <p className="code">{booking.destination}</p>
          <p className="station">{getStationName(booking.destination)}</p>
          <p className="time">
            {moment(booking.arrivalDateTime).format("hh:mm A")} 
          </p>
        </div>
      </div>
      {show && (
        <BookingDetails
          tabNumber={1}
          data={booking}
          handleCloseDrawer={() => {
            setShow(false);
          }}
        />
      )}
    </Container>
  );
};

export default Booking;
