import moment from "moment";
import { getQuotaName } from "../../../../Helpers/getQuotaName";
import { getStationName } from "../../../../Helpers/getStationName";

const Journey = ({ train }) => {
  const getDuration = () => {
    const start =
      moment(train.departureTime, "HH:mm").format("HH:mm") +
      ", " +
      train.departureDate;
    const end =
      moment(train.arrivalTime, "HH:mm").format("HH:mm") +
      ", " +
      train.arrivalDate;

    const date1 = moment(start, "hh:mm a, YYYYMMDD");
    const date2 = moment(end, "hh:mm a, YYYYMMDD");

    const diff = date2.diff(date1);

    let duration = moment.duration(diff);

    var hours = parseInt(duration.asHours().toString());

    var minutes = parseInt(duration.asMinutes().toString());

    minutes = minutes - hours * 60;

    return `${hours}hrs ${minutes}min`;
  };

  // const [durationString, setDurationString] = useState("");
  // useEffect(() => {
  //   let duration = getDuration();
  //   setDurationString(duration.hours + "hrs " + duration.minutes + "min");
  // }, []);

  return (
    <div
      style={{
        padding: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          style={{
            margin: "0px",
            fontSize: "14px",
            fontWeight: "400",
            color: "#282828",
          }}
        >
          {train.trainName} - {train.trainNumber}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: "4px",
          color: "gray",
        }}
      >
        {/* <p style={{ margin: "0px", fontSize: "12px", marginRight: "5px" }}>
          (12023)
        </p> */}
        {/* <p style={{ margin: "0px", fontSize: "12px", marginRight: "5px" }}>|</p> */}
        <p style={{ margin: "0px", fontSize: "12px", marginRight: "5px" }}>
          {train.class}
        </p>
        <p style={{ margin: "0px", fontSize: "12px", marginRight: "5px" }}>|</p>
        <p style={{ margin: "0px", fontSize: "12px" }}>
          {getQuotaName(train.quota)} ({train.quota})
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <p
          style={{
            fontSize: "18px",
            margin: "0",
            color: "#282828",
            textAlign: "left",
          }}
        >
          {train.source}
        </p>
        <div style={{ width: "120px", marginTop: "-4px" }}>
          <p
            style={{
              margin: "0px",
              marginBottom: "4px",
              textAlign: "center",
              fontSize: "12px",
              color: "gray",
            }}
          >
            {getDuration()}
          </p>
          <div style={{ width: "100%", borderTop: "1px solid gray" }}></div>
        </div>
        <p
          style={{
            fontSize: "18px",
            margin: "0",
            color: "#282828",
            textAlign: "right",
          }}
        >
          {train.destination}
        </p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "-2px",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <span style={{ fontSize: "12px", color: "#4f4f4f" }}>
            {getStationName(train.source)}
          </span>
          <p
            style={{
              margin: "4px 0px 0px",
              fontSize: "12px",
              color: "#575757",
              fontWeight: "500",
            }}
          >
            {moment(train.departureTime, "hh:mm").format("hh:mm a")},{" "}
            <span>
              {moment(train.departureDate, "YYYYMMDD").format("DD MMM")}
            </span>
          </p>
        </div>
        <div style={{ textAlign: "right" }}>
          <span style={{ fontSize: "12px", color: "#4f4f4f" }}>
            {getStationName(train.destination)}
          </span>
          <p
            style={{
              margin: "4px 0px 0px",
              fontSize: "12px",
              color: "#575757",
              fontWeight: "500",
            }}
          >
            {moment(train.arrivalTime, "hh:mm").format("hh:mm a")},{" "}
            <span>
              {moment(train.arrivalDate, "YYYYMMDD").format("DD MMM")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Journey;
