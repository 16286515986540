import moment from "moment";
import { getStatus, getTrains as getTrainsApi } from "../../Api/trains";

const makeClone = (arr) => {
  return JSON.parse(JSON.stringify(arr));
};
export const getPopUp = (avlClass) => {
  try {
    if (!Array.isArray(avlClass.informationMessage)) {
      avlClass.informationMessage = [avlClass.informationMessage];
    }
    for (let msg of avlClass.informationMessage) {
      if (msg.popup === "true") {
        return msg.message;
      }
    }
  } catch (err) {}

  return "";
};

const getDestinationDate = (startDate, startTime, duration) => {
  let hoursStart = parseInt(startTime.split(":")[0]);
  let minutesStart = parseInt(startTime.split(":")[1]);
  let hoursDur = parseInt(duration.split(":")[0]);
  let minutesDur = parseInt(duration.split(":")[1]);
  let totalMinutes = (hoursDur + hoursStart) * 60 + (minutesDur + minutesStart);

  return moment(startDate, "YYYYMMDD")
    .add(totalMinutes, "minutes")
    .format("YYYYMMDD");
};

const refactorTrain = (chosenDate, train) => {
  // const train = this.getTrain(trains, trainNumber);
  let trainObj: any = {};

  trainObj = {
    trainName: train.trainName,
    trainNumber: train.trainNumber,
    source: train.fromStnCode,
    departureDate: chosenDate,
    arrivalDate: getDestinationDate(
      chosenDate,
      train.departureTime,
      train.duration
    ),
    destination: train.toStnCode,
    departureTime: train.departureTime,
    arrivalTime: train.arrivalTime,
    duration: train.duration,
    running: {
      Mon: train.runningMon == "Y" ? true : false,
      Tue: train.runningTue == "Y" ? true : false,
      Wed: train.runningWed == "Y" ? true : false,
      Thu: train.runningThu == "Y" ? true : false,
      Fri: train.runningFri == "Y" ? true : false,
      Sat: train.runningSat == "Y" ? true : false,
      Sun: train.runningSun == "Y" ? true : false,
    },
    classes: train.avlClasses,
    cachedAvailabilities: train.availability ? train.availability : null,
    tickets: null,
  };

  return trainObj;
};

const isBetween = ({ low, high }) => {
  return moment().isBetween(moment(low, "hh:mm"), moment(high, "hh:mm"));
};

const parseStatus = ({ status, jClass, quota }) => {
  if (!status) return "";

  if (status.includes("#")) {
    if (quota !== "TQ") {
      if (!isBetween({ low: "00:00", high: "08:00" }))
        return status.replace("#", "");

      return status;
    } else {
      if (jClass === "SL" || jClass === "2S") {
        if (!isBetween({ low: "00:00", high: "11:00" }))
          return status.replace("#", "");

        return status;
      } else {
        if (!isBetween({ low: "00:00", high: "10:00" }))
          return status.replace("#", "");

        return status;
      }
    }
  }

  return status;
};

const makeTickets = ({ train, date, quota }) => {
  let tr = train;

  let tickets = [];

  const parsedDate = moment(date, "YYYYMMDD").format("D-M-YYYY");

  let cachedAvailabilities = train.cachedAvailabilities;
  let classesInCache = cachedAvailabilities
    ? Object.keys(cachedAvailabilities)
    : [];
  let classesInTrain = train.classes;
  for (let classObj of classesInTrain) {
    let ticket = null;
    if (classesInCache.includes(classObj)) {
      let cachedClass = cachedAvailabilities[classObj];

      let avlDList = cachedClass.avlDayList;
      if (!Array.isArray(avlDList)) {
        if (avlDList && !cachedClass.hasOwnProperty("errorMessage"))
          avlDList = [cachedClass.avlDayList];
        else avlDList = [];
      }
      let status = "";

      for (let day of avlDList) {
        if (day.availablityDate === parsedDate) {
          status = day.availablityStatus;
          break;
        }
      }

      if (status !== "")
        ticket = {
          class: classObj,
          fare: cachedClass.totalFare,
          availablityStatus: parseStatus({
            status: status,
            jClass: classObj,
            quota: quota,
          }),
          msg: getPopUp(cachedClass),
          error: false,
          cached: true,
          time: moment(cachedClass.updateDtm).fromNow(),
        };
      else
        ticket = {
          class: classObj,
          fare: "",
          availablityStatus: "",
          msg: "",
          error: false,
          cached: false,
          time: "",
        };
    } else {
      ticket = {
        class: classObj,
        fare: "",
        availablityStatus: "",
        msg: "",
        error: false,
        cached: false,
        time: "",
      };
    }

    tickets.push(ticket);
  }

  tr.tickets = tickets;
  return tr;
};

const getAllTrains = (date, trains, quota) => {
  let allTrains: any = [];
  let tArr = [];
  if (!Array.isArray(trains)) tArr = [trains];
  else tArr = trains;
  for (let train of tArr) {
    let temp = refactorTrain(date, train);
    let temp2 = makeTickets({ train: temp, date, quota });
    allTrains.push(temp2);
  }
  return allTrains;
};

export const getTrains = async ({
  source,
  destination,
  date,
  quota,
  isDirect = false,
}) => {
  let allTrains: any = [];

  const { trainBtwnStnsList } = await getTrainsApi({
    source,
    destination,
    date,
    quota,
    isDirect,
  });
  if (!trainBtwnStnsList || trainBtwnStnsList.length === 0) {
    // setTimeout(() => {
    //   lengthCallback(0);
    // }, 500);
    return [];
  }

  allTrains = getAllTrains(date, trainBtwnStnsList, quota);

  allTrains = makeClone(allTrains).sort(function (a, b) {
    var durationA = a.duration;
    var durationB = b.duration;
    let totalA =
      parseInt(durationA.split(":")[0]) * 60 +
      parseInt(durationA.split(":")[1]);
    let totalB =
      parseInt(durationB.split(":")[0]) * 60 +
      parseInt(durationB.split(":")[1]);
    return totalA < totalB ? -1 : 1;
  });
  //   setTimeout(() => {
  //     lengthCallback(allTrains.length);
  //   }, 500);

  return allTrains;
};

export const getAvailability = async ({ train, classes, quota }) => {
  const data = await getStatus({
    trainNumber: train.trainNumber,
    from: train.source,
    to: train.destination,
    journeyDate: train.departureDate,
    avlClasses: classes,
    jQuota: quota,
  });

  return data;
};
