import store from "..";
import {
  setChosenBoarding,
  setChosenDate,
  setChosenDestination,
  setChosenQuota,
  setChosenSource,
  setJourneyData,
} from "../Actions/journey";
import { Journey } from "../Reducers/types";

export const setJourney = (journey: Journey) => {
  store.dispatch(setJourneyData(journey));
};

export const setJourneySource = (value: string) => {
  store.dispatch(setChosenSource(value));
};

export const setJourneyDestination = (value: string) => {
  store.dispatch(setChosenDestination(value));
};

export const setJourneyDate = (value: string) => {
  store.dispatch(setChosenDate(value));
};

export const setJourneyQuota = (value: string) => {
  store.dispatch(setChosenQuota(value));
};

export const setJourneyBoarding = (value: string) => {
  store.dispatch(setChosenBoarding(value));
};
