import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CheckBox = ({
  label,
  boxSize,
  boxRadius,
  fontSize,
  fontColor,
  fontWeight,
  lineHeight,
  margin,
  space,
  isChecked,
  onCheck,
}) => {
  const handleCheck = () => {
    onCheck();
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        cursor: "pointer",
        margin: margin,
      }}
      onClick={handleCheck}
    >
      <div
        style={{
          width: boxSize,
          height: boxSize,
          backgroundColor: isChecked ? "#326abb" : "gainsboro",
          borderRadius: boxRadius,
          marginRight: space,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isChecked && (
          <FontAwesomeIcon
            icon={faCheck}
            style={{
              color: "white",
              fontSize: fontSize,
              fontWeight: "500px",
            }}
          />
        )}
      </div>{" "}
      <span
        style={{
          fontSize: fontSize,
          lineHeight: lineHeight,
          color: fontColor,
          fontWeight: fontWeight,
        }}
      >
        {label}
      </span>
    </div>
  );
};
