const translations = {
  en: {
    feedback: "Thank you for your feedback",
    sorry: "Sorry for your inconvenience.",
    share:
      "Please share your feedback below. It will help me to improve myself.",
    placeholder: "Enter your feedback...",
    buttons: {
      cancel: "Cancel",
      submit: "Submit",
    },
  },
  hi: {
    feedback: "आपकी प्रतिक्रिया के लिए आपका धन्यवाद",
    sorry: "आपकी असुविधा के लिए खेद है।",
    share:
      "कृपया अपनी प्रतिक्रिया नीचे साझा करें। इससे मुझे खुद को बेहतर बनाने में मदद मिलेगी।",
    placeholder: "अपनी प्रतिक्रिया दर्ज करें...",
    buttons: {
      cancel: "रद्द करें",
      submit: "दर्ज करें",
    },
  },
  gu: {
    feedback: "તમારા પ્રતિભાવ બદલ આભાર.",
    sorry: "તમારી અસુવિધા બદલ માફ કરશો.",
    share:
      "મહેરબાની કરીને તમારો પ્રતિસાદ નીચે શેર કરો. આ મને મારી જાતને સુધારવામાં મદદ કરશે.",
    placeholder: "તમારો જવાબ દાખલ કરો...",
    buttons: {
      cancel: "રદ કરો",
      submit: "દાખલ કરો",
    },
  },
};

export default translations;
