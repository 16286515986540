import useGetReduxState from "../../../../../../../Hooks/useGetReduxState";
import translations from "./translations";
const Greeting = () => {
  let lang = useGetReduxState().behaviour.lang;

  const userData = useGetReduxState().user;
  const getCurrentGreeting = () => {
    const d = new Date();
    const hour = d.getHours();

    let text = "";
    if (hour < 12) text = translations[lang].greeting.morning;
    else if (hour < 16) text = translations[lang].greeting.afternoon;
    else text = translations[lang].greeting.evening;

    if (userData.name && userData.name !== "")
      text = text + ", " + userData.name.split(" ")[0];

    return text;
  };

  return (
    <div style={{ padding: "10px 15px", margin: "10px 0px" }}>
      <h2
        style={{
          color: "#080808",
          fontSize: "22px",
          fontWeight: "500",
          letterSpacing: "0",
        }}
        tabIndex={0}
      >
        {getCurrentGreeting()}
      </h2>
      <h4
        style={{
          color: "#4d4d4d",
          fontSize: "14px",
          fontWeight: "500",
          marginTop: "6px",
        }}
        tabIndex={0}
      >
        {translations[lang].help}
      </h4>
    </div>
  );
};

export default Greeting;
