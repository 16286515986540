const Disabled = () => {
  return (
    <div
      style={{
        position: "absolute",
        inset: "0px",
        background: "#ffffff9c",
        borderRadius: "8px",
        zIndex: "1",
      }}
    ></div>
  );
};

export default Disabled;
