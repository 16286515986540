// Flow Names
export const PNR_FLOW = "PNR_FLOW";

//STEPS
//1. pnr steps
export const ENTER_PNR = "ENTER_PNR";
export const RENDER_PNR = "RENDER_PNR";

// template Names
export const PNRStatus = "PNRStatus";
export const RefundStatus = "RefundStatus";
export const BookingHistory = "BookingHistory";
