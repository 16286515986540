import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState } from "react";
import { getStationName } from "../../../../Helpers/getStationName";
import useGetReduxState from "../../../../Hooks/useGetReduxState";

import BookingDetails from "../BookingDetails";
import BookingContainer from "./Booking.styles";
import langText from "./translations";
const Booking = ({ tabNumber, data }) => {
  const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleCloseDrawer = () => {
    setTimeout(() => {
      setOpenDrawer(false);
    }, 4);
  };

  if (tabNumber === 5)
    return (
      <BookingContainer>
        <div className="date-container">
          <h4>{moment(data.date).format("DD MMM")}</h4>
          <span>{moment(data.date).format("ddd YYYY")}</span>
        </div>
        <div className="seperator"></div>
        <div className="failed-booking-details">
          <p>
            {langText[lang].order} <span>{data.orderId}</span>
          </p>
          <p>
            {langText[lang].amount}{" "}
            <span style={{ color: "#585858" }}>₹{data.txnAmount}</span>
          </p>
        </div>
      </BookingContainer>
    );
  else if (tabNumber === 3)
    return (
      <BookingContainer
        refunded={data.status === "DONE"}
        onClick={() => setOpenDrawer(true)}
      >
        <div className="date-container">
          <h4>{moment(data.date).format("DD MMM")}</h4>
          <span>{moment(data.date).format("ddd YYYY")}</span>
        </div>
        <div className="seperator"></div>
        <div className="failed-booking-details">
          <p>
            {langText[lang].order} <span>{data.orderId}</span>
          </p>
          <p>
            {langText[lang].amount} <span>₹{data.txnAmount}</span>
          </p>
          <p>
            {langText[lang].refund}{" "}
            <span className="status">
              {data.status === "DONE"
                ? langText[lang].initiated
                : langText[lang].pending}
            </span>
          </p>
        </div>
        <FontAwesomeIcon className="icon" icon={faAngleRight} />
        {openDrawer && (
          <BookingDetails
            tabNumber={tabNumber}
            data={data}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
      </BookingContainer>
    );
  else
    return (
      <BookingContainer onClick={() => setOpenDrawer(true)}>
        <div className="date-container">
          <h4>{moment(data.departureDate).format("DD MMM")}</h4>
          <span>{moment(data.departureDate).format("ddd YYYY")}</span>
        </div>
        <div className="seperator"></div>
        <div className="details">
          <div className="first-row">
            <p>{data.trainName}</p>
            <span>{data.trainNumber}</span>
          </div>

          <div className="second-row">
            <p>{data.source}</p>
            <div className="hr"></div>
            <p>{data.destination}</p>
          </div>
          <div className="third-row">
            <p>{getStationName(data.source)}</p>
            <p>{getStationName(data.destination)}</p>
          </div>
        </div>
        {openDrawer && (
          <BookingDetails
            tabNumber={tabNumber}
            data={data}
            handleCloseDrawer={handleCloseDrawer}
          />
        )}
        <FontAwesomeIcon className="icon" icon={faAngleRight} />
      </BookingContainer>
    );
};

export default Booking;
