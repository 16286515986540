import styled from "styled-components";

const Component = styled.div`
  padding: 12px 0px;

  h1 {
    font-size: 14px;
    color: #0e0e0e;
    font-weight: 400;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 8px;

    span {
      font-weight: 600;
    }
  }

  .hr {
    width: 92%;
    border-top: 1px solid gainsboro;
    margin: 15px auto;
  }

  .container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;

    div {
      text-align: center;
      font-size: 12px;
      color: #333333;
      font-weight: 500;
      width: 80px;
      padding: 10px;
      /* border: 1px solid gray; */
      cursor: pointer;
      border-radius: 5px;

      p {
        line-height: 1.2;
      }

      span {
        color: gray;
        font-size: 8px;
        display: block;
        margin-top: 4px;
      }
      img {
        width: 24px;
        margin-bottom: 10px;
      }
    }
  }
`;
export default Component;
