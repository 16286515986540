const translations = {
  en: {
    pnr: "PNR",
    adult: "Adult",
    child: "Child",
    from: "From",
    to: "To",
    depart: "Departure",
    arrive: "Arrival",
    class: "Journey Class",
    quota: "Journey Quota",
    duration: "Duration",
    distance: "Distance",
    train: "Train",
    boarding: "Boarding",
    passengers: "Passengers",
  },
  hi: {
    pnr: "PNR",
    adult: "वयस्क",
    child: "बच्चा",
    from: "प्रस्थान",
    to: "आगमन",
    depart: "प्रस्थान",
    arrive: "आगमन",
    class: "यात्रा श्रेणी",
    quota: "यात्रा कोटा",
    duration: "समयांतराल",
    distance: "फासला",
    train: "ट्रैन",
    boarding: "बोर्डिंग",
    passengers: "यात्रीगण",
  },
  gu: {
    pnr: "PNR",
    adult: "પુખ્ત વયના",
    child: "બાળક",
    from: "પ્રસ્થાન",
    to: "આગમન",
    depart: "પ્રસ્થાન",
    arrive: "આગમન",
    class: "પ્રવાસની શ્રેણી",
    quota: "મુસાફરીનો ક્વોટા",
    duration: "સમય અંતરાલ",
    distance: "ફાસલા",
    train: "ટ્રેન",
    boarding: "બોર્ડિંગ",
    passengers: "મુસાફરો",
  },
};

export default translations;
