import {
  faExclamationCircle,
  faLongArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getStationName } from "../../../Helpers/getStationName";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import Container from "./styles";
import { getRefundOfFailed } from "../../../Api/utils";

const text = {
  en: {
    heading: "Confirm your booking",
    chosen: "You had searched for trains between:",
    but: "But the train selected runs between:",
    buttons: { cancel: "Cancel", confirm: "Confirm" },
  },
  hi: {
    heading: "अपनी बुकिंग की पुष्टि करें",
    chosen: "आपने इन स्टेशनों के बीच ट्रेनों की खोज की थी:",
    but: "लेकिन आपके द्वारा चुनी गई ट्रेन इन स्टेशनों के बीच चलती है:",
    buttons: { cancel: "रद्द करें", confirm: "बुकिंग जारी रखें" },
  },
  gu: {
    heading: "તમારા આરક્ષણની પુષ્ટિ કરો",
    chosen: "તમે વચ્ચે ટ્રેનો માટે શોધ કરી હતીઃ",
    but: "પરંતુ પસંદ કરેલી ટ્રેન આ વચ્ચે દોડે છેઃ",
    buttons: { cancel: "રદ કરો", confirm: "પુષ્ટિ કરો" },
  },
};

const Content = ({ ticket, train }) => {
  const journey = useGetReduxState().journey;

  const [applicable, setApplicable] = useState("");
  const [different, setDifferent] = useState(false);
  useEffect(() => {
    if (ticket.msg !== "") {
      setApplicable(ticket.msg);
      // setPopup(obj);
    }

    if (
      train.source !== journey.source ||
      train.destination !== journey.destination
    ) {
      setDifferent(true);

      // setPopup(obj);
    }
  }, []);

  const lang = useGetReduxState().behaviour.lang;
  const showMsg = () => {
    let r = applicable.toLowerCase().split(" ");
    if (
      applicable ===
      "No concessions are allowed in this trainFull fare will be applicable in case of child passengers."
    )
      return "No concessions are allowed in this train. Full fare will be applicable in case of child passengers.";
    if (lang === "en") {
      return applicable;
    } else if (lang == "gu") {
      if (r.includes("full") && r.includes("fare") && r.includes("child")) {
        return "બાળ મુસાફરોના કિસ્સામાં સંપૂર્ણ ભાડું લાગુ થશે.";
      } else if (
        r.includes("dynamic") &&
        r.includes("pricing") &&
        r.includes("fare") &&
        r.includes("increase")
      ) {
        return "આ ટ્રેનમાં ગતિશીલ કિંમત લાગુ પડે છે. બુકિંગ સમયે ભાડામાં વધારો થઈ શકે છે.";
      } else return applicable;
    } else {
      if (r.includes("full") && r.includes("fare") && r.includes("child")) {
        return "बाल यात्रियों के मामले में पूरा किराया लागू होगा।";
      } else if (
        r.includes("dynamic") &&
        r.includes("pricing") &&
        r.includes("fare") &&
        r.includes("increase")
      ) {
        return "इस ट्रेन में डायनेमिक प्राइसिंग लागू है। बुकिंग के समय किराया बढ़ सकता है।";
      } else return applicable;
    }
  };

  return (
    <Container>
      {applicable !== "" && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            borderBottom: different ? "1px solid gainsboro" : "none",
            paddingBottom: "10px",
            marginBottom: different ? "20px" : "10px",
          }}
        >
          <FontAwesomeIcon
            icon={faExclamationCircle}
            style={{
              color: "rgb(92, 91, 91)",
              fontSize: "26px",
              marginRight: "10px",
            }}
          />
          <p
            style={{
              margin: "0",
              lineHeight: "18px",
            }}
          >
            {showMsg()}
          </p>
        </div>
      )}
      {different && (
        <div style={{ marginBottom: "20px" }}>
          <div>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "500",
                margin: "0px 0px 10px",
                textAlign: "center",
                textDecoration: "underline",
              }}
            >
              {text[lang].chosen}{" "}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  opacity: journey.source !== train.source ? 0.6 : 1,
                }}
              >
                <p
                  style={{
                    margin: "0",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#5b5b5b",
                  }}
                >
                  {journey.source}
                </p>
                <p style={{ margin: "0", fontSize: "12px", marginTop: "2px" }}>
                  {getStationName(journey.source)}
                </p>
              </div>
              <FontAwesomeIcon
                icon={faLongArrowRight}
                style={{
                  color: "#5c5b5b",

                  fontSize: "24px",
                }}
              />
              <div
                style={{
                  textAlign: "center",
                  opacity: journey.destination !== train.destination ? 0.6 : 1,
                }}
              >
                <p
                  style={{
                    margin: "0",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#5b5b5b",
                  }}
                >
                  {journey.destination}
                </p>
                <p style={{ margin: "0", fontSize: "12px", marginTop: "2px" }}>
                  {getStationName(journey.destination)}
                </p>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "20px", marginBottom: "10px" }}>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "500",
                margin: "0px 0px 10px",
                textAlign: "center",
                textDecoration: "underline",
              }}
            >
              {text[lang].but}{" "}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <p
                  style={{
                    margin: "0",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#5b5b5b",
                  }}
                >
                  {train.source}
                </p>
                <p style={{ margin: "0", fontSize: "12px", marginTop: "2px" }}>
                  {getStationName(train.source)}
                </p>
              </div>
              <FontAwesomeIcon
                icon={faLongArrowRight}
                style={{
                  color: "#5c5b5b",

                  fontSize: "24px",
                }}
              />
              <div style={{ textAlign: "center" }}>
                <p
                  style={{
                    margin: "0",
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "#5b5b5b",
                  }}
                >
                  {train.destination}
                </p>
                <p style={{ margin: "0", fontSize: "12px", marginTop: "2px" }}>
                  {getStationName(train.destination)}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Content;
