const translations = {
  en: {
    header: "Refund Status",
    order: "Order Id",
    pnr: "PNR",
    bankTxnId: "Bank Txn Id",
    status: "Status",
    payMode: "Payment Mode",
    paidAmount: "Total Amount Paid",
    deductedAmount: "Total Amount Deducted",
    refundAmount: "Refundable Amount",
    dateTimeTxn: "Date and Time of Transaction",
    dateTimeRefund: "Date and Time of Refund",
    dateTimeExpected: "Expected Date and Time of Refund",
  },
  hi: {
    header: "रिफंड की स्थिति",
    order: "आर्डर ID",
    pnr: "PNR",
    bankTxnId: "बैंक ट्रांसेक्शन Id",
    status: "वर्तमान स्थिति",
    payMode: "भुगतान का प्रकार",
    paidAmount: "भुगतान की गई राशि",
    deductedAmount: "काटी गई राशि",
    refundAmount: "वापसी योग्य राशि",
    dateTimeTxn: "ट्रांसेक्शन की तारीख और समय",
    dateTimeRefund: "धनवापसी की तारीख और समय",
    dateTimeExpected: "धनवापसी की अपेक्षित तारीख और समय",
  },
  gu: {
    header: "રિફંડની સ્થિતિ",
    order: "ઓર્ડર ID",
    pnr: "PNR",
    bankTxnId: "બેંક ટ્રાન્ઝેક્શન ID",
    status: "હાલની સ્થિતિ",
    payMode: "ચુકવણીનો પ્રકાર",
    paidAmount: "ચૂકવેલ રકમ",
    deductedAmount: "કાપવામાં આવેલી રકમ",
    refundAmount: "રિફંડપાત્ર રકમ",
    dateTimeTxn: "વ્યવહારની તારીખ અને સમય",
    dateTimeRefund: "રિફંડની તારીખ અને સમય",
    dateTimeExpected: "રિફંડની અપેક્ષિત તારીખ અને સમય",
  },
};

export default translations;
