import moment from "moment";

import useGetReduxState from "../../../Hooks/useGetReduxState";
import langText from "../translations";

const Station = ({ station, OnClick }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid gainsboro",
        padding: "10px",
        borderRadius: "8px",
        marginTop: "12px",
        position: "relative",
      }}
    >
      {station.disabled && (
        <div
          style={{
            position: "absolute",
            inset: 0,
            borderRadius: "8px",
            background: "#ffffffb8",
          }}
        ></div>
      )}

      <div style={{ width: "120px" }}>
        <p style={{ fontSize: "16px", fontWeight: "600", color: "#616161" }}>
          {station.code}
        </p>
        <span style={{ fontSize: "12px" }}>{station.name}</span>
      </div>

      <div
        style={{
          textAlign: "center",
          color: "darkslategray",
          fontWeight: "400",
        }}
      >
        <p style={{ fontSize: "14px" }}>
          {moment(station.date).format("DD MMMM")}
        </p>
        <span style={{ fontSize: "12px" }}>
          {moment(station.time, "hh:mm").format("hh:mm a")}
        </span>
      </div>
      <button
        onClick={OnClick}
        style={{
          border: "none",
          background: "#326abb",
          color: "white",
          padding: "8px 14px",
          borderRadius: "6px",
          cursor: "pointer",
        }}
      >
        {langText[lang].select}
      </button>
    </div>
  );
};

export default Station;
