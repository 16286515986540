import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.sender === "BOT" ? "flex-start" : "flex-end"};
  align-items: flex-start;
  margin-top: 10px;
  column-gap: 4px;

  img {
    width: 25px;
  }

  .speaker {
    width: 18px;
    position: absolute;
    top: 5px;
    right: -10px;
    cursor: pointer;
  }

  .message-container {
    min-height: 55px;
    border-radius: 8px;

    border-top-left-radius: ${(props) =>
      props.sender === "BOT" ? "0px" : "8px"};
    border-bottom-right-radius: ${(props) =>
      props.sender === "BOT" ? "8px" : "0px"};

    margin-top: 6px;

    min-width: ${(props) => (props.sender === "BOT" ? "25%" : "50px")};

    max-width: 75%;
    position: relative;
  }

  .message-text {
    background-color: ${(props) =>
      props.sender === "BOT" ? "#f4f7f9" : "#265aa6"};
    border-top-left-radius: ${(props) =>
      props.sender === "BOT" ? "0px" : "8px"};
    border-bottom-right-radius: ${(props) =>
      props.sender === "BOT" ? "8px" : "0px"};
    color: ${(props) => (props.sender === "BOT" ? "#265aa6" : "white")};
    border-radius: 8px;
    border-top-left-radius: ${(props) =>
      props.sender === "BOT" ? "0px" : "8px"};
    border-bottom-right-radius: ${(props) =>
      props.sender === "BOT" ? "8px" : "0px"};
    padding: 15px 16px;
    font-weight: 500;
    border: 1px solid #0b74fa26;
    line-height: 1.4;
    position: relative;
    font-size: 16px;
    overflow-wrap: break-word;

    p {
      line-height: 1.4;
      a {
        text-decoration: none;
        color: #488aff;
      }
    }
  }

  .question-mark {
    position: absolute;
    top: 15%;
    width: 32px;
    right: -52px;
  }

  .message-toolbar {
    display: flex;
    justify-content: ${(props) =>
      props.sender === "BOT" ? "space-between" : "flex-end"};

    align-items: flex-start;
    width: 96%;
    margin: 0 auto;
    margin-top: 6px;

    span {
      font-size: 10px;
      color: dimgray;
    }

    img {
      width: 15px;
      cursor: pointer;
    }
  }
`;

export default Container;
