import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import { setJourneyPreferences } from "../../../../../Store/Dispatcher/passengerForm";
import langText from "../translations";

const icon =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/insurance-refactored.png";

const Insurance = () => {
  const pref = useGetReduxState().passengerform.journeyPreferences;
  const insuranceSelected = pref.insuranceSelected;
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  const setSelected = (bool) => {
    setJourneyPreferences({ ...pref, insuranceSelected: bool });
  };

  return (
    <div style={{ padding: "10px 15px", paddingBottom: "0px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          columnGap: "5px",
        }}
      >
        <img src={icon} alt="" style={{ width: "24px" }} />
        <div>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "rgb(71 71 71)",
            }}
          >
            {langText[lang].insurance.text}
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              columnGap: "10px",
              marginTop: "3px",
            }}
          >
            <span
              style={{
                fontSize: "11px",
                lineHeight: "1.2",
                display: "block",
                color: "rgb(109 109 109)",
              }}
            >
              {langText[lang].insurance.subtext}{" "}
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                columnGap: "5px",
              }}
            >
              <button
                style={{
                  background: insuranceSelected ? "#326abb" : "#ececec",
                  color: insuranceSelected ? "white" : "gray",
                  fontSize: "14px",
                  padding: "5px 10px",
                  border: "none",
                  outline: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => setSelected(true)}
              >
                {langText[lang].insurance.yes}
              </button>
              <button
                style={{
                  background: !insuranceSelected ? "#326abb" : "#ececec",
                  color: !insuranceSelected ? "white" : "gray",
                  fontSize: "14px",
                  padding: "5px 10px",
                  border: "none",
                  outline: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => setSelected(false)}
              >
                {langText[lang].insurance.no}
              </button>
            </div>
          </div>
        </div>

        {/* <img style={{ width: "16px" }} src={info} alt="" /> */}
      </div>
    </div>
  );
};

export default Insurance;
