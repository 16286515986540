import useGetReduxState from "../../../Hooks/useGetReduxState";

const stat =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/station-icon.png";

const Station = ({ station, OnClick }) => {
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const lang = useGetReduxState().behaviour.lang;

  return (
    <div
      style={{
        padding: "0px 0px",
        width: "100%",
        cursor: "pointer",
        display: "block",
      }}
      onClick={() => OnClick(station)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #0000001f",
          padding: "10px 0px",
          minHeight: "46px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <img
            src={stat}
            alt=""
            style={{
              width: "22px",
              marginRight: "7px",
              display: "block",
              marginTop: "3px",
            }}
          />
          <div>
            <p
              style={{
                margin: "0px",
                fontSize: "15px",
                color: "black",
                fontWeight: 600,
              }}
            >
              {lang === "en"
                ? toTitleCase(station.name)
                : lang == "hi"
                ? station.name_hi
                : station.name_gu}
            </p>
            <span
              style={{
                fontSize: "12px",
                color: "black",
                marginTop: "4px",
                display: "block",
              }}
            >
              {station.state !== "" && station.district !== "" && (
                <span>{station.district}, &nbsp;</span>
              )}
              {station.state && station.state}
            </span>
            {station.distance && (
              <p
                style={{
                  margin: "0px",
                  color: "#0C77FF",
                  fontWeight: "600",
                  marginTop: "3px",
                  fontSize: "10px",
                }}
              >
                {station.distance} Km away
              </p>
            )}
          </div>
        </div>

        <p
          style={{
            margin: "0px",
            fontSize: "13px",
            padding: "5px 5px",
            borderRadius: "5px",
            width: "50px",
            textAlign: "center",
            color: "#464444",
            fontWeight: 700,
            backgroundColor: "#FCDD5E",
          }}
        >
          {station.code}
        </p>
      </div>
    </div>
  );
};

export default Station;
