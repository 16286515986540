const text = {
  gu: {
    head: "AskDISHA વિશે",
    first:
      "AskDISHA 2.0 (કોઈપણ સમયે મદદ મેળવવા માટે ડિજિટલ ઇન્ટરેક્શન) એ આર્ટિફિશિયલ ઈન્ટેલિજન્સ (AI), મશીન લર્નિંગ (ML) અને NLP આધારિત વર્ચ્યુઅલ આસિસ્ટન્ટ (ચેટબોટ, વોઈસબોટ) છે જે વપરાશકર્તાઓને ચૂકવણી, ટ્રેન રદ કરવા સહિત અંતથી અંત સુધી ટ્રેન ટિકિટ બુકિંગ જેવા વિવિધ વ્યવહારો કરવામાં મદદ કરે છે. ટિકિટો, બોર્ડિંગ સ્ટેશન બદલો, રિફંડની સ્થિતિ તપાસો, PNR સ્થિતિ તપાસો, ઑફર્સ મેળવો અને IRCTC દ્વારા ઓફર કરવામાં આવતી વિવિધ સેવાઓ સંબંધિત પ્રશ્નોના જવાબ આપો અને વધુ.",
    second:
      "IRCTC પાસવર્ડનો ઉપયોગ કર્યા વિના/જાણ્યા વિના અને માત્ર એક OTP વડે, પહેલીવાર ટ્રેનની ઈ-ટિકિટ બુકિંગ વાતચીતની રીતે શક્ય બન્યું છે. તે ટ્રેનની ટિકિટ બુક કરવાનો અને તમારો બોર્ડિંગ પાસ/ટ્રેન ટિકિટ મેળવવાનો સૌથી ઝડપી અને સહેલો રસ્તો હશે.",
    third:
      "AskDISHA એ ભારતીય રેલ્વે/IRCTC નું વૉઇસ-કોમર્સ સક્ષમ ઇ-ટિકિટીંગ વર્ચ્યુઅલ સહાયક છે જે બહુભાષી, બહુ-ફોર્મેટ (ક્લિક, ટેક્સ્ટ, વૉઇસ), મલ્ટિ-ચેનલ (વેબ, એપ્લિકેશન) છે. AskDISHA એ Gen AI સંચાલિત વાર્તાલાપ AI પ્લેટફોર્મ, CoRover.ai નો ઉપયોગ કરીને બનાવવામાં આવ્યું છે.",
    subone: "AskDISHA 2.0 USP's",
    l11: "માર્ગદર્શિત ટિકિટ બુકિંગનો અનુભવ",
    l12: "વૉઇસ દ્વારા બુકિંગ (અંગ્રેજી, હિન્દી, હિંગ્લિશ, ગુજરાતી અને વધુમાં)",
    l13: "OTP આધારિત ટિકિટ બુકિંગ: IRCTC પાસવર્ડ જાણ્યા વિના",
    l14: "ફાસ્ટ્ડ રિફંડ",
    l15: "પેસેન્જર લિસ્ટ સાચવો",
    l16: "15 મિનિટની અંદર નિષ્ફળ વ્યવહારનો ફરી પ્રયાસ કરો",
    subtwo: "મુખ્ય વિશેષતાઓ",
    l21: "ટ્રેન ટિકિટ બુક કરો",
    l22: "PNR સ્ટેટસ તપાસો",
    l23: "ટિકિટ રદ કરો",
    l24: "રિફંડ સ્ટેટસ મેળવો",
    l25: "બોર્ડિંગ સ્ટેશન બદલો",
    l26: "બોર્ડિંગ સ્ટેશન બદલો",
    l27: "બુકિંગ ઇતિહાસ તપાસો",
    l28: "ઈ-ટિકિટ જુઓ",
    l29: "ERS ડાઉનલોડ કરો",
    l211: "ઈ-ટિકિટ છાપો અને શેર કરો",
    l20: "અને વધુ",
  },
  hi: {
    head: "AskDISHA के बारे में",
    first:
      "AskDISHA 2.0 (कभी भी मदद मांगने के लिए डिजिटल इंटरेक्शन) एक आर्टिफिशियल इंटेलिजेंस (एआई), मशीन लर्निंग (एमएल) और एनएलपी आधारित वर्चुअल असिस्टेंट (चैटबॉट, वॉयसबॉट) है जो उपयोगकर्ताओं को भुगतान, ट्रेन रद्द करने सहित ट्रेन टिकट बुकिंग शुरू से अंत तक विभिन्न लेनदेन करने में मदद करता है। टिकट, बोर्डिंग स्टेशन बदलना, रिफंड स्थिति की जांच करना, पीएनआर स्थिति की जांच करना, ऑफर प्राप्त करना और आईआरसीटीसी द्वारा दी जाने वाली विभिन्न सेवाओं से संबंधित प्रश्नों के उत्तर देना, और भी बहुत कुछ।",
    second:
      "यह पहली बार है कि ट्रेन ई-टिकट बुकिंग को आईआरसीटीसी पासवर्ड का उपयोग किए बिना और केवल एक ओटीपी के साथ बातचीत के तरीके से संभव बनाया जा रहा है। यह ट्रेन टिकट बुक करने और अपना बोर्डिंग पास/ट्रेन टिकट प्राप्त करने का सबसे तेज़ और आसान तरीका होगा।",
    third:
      "AskDISHA भारतीय रेलवे/आईआरसीटीसी का एक वॉयस-कॉमर्स सक्षम ई-टिकटिंग वर्चुअल असिस्टेंट है जो बहुभाषी, बहु-प्रारूप (क्लिक, टेक्स्ट, वॉयस), मल्टी-चैनल (वेब, ऐप) है। AskDISHA को Gen AI संचालित कन्वर्सेशनल AI प्लेटफॉर्म, CoRover.ai का उपयोग करके बनाया गया है।",
    subone: "AskDISHA 2.0 की यूएसपी",
    l11: "निर्देशित टिकट बुकिंग अनुभव",
    l12: "आवाज द्वारा बुकिंग (अंग्रेजी, हिंदी, हिंग्लिश, गुजराती और अधिक में)",
    l13: "ओटीपी आधारित टिकट बुकिंग: आईआरसीटीसी पासवर्ड जाने बिना",
    l14: "निश्चित रिफंड",
    l15: "यात्री सूची सहेजें",
    l16: "15 मिनट के भीतर विफल लेनदेन का पुनः प्रयास करें",
    subtwo: "प्रमुख विशेषताऐं",
    l21: "ट्रेन टिकट बुक करें",
    l22: "PNR स्थिति जांचें",
    l23: "टिकट रद्द करें",
    l24: "रिफंड स्थिति प्राप्त करें",
    l25: "बोर्डिंग स्टेशन बदलें",
    l26: "बुकिंग हिस्टरी की जाँच करें",
    l27: "ई-टिकट देखें",
    l28: "ERS डाउनलोड करें",
    l29: "ई-टिकट प्रिंट करें और साझा करें",
    l211: "सवाल पूछें",
    l20: "और अधिक",
  },
  en: {
    head: "About AskDISHA",
    first:
      "AskDISHA 2.0 (Digital Interaction To Seek Help Anytime) is an Artificial Intelligence (AI), Machine learning (ML) and NLP based Virtual Assistant (ChatBot, VoiceBot) that helps users perform various transactions like end to end train ticket booking including payment, canceling train tickets, change boarding station, check refund status, check PNR status, get offers and answer queries pertaining to various services offered by IRCTC, and more.",
    second:
      "It is the first time that train e-ticket booking is being made possible in a conversational way, without using/knowing the IRCTC password and with just an OTP. It will be the fastest and the easiest way to book a train ticket and also to get your boarding pass/train ticket.",
    third:
      "AskDISHA is a voice-commerce enabled e-ticketing Virtual Assistant of Indian Railways/IRCTC which is multi-lingual, multi-format (click, text, voice), multi-channel (web, app). AskDISHA is created using Gen AI powered Conversational AI platform, CoRover.ai.",
    subone: "AskDISHA 2.0 USPs",
    l11: "Guided ticket booking experience",
    l12: "Booking by voice (in English, Hindi, Hinglish, Gujarati and more)",
    l13: "OTP based ticket booking: without knowing the IRCTC password",
    l14: "Fastened Refunds",
    l15: "Save Passenger List",
    l16: "Retry Failed Transaction in 15 min",
    subtwo: "Key Features",
    l21: "Book Train Ticket",
    l22: "Check PNR Status",
    l23: "Cancel Ticket",
    l24: "Get Refund Status",
    l25: "Change Boarding Station",
    l26: "Check Booking History",
    l27: "View E-Ticket",
    l28: "Dowload ERS",
    l29: "Print & Share E-Ticket",
    l211: "Ask Queries ",
    l20: "and more",
  },
};
export default text;
