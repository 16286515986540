const translations = {
  en: {
    loader: {
      header: "Please Wait..",
      text: "Initialising Payment Gateway.",
    },
    bookNew: {
      header: "Oops! Time Expired",
      text: "Sorry, you have ran out of time for making the transaction. You can try booking a ticket again.",
      would: "Would you like to book the ticket again?",
      buttons: {
        yes: "Yes",
        no: "No",
      },
      query: "Book Ticket",
    },
    back: "Go Back",
    time: "Time Left",
  },
  hi: {
    loader: {
      header: "कृपया प्रतीक्षा करें..",
      text: "भुगतान गेटवे का प्रारंभ हो रहा है",
    },
    bookNew: {
      header: "समय समाप्त हो गया है",
      text: "क्षमा करें, भुगतान करने के लिए आपका समय समाप्त हो गया है। आप फिर से टिकट बुक करने का प्रयास कर सकते हैं।",
      would: "क्या आप फिर से टिकट बुक करना चाहेंगे?",
      buttons: {
        yes: "हां",
        no: "नही",
      },
      query: "टिकट बुक करो",
    },
    back: "वापस जाएं",
    time: "शेष समय",
  },
  gu: {
    loader: {
      header: "કૃપા કરીને રાહ જુઓ...",
      text: "ચુકવણી ગેટવે શરૂ કરી રહ્યા છીએ",
    },
    bookNew: {
      header: "સમય પૂરો થઈ ગયો છે.",
      text: "માફ કરશો, તમારો ચૂકવણી કરવાનો સમય સમાપ્ત થઈ ગયો છે. તમે ફરીથી ટિકિટ બુક કરવાનો પ્રયાસ કરી શકો છો.",
      would: "શું તમે ફરીથી ટિકિટ બુક કરવા માંગો છો?",
      buttons: {
        yes: "હા.",
        no: "ના.",
      },
      query: "ટિકિટ બુક કરો",
    },
    back: "પર પાછા જાઓ",
    time: "બાકીનો સમય",
  },
};

export default translations;
