import useSendQuery from "../../Hooks/useSendQuery/useSendQuery";

const Content = () => {
  const data = [
    {
      reasonIndex: "2",
      tdrReason:
        "Train Late More Than Three Hours and Passenger Not Travelled.",
    },
    {
      reasonIndex: "3",
      tdrReason: "Difference Of Fare In Case proper Coach Not Attached.",
    },
    {
      reasonIndex: "4",
      tdrReason: "Ac Failure",
    },
    {
      reasonIndex: "7",
      tdrReason:
        "Party Partially Travelled. (Journey terminated short of destination) ",
    },
    {
      reasonIndex: "8",
      tdrReason: "ALL Confirmed Passenger Not Travelled",
    },
    {
      reasonIndex: "9",
      tdrReason: "Train Diverted And Passenger Not Travelled",
    },
    {
      reasonIndex: "11",
      tdrReason: "Train Diverted And Train Not Touching Boarding Station.",
    },
    {
      reasonIndex: "12",
      tdrReason: "Train Diverted And Train Not Touching Destination Station.",
    },
    {
      reasonIndex: "13",
      tdrReason:
        "Passenger Not Travelled As Reservation Provided In Lower Class.",
    },
    {
      reasonIndex: "14",
      tdrReason:
        "Passenger Not Travelled Due To Ticket In RAC After Chart Preparation.",
    },
    {
      reasonIndex: "15",
      tdrReason: "Train Terminated Short Of Destination.",
    },
    {
      reasonIndex: "17",
      tdrReason:
        "Party Partially Confirmed/Waitlisted And All Passengers Did Not Travel.",
    },
    {
      reasonIndex: "16",
      tdrReason:
        "Party Partially Confirmed/Waitlisted And Waitlisted Passengers Did Not Travel.",
    },
    {
      reasonIndex: "22",
      tdrReason: "Difference Of Fare As Passenger Travelled In Lower Class.",
    },
    {
      reasonIndex: "25",
      tdrReason: "Passenger Not Travelled Due To Coach Damage.",
    },
    {
      reasonIndex: "29",
      tdrReason:
        "Passengers Not Allowed To Travel by Railway due To COVID-19 Condition",
    },
    {
      reasonIndex: "28",
      tdrReason:
        "Unable To Cancel Due To Train Restored After Train Cancellation.",
    },
  ];
  const { sendQueryPayload } = useSendQuery(() => {});
  const handleSend = (reason) => {
    sendQueryPayload({
      query: reason.tdrReason,
      data: reason.reasonIndex,
      inputType: "",
    });
  };

  return (
    <div style={{ overflowY: "auto" }}>
      {data.map((reason) => (
        <p
          style={{
            padding: "10px 15px",
            textAlign: "justify",
            lineHeight: "1.2",
            color: "#1970d5",
            fontWeight: "500",
            borderBottom: "1px solid gainsboro",
            cursor: "pointer",
            fontSize: "16px",
          }}
          onClick={() => handleSend(reason)}
        >
          {reason.tdrReason}
        </p>
      ))}
    </div>
  );
};

export default Content;
