import { getGender } from "../Logic";

const PassengerWithoutBerthDetails = ({ passenger }) => {
  const getColor = (status) => {
    const check = status.toLowerCase();
    if (check.includes("wl")) return "tomato";
    return "#04cc04";
  };

  const getStatus = (status) => {
    if (status === "CNF") return "Confirmed";
    return status;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "2px 0px",
        borderBottom: "1px solid gainsboro",
      }}
    >
      <div style={{ width: "65%" }}>
        <p style={{ margin: "0", fontSize: "14px", color: "#5b5b5b" }}>
          {passenger.name}
        </p>
        <div
          style={{
            fontSize: "12px",
            marginTop: "2px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            color: "#929292",
          }}
        >
          <span>{passenger.age} years</span>
          <span style={{ display: "block", margin: "0 5px" }}>|</span>
          <span>{getGender(passenger.gender)}</span>
        </div>
      </div>
      <p
        style={{
          margin: "0px",
          border: "1px solid",
          borderRadius: "4px",
          width: "35%",
          textAlign: "center",
          color: getColor(passenger.status),
          marginRight: "10px",
          fontSize: "12px",
        }}
      >
        {getStatus(passenger.status)}
      </p>
    </div>
  );
};

export default PassengerWithoutBerthDetails;
