import { BookingHistory, PNRStatus, RefundStatus } from "./constants";

const getTemplate = (templateName) => {
  if (!templateName) return null;

  switch (templateName) {
    case "pnrStatus":
      return PNRStatus;

    case "refundDetails":
      return RefundStatus;

    case "showBookings":
      return BookingHistory;

    case "refundOptions":
      return "RefundOptions";

    case "refundDetails":
      return "RefundStatus";

    case "failedBookings":
      return "FailedBookings";

    case "selectBookingHistory":
    case "tdrEligiblePNRs":
      return "SelectBooking";

    case "passengersToCancel":
      return "SelectPassengersForCancel";
    case "tdrPassengerList":
      return "SelectPassengersForTDR";

    case "passengerDetails":
      return "PassengerForm";

    case "cancelConfirmation":
      return "CancelTicket";

    default:
      return null;
  }
};

export default getTemplate;
