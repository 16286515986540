import { SET_TRAIN } from "../Actions/constants";
import { Train } from "./types";

const intialTrain: Train = {
  trainName: "",
  trainnumber: "",
  source: "",
  destination: "",
  departureDate: "",
  departureTime: "",
  arrivalDate: "",
  arrivalTime: "",
  quota: "",
  class: "",
  status: "",
  duration: "",
};

const reducer = (train: Train = intialTrain, action) => {
  switch (action.type) {
    case SET_TRAIN:
      return {
        trainName: action.train.trainName,
        trainnumber: action.train.trainnumber,
        source: action.train.source,
        destination: action.train.destination,
        departureDate: action.train.departureDate,
        departureTime: action.train.departureTime,
        arrivalDate: action.train.arrivalDate,
        arrivalTime: action.train.arrivalTime,
        quota: action.train.quota,
        class: action.train.class,
        status: action.train.status,
        duration: action.train.duration,
      };

    default:
      return train;
  }
};

export default reducer;
