import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import useGetReduxState from "../../../../../../Hooks/useGetReduxState";

import { RadioSelect } from "../../../../../../UI/UIComponents/RadioSelect/RadioSelect";
import { SelectBox } from "../../../../../../UI/UIComponents/TextBox/SelectBox";
import { TextBox } from "../../../../../../UI/UIComponents/TextBox/TextBox";
import {
  getEntity,
  getEntityYearSpan,
  isAdult,
  isChild,
  isInfant,
} from "../../../../utils";

import { handleChange } from "../Logic/handleChange";
import { handleSelect } from "../Logic/handleSelect";
import ListBox from "./ListBox.tsx/ListBox";
import langText from "../translations";
import React from "react";
import { CheckBox } from "../../../../../../UI/UIComponents/Checkbox";

const male =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/male.png";
const female =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/female.png";
const trans =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/transgender.png";
const veg =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/veg.png";
const nonveg =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/non-veg.png";
const nofood =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/no-food.png";
const snacks = "snacks.svg";
const tea = "tea.svg";
const vegd =
  "https://cdn.jsdelivr.net/gh/corover/assets@11Sept6/askdisha-bucket/veg-d1.png";
const nonvegd =
  "https://cdn.jsdelivr.net/gh/corover/assets@11Sept6/askdisha-bucket/nonveg-d1.png";
const jain =
  "https://cdn.jsdelivr.net/gh/corover/assets@11Sept6/askdisha-bucket/jain.png";
  
const PassengerValues = {
  name: "name",
  gender: "gender",
  age: "age",
  berth: "berth",
  nationality: "nationality",
  food: "food",
  passport: "passport",
  dob: "dob",
};

const Content = ({ passengerState, setPassenger, isAlloted }) => {
  const passenger = passengerState;
  const countries = useGetReduxState().utils.countries;

  const [showCountryBox, setShowCountryBox] = useState(false);
  const irctcConfig = useGetReduxState().passengerform.irctcConfig;
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const translations = {
    en: {
      name: "Passenger's Name	",
      age: "Passenger's Age	",
      dob: "Passenger's Date of Birth	",
      passNo: "Passenger's Passport Number	",
    },
    hi: {
      name: "यात्री का नाम	",
      age: "यात्री की आयु	",
      dob: "यात्री की जन्मतिथि	",
      passNo: "यात्री का पासपोर्ट नंबर	",
    },
    gu: {
      name: "મુસાફરનું નામ",
      age: "મુસાફરની ઉંમર",
      dob: "મુસાફરની જન્મ તારીખ",
      passNo: "મુસાફરનો પાસપોર્ટ નંબર",
    },
  };

  const genders = [
    {
      label: langText[lang].options.gender.male,
      value: "M",
      show: true,
      icon: male,
      iconSize: "21px",
    },
    {
      label: langText[lang].options.gender.female,
      value: "F",
      show: true,
      icon: female,
      iconSize: "20px",
    },
    {
      label: langText[lang].options.gender.trans,
      value: "T",
      show: true,
      icon: trans,
      iconSize: "14px",
    },
  ];

  const foods = [
    {
      label: langText[lang].options.foods.veg,
      value: "V",
      show: irctcConfig.applicableFoodChoices.includes("V"),
      icon: veg,
      iconSize: "20px",
    },
    {
      label: langText[lang].options.foods.nonveg,
      value: "N",
      show: irctcConfig.applicableFoodChoices.includes("N"),
      icon: nonveg,
      iconSize: "20px",
    },
    {
      label: langText[lang].options.foods.snacks,
      value: "E",
      show: irctcConfig.applicableFoodChoices.includes("E"),
      icon: snacks,
      iconSize: "23px",
    },
    {
      label: langText[lang].options.foods.tea,
      value: "T",
      show: irctcConfig.applicableFoodChoices.includes("T"),
      icon: tea,
      iconSize: "23px",
    },
    {
      label: langText[lang].options.foods.none,
      value: "D",
      show: irctcConfig.applicableFoodChoices.includes("D"),
      icon: nofood,
      iconSize: "18px",
    },
    {
      label: langText[lang].options.foods.vegd,
      value: "F",
      show: irctcConfig.applicableFoodChoices.includes("F"),
      icon: vegd,
      iconSize: "42px",
    },
    {
      label: langText[lang].options.foods.nonvegd,
      value: "G",
      show: irctcConfig.applicableFoodChoices.includes("G"),
      icon: nonvegd,
      iconSize: "42px",
    },
    {
      label: langText[lang].options.foods.jain,
      value: "J",
      show: irctcConfig.applicableFoodChoices.includes("J"),
      icon: jain,
      iconSize: "20px",
    },
  ];

  const onChange = (e) => {
    const p = handleChange(e, passenger);
    setPassenger(p);
  };

  const onSelect = (name, value) => {
    const p = handleSelect(name, value, passenger);
    setPassenger(p);
  };

  const handleCountrySelect = (country) => {
    const p = handleSelect(
      PassengerValues.nationality,
      country.countryCode,
      passenger
    );
    setPassenger(p);
    setShowCountryBox(false);
  };

  const getCountry = (code) => {
    let matched = countries.filter((country) => country.countryCode === code);
    if (matched.length > 0) return matched[0].country;
    return "invalid";
  };

  const handleVoice = (value, id) => {
    let e = {
      target: {
        name: id,
        value: value,
      },
    };
    const p = handleChange(e, passenger);
    setPassenger(p);
  };

  return (
    <div style={{ width: "100%", padding: "8px 15px" }}>
      <TextBox
        label={langText[lang].fields.name}
        placeholder={langText[lang].placeholders.name}
        maxLength={16}
        id={PassengerValues.name}
        onChange={onChange}
        error={passenger.errors.name}
        value={passenger.values.name}
        type={"text"}
        handleVoice={handleVoice}
        content={translations[lang].name}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "2px",
          color: "#818181",
        }}
      >
        <FontAwesomeIcon
          icon={faExclamationCircle}
          style={{
            fontSize: "12px",
            marginRight: "4px",
          }}
        />
        <span style={{ fontSize: "12px", lineHeight: "20px" }}>
          {langText[lang].helpers.name}
        </span>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextBox
          label={langText[lang].fields.age}
          placeholder={langText[lang].placeholders.age}
          id={PassengerValues.age}
          onChange={onChange}
          error={passenger.errors.age}
          value={passenger.values.age}
          type={"number"}
          handleVoice={handleVoice}
          content={translations[lang].age}
        />

        {passenger.values.age && (
          <div
            style={{
              textAlign: "center",
              flexShrink: "0",
              marginLeft: "15px",
              marginTop: "8px",
            }}
          >
            <p
              style={{ fontSize: "16px", fontWeight: "500", color: "#666666" }}
            >
              {getEntity(passenger.values.age)}
            </p>
            <span
              style={{
                fontSize: "12px",
                color: "slategray",
                fontWeight: "400",
              }}
            >
              ({getEntityYearSpan(passenger.values.age)})
            </span>
          </div>
        )}
      </div>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: "2px",
          marginLeft: "2px",
          color: "#818181",
        }}
      >
        <FontAwesomeIcon
          icon={faExclamationCircle}
          style={{
            fontSize: "12px",
            marginRight: "4px",
          }}
        />
        <span style={{ fontSize: "12px", lineHeight: "20px" }}>
          Infants will not be charged
        </span>
      </div> */}

      <div>
        <RadioSelect
          options={genders}
          label={langText[lang].fields.gender}
          bordered={true}
          handleSelect={(value) => onSelect(PassengerValues.gender, value)}
          value={passenger.values.gender}
          error={passenger.errors.gender}
        />
      </div>
      {/* {isInfant(passenger.values.age) && (
        <CheckBox
          label={"Allot Berth to Infant"}
          boxSize="18px"
          boxRadius={"2px"}
          fontColor={"#5c5c5c"}
          fontSize={"16px"}
          fontWeight={"500px"}
          lineHeight={"16px"}
          margin={"15px 0px 15px 5px"}
          space={"8px"}
          isChecked={() => {}}
          onCheck={() => {}}
        />
      )} */}

      {(isAdult(passenger.values.age) ||
        isChild(passenger.values.age) ||
        (isInfant(passenger.values.age) && isAlloted)) && (
        <>
          <div>
            {showCountryBox && (
              <ListBox
                handleSelect={handleCountrySelect}
                handleClose={() => setShowCountryBox(false)}
              />
            )}

            <SelectBox
              isForSelect={true}
              label={langText[lang].fields.nationality}
              value={getCountry(passenger.values.nationality)}
              handleClick={() => {
                setShowCountryBox(true);
              }}
              showIcon={true}
            />
          </div>

          {irctcConfig.showFoodChoice && (
            <RadioSelect
              options={foods}
              label={langText[lang].fields.food}
              bordered={true}
              handleSelect={(value) => onSelect(PassengerValues.food, value)}
              value={passenger.values.food}
              error={passenger.errors.food}
            />
          )}

          {passenger.values.nationality !== "IN" && (
            <React.Fragment>
              <TextBox
                label={langText[lang].fields.passport}
                placeholder={langText[lang].placeholders.passport}
                id={"passport"}
                onChange={onChange}
                error={passenger.errors.passport}
                value={passenger.values.passport}
                type={"text"}
                handleVoice={handleVoice}
                content={translations[lang].passNo}
              />
              <TextBox
                label={langText[lang].fields.dob}
                placeholder={`${langText[lang].placeholders.dob} (DD-MM-YYYY)`}
                id={"dob"}
                onChange={onChange}
                error={passenger.errors.dob}
                value={passenger.values.dob}
                type={"text"}
                handleVoice={handleVoice}
                content={translations[lang].dob}
              />
            </React.Fragment>
          )}
        </>
      )}
      {/* {!isEdit && (
        <CheckBox
          label={"Save Passenger"}
          boxSize="18px"
          boxRadius={"2px"}
          fontColor={"#5c5c5c"}
          fontSize={"16px"}
          fontWeight={"500px"}
          lineHeight={"16px"}
          margin={"15px 0px 15px 5px"}
          space={"8px"}
          isChecked={isSaveInMasterData}
          onCheck={() => {
            setSaveInMasterData((prev) => !prev);
          }}
        />
      )} */}
    </div>
  );
};

export default Content;
