import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  font-family: "Montserrat", sans-serif;

  .phone {
    width: 100%;
    padding: 15px;
    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 24px;
        margin-right: 10px;
      }

      h4 {
        font-size: 18px;
        font-weight: 600;
        color: black;
      }

      p {
        font-size: 12px;
        font-weight: 500;
        color: #4c4c4c;
        margin-top: 2px;
      }
      span {
        color: #e08f17;
        font-weight: 500;
        font-size: 10px;
        display: block;
        margin-top: 0px;
      }
    }

    .input {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 10px;
      border-radius: 10px;
      margin-top: 18px;
      box-shadow:
        rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        rgb(209, 213, 219) 0px 0px 0px 1px inset;

      .prefix {
        font-size: 24px;
        font-weight: 600;
        color: black;
        padding-right: 10px;
        border-right: 1px solid #a3a3a3;
        margin-right: 10px;
        height: 100%;
      }

      button {
        background-color: white;
        cursor: pointer;
        border: none;
        font-size: 16px;
        font-weight: 600;
        color: #008ad4;
      }

      input {
        padding: 0px;
        font-size: 24px;
        font-weight: 600;
        width: 100%;
        border: none;
        border-radius: 0px;
        font-family: "Montserrat", sans-serif;
        color: black;
        letter-spacing: 1px;
        text-align: left;

        &::-webkit-input-placeholder {
          font-weight: 500;
          color: #aaaaaa;
          font-size: 18px;
        }

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type="number"] {
          -moz-appearance: textfield;
        }
      }

      .mic-btn {
        width: 25px;
        cursor: pointer;
      }
    }

    .sent {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 12px;
      img {
        width: 15px;
        margin-right: 7px;
      }
      p {
        font-size: 12px;
        font-weight: 600;
        color: #32bea6;
      }
    }
  }

  .otp-container {
    width: 100%;
    padding: 15px;
    padding-top: 0px;

    .header {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 24px;
        margin-right: 10px;
      }

      h4 {
        font-size: 18px;
        font-weight: 600;
        color: black;
      }

      p {
        font-size: 12px;
        font-weight: 500;
        color: #4c4c4c;
        margin-top: 4px;
      }
      span {
        font-weight: 700;
      }
    }

    .input {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 10px;
      border-radius: 10px;
      margin-top: 10px;
      box-shadow:
        rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        rgb(209, 213, 219) 0px 0px 0px 1px inset;

      button {
        background-color: white;
        cursor: pointer;
        border: none;
        font-size: 16px;
        font-weight: 600;
        color: #008ad4;
      }

      input {
        padding: 0px;
        font-size: 24px;
        font-weight: 600;
        width: 100%;
        border: none;
        border-radius: 0px;
        font-family: "Montserrat", sans-serif;
        color: black;
        text-align: center;
        letter-spacing: 16px;

        &::-webkit-input-placeholder {
          font-weight: 500;
          color: #aaaaaa;
          font-size: 20px;
          letter-spacing: 1px;
        }

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type="number"] {
          -moz-appearance: textfield;
        }
      }

      .mic-btn {
        width: 25px;
        cursor: pointer;
      }
    }

    .retry {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 12px;
      p {
        font-size: 12px;
        font-weight: 400;
        margin-right: 6px;
      }

      span {
        font-size: 12px;
        font-weight: 600;
      }

      button {
        font-size: 14px;
        background: white;
        border: none;
        font-weight: 600;
        color: #5287e7;
        cursor: pointer;
      }
    }
  }
`;
export default Container;
