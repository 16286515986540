import useGetReduxState from "../../../Hooks/useGetReduxState";
import Speaker from "../../../UI/UIComponents/Speaker";
import audios from "../../../UI/UIComponents/Speaker/audios";

const Header = () => {
  const lang: "hi" | "en" = useGetReduxState().behaviour.lang;

  const getText = () => {
    if (lang === "en") return "Select Journey Date";
    if (lang === "hi") return "यात्रा तिथि का चयन करें";
    if (lang === "gu") return "મુસાફરીની તારીખ પસંદ કરો";
  };

  return (
    <div
      style={{
        fontFamily: "Montserrat, sans-serif",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "94%",
        fontWeight: "600",
        fontSize: "14px",
      }}
    >
      <span>{getText()}</span>

      <Speaker
        sizeBg={"25px"}
        sizeIcon={"12px"}
        audioUrl={audios[lang].CHOOSE_DATE}
        disabled={false}
      />
    </div>
  );
};

export default Header;
