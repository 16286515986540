import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import useButtonQueries from "../../../Hooks/useButtonQueries/useButtonQueries";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import useSendQuery from "../../../Hooks/useSendQuery/useSendQuery";

import Feedback from "../Success/components/Feedback";
import Header from "./components/Header";

const BookingFailure = ({ msg }) => {
  const { useCheckFailed } = useButtonQueries();

  const lang = useGetReduxState().behaviour.lang;

  const langText = {
    en: {
      failed: "Check Failed Bookings",
      home: "Back to Home",
    },
    hi: {
      failed: "फेल्ड बुकिंग्स देखें",
      home: "Home पर वापस जाएं",
    },
    gu: {
      failed: "નિષ્ફળ થયેલાં આરક્ષણો તપાસો",
      home: "ઘરે પાછા ફરો",
    },
  };

  useEffect(() => {}, []);

  const { goToHome } = useSendQuery(() => {});

  // const CheckFailed = (query) => {
  //   // bookingStatusSetter({ status: 3 });
  //   // EventEmitter.emit("HIDE_DRAWER");
  //   // if (!messageLoader)
  //   //   eventBus.dispatch("sendMessage", {
  //   //     message: {
  //   //       userInput: `refresh`,
  //   //       type: "text",
  //   //     },
  //   //   });
  //   // EventEmitter.emit("QUERY_HANDLER2", {
  //   //   query: "Booking History",
  //   //   userInput: lang === "en" ? "Failed Bookings" : "असफल ट्रांसक्शन्स",
  //   // });
  // };

  return (
    <div
      style={{
        width: "100%",
        borderRadius: "10px",

        padding: "20px 15px 10px",
        position: "relative",
        boxShadow:
          "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
      }}
    >
      <Header />
      <p
        style={{
          margin: "15px 0px 0px",
          lineHeight: "14px",

          textAlign: "center",
          backgroundColor: "#ffe0e0",
          color: "tomato",
          padding: "8px 16px",
          borderRadius: "5px",
          marginBottom: "15px",
        }}
        dangerouslySetInnerHTML={{ __html: String(msg) }}
      ></p>

      <Feedback pnr={"failed"} />

      <button
        style={{
          border: "none",
          backgroundColor: "rgb(11, 116, 250)",
          color: "white",
          fontSize: "14px",
          padding: "12px 15px",
          fontWeight: "500",
          borderRadius: "5px",
          cursor: "pointer",
          width: "80%",
          margin: "25px auto 15px auto",
          display: "block",
        }}
        onClick={useCheckFailed}
      >
        {langText[lang].failed}
      </button>
      <button
        style={{
          border: "none",
          backgroundColor: "transparent",
          color: "rgb(11, 116, 250)",
          fontSize: "14px",
          //   padding: "8px",
          fontWeight: "500",
          borderRadius: "5px",
          cursor: "pointer",
          minWidth: "130px",
          display: "block",
          margin: "0 auto",
          marginBottom: "15px",
        }}
        onClick={goToHome}
      >
        <FontAwesomeIcon
          icon={faHouse}
          style={{
            color: "0b74fa",
            marginRight: "5px",
          }}
        />
        {langText[lang].home}
      </button>
    </div>
  );
};

export default BookingFailure;
