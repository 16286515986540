const translations = {
  en: {
    header: "PNR Status",
    status: "Chart Status",
    class: "Journey Class",
    jDate: "Journey Date",
    quota: "Quota",
    bDate: "Booking Date",
    passenger: "Passenger",
    bookingStatus: "Booking Status",
    currentStatus: "Current Status",
    number: "Number",
    ticket: "Ticket",
    seat: "Seat",
    coach: "Coach",
  },
  hi: {
    header: "PNR स्थिति",
    status: "चार्ट स्थिति",
    class: "यात्रा वर्ग",
    jDate: "यात्रा की तिथि",
    quota: "कोटा",
    bDate: "बुकिंग की तिथि",
    passenger: "यात्रीगण",
    bookingStatus: "बुकिंग स्थिति",
    currentStatus: "वर्तमान स्थिति",
    number: "संख्या",
    ticket: "टिकट",
    seat: "सीट",
    coach: "कोच",
  },
  gu: {
    header: "પીએનઆર સ્થિતિ",
    status: "ચાર્ટની સ્થિતિ",
    class: "પ્રવાસની શ્રેણી",
    jDate: "મુસાફરીની તારીખ",
    quota: "કોટા",
    bDate: "બુકિંગની તારીખ",
    passenger: "મુસાફરો",
    bookingStatus: "આરક્ષણની સ્થિતિ",
    currentStatus: "હાલની સ્થિતિ",
    number: "નંબર",
    ticket: "ટિકિટ",
    seat: "બેઠક",
    coach: "કોચ",
  },
};

export default translations;
