import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { generateCaptcha, verifyCaptcha } from "../../Api/captcha";
import { CloseDrawer } from "../../Helpers/EventEmitter";
import useGetReduxState from "../../Hooks/useGetReduxState";
import Drawer from "../../UI/UIComponents/Drawer";
import Spinner from "../../UI/UIComponents/Spinner";
import Container from "./styles";
import langText from "./translations";
const Header = ({ lang }) => {
  return (
    <div>
      <p style={{ fontSize: "18px", fontWeight: "600", color: "#565656" }}>
        {langText[lang].captcha}
      </p>
      <span
        style={{
          display: "block",
          fontSize: "12px",
          fontWeight: "500",
          marginTop: "8px",
          color: "#227fc9",
        }}
      >
        {langText[lang].testCaptcha}
      </span>
    </div>
  );
};

const Content = ({
  lang,
  captcha,
  refresh,
  input,
  handleChange,
  error,
  handleKeyDown,
}) => {
  return (
    <Container>
      <img src={captcha} alt="" />
      <h2>{langText[lang].enterCaptcha}</h2>
      <div>
        <input
          type="text"
          placeholder={lang === "en" ? "Enter captcha" : "कैप्चा दर्ज करें"}
          onChange={handleChange}
          value={input}
          onKeyDown={handleKeyDown}
        />
        <button
          style={{
            fontSize: "24px",
            background: "none",
            border: "none",
            color: "#4787f9",
            cursor: "pointer",
            marginLeft: "18px",
          }}
          onClick={refresh}
        >
          <FontAwesomeIcon icon={faArrowsRotate} />
        </button>
      </div>
      {error && <p>{langText[lang].error}</p>}
    </Container>
  );
};

const Footer = ({ lang, loading, handleSubmit }) => {
  return (
    <button
      style={{
        width: "100%",
        background: "#326abb",
        border: "none",
        fontSize: "18px",
        padding: "12px",
        cursor: "pointer",
        color: "white",
        borderRadius: "6px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={handleSubmit}
    >
      {loading ? (
        <Spinner
          borderWidth={"2px"}
          activeColor={"#6495ed"}
          inactiveColor={"#f9f9f9"}
          speed={"1s"}
          size={"20px"}
        />
      ) : (
        langText[lang].action
      )}
    </button>
  );
};

const Captcha = ({ handleClose }) => {
  const [captcha, setCaptcha] = useState("");
  const [input, setInput] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const setCapatchaText = async () => {
    const data = await generateCaptcha();
    setCaptcha(data);
  };

  useEffect(() => {
    setCapatchaText();
  }, []);

  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  const handleChange = (e) => {
    setError(false);
    let value = e.target.value;
    setInput(value);
  };

  const handleSubmit = async () => {
    if (!input || error || loading) return;
    setLoading(true);

    const result = await verifyCaptcha(input);
    if (result) {
      setError(false);
      CloseDrawer();
    } else {
      setError(true);
      setInput("");
      setCapatchaText();
    }
    setLoading(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Drawer
      header={<Header lang={lang} />}
      content={
        <Content
          input={input}
          handleChange={handleChange}
          error={error}
          captcha={captcha}
          lang={lang}
          refresh={setCapatchaText}
          handleKeyDown={handleKeyDown}
        />
      }
      footer={
        <Footer lang={lang} loading={loading} handleSubmit={handleSubmit} />
      }
      tatkal={false}
      fullHeight={false}
      showCross={false}
      showHeaderSeperator={false}
      handleCloseDrawer={handleClose}
    />
  );
};

export default Captcha;
