import useGetReduxState from "../../../../../../Hooks/useGetReduxState";
import langText from "../translations";

const Footer = ({ handleClose, isEdit, isDisabled }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  return (
    <button
      style={{
        width: "100%",
        border: "none",
        backgroundColor: "#326abb",
        color: "white",
        fontSize: "18px",
        padding: "12px",
        borderRadius: "6px",
        cursor: "pointer",
        display: "block",
      }}
      onClick={handleClose}
      disabled={isDisabled}
    >
      {isEdit !== null ? langText[lang].footer.edit : langText[lang].footer.add}
    </button>
  );
};

export default Footer;
