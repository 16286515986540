import { useEffect, useState } from "react";
import useDirectQueries from "../../../../../Hooks/useDirectQuery";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";

import Modal from "./Modal";
import Container from "./styles";
import langText from "./translations";
const boarding =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/bording-station.svg";
const eticket = "https://d3upbvvdvllr10.cloudfront.net/dishav3/pnr.svg";
const ers = "https://d3upbvvdvllr10.cloudfront.net/dishav3/pnr.svg";
const refund =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/refund.svg";
const tdr =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/tdr.svg";
const cancel =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/cancle.svg";

const Footer = ({ handleCloseDrawer, ticketData, bookingStatus }) => {
  const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;
  const {
    changeBoarding,
    checkRefundCancelled,
    getCancel,
    checkRefundFailed,
    fileTDR,
  } = useDirectQueries({ booking: ticketData });
  // const [data, setData] = useState(ticketData);

  useEffect(() => {}, []);

  const [drawer, showDrawer] = useState("");

  const handleClose = () => {
    showDrawer("");
  };

  if (bookingStatus === 1)
    return (
      <Container>
        <div>
          <button onClick={() => showDrawer("TICKET")}>
            <img src={eticket} alt="" />
            {langText[lang].ticket}
          </button>
          <button onClick={() => showDrawer("ERS")}>
            <img src={ers} alt="" />
            {langText[lang].ers}
          </button>
        </div>
        <div>
          <button onClick={changeBoarding}>
            <img src={boarding} alt="" />
            {langText[lang].boarding}
          </button>
          <button onClick={getCancel}>
            <img src={cancel} alt="" style={{ width: "18px" }} />
            {langText[lang].cancel}
          </button>
        </div>
        {drawer !== "" && (
          <Modal mode={drawer} data={ticketData} handleClose={handleClose} />
        )}
      </Container>
    );

  if (bookingStatus === 2)
    return (
      <Container>
        <div>
          <button onClick={() => showDrawer("ERS")}>
            <img src={ers} alt="" />
            {langText[lang].ers}
          </button>
          {ticketData.journeyClass !== "2S" && (
            <button onClick={checkRefundCancelled}>
              <img src={refund} style={{ width: "15px" }} alt="" />
              {langText[lang].refund1}
            </button>
          )}
        </div>
        {drawer !== "" && (
          <Modal mode={drawer} data={ticketData} handleClose={handleClose} />
        )}
      </Container>
    );

  if (bookingStatus === 3)
    return (
      <Container>
        <div>
          <button onClick={checkRefundFailed}>
            <img src={refund} alt="" style={{ width: "15px" }} />
            {langText[lang].refund2}
          </button>
        </div>
      </Container>
    );

  if (bookingStatus === 4)
    return (
      <Container>
        <div>
          <button onClick={() => showDrawer("ERS")}>
            <img src={ers} alt="" />
            {langText[lang].ers}
          </button>
          {ticketData.journeyClass !== "2S" && (
            <button onClick={fileTDR}>
              <img src={tdr} style={{ width: "16px" }} alt="" />
              {langText[lang].tdr}
            </button>
          )}
        </div>
        {drawer !== "" && (
          <Modal mode={drawer} data={ticketData} handleClose={handleClose} />
        )}
      </Container>
    );
};

export default Footer;
