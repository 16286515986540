import { CloseDrawer } from "../../Helpers/EventEmitter";
import useLogin from "../../Hooks/useLogin";
import Drawer from "../../UI/UIComponents/Drawer";

import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";

const Login = ({ handleLogin, handleCloseDrawer }) => {
  const {
    getOTP,
    sendOTP,
    sendName,
    values,
    handle,
    component,
    loading,
    error,
  } = useLogin({
    handleLoginComplete: () => {
      handleLogin();
      CloseDrawer();
    },
  });

  return (
    <Drawer
      tatkal={false}
      fullHeight={false}
      header={<Header component={component} />}
      content={
        <Content
          component={component}
          values={values}
          handle={handle}
          error={error}
          getOTP={getOTP}
          reEnter={handle.reEnterPhone}
          sendOTP={sendOTP}
          sendName={sendName}
          loading={loading}
        />
      }
      footer={
        <Footer
          component={component}
          getOTP={getOTP}
          sendOTP={sendOTP}
          loading={loading}
          sendName={sendName}
        />
      }
      handleCloseDrawer={handleCloseDrawer}
      showCross={false}
      showHeaderSeperator={true}
      onEnter={() => {}}
    />
  );
};

export default Login;
