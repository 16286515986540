import Container from "./styles";

const AgentDetails = () => {
  return (
    <Container>
      <p className="note">
        <span>●</span>
        This ticket is booked on a personal User ID, its sale/purchase is an
        offense u/s 143 of Railways Act, 1989.
      </p>
      <p className="note">
        <span>●</span>
        Prescribed original ID proof is required while travelling along with
        SMS/ VRM/ ERS otherwise will be treated as without ticket and penalized
        as per Railway Rules.
      </p>
    </Container>
  );
};

export default AgentDetails;
