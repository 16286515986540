import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

import ReactDOM from "react-dom";

const disha = "WarningAlert.png";
const down =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/close.png";

const AlertWarning = ({ error, handleClose, suberror }) => {
  useEffect(() => {
    setTimeout(() => {
      document.getElementById("disha-alert")?.focus();
    });
  }, []);

  return ReactDOM.createPortal(
    <div
      style={{
        position: "absolute",
        inset: 0,
        background: "#00000096",
        zIndex: 999999,
      }}
      tabIndex={1}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          setTimeout(() => {
            handleClose();
          }, 500);
        }
      }}
      id="disha-alert"
    >
      <div
        style={{
          position: "absolute",
          top: "45%",
          left: "50%",
          background: "white",
          transform: "translate(-50%, -50%)",
          width: "90%",
          padding: "18px 15px",
          borderRadius: "10px",
        }}
      >
        {/* <img
          src={down}
          style={{
            position: "absolute",
            top: "12px",
            right: "12px",
            width: "14px",
            cursor: "pointer",
          }}
          alt=""
          onClick={handleClose}
        /> */}
        <div style={{}}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{
                color: "tomato",
                fontSize: "50px",
              }}
            />*/}
            <img
              src={disha}
              alt=""
              style={{ width: "70px", marginLeft: "-12px", marginTop: "15px" }}
            />
          </div>

          <p
            style={{
              margin: "12px 0px 0px",
              fontSize: "20px",
              color: "rgb(102, 102, 102)",
              textAlign: "center",
              lineHeight: "20px",
            }}
          >
            {error}
          </p>

          <p
            style={{
              margin: "12px 0px 0px",
              fontSize: "16px",
              color: "gray",
              textAlign: "justify",
              lineHeight: "20px",
            }}
          >
            {suberror}
          </p>

          <button
            style={{
              width: "50%",
              display: "block",
              margin: "12px auto 0px",
              fontSize: "16px",
              border: "none",
              backgroundColor: "#326abb",
              color: "white",
              borderRadius: "5px",
              padding: "10px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            Ok
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("corover-body")
  );
};

export default AlertWarning;
