import { useState } from "react";
import { verifyId } from "../../../../Api/irctc";
import useGetReduxState from "../../../../Hooks/useGetReduxState";
import { setIrctcDetails } from "../../../../Store/Dispatcher/behaviour";
import {
  setCurrentPassengerFormStep,
  setJourneyPreferences,
} from "../../../../Store/Dispatcher/passengerForm";
import IconTextBox from "../../../../UI/UIComponents/IconTextBox";
import Spinner from "../../../../UI/UIComponents/Spinner";
import Container from "./styles";
import langText from "./translations";
const IrctcUserId = () => {
  const userData = useGetReduxState().user.irctcId;
  const [value, setValue] = useState(userData);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const currentStep = useGetReduxState().passengerform.currentPassengerFormStep;
  const preferences = useGetReduxState().passengerform.journeyPreferences;
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const translations = {
    en: {
      content: "IRCTC ID	",
    },
    hi: {
      content: "IRCTC आईडी	",
    },

    gu: {
      content: "IRCTC આઈડી",
    },
  };

  const handleChange = (e) => {
    let v = e.target.value;
    setValue(v);
  };

  function onlyLettersAndNumbers(str) {
    return /^[A-Za-z0-9_.]*$/.test(str);
  }

  const handleVerify = async () => {
    if (loading) return;
    if (value.trim() === "") return;

    if (
      value.length < 3 ||
      value.length > 35 ||
      !onlyLettersAndNumbers(value)
    ) {
      setError(
        "User Id should be 3 to 35 characters long. Only numbers and letters are allowed.",
      );
      return;
    }

    setLoading(true);
    const data = await verifyId(value);

    setError(data.error);

    setLoading(false);
    if (!data.error) {
      setJourneyPreferences({ ...preferences, irctcUserId: value });
      setCurrentPassengerFormStep(currentStep + 1);
      setIrctcDetails({ email: data.email, mobile: data.mobile, txnId: "" });
    }
  };

  const handleVoice = (value) => {
    if (!value) return;
    let e = {
      target: {
        value: value.split(" ").join(""),
      },
    };
    handleChange(e);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && value !== "") {
      handleVerify();
    }
  };

  return (
    <Container onKeyDown={handleKeyDown}>
      <p>{langText[lang].text}</p>
      <IconTextBox
        placeholder={langText[lang].placeholder}
        icon={null}
        onChange={handleChange}
        value={value}
        handleVoice={handleVoice}
        content = {translations[lang].content}

      />
      {error && (
        <p style={{ color: "tomato", margin: "5px 0px 0px 0px", padding: "0" }}>
          {error}
        </p>
      )}

      <button
        style={{
          width: "100%",
          border: "1px solid rgb(50, 106, 187)",
          backgroundColor: "rgb(50, 106, 187)",
          color: "white",
          fontSize: "18px",
          padding: loading ? "8px 0px" : "12px 0px",
          borderRadius: "5px",
          cursor: "pointer",
          marginTop: "13px",
          display: "flex",
          justifyContent: "center",
        }}
        onClick={handleVerify}
      >
        {loading ? (
          <Spinner
            borderWidth={"3px"}
            activeColor={"#f9f9f9"}
            inactiveColor={"#6495ed"}
            speed={"0.8s"}
            size={"25px"}
          />
        ) : (
          <span>{langText[lang].button}</span>
        )}
      </button>

      <div className="secondary">
        <p>{langText[lang].dont}</p>
        <a
          href="https://www.irctc.co.in/nget/profile/user-registration"
          target="_blank"
        >
          {langText[lang].create}
        </a>
      </div>

      {/* <button>Verify</button>
      <button className="secondary">I dont have an IRCTC User Id</button> */}
    </Container>
  );
};

export default IrctcUserId;
