const translations = {
  en: {
    header: "Select Your Booking",
    no: "No appropriate bookings found",
    select: "Select",
  },
  hi: {
    header: "अपनी बुकिंग चुनें",
    no: "आपके पास कोई उपयुक्त बुकिंग नहीं है",
    select: "चुनिए",
  },
  gu: {
    header: "તમારું આરક્ષણ પસંદ કરો",
    no: "તમારી પાસે માન્ય આરક્ષણ નથી",
    select: "પસંદ કરો",
  },
};

export default translations;
