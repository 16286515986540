import { faRectangleList, faTrain } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { getStationName } from "../../../Helpers/getStationName";
import useGetReduxState from "../../../Hooks/useGetReduxState";
import Container from "./styles";
import langText from "./translations";

const arrow =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/arrow_right.svg";
const volume =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/volume.png";
const mute =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/mute.png";

const Speaker = () => {
  const [speaker, setSpeaker] = useState(false);
  const toggleSpeaker = () => {
    var x = document.getElementById("myAudio") as HTMLAudioElement;
    if (speaker) {
      x.pause();
    } else {
      x.play();
    }
    setSpeaker(!speaker);
    // e.preventDefaults();
  };

  return (
    <div className="new-speaker" onClick={toggleSpeaker}>
      {speaker ? (
        <img style={{ width: "20px", height: "20px" }} src={volume} />
      ) : (
        <img style={{ width: "20px", height: "20px" }} src={mute} />
      )}
    </div>
  );
};

const Content = ({ train }) => {
  const [mode, setMode] = useState(0);

  useEffect(() => {
    document.documentElement.style.setProperty(
      `--animLeft`,
      -(train?.stationList?.length * 166 + 166 + 117 + 50) + "px",
    ); //suffix may be px or ''
    document.documentElement.style.setProperty(
      `--animTime`,
      train?.stationList?.length * 2.5 + "s",
    ); //suffix may be px or ''
  }, []);

  const Stop = (e) => {
    e.stopPropagation();
  };

  const Train = () => {
    return (
      <React.Fragment>
        <Speaker />
        <div className="availability-container">
          <p className="top">
            {train?.trainName} ({train?.trainNumber})
          </p>
          <div className="details-wrap-availability">
            <div className="left">
              <p className="code">{train?.stationFrom}</p>
              <p className="station">{getStationName(train?.stationFrom)}</p>
              {/* <p className="time">04:15 pm</p> */}
            </div>
            <div className="middle" style={{ margin: "0px 16px" }}>
              <img style={{ width: "20px" }} src={arrow} />
            </div>
            <div className="right">
              <p className="code">{train?.stationTo}</p>
              <p className="station">{getStationName(train?.stationTo)}</p>
              {/* <p className="time">08:30 pm</p> */}
            </div>
          </div>
        </div>

        <div className="train-container">
          <ScrollContainer>
            <div className="train-anim train">
              <div className="train-car train-end">
                <div className="schedule">
                  <div>
                    <span>
                      {" "}
                      {train?.trainNumber} <br /> {train?.trainName}
                    </span>
                  </div>
                </div>
                <div className="train-windows engine">
                  <div className="train-end-window" />
                  {/* <div class="w-left"></div>
        <div class="w-right"></div> */}
                </div>
                <div className="train-door" />
              </div>
              {/* Train head end */}
              {/* Train head end traiscudule */}
              {train.stationList.map((train, index) => (
                <div key={index} className="train-car">
                  <div className="schedule">
                    <div>
                      <span>
                        {" "}
                        {getStationName(train?.stationCode)} (
                        {train?.stationCode}){" "}
                      </span>
                    </div>
                  </div>
                  <div className="info">
                    <div>
                      {train?.haltTime === "--" ? (
                        <>
                          {train?.arrivalTime !== "--" ? (
                            <>arrival: {train?.arrivalTime}</>
                          ) : (
                            <>departure: {train?.departureTime}</>
                          )}
                        </>
                      ) : (
                        <>
                          Halt : {train?.haltTime} min (
                          {train?.arrivalTime && (
                            <span>{train?.arrivalTime} - </span>
                          )}
                          {train?.departureTime})
                        </>
                      )}
                    </div>
                  </div>
                  <div className="train-windows"></div>
                  <div className="train-door" />
                  <div className="train-windows"></div>
                </div>
              ))}
              {/* Train car end */}
              {/* Train car end */}

              <div className="train-car train-head back">
                <div className="train-door" />
                <div className="train-windows">
                  {/* <div class="train-head-window"></div> */}
                  <div className="w-left" />
                </div>
              </div>
            </div>
          </ScrollContainer>

          {/* Train end */}
          {/* train-track end */}
        </div>
      </React.Fragment>
    );
  };

  const List = () => {
    const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;
    return (
      <div className="days">
        <div
          style={{ position: "static", border: "none", margin: "10px" }}
          className="availability-container"
        >
          <p className="top">
            {train?.trainName} ({train?.trainNumber})
          </p>
          <div className="details-wrap-availability">
            <div className="left">
              <p className="code">{train?.stationFrom}</p>
              <p className="station">{getStationName(train?.stationFrom)}</p>
              {/* <p className="time">04:15 pm</p> */}
            </div>
            <div className="middle" style={{ margin: "0px 16px" }}>
              <img style={{ width: "20px" }} src={arrow} />
            </div>
            <div className="right">
              <p className="code">{train?.stationTo}</p>
              <p className="station">{getStationName(train?.stationTo)}</p>
              {/* <p className="time">08:30 pm</p> */}
            </div>
          </div>
        </div>
        <table className="list">
          <tr>
            <th>Sno</th>
            <th>{langText[lang].station}</th>
            <th>{langText[lang].arrival}</th>
            <th>{langText[lang].departure}</th>
            <th>{langText[lang].day}</th>
          </tr>

          {train.stationList.map((train, index) => {
            return (
              <tr>
                <td>{index + 1}</td>

                <td>
                  {getStationName(train?.stationCode)} ({train?.stationCode})
                </td>
                <td>{train?.arrivalTime}</td>
                <td>{train?.departureTime}</td>

                <td>{train?.dayCount}</td>
              </tr>
            );
          })}
        </table>
      </div>
    );
  };

  return (
    <Container onClick={Stop} mode={mode}>
      <audio id="myAudio" loop>
        <source
          src="https://coroverbackendstorage.blob.core.windows.net/askdisha-irctc/trainsound.mp3"
          type="audio/mpeg"
        />
      </audio>
      <div className="tabs">
        <div onClick={() => setMode(0)}>
          <FontAwesomeIcon
            icon={faTrain}
            style={{
              color: mode === 0 ? "#4782eb" : "#646363",
              fontSize: "18px",
              cursor: "pointer",
            }}
          />

          {mode === 0 && <span></span>}
        </div>
        <div
          onClick={() => {
            // if (speaker) toggleSpeaker();
            var x = document.getElementById("myAudio") as HTMLAudioElement;

            x.pause();

            setMode(1);
          }}
        >
          <FontAwesomeIcon
            icon={faRectangleList}
            style={{
              color: mode === 1 ? "#4782eb" : "#646363",
              fontSize: "18px",
              cursor: "pointer",
            }}
          />
          {mode === 1 && <span></span>}
        </div>
      </div>

      {mode === 0 ? <Train /> : <List />}
    </Container>
  );
};

export default Content;
