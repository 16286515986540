import Container from "./styles";
import parseData from "./utils";
import langText from "./translations";
import { getStationName } from "../../Helpers/getStationName";
import { getClassName } from "../../Helpers/getClassName";
import useGetReduxState from "../../Hooks/useGetReduxState";
const icon = "https://d3upbvvdvllr10.cloudfront.net/dishav3/pnr.svg";

const PNRStatus = ({ data }) => {
  const {
    pnr,
    passengers,
    bookingDate,
    source,
    destination,
    dateOfJourney,
    chartStatus,
    chartStatusColor,
    journeyClass,
    quota,
    trainName,
    trainNumber,
  } = parseData(data);

  const lang = useGetReduxState().behaviour.lang;

  return (
    <Container>
      <div className="header">
        <div className="icon">
          <img src={icon} alt="" />
          <p>{langText[lang].header}</p>
        </div>
        <p>{pnr}</p>
      </div>

      <div className="train-details">
        {trainName} <span>({trainNumber})</span>
      </div>
      <div className="journey-details">
        <div className="from">
          <p className="code">{source}</p>
          <p className="station">{getStationName(source)}</p>
        </div>
        <div className="middle">
          <div className="hr"></div>
        </div>
        <div className="to">
          <p className="code">{destination}</p>
          <p className="station">{getStationName(destination)}</p>
        </div>
      </div>
      <div className="seperator"></div>
      <div className="chart-status">
        {langText[lang].status}:{" "}
        <span style={{ color: chartStatusColor }}>{chartStatus}</span>
      </div>
      <div className="seperator"></div>
      <div className="other-details">
        <div>
          <span>{langText[lang].class}:</span>
          <span>{langText[lang].jDate}:</span>
          <span>{langText[lang].quota}:</span>

          <span>{langText[lang].bDate}:</span>
        </div>
        <div>
          <span>
            {getClassName(journeyClass)} - {journeyClass}
          </span>
          <span>{dateOfJourney}</span>
          <span>{quota}</span>

          <span>{bookingDate} </span>
        </div>
      </div>
      <div className="seperator"></div>
      <div className="passengers">
        {/* <h1>Passengers (2)</h1> */}
        <div className="passengers-header">
          <div>
            <p style={{}}>{langText[lang].passenger}</p>
            <span>{langText[lang].number}</span>
            <div style={{ textAlign: "center", width: "100%" }}>
              {/* @ts-ignore */}
              {passengers.map((passenger, index) => (
                <p style={{ fontWeight: 400, marginTop: "3px" }}>
                  #{index + 1}
                </p>
              ))}
            </div>
          </div>
          <div>
            <p>{langText[lang].bookingStatus}</p>
            <div className="status-container">
              <div style={{ width: "100%", textAlign: "center" }}>
                <span>{langText[lang].ticket}</span>
                {passengers.map((passenger) => (
                  <p>{passenger.atBooking.status}</p>
                ))}
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <span>{langText[lang].coach}</span>
                {passengers.map((passenger) => (
                  <p>{passenger.atBooking.coach}</p>
                ))}
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <span>{langText[lang].seat}</span>
                {passengers.map((passenger) => (
                  <p>{passenger.atBooking.seat}</p>
                ))}
              </div>
            </div>
          </div>
          <div>
            <p>{langText[lang].currentStatus}</p>
            <div className="status-container">
              <div style={{ width: "100%", textAlign: "center" }}>
                <span>{langText[lang].ticket}</span>
                {passengers.map((passenger) => (
                  <p>{passenger.atCurrent.status}</p>
                ))}
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <span>{langText[lang].coach}</span>
                {passengers.map((passenger) => (
                  <p>{passenger.atCurrent.coach}</p>
                ))}
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <span>{langText[lang].seat}</span>
                {passengers.map((passenger) => (
                  <p>{passenger.atCurrent.seat}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PNRStatus;
