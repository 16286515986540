import useGetReduxState from "../../../Hooks/useGetReduxState";

const Header = () => {
  const lang = useGetReduxState().behaviour.lang;

  if (lang === "en") return <div>Select Journey Date</div>;
  if (lang === "hi") return <div>यात्रा की तिथि चुनें</div>;
  if (lang === "gu") return <div>મુસાફરીની તારીખ પસંદ કરો</div>;
};

export default Header;
