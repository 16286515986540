import styled from "styled-components";

const TabsContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 14px;
  padding: 0px 10px;
  padding-bottom: 5px;
  background: #f0f0ff;
  padding: 6px 15px;

  button {
    background: transparent;
    /* border: 1px solid t; */
    border: none;
    padding: 5px 8px;
    /* border-radius: 6px; */
    color: #4e4e4e;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
  }

  .active-tab {
    /* background: #3f63f5; */
    border-bottom: 2px solid #3f63f5;
    color: #3f63f5;
    font-weight: 500;
  }
`;

export default TabsContainer;
