import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  IMAGE_MESSAGE_MUTE,
  IMAGE_MESSAGE_SPEAKER,
} from "../../../../../../../../Assets";
import useGetReduxState from "../../../../../../../../Hooks/useGetReduxState";
import {
  playAudio,
  stopAudio,
} from "../../../../../../../../Store/Dispatcher/behaviour";
import { RootState } from "../../../../../../../../Store/Reducers";

const Speaker = ({ id, audioUrl, render }) => {
  const { audio } = useSelector((state: RootState) => state.behaviour);
  const [playing, setPlaying] = useState(
    audio.current === id &&
      render !== "trains" &&
      render !== "passengerDetails",
  );
  const toggle: any = () => {
    if (!playing) {
      playAudio({ id: id, url: audioUrl });
    } else {
      stopAudio();
    }
    setPlaying(!playing);
  };

  useEffect(() => {
    setPlaying(audio.current === id);
  }, [audio.current]);

  return (
    <img
      src={playing ? IMAGE_MESSAGE_MUTE : IMAGE_MESSAGE_SPEAKER}
      alt=""
      className="speaker"
      onClick={toggle}
      data-audioUrl={audioUrl}
    />
  );
};

export default Speaker;
