import styled from "styled-components";

const BookingDetailsContainer = styled.div`
  width: 100%;

  p,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    line-height: 1;
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 16px;
    padding: 15px;

    button {
      background: white;
      border: none;
      box-shadow:
        rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        rgb(209, 213, 219) 0px 0px 0px 1px inset;
      padding: 8px 8px;
      min-width: 100px;
      border-radius: 5px;
      font-size: 12px;
      cursor: pointer;

      img {
        width: 18px;
        margin-right: 5px;
      }
    }
  }

  .passengers {
    padding: 15px;

    h3 {
      color: black;
      font-weight: 500;
      margin-bottom: 15px;
      font-size: 16px;
    }

    .passenger {
      padding: 10px 10px;
      border: 1px solid gainsboro;
      border-radius: 8px;
      margin-top: 10px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 16px;
          color: #282828;
          font-weight: 500;
          margin-bottom: 0px;
        }
        .age-gender {
          font-size: 12px;
          margin-left: 15px;
          font-weight: 400;
        }
      }

      .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        margin-top: 5px;

        .status {
          /* display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 10px; */

          .label {
            font-size: 10px;
            color: #1c1c1c;
          }

          .value {
            font-size: 12px;
            border: 1px solid;
            padding: 3px 5px;
            border-radius: 4px;
            min-width: 100px;
            margin-top: 4px;
            font-weight: 600;

            &:first-child {
              margin-top: 0;
            }
          }
        }

        .seat-details {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 10px;

          p {
            font-size: 10px;
            color: #708090;
          }

          span {
            font-size: 12px;
            color: #151515;
            font-weight: 500;
            margin-top: 2px;
            display: block;
          }
        }
      }
    }
  }

  .other-details {
    padding: 15px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;
    font-size: 12px;
    p {
      font-weight: 500;
      margin-top: 5px;
    }

    span {
      display: block;
      line-height: 1;
      margin-top: 5px;
    }
  }

  .pnr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    p {
      font-size: 16px;
      color: #353535;
      font-weight: 600;
    }

    span {
      font-weight: 400;
    }

    button {
      font-size: 12px;
      background: white;
      cursor: pointer;
      background-color: #ebeefd;
      font-weight: 500;
      padding: 5px 10px;
      border-radius: 8px;
      border: 1px solid #d2d2ff;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .seperator {
    width: 100%;
    border: 2px solid #f2f2f2;
  }

  .class {
    p {
      box-shadow:
        rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
        rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
      width: fit-content;
      margin: 0 auto;
      padding: 8px;
      font-size: 16px;
      border-radius: 6px;
      color: #333333;
    }

    span {
      font-size: 10px;
      color: darkslategray;
      margin-top: 4px;
      display: block;
    }
  }

  .middle {
    width: 100%;
    margin: 0 15px;
  }

  .duration {
    font-size: 12px;
    text-align: center;
    margin-bottom: 4px;
    color: darkslategray;
  }

  .distance {
    font-size: 12px;
    text-align: center;
    margin-top: 4px;
    color: darkslategray;
  }

  .train-details {
    padding: 0px 15px;
    text-align: left;
    font-size: 16px;
    color: #2f2f2f;
    margin-top: 12px;
    font-weight: 500;

    span {
      font-weight: 400;
    }
  }

  .journey-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    width: 100%;
    margin: 12px 0px;
  }

  .hr {
    width: 100%;
    border: 1px solid gainsboro;
    margin: 0px 15px;
  }

  .from,
  .to {
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
  }

  .from {
    text-align: left;
  }

  .to {
    text-align: right;
  }

  .station {
    font-size: 10px;
    line-height: 1.2;
    color: black;
  }

  .code {
    font-size: 18px;
    color: #404040;
    line-height: 1;
    margin-bottom: 2px;
    font-weight: 700;
  }

  .time-details {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;
    width: 100%;
    margin: 10px 0;
    margin-top: -11px;
  }

  .time {
    font-size: 12px;
    color: #636363;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 4px;
    display: inline-block;
  }

  .date {
    font-size: 12px;
    color: #636363;
    font-weight: 500;
    line-height: 1;
    display: inline-block;
  }
`;

export default BookingDetailsContainer;
