import useGetReduxState from "../../../Hooks/useGetReduxState";
import langText from "./translations";
const Footer = ({ handleSubmit }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  return (
    <button
      style={{
        border: "none",
        background: "#346db3",
        color: "white",
        width: "98%",
        padding: "12px",
        borderRadius: "8px",
        fontSize: "16px",
        marginBottom: "5px",
        cursor: "pointer",
      }}
      onClick={handleSubmit}
    >
      {langText[lang].button}
    </button>
  );
};

export default Footer;
