import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  background-color: white;
  border: 1px solid #aaaaaa;
  width: 98%;
  padding: 12px 15px;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 5px;

  p {
    color: #696969;
  }
`;

export default Container;
