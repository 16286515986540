import { useEffect, useState } from "react";
import Drawer from "../../UI/UIComponents/Drawer";
import Content from "./Content/Content";
import Header from "./Header/Header";

import { getStationName } from "../../Helpers/getStationName";
import { getSchedule } from "../../Api/trains";
import moment from "moment";
import { CloseDrawer } from "../../Helpers/EventEmitter";
import useGetReduxState from "../../Hooks/useGetReduxState";
import React from "react";

const BoardingPicker = ({
  trainNumber,
  current,
  date,
  handleClose,
  callBack,
  sourcePoint,
}) => {
  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(true);
  const irctcConfig = useGetReduxState().passengerform.irctcConfig;
  const [currentBoarding, setCurrentBoarding] = useState({
    code: "",
    time: "",

    date: "",
    name: "",
    disabled: false,
  });

  const get = async () => {
    const data = await getSchedule({
      trainNumber: trainNumber,
      date: date,
      station: sourcePoint,
    });

    let stations = data.stationListUpdated.map((station) => {
      return {
        code: station.stationCode,
        time: station.departureTime,

        date: moment(date, "YYYYMMDD").add(
          parseInt(station.dayCount) - 1,
          "days",
        ),
        name: getStationName(station.stationCode),
        disabled: false,
        // disabled: moment(date, "YYYYMMDD")
        //   .add(parseInt(station.dayCount) - 1, "days")
        //   .isBefore(moment()),
      };
    });

    setCurrentBoarding(
      stations.filter((station) => station.code === current)[0],
    );

    let refactored = [];

    for (let station of stations) {
      if (irctcConfig.boardingStations.includes(station.code))
        refactored.push(station);
    }

    setStations(refactored);
    setLoading(false);
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <Drawer
      header={<Header />}
      content={
        <Content
          stations={stations}
          isLoading={loading}
          handleClick={({ code, date, time }) => {
            CloseDrawer();
            callBack({ code, date, time });
          }}
          current={currentBoarding}
        />
      }
      footer={<React.Fragment></React.Fragment>}
      handleCloseDrawer={handleClose}
      showCross={true}
      tatkal={false}
      fullHeight={false}
      showHeaderSeperator={true}
    />
  );
};

export default BoardingPicker;
