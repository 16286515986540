import { useState } from "react";
import { getBerthName } from "../../../../../Helpers/getBerthNames";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import langText from "../../../translations";
import Drawer from "../../../../../UI/UIComponents/Drawer";
import { RadioSelect } from "../../../../../UI/UIComponents/RadioSelect/RadioSelect";
import { isChild } from "../../../utils";
import { setPassengers } from "../../../../../Store/Dispatcher/passengerForm";
import { CloseDrawer } from "../../../../../Helpers/EventEmitter";
import langText2 from "./translations";
import React from "react";
import Speaker from "../../../../../UI/UIComponents/Speaker";

const veg =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/veg.png";
const nonveg =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/non-veg.png";
const nofood =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/no-food.png";
const snacks = "snacks.svg";
const tea = "tea.svg";
const vegd =
  "https://cdn.jsdelivr.net/gh/corover/assets@11Sept6/askdisha-bucket/veg-d1.png";
const nonvegd =
  "https://cdn.jsdelivr.net/gh/corover/assets@11Sept6/askdisha-bucket/nonveg-d1.png";
const jain =
  "https://cdn.jsdelivr.net/gh/corover/assets@11Sept6/askdisha-bucket/jain.png";
// const seat = require("../../../../../Assets/F/seat-black.png");

const audios = {
  hi: {
    both: "https://storage.corover.ai/chatbot-audio-bucket-aws/7f214fe5-1be0-46d3-81fc-e0a4003206d5_hi.wav",
    berth:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/6d2ca632-c01f-461b-a8bd-73787e3b2e9b_hi.wav",
    food: "https://storage.corover.ai/chatbot-audio-bucket-aws/d14ee82c-9f6b-4c03-9587-89e24f1e9426_hi.wav",
  },
  en: {
    both: "https://storage.corover.ai/chatbot-audio-bucket-aws/ef379eef-6872-4939-8c86-ef5583eb8c26_en.wav",
    berth:
      "https://storage.corover.ai/chatbot-audio-bucket-aws/4e24099e-14d0-4b64-aa32-c9563fd5c859_en.wav",
    food: "https://storage.corover.ai/chatbot-audio-bucket-aws/2f587fb9-0295-4b25-a6cc-1565fefcce80_en.wav",
  },
  gu: {
    both: "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/f101283f-a6a9-4665-8bf8-740d16d8f5c1.wav",

    food: "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/ef92ac75-47f4-4586-802c-0fab73ed1e97.wav",

    berth:
      "https://storage.googleapis.com/corover-prod-bucket/disha-bhashini/c974bc0c-44a3-46a8-9866-046702bd2fba.wav",
  },
};

const Header = ({ mode, passenger }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  if (mode === "BOTH")
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "95%",
        }}
      >
        <p
          style={{
            margin: "0px",
            fontSize: "16px",
            fontWeight: "500",
            color: "#3f3f3f",
          }}
        >
          {langText2[lang].select.both} {passenger.name}
        </p>
        <Speaker
          sizeBg={"25px"}
          sizeIcon={"12px"}
          audioUrl={audios[lang].both}
          disabled={false}
        />
      </div>
    );
  if (mode === "FOOD")
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "95%",
        }}
      >
        <p
          style={{
            margin: "0px",
            fontSize: "16px",
            fontWeight: "500",
            color: "#3f3f3f",
          }}
        >
          {langText2[lang].select.food} {passenger.name}
        </p>
        <Speaker
          sizeBg={"25px"}
          sizeIcon={"12px"}
          audioUrl={audios[lang].food}
          disabled={false}
        />
      </div>
    );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "95%",
      }}
    >
      <p
        style={{
          margin: "0px",
          fontSize: "16px",
          fontWeight: "500",
          color: "#3f3f3f",
        }}
      >
        {langText2[lang].select.berth} {passenger.name}
      </p>
      <Speaker
        sizeBg={"25px"}
        sizeIcon={"12px"}
        audioUrl={audios[lang].berth}
        disabled={false}
      />
    </div>
  );
};

const Content = ({
  mode,
  handleSelect,
  berthCh,
  foodCh,
  setBerthCh,
  setFoodCh,
  passenger,
}) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const irctcConfig = useGetReduxState().passengerform.irctcConfig;

  const berthCodes =
    isChild(passenger.age) && irctcConfig.childBerthMandatory
      ? irctcConfig.berths
      : ["NBC", ...irctcConfig.berths];

  const options = berthCodes.map((berthCode) => {
    return {
      label: getBerthName(berthCode),
      value: berthCode,
      show: true,
      // icon: seat,
      // iconSize: "21px",
    };
  });

  const foods = [
    {
      label: langText[lang].utils.food.full.veg,
      value: "V",
      show: irctcConfig.applicableFoodChoices.includes("V"),
      icon: veg,
      iconSize: "20px",
    },
    {
      label: langText[lang].utils.food.full.nonveg,
      value: "N",
      show: irctcConfig.applicableFoodChoices.includes("N"),
      icon: nonveg,
      iconSize: "20px",
    },
    {
      label: langText[lang].utils.food.full.snacks,
      value: "E",
      show: irctcConfig.applicableFoodChoices.includes("E"),
      icon: snacks,
      iconSize: "23px",
    },
    {
      label: langText[lang].utils.food.full.tea,
      value: "T",
      show: irctcConfig.applicableFoodChoices.includes("T"),
      icon: tea,
      iconSize: "23px",
    },
    {
      label: langText[lang].utils.food.full.vegd,
      value: "F",
      show: irctcConfig.applicableFoodChoices.includes("F"),
      icon: vegd,
      iconSize: "42px",
    },
    {
      label: langText[lang].utils.food.full.nonvegd,
      value: "G",
      show: irctcConfig.applicableFoodChoices.includes("G"),
      icon: nonvegd,
      iconSize: "42px",
    },
    {
      label: langText[lang].utils.food.full.jain,
      value: "J",
      show: irctcConfig.applicableFoodChoices.includes("J"),
      icon: jain,
      iconSize: "20px",
    },
    {
      label: langText[lang].utils.food.full.none,
      value: "D",
      show: irctcConfig.applicableFoodChoices.includes("D"),
      icon: nofood,
      iconSize: "18px",
    },
  ];

  if (mode === "BOTH")
    return (
      <div style={{ width: "100%", padding: "0px 15px" }}>
        <RadioSelect
          options={foods}
          label={langText2[lang].label.food}
          bordered={true}
          handleSelect={(food) => setFoodCh(food)}
          value={foodCh}
          error={""}
        />
        {isChild(passenger.age) && (
          <React.Fragment>
            {irctcConfig.childBerthMandatory ? (
              <p
                style={{
                  textAlign: "center",
                  color: "lightslategray",
                  marginTop: "15px",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {langText2[lang].mandatory}
              </p>
            ) : (
              <p
                style={{
                  textAlign: "center",
                  color: "lightslategray",
                  marginTop: "15px",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {langText2[lang].fullFare}
              </p>
            )}
          </React.Fragment>
        )}

        <RadioSelect
          options={options}
          label={langText2[lang].label.berth}
          bordered={true}
          handleSelect={(berth) => setBerthCh(berth)}
          value={berthCh}
          error={""}
        />
      </div>
    );

  if (mode === "FOOD")
    return (
      <div style={{ width: "100%", padding: "0px 15px" }}>
        <RadioSelect
          options={foods}
          label={null}
          bordered={false}
          handleSelect={handleSelect}
          value={foodCh}
          error={""}
        />
      </div>
    );

  return (
    <div style={{ width: "100%", padding: "0px 15px" }}>
      {isChild(passenger.age) && (
        <React.Fragment>
          {irctcConfig.childBerthMandatory ? (
            <p
              style={{
                textAlign: "center",
                color: "lightslategray",
                marginTop: "15px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {langText2[lang].mandatory}
            </p>
          ) : (
            <p
              style={{
                textAlign: "center",
                color: "lightslategray",
                marginTop: "15px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {langText2[lang].fullFare}
            </p>
          )}
        </React.Fragment>
      )}

      <RadioSelect
        options={options}
        label={null}
        bordered={false}
        handleSelect={handleSelect}
        value={berthCh}
        error={""}
      />
    </div>
  );
};

const Footer = ({ handleSelect, mode }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  if (mode === "BOTH")
    return (
      <button
        style={{
          width: "100%",
          border: "none",
          backgroundColor: "#326abb",
          color: "white",
          fontSize: "18px",
          padding: "12px",
          borderRadius: "6px",
          cursor: "pointer",
          display: "block",
        }}
        onClick={handleSelect}
      >
        {langText2[lang].footer}
      </button>
    );

  return <React.Fragment></React.Fragment>;
};

const ChoosePreference = ({ handleCloseDrawer, mode, passenger }) => {
  const [berthCh, setBerthCh] = useState(passenger.berth);
  const [foodCh, setFoodCh] = useState(passenger.food);
  const passengers = useGetReduxState().passengerform.passengers;

  const setBerthForPassenger = (berth) => {
    let temp = passengers;
    const ind = passengers.indexOf(passenger);

    let updatedPassenger = { ...passenger, berth: berth };
    temp[ind] = updatedPassenger;
    setPassengers([...temp]);
  };

  const setFoodForPassenger = (food) => {
    let temp = passengers;
    const ind = passengers.indexOf(passenger);
    let updatedPassenger = { ...passenger, food: food };
    temp[ind] = updatedPassenger;

    setPassengers([...temp]);
  };

  const handleSelectSingle = (value) => {
    if (mode === "FOOD") setFoodForPassenger(value);
    else setBerthForPassenger(value);
    CloseDrawer();
  };

  const handleSelectDouble = () => {
    if (foodCh === "" || berthCh === "") return;
    let temp = passengers;
    const ind = passengers.indexOf(passenger);
    let updatedPassenger = { ...passenger, food: foodCh, berth: berthCh };
    temp[ind] = updatedPassenger;
    setPassengers([...temp]);
    CloseDrawer();
  };

  const getShowCross = () => {
    if (mode === "BERTH") {
      return true;
    }
    if (mode === "FOOD") return false;

    if (mode === "BOTH") return false;

    return true;
  };

  return (
    <Drawer
      header={<Header mode={mode} passenger={passenger} />}
      content={
        <Content
          mode={mode}
          handleSelect={handleSelectSingle}
          berthCh={berthCh}
          foodCh={foodCh}
          setBerthCh={setBerthCh}
          setFoodCh={setFoodCh}
          passenger={passenger}
        />
      }
      footer={<Footer handleSelect={handleSelectDouble} mode={mode} />}
      handleCloseDrawer={handleCloseDrawer}
      showCross={getShowCross()}
      showHeaderSeperator={true}
      tatkal={false}
      fullHeight={false}
    />
  );
};

export default ChoosePreference;
