import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  border-bottom: 2px solid black;
  padding: 10px;
  h4 {
    font-weight: normal;
    font-size: 18px;
    text-decoration: underline;
    text-align: center;
  }

  .logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    .ir {
      width: 60px;
    }

    .disha {
      width: 120px;
    }

    .irctc {
      width: 50px;
    }

    .azadi {
      width: 90px;
    }

    .g20 {
      width: 100px;
    }
  }

  .journey-ers {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    div {
      text-align: center;
    }

    span {
      font-size: 13pt;
    }

    p {
      font-size: 12pt;
      line-height: 18pt;
    }

    h5 {
      font-size: 13pt;
    }

    img {
      width: 1.8in;
      height: 0.38in;
    }
  }
`;

export default Container;
