const translations = {
  en: {
    heading: {
      source: "Select Source Station",
      destination: "Select Destination Station",
    },
    search: "Search your Station or City",
    popular: "Popular Stations",
    recent: "Recent Searches",
    find: "Find Stations Near Me",
    near: "Stations Near You",
    viewAll: "View All Stations",
    wait: "",
    finding: "",
    no: "No Matching Stations Found",
  },
  hi: {
    heading: {
      source: "अपना सोर्स स्टेशन चुनें",
      destination: "अपना डेस्टिनेशन स्टेशन चुनें",
    },
    search: "अपना स्टेशन या शहर खोजें",
    popular: "लोकप्रिय स्टेशन",
    recent: "हाल की खोजें",
    find: "मेरे पास के स्टेशन",
    near: "आपके आस-पास के स्टेशन",
    viewAll: "सभी स्टेशन देखें",
    wait: "",
    finding: "",
    no: "मेल खाने वाला कोई स्टेशन नहीं मिला",
  },
  gu: {
    heading: {
      source: "તમારું સ્રોત સ્ટેશન પસંદ કરો",
      destination: "તમારું ગંતવ્ય સ્ટેશન પસંદ કરો",
    },
    search: "તમારું સ્ટેશન અથવા શહેર શોધો.",
    popular: "લોકપ્રિય સ્ટેશનો",
    recent: "તાજેતરની શોધો",
    find: "મારી નજીકના સ્ટેશનો",
    near: "તમારી નજીકના સ્ટેશનો",
    viewAll: "બધા સ્ટેશનો જુઓ.",
    wait: "",
    finding: "",
    no: "કોઈ મેળ ખાતું સ્ટેશન મળ્યું નથી",
  },
};

export default translations;
