import moment from "moment";
import store from "../../Store";

import langTextPassengers from "../../Templates/BookingHistory/Components/BookingDetails/Content/translations";

const parseDateTimeForBackendDumbFucks = (dateTime) => {
  if (
    !dateTime.includes("T") &&
    dateTime.includes(" ") &&
    dateTime.includes(".")
  )
    return moment(dateTime.split(".")[0], "YYYY-MM-DD hh:mm:ss");
  else if (dateTime.includes("T")) {
    return moment(dateTime.split(".")[0], "YYYY-MM-DDTh:m:s");
  } else return moment(dateTime, "MMM DD, YYYY, hh:mm:ss a");
};

const makeArray = (array) => {
  if (Array.isArray(array)) return array;
  else return [array];
};

const getGender = (code) => {
  const lang: "en" | "gu" | "hi" = store.getState().behaviour.lang;
  if (code === "M") return langTextPassengers[lang].male;
  if (code === "F") return langTextPassengers[lang].female;
  else return langTextPassengers[lang].transgender;
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const getBerthDetail = (detail) => {
  if (detail === "" || detail === "0" || !detail) return "NA";

  return detail;
};

// const getStatus = () => {};

const constructassengers = (passengers) => {
  const lang: "en" | "gu" | "hi" = store.getState().behaviour.lang;

  return makeArray(passengers).map((passenger) => {
    return {
      name: toTitleCase(passenger.passengerName),
      gender: getGender(passenger.passengerGender),
      age: passenger.passengerAge + langTextPassengers[lang].years,

      status: {
        status: passenger.currentStatus,
        seat: getBerthDetail(passenger.currentBerthNo),
        berth: getBerthDetail(passenger.currentBerthCode),
        coach: getBerthDetail(passenger.currentCoachId),
      },
    };
  });
};

export const parseBooking = (data) => {
  // const lang: "en" | "gu" |"hi" = store.getState().behaviour.lang;

  const bookingData = data;

  return {
    pnr: bookingData.pnrNumber,
    trainName: toTitleCase(bookingData.trainName),
    trainNumber: bookingData.trainNumber,
    source: bookingData.fromStn,
    destination: bookingData.destStn,
    boarding: bookingData.boardingStn,
    jClass: bookingData.journeyClass,
    quota: bookingData.journeyQuota,
    departureDate: parseDateTimeForBackendDumbFucks(bookingData.journeyDate),
    arrivalDate: parseDateTimeForBackendDumbFucks(bookingData.destArrvDate),
    boardingDate: parseDateTimeForBackendDumbFucks(bookingData.boardingDate),
    departureTime: bookingData.departureTime,
    arrivalTime: bookingData.arrivalTime,
    distance: bookingData.distance,
    passengers: constructassengers(bookingData.psgnDtlList),
    fares: {
      ticket: parseFloat(bookingData.totalFare).toFixed(2),
      convenience: (
        parseFloat(bookingData.wpServiceCharge) +
        parseFloat(bookingData.wpServiceTax)
      ).toFixed(2),
      ctc: "11.80",
      insurance: parseFloat(bookingData.insuranceCharge).toFixed(2),
      total: (parseFloat(bookingData.totalCollectibleAmount) + 11.8).toFixed(2),
    },
    QR: bookingData.QR,
  };
};
