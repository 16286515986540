import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: 8px 0px;
  border-top: 1px solid gainsboro;
  margin-top: 4px;
  cursor: pointer;

  &:first-child {
    margin-top: 0px;
    border-top: none;
    padding-top: 0px;
  }

  h4 {
    font-size: 12px;
    color: #2b2b2b;
    font-weight: 600;
  }

  .second-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 5px;
    font-size: 10px;
    margin-top: 4px;
    color: #767676;
    font-weight: 500;
  }

  .select-berth {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 4px;
    margin-left: 25px;

    .berth {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 10px;
      img {
        width: 20px;
      }
    }

    p {
      font-size: 11px;
      font-weight: 600;
    }

    div:not(.berth) {
      position: relative;
      font-size: 12px;
      padding: 3px 12px;
      border-radius: 4px;
      color: black;
      width: 100%;
      text-align: center;
      cursor: pointer;
      border: 1px solid #53adec;
      background: #eef7ff;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #45484a;
        overflow: hidden;
      }
    }
  }

  .view-all {
    background: white;
    padding: 0;
    border: none;
    color: #3f81df;
    font-weight: 600;
    font-size: 12px;
    margin: 0;
    cursor: pointer;
  }
`;

export default Container;
