import styled from "styled-components";

const Container = styled.div`
  width: 98%;
  margin: 0px auto;
  margin-bottom: 20px;
  /* padding: 12px 15px; */
  border: 1px solid gainsboro;
  border-radius: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding: 15px 0px;
  position: relative;
  h2 {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #4e4e4e;
  }

  .bookings-container {
    max-height: 250px;
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 15px;
    margin-top: 15px;
    position: relative;

    h4 {
      position: absolute;
      top: 40%;

      width: 100%;
      text-align: center;
      font-weight: 400;
      color: gray;
    }
  }
`;
export default Container;
