const translations = {
  en: {
    atleast: {
      error: "Please Select atleast one passenger for the journey.",
      sub: "",
    },
    maxPassenger: {
      error: "",
      sub: "Please unselect some of the passengers.",
    },
    minInfants: {
      error: "",
      sub: "Please unselect some of the infants.",
    },
    ratio: {
      error:
        "Number of children who have not been alloted berth  must be less or equal to number of passengers with berth.",
      sub: "",
    },
    oops: {
      error: "Oops! Something went wrong. Please Try Again.",
      sub: "",
    },
    runout: {
      error: "Sorry. The Train has run out of available tickets.",
      sub: "",
    },
  },
  hi: {
    atleast: {
      error: "कृपया यात्रा के लिए कम से कम एक यात्री का चयन करें।",
      sub: "",
    },
    maxPassenger: {
      error: "",
      sub: "कृपया कुछ यात्रियों को अचयनित करें।",
    },
    minInfants: {
      error: "",
      sub: "कृपया कुछ यात्रियों को अचयनित करें।",
    },
    ratio: {
      error:
        "जिन बच्चों को बर्थ आवंटित नहीं की गई है उनकी संख्या बर्थ वाले यात्रियों की संख्या से कम या उसके बराबर होनी चाहिए।",
      sub: "",
    },
    oops: {
      error: "क्षमा करना! कुछ गलत हो गया। कृपया पुन: प्रयास करें।",
      sub: "",
    },
    runout: {
      error: "माफ़ करना। ट्रेन में उपलब्ध टिकट खत्म हो गए हैं।",
      sub: "",
    },
  },
  gu: {
    atleast: {
      error: "મહેરબાની કરીને સફર માટે ઓછામાં ઓછો એક પ્રવાસી પસંદ કરો.",
      sub: "",
    },
    maxPassenger: {
      error: "",
      sub: "મહેરબાની કરીને કેટલાક મુસાફરોને બાકાત રાખો.",
    },
    minInfants: {
      error: "",
      sub: "મહેરબાની કરીને કેટલાક મુસાફરોને બાકાત રાખો.",
    },
    ratio: {
      error:
        "જે બાળકોને બર્થ ફાળવવામાં આવી નથી તેમની સંખ્યા બર્થ ધરાવતા મુસાફરોની સંખ્યા કરતાં ઓછી અથવા સમાન હોવી જોઈએ.",
      sub: "",
    },
    oops: {
      error: "માફ કરશો! કંઇક ખોટું થયું છે. મહેરબાની કરીને ફરી પ્રયાસ કરો.",
      sub: "",
    },
    runout: {
      error: "માફ કરી દો. ટ્રેનમાં ઉપલબ્ધ ટિકિટ પૂરી થઈ ગઈ છે.",
      sub: "",
    },
  },
};

export default translations;
