import { faSignIn, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { CloseAllDrawers } from "../../Helpers/EventEmitter";
import useGetReduxState from "../../Hooks/useGetReduxState";
import usePersistentStorage from "../../Hooks/usePersistentStorage";
import useSendQuery from "../../Hooks/useSendQuery/useSendQuery";
import Login from "../../Templates/Login2";
import MenuButtons from "../Body/Components/Content/Components/Home/Menu";
const Content = () => {
  const [openLogin, setOpenLogin] = useState(false);
  const cxToken = useGetReduxState().app.cxtoken;
  const { logout } = usePersistentStorage();
  const lang = useGetReduxState().behaviour.lang;
  const { goToHome } = useSendQuery(() => {});

  const getButton = () => {
    if (cxToken) {
      if (lang === "en") return "Log out";
      if (lang === "hi") return "लाग आउट";
      if (lang === "gu") return "લૉગ આઉટ કરો";
    } else {
      if (lang === "en") return "Log in";
      if (lang === "hi") return "लाग इन";
      if (lang === "gu") return "લૉગ ઇન કરો";
    }
  };

  return (
    <div>
      <div style={{ padding: "0px 12px", marginBottom: "30px" }}>
        <MenuButtons tab={1} />
      </div>
      <button
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          color: "white",
          backgroundColor: "#326abb",
          fontSize: "18px",
          width: "40%",
          padding: "10px",
          borderRadius: "5px",
          margin: "0px auto 15px",
          cursor: "pointer",
        }}
        tabIndex={1}
        onClick={() => {
          if (cxToken) {
            logout();
            goToHome();
          } else {
            setOpenLogin(true);
          }
        }}
      >
        <FontAwesomeIcon
          icon={cxToken ? faSignOut : faSignIn}
          style={{
            color: "white",
            marginRight: "10px",
          }}
        />{" "}
        {getButton()}
      </button>
      {openLogin && (
        <Login
          handleCloseDrawer={() => {
            setOpenLogin(false);
          }}
          handleLogin={() => {
            CloseAllDrawers();
          }}
        />
      )}
    </div>
  );
};

export default Content;
