import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Overlay } from "../../UI/UIComponents/Overlay";
import langText from "./translations";
import "./styles.scss";
import { thumbsFeedBack } from "../../Api/feedback";
import useGetReduxState from "../../Hooks/useGetReduxState";
import React from "react";

const Dislike = ({ answerId, handleClose, handleSent }) => {
  const lang = useGetReduxState().behaviour.lang;
  const [comment, setComment] = useState("");
  const [feedDone, setFeedDone] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setError("");
  }, [comment]);

  const handleSubmit = () => {
    // if (comment.length < 100) {
    //   setError(
    //     lang === "en"
    //       ? "Please enter a minimum of 100 characters"
    //       : "कृपया कम से कम 100 वर्ण दर्ज करें",
    //   );
    //   return;
    // }
    setFeedDone(true);
    thumbsFeedBack({
      answerId: answerId,
      feedback: "Dislike",
      comment: comment,
    });
    setTimeout(() => {
      handleSent();
    }, 1500);
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <Overlay>
      <div
        style={{
          backgroundColor: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          padding: feedDone ? "26px" : "18px",
          borderRadius: "15px",
          width: "80%",
          textAlign: "center",
        }}
      >
        {feedDone ? (
          <React.Fragment>
            <h1
              style={{
                marginBottom: "16px",
                fontSize: "18px",
                fontWeight: 400,
              }}
            >
              {langText[lang].feedback}
            </h1>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: "0b74fa",
                fontSize: "50px",
              }}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {" "}
            <h1
              style={{ marginBottom: "0px", fontSize: "18px", fontWeight: 400 }}
            >
              {langText[lang].sorry}
            </h1>
            <p
              style={{
                fontSize: "12px",
                margin: 0,
                color: "gray",
                lineHeight: "13px",
                marginTop: "8px",
                marginBottom: "20px",
              }}
            >
              {langText[lang].share}
            </p>
            <textarea
              id="feedback-text"
              name=""
              cols={30}
              placeholder={langText[lang].placeholder}
              rows={5}
              onChange={(e) => setComment(e.target.value)}
            ></textarea>
            {error && (
              <p
                style={{ color: "tomato", textAlign: "left", fontSize: "14px" }}
              >
                {error}
              </p>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              <button
                style={{
                  border: "1px solid #0b74fa",
                  background: "white",
                  color: "#0b74fa",
                  borderRadius: "8px",
                  marginRight: "12px",
                  fontSize: "14px",
                  padding: "5px 8px",
                  cursor: "pointer",
                }}
                onClick={handleCancel}
              >
                {langText[lang].buttons.cancel}
              </button>
              <button
                disabled={comment === "" || error !== ""}
                style={{}}
                onClick={handleSubmit}
                id="feedback-submit"
              >
                {langText[lang].buttons.submit}
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    </Overlay>
  );
};

export default Dislike;
