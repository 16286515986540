import store from "..";
import * as actions from "../Actions/user";

export const setUser = (userData) => {
  let actualData =
    Array.isArray(userData) && userData.length > 0
      ? userData[0]
      : {
          name: "",
          irctcId: "",
          mobile: "",
          email: "",
          passengers: [],
          savedPassengers: [],
        };
  const user = {
    name: actualData.name || "",
    irctcId: actualData.loginId || "",
    mobile: actualData.mobileNumber || "",
    email: actualData.email || "",
    passengers: actualData.passengerList || [],
    savedPassengers: actualData.masterData || [],
  };

  store.dispatch(actions.setUser(user));
  // store.dispatch(setTrainData(train));
};
