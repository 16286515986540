import Address from "./Address";

const Content = ({ addresses, handleClick }) => {
  return (
    <div
      style={{
        height: "100%",
        position: "relative",
        padding: "0px 15px",
        overflowY: "auto",
      }}
    >
      <div
        className="stations"
        style={{
          minHeight: "100px",
          display: "flex",
          width: "100%",
          flexDirection: "column",
        }}
      >
        {addresses.map((address) => {
          return (
            <Address address={address} OnClick={() => handleClick(address)} />
          );
        })}
      </div>
    </div>
  );
};

export default Content;
