const translations = {
  hi: {
    noForFilter: "आपके फ़िल्टर के लिए कोई ट्रेन नहीं मिली",
    noForJourney: "आपकी यात्रा के लिए कोई ट्रेन नहीं मिली",
    try: "अन्य मार्गों का प्रयास करें",
    clear: "",
  },
  en: {
    noForFilter: "No Trains found for your filter",
    noForJourney: "No Trains found for your journey",
    try: "Try Other Routes",
    clear: "फ़िल्टर क्लियर करें",
  },
  gu: {
    noForFilter: "તમારા ફિલ્ટર માટે કોઈ ટ્રેન મળી નથી",
    noForJourney: "તમારી મુસાફરી માટે કોઈ ટ્રેન મળી નથી.",
    try: "અન્ય રીતો અજમાવો",
    clear: "",
  },
};

export default translations;
