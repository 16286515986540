import { useState } from "react";
import useGetReduxState from "../../../../../../Hooks/useGetReduxState";
import Drawer from "../../../../../../UI/UIComponents/Drawer";
import { TextBox } from "../../../../../../UI/UIComponents/TextBox/TextBox";
import { handleChangeGST } from "./handleChange";
import { missingFields } from "./validations";
import langText from "./translations";
import { getPinDetails } from "../../../../../../Api/pincode";
import AddressPicker from "../../../../../../Pickers/AddressPicker";
import Fuse from "fuse.js";
const Header = () => {
  const lang = useGetReduxState().behaviour.lang;
  if (lang === "en")
    return (
      <p style={{ margin: "0", fontSize: "18px", fontWeight: "400" }}>
        Add GST Details <span>(optional)</span>
      </p>
    );
  if (lang === "gu")
    return (
      <p style={{ margin: "0", fontSize: "18px", fontWeight: "400" }}>
        જીએસટી વિગતો ઉમેરો <span>(વૈકલ્પિક)</span>
      </p>
    );

  return (
    <p style={{ margin: "0", fontSize: "18px", fontWeight: "400" }}>
      GST विवरण दें <span>(वैकल्पिक)</span>
    </p>
  );
};

const GSTFormValues = {
  gstnumber: "gstnumber",
  gstcompany: "gstcompany",
  gstflat: "gstflat",
  gststreet: "gststreet",
  gstarea: "gstarea",
  gstpincode: "gstpincode",
  gststate: "gststate",
  gstcity: "gstcity",
};

const Content = ({ formvalues, setFormValues }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [showStates, setShowStates] = useState(false);
  const [showCities, setShowCities] = useState(false);

  const getPinData = async (pin) => {
    const pinData = await getPinDetails(pin, null);

    if (!pinData.error) {
      setStates(pinData.stateList);
      setState(pinData.stateList[0]);
      setCities(pinData.cityList);
      setCity(pinData.cityList[0]);
      setFormValues({
        ...formvalues,
        values: {
          ...formvalues.values,
          gstpincode: pin,
          gststate: pinData.stateList[0],
          gstcity: pinData.cityList[0],
        },
      });
    } else {
      setStates([]);
      setState("");
      setCities([]);
      setCity("");
      setFormValues({
        ...formvalues,
        values: {
          ...formvalues.values,
          gststate: "",
          gstcity: "",
        },
      });
    }
  };

  const onChange = (e) => {
    const g = handleChangeGST(e, formvalues);
    setFormValues(g);

    if (e.target.id === "gstpincode") {
      setStates([]);
      setState("");
      setCities([]);
      setCity("");
      e.target.value.length === 6 && getPinData(e.target.value);
    }
  };

  const handleAddressVoice = (value, id, addresses) => {
    setShowStates(false);
    setShowCities(false);
    if (addresses.length === 0) {
      return;
    }

    const fuse = new Fuse(addresses);
    let searchValue: any = fuse.search(value);

    if (!searchValue) return;

    const e = {
      target: {
        name: id,
        value: searchValue[0].item,
      },
    };
    const g = handleChangeGST(e, formvalues);
    setFormValues(g);
  };

  const handleVoice = (value, id) => {
    let e = {
      target: {
        name: id,
        value: value,
      },
    };
    const g = handleChangeGST(e, formvalues);
    setFormValues(g);
  };

  return (
    <div style={{ width: "100%", padding: "8px 15px" }}>
      <TextBox
        label={langText[lang].fields.number}
        placeholder={langText[lang].placeholders.number}
        id={GSTFormValues.gstnumber}
        onChange={onChange}
        error={formvalues.errors.gstnumber}
        value={formvalues.values.gstnumber}
        type={"text"}
        handleVoice={handleVoice}
      />
      <TextBox
        label={langText[lang].fields.company}
        placeholder={langText[lang].placeholders.company}
        id={GSTFormValues.gstcompany}
        onChange={onChange}
        error={formvalues.errors.gstcompany}
        value={formvalues.values.gstcompany}
        type={"text"}
        handleVoice={handleVoice}
      />
      <TextBox
        label={langText[lang].fields.flat}
        placeholder={langText[lang].placeholders.flat}
        id={GSTFormValues.gstflat}
        onChange={onChange}
        error={formvalues.errors.gstflat}
        value={formvalues.values.gstflat}
        type={"text"}
        handleVoice={handleVoice}
      />
      <TextBox
        label={langText[lang].fields.street}
        placeholder={langText[lang].placeholders.street}
        id={GSTFormValues.gststreet}
        onChange={onChange}
        error={formvalues.errors.gststreet}
        value={formvalues.values.gststreet}
        type={"text"}
        handleVoice={handleVoice}
      />
      <TextBox
        label={langText[lang].fields.area}
        placeholder={langText[lang].placeholders.area}
        id={GSTFormValues.gstarea}
        onChange={onChange}
        error={formvalues.errors.gstarea}
        value={formvalues.values.gstarea}
        type={"text"}
        handleVoice={handleVoice}
      />
      <TextBox
        label={langText[lang].fields.pincode}
        placeholder={langText[lang].placeholders.pincode}
        id={GSTFormValues.gstpincode}
        onChange={onChange}
        error={formvalues.errors.gstpincode}
        value={formvalues.values.gstpincode}
        type={"number"}
        handleVoice={handleVoice}
      />
      <TextBox
        label={langText[lang].fields.state}
        placeholder={langText[lang].placeholders.state}
        id={GSTFormValues.gststate}
        onChange={onChange}
        error={formvalues.errors.gststate}
        value={state}
        type={"text"}
        handleVoice={(value, id) => handleAddressVoice(value, id, states)}
        onClick={() => setShowStates(true)}
      />

      {showStates && (
        <AddressPicker
          addresses={states}
          handleClose={() => setShowStates(false)}
          callBack={(value) => {
            setState(value);
            onChange({
              target: {
                name: GSTFormValues.gststate,
                value,
              },
            });
          }}
          headerText={langText[lang].placeholders.popupState}
        />
      )}

      <TextBox
        label={langText[lang].fields.city}
        placeholder={langText[lang].placeholders.city}
        id={GSTFormValues.gstcity}
        onChange={onChange}
        error={formvalues.errors.gstcity}
        value={city}
        type={"text"}
        handleVoice={(value, id) => handleAddressVoice(value, id, cities)}
        onClick={() => setShowCities(true)}
      />

      {showCities && (
        <AddressPicker
          addresses={cities}
          handleClose={() => setShowCities(false)}
          callBack={(value) => {
            setCity(value);
            onChange({
              target: {
                name: GSTFormValues.gstcity,
                value,
              },
            });
          }}
          headerText={langText[lang].placeholders.popupCity}
        />
      )}
      <div style={{ marginBottom: "15px" }}></div>
      {/* <SelectBox
        isForSelect={true}
        label={"Nationality"}
        value={country}
        handleClick={handleOpenList}
      /> */}
    </div>
  );
};

const Footer = ({ handleClose, formvalues, setFormValues }) => {
  const disableIt = () => {
    if (
      formvalues.errors.gstarea !== "" ||
      formvalues.errors.gstcity !== "" ||
      formvalues.errors.gstcompany !== "" ||
      formvalues.errors.gstflat !== "" ||
      formvalues.errors.gstnumber ||
      formvalues.errors.gstpincode !== "" ||
      formvalues.errors.gststate !== "" ||
      formvalues.errors.gststreet !== ""
    )
      return true;

    return false;
  };

  const handleSubmit = () => {
    const { isMissing, passengerSet } = missingFields(formvalues);
    if (isMissing) {
      setFormValues(passengerSet);
      return;
    }
    console.log(formvalues);
    handleClose();
  };

  const lang = useGetReduxState().behaviour.lang;

  return (
    <button
      style={{
        width: "100%",
        border: "none",
        backgroundColor: "#326abb",
        color: "white",
        fontSize: "18px",
        padding: "12px",
        borderRadius: "6px",
        cursor: "pointer",
        display: "block",
      }}
      disabled={disableIt()}
      onClick={handleSubmit}
    >
      {lang === "en" ? (
        <span>Save GST Details</span>
      ) : lang === "hi" ? (
        <span>जीएसटी विवरण सेव करें</span>
      ) : (
        <span>જીએસટી વિગતો સાચવો</span>
      )}
    </button>
  );
};

const GstDetails = ({ handleSubmit, handleCloseDrawer }) => {
  const data = useGetReduxState().passengerform.gstDetails;
  const initialFormValues = {
    values: data,

    errors: {
      gstnumber: "",
      gstcompany: "",
      gstflat: "",
      gststreet: "",
      gstarea: "",
      gstpincode: "",
      gststate: "",
      gstcity: "",
    },
  };

  const [formvalues, setFormValues] = useState(initialFormValues);

  return (
    <Drawer
      header={<Header />}
      content={
        <Content formvalues={formvalues} setFormValues={setFormValues} />
      }
      footer={
        <Footer
          handleClose={() => handleSubmit(formvalues.values)}
          formvalues={formvalues}
          setFormValues={setFormValues}
        />
      }
      handleCloseDrawer={handleCloseDrawer}
      showCross={true}
      tatkal={false}
      fullHeight={false}
      showHeaderSeperator={true}
    />
  );
};

export default GstDetails;
