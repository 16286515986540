const text = {
  hi: {
    heading: "अपना सुझाव दें",
    please: "कृपया मुझे सुधारने में मदद करें।",
    learning:
      "मैं आसानी से और तेज़ी से ट्रेन टिकट बुक करने में आपकी मदद करने के लिए लगातार सीख रही हूँ और सुधार कर रही हूँ!",
    rate: "कृपया मेरे साथ अपने अनुभव का मूल्यांकन करें।",
    thanks: "आपका सुझाव देने के लिए धन्यवाद।",
    submit: "सुझाव सबमिट करें",
    placeholders: {
      feedback: "अपना सुझाव यहां दर्ज करें...",
      name: "श्रेणी दर्ज करें..",
      email: "अपने विकल्प चुनें...",
    },
    errors: {
      feedback: "कृपया अपना सुझाव दें",
      name: "कृपया अपना प्रदान करें",
      email: "कृपया अपना email प्रदान करें",
      email2: "कृपया एक मान्य email प्रदान करें",
    },
  },
  en: {
    heading: "Provide your feedback",
    please: "Please help me improve.",
    learning:
      "I'm learning and improving continuously to help you book the train tickets easier and faster!",
    rate: "Please rate your experience with me.",
    thanks: "Thank you for your feedback.",
    submit: "Submit Feedback",
    placeholders: {
      feedback: "Enter your feedback here...",
      name: "Enter the category..",
      email: "Select your options...",
    },
    errors: {
      feedback: "Please provide your feedback",
      name: "Please provide your others",
      email: "Please provide your email",
      email2: "Please Enter a valid email",
    },
  },
  gu: {
    heading: "તમારા સૂચનો આપો.",
    please: "મહેરબાની કરીને મને સુધારવામાં મદદ કરો.",
    learning:
      "હું તમને સરળતાથી અને ઝડપથી ટ્રેનની ટિકિટ બુક કરવામાં મદદ કરવા માટે સતત શીખી રહ્યો છું અને સુધારી રહ્યો છું!",
    rate: "મહેરબાની કરીને મારી સાથેના તમારા અનુભવને મૂલ્યાંકન કરો.",
    thanks: "તમારા સૂચન બદલ આભાર.",
    submit: "સૂચન સબમિટ કરો",
    placeholders: {
      feedback: "તમારું સૂચન અહીં દાખલ કરો...",
      name: "શ્રેણી દાખલ કરો..",
      email: "તમારા વિકલ્પો પસંદ કરો...",
    },
    errors: {
      feedback: "મહેરબાની કરીને તમારું સૂચન આપો.",
      name: "મહેરબાની કરીને તમારું પ્રદાન કરો.",
      email: "મહેરબાની કરીને તમારો ઇમેઇલ પ્રદાન કરો",
      email2: "મહેરબાની કરીને માન્ય ઇમેઇલ પ્રદાન કરો",
    },
  },
};

export default text;
