const translations = {
  en: {
    pnr: "PNR Status",
    class: "Journey Class:",
    quota: "Quota:",
    date: "Booking Date:",
    amount: "Booking Amount:",
    order: "Order #:",
    passengers: "Passengers",
    years: " years",
    male: "Male",
    female: "Female",
    transgender: "Transgender",
    coach: "Coach",
    seat: "Seat",
    berth: "Berth",
    cnf: "Confirmed",
    can: "Cancelled",
    wl: "Waiting",
  },
  hi: {
    pnr: "PNR की स्थिति",
    class: "यात्रा वर्ग:",
    quota: "कोटा:",
    date: "बुकिंग की तारीख:",
    amount: "बुकिंग राशि:",
    order: "आर्डर #:",
    passengers: "यात्रीगण",
    years: " वर्ष",
    male: "पुरुष",
    female: "महिला",
    transgender: "ट्रांसजेंडर",
    coach: "कोच",
    seat: "सीट",
    berth: "बर्थ",
    cnf: "कनफर्म्ड",
    can: "कांसेल्लेड",
    wl: "वेटिंग",
  },
  gu: {
    pnr: "પી. એન. આર. સ્થિતિ",
    class: "મુસાફરીનો વર્ગઃ",
    quota: "ક્વોટાઃ",
    date: "બુકિંગની તારીખઃ",
    amount: "બુકિંગની રકમઃ",
    order: "ઓર્ડર #:",
    passengers: "મુસાફરો",
    years: "વર્ષ",
    male: "માણસ",
    female: "સ્ત્રી",
    transgender: "ટ્રાન્સજેન્ડર",
    coach: "કોચ",
    seat: "બેઠક",
    berth: "જન્મ.",
    cnf: "પુષ્ટિ કરી",
    can: "છુપાયેલું",
    wl: "રાહ જોવાઈ રહી છે",
  },
};

export default translations;
