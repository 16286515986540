const translations = {
  en: {
    book: "Book Ticket",
    refund: "Check Refund Status",
    cancel: "Cancel My Ticket",
    pnr: "Check PNR Status",
    history: "Booking History",
    failed: "My Failed Transactions",
    boarding: "Change My Boarding Station",
    tdr: "File TDR",
  },
  hi: {
    book: "टिकट बुक करो",
    refund: "मुझे रिफंड स्तिथि की जांच करनी है",
    cancel: "टिकट रद्द करो",
    pnr: "PNR की स्थिति दिखाओ",
    history: "बुकिंग इतिहास",
    failed: "मेरी असफल ट्रांसक्शन्स दिखाओ",
    boarding: "मेरा बोर्डिंग स्टेशन बदलो",
    tdr: "TDR फाइल",
  },
  gu: {
    book: "ટિકિટ બુક કરો",
    refund: "મારે રિફંડની સ્થિતિ તપાસવાની છે.",
    cancel: "ટિકિટ રદ કરો",
    pnr: "પી. એન. આર. સ્થિતિ બતાવો",
    history: "બુકિંગનો ઈતિહાસ",
    failed: "મારા નિષ્ફળ વ્યવહારો બતાવો",
    boarding: "મારું બોર્ડિંગ સ્ટેશન બદલો",
    tdr: "ટીડીઆર ફાઇલ",
  },
};

export default translations;
