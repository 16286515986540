import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  .home-bookings {
    margin-top: 8px;
    padding: 15px;
    white-space: nowrap;
  }
`;
export default Container;
