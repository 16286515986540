import styled from "styled-components";

const Container = styled.div`
  width: 98%;
  margin: 0px auto;
  position: relative;
  /* margin-bottom: 20px; */
  /* padding: 12px 15px; */
  border: 1px solid gainsboro;
  border-radius: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  height: 100%;
  padding-bottom: 10px;
  > * {
    line-height: 1;
    font-family: "Montserrat", sans-serif;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    /* margin: 0; */
  }

  .header {
    padding: 10px 15px;
    color: #5a5a5a;
    font-weight: 500;
    border-bottom: 1px solid gainsboro;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .first-column {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .step {
      font-size: 10px;
      font-weight: 500;
      color: #7e7e7e;
      position: absolute;
      right: 10px;
      top: 10px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      row-gap: 8px;
    }
  }
`;

export default Container;
