import {
  faDownload,
  faEye,
  faPrint,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useGetReduxState from "../../../../Hooks/useGetReduxState";
import usePDF from "../../../../Hooks/usePDF";
import Spinner from "../../../../UI/UIComponents/Spinner";

const cross =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/close.png";

const langText = {
  hi: {
    view: "देखें",
    download: "डाउनलोड करें",
    print: "प्रिंट करें",
    share: "शेयर करें",
  },
  en: {
    view: "View",
    download: "Download",
    print: "Print",
    share: "Share",
  },
  gu: {
    view: "દર્શન કરો",

    download: "ડાઉનલોડ કરો",

    print: "પ્રિન્ટ કરો",

    share: "શેર કરો",
  },
};

const Modal = ({ mode, handleClose, data }) => {
  const lang = useGetReduxState().behaviour.lang;
  const render = mode === "TICKET" ? <span>E-Ticket</span> : <span>ERS</span>;

  const {
    handleDownload,
    handlePrint,
    handleShare,
    handleView,
    isShareEnabled,
    isLoading,
  } = usePDF({ template: mode, data: data });

  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: "white",
        bottom: "0px",
        left: "0",
        display: "flex",
        flexDirection: "column",
        right: "0",
        padding: "10px 0px",
        borderTop: "2px solid #e9e9e9",
        borderRadius: "30px",
      }}
    >
      <img
        src={cross}
        style={{
          position: "absolute",
          top: "14px",
          right: "18px",
          width: "12px",
          cursor: "pointer",
        }}
        alt=""
        onClick={handleClose}
      />
      <button
        style={{
          fontSize: "16px",
          border: "none",
          backgroundColor: "white",
          color: "rgb(11, 116, 250)",
          fontWeight: "500",
          padding: "8px",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          columnGap: "6px",
        }}
        onClick={handleView}
      >
        {" "}
        {isLoading.view ? (
          <Spinner
            borderWidth={"2px"}
            activeColor={"#6495ed"}
            inactiveColor={"#f9f9f9"}
            speed={"1s"}
            size={"20px"}
          />
        ) : (
          <FontAwesomeIcon
            icon={faEye}
            style={{
              color: "0b74fa",
              marginRight: "5px",
            }}
          />
        )}
        {langText[lang].view} {render}
      </button>

      <button
        style={{
          fontSize: "16px",
          border: "none",
          backgroundColor: "white",
          color: "rgb(11, 116, 250)",
          fontWeight: "500",
          padding: "8px",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          columnGap: "6px",
        }}
        onClick={handleDownload}
      >
        {" "}
        {isLoading.download ? (
          <Spinner
            borderWidth={"2px"}
            activeColor={"#6495ed"}
            inactiveColor={"#f9f9f9"}
            speed={"1s"}
            size={"20px"}
          />
        ) : (
          <FontAwesomeIcon
            icon={faDownload}
            style={{
              color: "0b74fa",
              marginRight: "5px",
            }}
          />
        )}
        {langText[lang].download} {render}
      </button>

      <button
        style={{
          fontSize: "16px",
          border: "none",
          backgroundColor: "white",
          color: "rgb(11, 116, 250)",
          fontWeight: "500",
          padding: "8px",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          columnGap: "6px",
        }}
        onClick={handlePrint}
      >
        {" "}
        {isLoading.print ? (
          <Spinner
            borderWidth={"2px"}
            activeColor={"#6495ed"}
            inactiveColor={"#f9f9f9"}
            speed={"1s"}
            size={"20px"}
          />
        ) : (
          <FontAwesomeIcon
            icon={faPrint}
            style={{
              color: "0b74fa",
              marginRight: "5px",
            }}
          />
        )}
        {langText[lang].print} {render}
      </button>
      {isShareEnabled && (
        <button
          style={{
            fontSize: "16px",
            border: "none",
            backgroundColor: "white",
            color: "rgb(11, 116, 250)",
            fontWeight: "500",
            padding: "8px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "6px",
          }}
          onClick={handleShare}
        >
          {" "}
          {isLoading.share ? (
            <Spinner
              borderWidth={"2px"}
              activeColor={"#6495ed"}
              inactiveColor={"#f9f9f9"}
              speed={"1s"}
              size={"20px"}
            />
          ) : (
            <FontAwesomeIcon
              icon={faShare}
              style={{
                color: "0b74fa",
                marginRight: "5px",
              }}
            />
          )}
          {langText[lang].share} {render}
        </button>
      )}
    </div>
  );
};

export default Modal;
