import styled from "styled-components";

const Container = styled.div`
  padding: 10px;

  h3 {
    font-weight: normal;
    font-size: 18px;
    text-decoration: underline;
    text-align: center;
    margin-bottom: 14pt;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 10pt;
    margin-top: 10pt;

    color: #000000;

    span {
      font-weight: normal;
    }
  }

  div {
    font-size: 10pt;
  }

  .expand {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    column-gap: 0.8in;
    font-size: 10pt;
    div {
      width: 50%;
    }
  }

  .total {
    span {
      font-weight: bold;
    }
  }

  .supply {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10pt;
  }
`;

export default Container;
