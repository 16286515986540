import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: 15px 15px;

  img {
    width: 180px;
    height: auto;
    display: block;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  p {
    text-align: left;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 400;
    color: tomato;
    cursor: pointer;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }

  input {
    width: 100%;
    font-size: 18px;
    padding: 12px;
    outline: none;

    border-radius: 8px;
    border: 1px solid gray;
  }
`;

export default Container;
