export const ADD_NEW_MESSAGE = "ADD_NEW_USER_MESSAGE";
export const TOGGLE_MESSAGE_LOADER = "TOGGLE_MESSAGE_LOADER";

export const PLAY_AUDIO = "PLAY_AUDIO";
export const STOP_AUDIO = "STOP_AUDIO";
export const DROP_MESSAGES = "DROP_MESSAGES";

// TRAIN
export const SET_TRAIN = "SET_TRAIN";

//Journey
export const SET_JOURNEY = "SET_JOURNEY";
export const SET_SOURCE = "SET_SOURCE";
export const SET_DESTINATION = "SET_DESTINATION";
export const SET_DATE = "SET_DATE";
export const SET_BOARDING = "SET_BOARDING";
export const SET_QUOTA = "SET_QUOTA";

//
export const SET_FAQS = "SET_FAQS";

export const SET_APP_LANGUAGE = "SET_APP_LANGUAGE";
export const SET_QUERY_LANGUAGE = "SET_QUERY_LANGUAGE";

export const SHOW_ALSO_TRY = "SHOW_ALSO_TRY";
export const HIDE_ALSO_TRY = "HIDE_ALSO_TRY";

export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_CXTOKEN = "SET_CXTOKEN";
export const SET_NEXT_CONTEXT = "SET_NEXT_CONTEXT";
export const SET_SESSION_ID = "SET_SESSION_ID";
export const SET_DEVICE_ID = "SET_DEVICE_ID";
export const SET_STATUS = "SET_STATUS";

export const SHOW_LOGIN = "SHOW_LOGIN";
export const HIDE_TEMPLATE = "HIDE_TEMPLATE";

export const SET_CURRENT = "SET_CURRENT";
export const SHOW_TRAINS = "SHOW_TRAINS";

export const SET_PREV_CODE = "SET_PREV_CODE";
export const SET_FALLBACK = "SET_FALLBACK";
export const SET_IS_REFUND = "SET_IS_REFUND";

export const SET_UPCOMING_BOOKINGS = "SET_UPCOMING_BOOKINGS"
// ////////////////////////////////////////////
// SETPASSENGERSINCARD
// SETPASSENGERSINMASTERLIST
// ADDPASSENGERFROMMASTERLIST

// SETADDITIONALPREFERENCES
// SETGSTDETAILS
// SETJOURNEYPREFERNCES

// SETCONFIGURATIONS
// RESETPASSENGERFORM
