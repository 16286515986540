const translations = {
  hi: {
    headerIRCTC: "IRCTC मेंटेनेंस अलर्ट!",
    headerDisha: "AskDISHA मेंटेनेंस अलर्ट!",
    text: "AskDISHA का मेंटेनेंस किया जा रहा है। कृपया कुछ समय बाद पुन: प्रयास करें। असुविधा के लिए खेद है।",
  },
  en: {
    headerIRCTC: "IRCTC Maintainance Alert!",
    headerDisha: "AskDISHA Maintainance Alert!",
    text: "AskDISHA is undergoing maintainance. Please try again after some time. Inconvenience is regretted.",
  },
  gu: {
    headerIRCTC: "આઈઆરસીટીસી જાળવણી ચેતવણી!",
    headerDisha: "આસ્કદિશા જાળવણી ચેતવણી!",
    text: "આસ્કદિશા જાળવવામાં આવી રહી છે. મહેરબાની કરીને થોડા સમય પછી ફરી પ્રયાસ કરો. અસુવિધા બદલ દિલગીર છું.",
  },
};

export default translations;
