import useGetReduxState from "../../../Hooks/useGetReduxState";
import useSendQuery from "../../../Hooks/useSendQuery/useSendQuery";
import { setFullScreen } from "../../../Store/Dispatcher/behaviour";
import Trains from "../../Trains";

import langText from "./translations";
const disha =
  "https://cdn.jsdelivr.net/gh/corover/assets@18June/askdisha-bucket/favi.svg";
const pay =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/paytm.png";

const TimeOver = () => {
  const lang = useGetReduxState().behaviour.lang;
  const { goToHome } = useSendQuery(() => {});

  const bookAgain = () => {
    goToHome();
    setFullScreen({ type: "TRAINS", component: <Trains /> });
  };

  return (
    <div
      style={{
        textAlign: "center",
        position: "absolute",
        top: "50%",
        transform: "translateY(-60%)",
        color: "#9f9f9f",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={disha} alt="" style={{ width: "50px" }} />
        <img src={pay} alt="" style={{ width: "120px", marginLeft: "15px" }} />
      </div>

      <p
        style={{
          margin: 0,
          fontSize: "24px",
          color: "rgb(79,79,79)",
          letterSpacing: "3px",
          wordSpacing: "1px",
          marginTop: "2px",
        }}
      >
        {langText[lang].bookNew.header}
      </p>
      <p
        style={{
          margin: "8px 5px",
          fontSize: "14px",
          color: "rgb(157,157,157)",
          lineHeight: "16px",
        }}
      >
        {langText[lang].bookNew.text}
      </p>
      <p
        style={{
          margin: 0,

          fontSize: "18px",
          color: "rgb(79,79,79)",

          marginTop: "25px",
          marginBottom: "15px",
        }}
      >
        {langText[lang].bookNew.would}
      </p>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        <button
          style={{
            backgroundColor: "white",
            fontSize: "20px",
            color: "#0b74fa",
            border: "2px solid #0b74fa",
            padding: "2px 25px",
            borderRadius: "10px",
            cursor: "pointer",
          }}
          onClick={bookAgain}
        >
          {langText[lang].bookNew.buttons.yes}
        </button>
        <button
          style={{
            backgroundColor: "white",
            fontSize: "20px",
            color: "#0b74fa",
            border: "2px solid #0b74fa",
            padding: "2px 25px",
            borderRadius: "10px",
            cursor: "pointer",
          }}
          onClick={goToHome}
        >
          {langText[lang].bookNew.buttons.no}
        </button>
      </div>
    </div>
  );
};

export default TimeOver;
