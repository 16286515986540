import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  border-bottom: 2px solid black;
  padding: 10px;
  text-align: center;

  .first-row,
  .second-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 12pt;
    line-height: 16pt;
  }

  span {
    font-weight: 600;
  }

  .first-row {
    p {
      font-size: 14pt;
      font-weight: 700;
      color: #0b5ea6;
    }
  }

  .second-row {
    margin-top: 15px;
  }
`;

export default Container;
