import styled from "styled-components";

const Container = styled.div`
  width: 98%;
  margin: 0px auto;
  margin-bottom: 20px;
  /* padding: 12px 15px; */
  border: 1px solid gainsboro;
  border-radius: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  position: relative;
  > * {
    line-height: 1;
    font-family: "Roboto", sans-serif;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .header {
    display: flex;
    column-gap: 6px;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid gainsboro;
    img {
      width: 24px;
    }

    p {
      font-size: 16px;
      color: #323232;
      font-weight: 500;
      margin-bottom: 4px;
    }

    span {
      font-size: 14px;
      color: #7c7c7c;
    }
  }

  .refund-options {
    width: 100%;

    padding: 15px 25px;
  }

  button {
    display: block;
    width: 100%;
    background: #326abb;
    border: none;
    color: white;
    padding: 12px 0px;
    font-size: 16px;
    border-radius: 20px;
    margin-bottom: 10px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export default Container;
