import { useEffect, useState } from "react";

import * as dispatcher1 from "../Store/Dispatcher/behaviour";
import * as dispatcher2 from "../Store/Dispatcher/user";
import { setRecentJourneys } from "../Store/Dispatcher/behaviour";
import { setUserToken as setUserTokenInRedux } from "../Store/Dispatcher/behaviour";
import useGetReduxState from "./useGetReduxState";
import { setLocalData } from "../Store/Dispatcher/behaviour";
import { v4 as uuid } from "uuid";

const usePersistentStorage = () => {
  const [cxtoken, setCxToken] = useState("");
  const [journeys, setJourneys] = useState([]);
  const userTokenInRedux = useGetReduxState().app.userToken;
  const [userToken, setUserToken] = useState("");
  const localData = useGetReduxState().app.local;

  const isInIframe = () => window.location !== window.parent.location;

  useEffect(() => {
    // if (isInIframe()) {
    //   window.parent.postMessage("GET_DISHA_DATA", "*");
    //   window.onmessage = (e) => {
    //     if (e.data?.action === "GET_DISHA_DATA") {
    //       setLocalData(e.data.data);
    //       getDataFromLocalStorage(JSON.parse(e.data.data));
    //     }
    //   };
    // } else {
    //   setLocalData(localStorage.getItem("disha-data"));
    //   getDataFromLocalStorage(JSON.parse(localStorage.getItem("disha-data")));
    // }
  }, []);

  const saveInLocalStorage = (data) => {
    try {
      if (isInIframe()) {
        window.parent.postMessage(
          { action: "SET_DISHA_DATA", data: data },
          "*"
        );
      } else {
        localStorage.setItem("disha-data", data);
      }
    } catch (e) {}
    setLocalData(data);
  };

  const getFromLocalStorage = () => {
    if (!localData) return null;
    return JSON.parse(localData);
  };

  const saveCxToken = (cxtoken) => {
    const data = getFromLocalStorage();
    let j = data && Array.isArray(data.journeys) ? data.journeys : [];
    let u = data && data.usertoken ? data.usertoken : userTokenInRedux;
    // let uuid = data && data.uuid ? data.uuid : "";
    // let mobile = data && data.mobile ? data.mobile : "";
    let newData = { cxtoken: cxtoken, journeys: j, usertoken: u };
    setCxToken(cxtoken);
    saveInLocalStorage(JSON.stringify(newData));
  };

  const saveUserToken = (token, data) => {
    let j = data && Array.isArray(data.journeys) ? data.journeys : [];
    let c = data && data.cxtoken ? data.cxtoken : "";
    let uuid = data && data.uuid ? data.uuid : "";
    let mobile = data && data.mobile ? data.mobile : "";
    // @ts-ignore
    let newData = { cxtoken: c, journeys: j, usertoken: token, uuid, mobile };
    setUserTokenInRedux(token);
    saveInLocalStorage(JSON.stringify(newData));
  };

  const saveJourney = ({ source, destination }) => {
    const data = getFromLocalStorage();

    let j = data && Array.isArray(data.journeys) ? data.journeys : [];
    let c = data && data.cxtoken ? data.cxtoken : "";
    let u = data && data.usertoken ? data.usertoken : userTokenInRedux;

    let newJourneys = [{ source, destination }, ...j];
    newJourneys = newJourneys
      // @ts-ignore
      .filter((j, index) => index === 0 || index === 1)
      .map((journey) => JSON.stringify(journey));
    newJourneys = [...new Set(newJourneys)];
    newJourneys = newJourneys.map((journey) => JSON.parse(journey));

    setRecentJourneys(newJourneys);

    //@ts-ignore
    let newData = { cxtoken: c, journeys: newJourneys, usertoken: u };
    // console.log(newData);
    saveInLocalStorage(JSON.stringify(newData));
  };

  const logout = () => {
    const dishaData = getFromLocalStorage();
    let j =
      dishaData && Array.isArray(dishaData.journeys) ? dishaData.journeys : [];
    let data = { cxtoken: "", journeys: j, usertoken: userTokenInRedux };
    saveInLocalStorage(JSON.stringify(data));
    dispatcher1.setCxToken("");

    dispatcher2.setUser(null);

    const pastDate = new Date(0).toUTCString();
    document.cookie = `udata=; expires=${pastDate}; path=/; samesite=lax; secure`;
  };

  const getDataFromLocalStorage = (data) => {
    let j = data && Array.isArray(data.journeys) ? data.journeys : [];
    let c = data && data.cxtoken ? data.cxtoken : "";
    let u = data && data.cxtoken ? data.usertoken : userTokenInRedux;
    setCxToken(c);
    setJourneys(j);
    setUserToken(u ? u : uuid());
    setUserTokenInRedux(u);
    setRecentJourneys(j);
    return data;
  };

  const savePendingOTP = ({ uuid, mobile }) => {
    const data = getFromLocalStorage();
    let j = data && Array.isArray(data.journeys) ? data.journeys : [];
    let c = data && data.cxtoken ? data.cxtoken : "";
    let u = data && data.usertoken ? data.usertoken : userTokenInRedux;

    let newData = { cxtoken: c, journeys: j, usertoken: u, uuid, mobile };
    saveInLocalStorage(JSON.stringify(newData));
  };

  useEffect(() => {
    getDataFromLocalStorage(JSON.parse(localData));
  }, [localData]);

  return {
    cxtoken,
    journeys,
    userToken,
    saveCxToken,
    saveJourney,
    logout,
    saveUserToken,
    savePendingOTP,
    getFromLocalStorage,
  };
};

export default usePersistentStorage;
