import store from "../Store";

export let Quotas = [
  {
    label: "GENERAL",
    label_hi: "जनरल",
    label_gu: "જનરલ",
    value: "GN",
  },
  {
    label: "LADIES",
    label_hi: "लेडीज",
    label_gu: "મહિલાઓ",
    value: "LD",
  },
  {
    label: "LOWER BERTH / SENIOR CITIZEN",
    label_hi: "लोअर बर्थ/सीनियर सिटी",
    label_gu: "લોઅર બર્થ/સિનિયર સિટીઝન",
    value: "SS",
  },
  {
    label: "TATKAL",
    label_hi: "ततकाल",
    label_gu: "તત્કાલ",
    value: "TQ",
  },
];

let QuotasNames = [
  {
    label: "GENERAL",
    label_hi: "जनरल",
    label_gu: "જનરલ",
    value: "GN",
  },
  {
    label: "LADIES",
    label_hi: "लेडीज",
    label_gu: "મહિલાઓ",
    value: "LD",
  },
  {
    label: "LOWER BERTH / SENIOR CITIZEN",
    label_hi: "लोअर बर्थ/सीनियर सिटी",
    label_gu: "લોઅર બર્થ/સિનિયર સિટીઝન",
    value: "SS",
  },
  {
    label: "TATKAL",
    label_hi: "ततकाल",
    label_gu: "તત્કાલ",
    value: "TQ",
  },
  {
    label: "POOLED QUOTA",
    label_hi: "पूल्ड कोटा",
    label_gu: "લ્ડ ક્વોટા",
    value: "PQ",
  },
];

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getQuotaName = (code) => {
  let lang = store.getState().behaviour.lang;

  let foundArr = QuotasNames.filter((quota) => quota.value === code);
  if (foundArr.length > 0) {
    if (lang === "en") return toTitleCase(foundArr[0].label);
    if (lang === "hi") return foundArr[0].label_hi;
    if (lang === "gu") return foundArr[0].label_gu;
  } else return "";
};
