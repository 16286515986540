import { useEffect, useState } from "react";
import { sendOTPagain } from "../../Api/irctc";
import { sendQuery } from "../../Api/sendQuery";
import { clearTempFlow } from "../../Api/utils";
import { CloseDrawer } from "../../Helpers/EventEmitter";
import { toTitleCase } from "../../Helpers/misc";
import {
  setIrctcDetails,
  setNextContext,
  stopAudio,
} from "../../Store/Dispatcher/behaviour";
import { setCurrentPassengerFormStep } from "../../Store/Dispatcher/passengerForm";
import {
  isAdult,
  isChild,
  isInfant,
} from "../../Templates/PassengerForm/utils";
import useGetReduxState from "../useGetReduxState";
import useSendQuery from "../useSendQuery/useSendQuery";
import { setBookingData } from "../../Store/Dispatcher/passengerForm";
import { makeCxPayload } from "./utils";
import langText from "./translations";
import { setVerifyOTPData } from "../../Store/Dispatcher/passengerForm";
import { setShowBookingSteps } from "../../Store/Dispatcher/passengerForm";
import { setCurrentBookingStep } from "../../Store/Dispatcher/passengerForm";
import { setPaymentData } from "../../Store/Dispatcher/passengerForm";
import { setOtpView } from "../../Store/Dispatcher/passengerForm";
import { on } from "stream";
import ClearFilter from "../../Templates/Trains/NoTrains/ClearFilter";
import { setShowNextSteps } from "../../Store/Dispatcher/passengerForm";
const useBookingFlow = () => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const otp = useGetReduxState().passengerform.otpValue;
  const otpData = useGetReduxState().passengerform.otpData;
  const bookingData = useGetReduxState().passengerform.bookingData;
  const showBookingSteps = useGetReduxState().passengerform.showBookingSteps;
  const paymentData = useGetReduxState().passengerform.paymentData;
  const { sendQueryPayload } = useSendQuery(() => {});

  const currentStep = useGetReduxState().passengerform.currentPassengerFormStep;
  const passengers = useGetReduxState().passengerform.passengers;
  const [error, setError] = useState("");
  const [subError, setSubError] = useState("");
  const irctcConfig = useGetReduxState().passengerform.irctcConfig;
  const preferences = useGetReduxState().passengerform.journeyPreferences;
  const gstDetails = useGetReduxState().passengerform.gstDetails;
  const additionalDetails = useGetReduxState().passengerform.additionalDetails;
  const [loading, setLoading] = useState(false);
  const [oneTimeDone, setOneTimeDone] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);

  const [verfiedOTP, setVerfiedOTP] = useState<any>();
  const train = useGetReduxState().train;

  const initialReviewData = {
    status: "",
    train: {
      source: "",
      destination: "",
      departureDate: "",
      arrivalDate: "",
      departureTime: "",
      arrivalTime: "",
      trainName: "",
      trainNumber: "",
      quota: "",
      class: "",
    },

    passengers: [],
    fares: {
      ctc: "",
      travel: "",
      irctc: "",
      ticket: "",
      total: "",
    },
  };

  const initialBookingData = {
    clientTransactionId: "",
    userId: "",
    txnToken: "",
    paymentAmount: "",
  };

  const isPending = useGetReduxState().utils.isBookingPending;
  const [reviewData, setReviewData] = useState(initialReviewData);
  const irctcDetails = useGetReduxState().app.irctcDetails;
  const [showReview, setShowReview] = useState(false);

  const currentBookingStep =
    useGetReduxState().passengerform.currentBookingStep;
  const getSelectedPassengers = () => {
    const temp = passengers.filter(
      (passenger) => passenger.isSelected === true
    );

    return temp;
  };

  const isAdultChildRatioViolated = () => {
    const selected = getSelectedPassengers();
    const adultsWithBerth = selected.filter(
      (passenger) =>
        isAdult(passenger.age) ||
        (isChild(passenger.age) && passenger.berth !== "")
    );
    const childrenWithoutBerth = selected.filter(
      (passenger) =>
        isInfant(passenger.age) ||
        (isChild(passenger.age) && passenger.berth === "")
    );

    if (
      childrenWithoutBerth.length > adultsWithBerth.length ||
      (childrenWithoutBerth.length == 0 && adultsWithBerth.length == 0)
    )
      return true;
    else return false;
  };

  const getPassengers = () => {
    const selected = getSelectedPassengers();
    return selected.filter(
      (passeng) =>
        !isInfant(passeng.age) || (isInfant(passeng.age) && passeng.berth)
    );
  };

  const getInfants = () => {
    const selected = getSelectedPassengers();
    let p = selected.filter(
      (passeng) => isInfant(passeng.age) && !passeng.berth
    );

    return p;
  };

  const handleNext = () => {
    setCurrentPassengerFormStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentPassengerFormStep(currentStep - 1);
  };

  const handleSubmitPassengers = () => {
    const selected = getSelectedPassengers();
    if (selected.length < 1) {
      setSubError("");
      setError(langText[lang].atleast.error);
      return;
    }

    if (getPassengers().length > irctcConfig.maxPassengers) {
      setSubError(langText[lang].maxPassenger.sub);
      setError(
        `You can select a maximum of ${irctcConfig.maxPassengers} passengers (adults and children).`
      );

      return;
    }

    if (getInfants().length > irctcConfig.maxInfants) {
      setSubError(langText[lang].minInfants.sub);

      setError(
        `You can select a maximum of ${irctcConfig.maxInfants} infants.`
      );
      return;
    }

    if (isAdultChildRatioViolated()) {
      setSubError("");
      setError(langText[lang].ratio.error);
      return;
    }

    handleNext();
  };

  const handleSubmitPreferences = async () => {
    if (preferences.isEmailError) return;
    if (loading) return;
    setLoading(true);
    const payload = makeCxPayload({
      flags: irctcConfig,
      preferences: preferences,
      passengers: passengers,
      gstDetails: gstDetails,
      additionalDetails: additionalDetails,
    });
    setNextContext("ade4a7db-d819-417d-832a-259307fd94c7,10");
    const data = await sendQuery({
      query: "send passengers",
      next_context: "ade4a7db-d819-417d-832a-259307fd94c7,10",
      langCode: "en",
      cxpayload: payload,
      isFallback: false,
    });
    setLoading(false);
    if (data.error) {
      setError(langText[lang].oops.error);
      return;
    }

    if (!data.renderTemplate.data.response) {
      setError(langText[lang].oops.error);
      return;
    }

    if (data.renderTemplate.data.response.errorMessage) {
      setError(data.renderTemplate.data.response.errorMessage);
      return;
    }

    if (!data.renderTemplate.data.response.avlDayList) {
      setError(langText[lang].oops.error);
      return;
    }

    let availabilityStatus = Array.isArray(
      data.renderTemplate.data.response.avlDayList
    )
      ? data.renderTemplate.data.response.avlDayList[0].availablityStatus
      : data.renderTemplate.data.response.avlDayList.availablityStatus;

    if (!availabilityStatus) {
      setError(langText[lang].oops.error);
      return;
    }

    if (
      !availabilityStatus.toLowerCase().includes("not") &&
      isNaN(
        parseFloat(data.renderTemplate.data.response.totalCollectibleAmount)
      )
    ) {
      setError(langText[lang].oops.error);
      return;
    }

    if (availabilityStatus.toLowerCase().includes("not")) {
      setError(langText[lang].runout.error);
      return;
    }
    setShowReview(true);
    let response = data.renderTemplate.data.response;

    let rData = {
      status: availabilityStatus,

      train: {
        source: preferences.boardingStation,
        destination: train.destination,
        departureDate: train.departureDate,
        arrivalDate: train.arrivalDate,
        departureTime: train.departureTime,
        arrivalTime: train.arrivalTime,
        trainName: toTitleCase(response.trainName),
        trainNumber: response.trainNo,
        quota: irctcConfig.quota,
        class: irctcConfig.trainClass,
      },

      passengers: getSelectedPassengers(),
      fares: {
        ctc: "11.80",
        travel: (
          parseFloat(response.travelInsuranceCharge) +
          parseFloat(response.travelInsuranceServiceTax)
        )
          .toFixed(2)
          .toString(),
        irctc: (
          parseFloat(response.wpServiceCharge) +
          parseFloat(response.wpServiceTax)
        )
          .toFixed(2)
          .toString(),
        ticket: parseFloat(response.totalFare).toFixed(2).toString(),
        total: (parseFloat(response.totalCollectibleAmount) + 11.8)
          .toFixed(2)
          .toString(),
      },
    };

    setReviewData(rData);
  };

  const submitReview = async () => {
    if (loading) return;
    setLoading(true);
    setNextContext("ade4a7db-d819-417d-832a-259307fd94c7,11");
    setPaymentData(reviewData.fares.total);
    const data = await sendQuery({
      query: "send fare",
      next_context: "ade4a7db-d819-417d-832a-259307fd94c7,11",
      langCode: "en",
      cxpayload: { paymentAmount: reviewData.fares.total },
      isFallback: false,
    });
    setLoading(false);

    if (data && data) {
      setOtpView(true);
      setShowReview(false);
    }
  };

  const backToReview = () => {
    // setBookingData(initialBookingData);
    setShowBookingSteps(false);
    setCurrentBookingStep(0);
    setShowReview(true);
    setOtpView(false);
    setVerifyOTPData("")
  };

  const nextStepsSubmit = () => {
    setOneTimeDone(true);
    setShowNextSteps(false);
    if (irctcConfig.quota === "TQ") {
      setShowCaptcha(true);
    } else {
      setShowBookingSteps(true);
    }
  };

  const handleCaptchaSubmit = () => {
    setShowCaptcha(false);
    setShowBookingSteps(true);
  };

  const handlePaymentDone = async () => {
    setNextContext("ade4a7db-d819-417d-832a-259307fd94c7,14");
    const data = await sendQuery({
      query: "payment success",
      next_context: "ade4a7db-d819-417d-832a-259307fd94c7,14",
      isFallback: false,
      langCode: "en",
      cxpayload: {
        resend: "N",
        otp: otp,
      },
    });

    if (data.data) {
      // setMessage(data.data.status);
      setCurrentBookingStep(2);
      setTimeout(() => {
        // final otp verfied and redirect to home
        clearTempFlow();
        stopAudio();
        sendQueryPayload({
          data: data.data,
          query: "BOOKING_SUCCESS",
          inputType: "",
        });
      }, 5000);
    } else {
      setError(langText[lang].oops.error);
      return;
    }
  };

  const resendOTP = async () => {
    const data = await sendOTPagain();

    if (!data) {
      setError(langText[lang].oops.error);
      return false;
    } else {
      return true;
    }
  };

  const handleVerifyOTP = async () => {
    setLoading(true);

    const data = await sendQuery({
      query: "send fare",
      next_context: "ade4a7db-d819-417d-832a-259307fd94c7,12",
      langCode: "en",
      cxpayload: { otp: otp },
      isFallback: false,
    });
    setLoading(false);
    setVerifyOTPData(data);
    // setShowNextSteps(true);
    if (data && data.data === "true") {
      setOtpView(false);
      setVerifyOTPData("")
      const data = await sendQuery({
        query: "send fare",
        next_context: "ade4a7db-d819-417d-832a-259307fd94c7,13",
        langCode: "en",
        cxpayload: { paymentAmount: paymentData },
        isFallback: false,
      });

      let det = data.renderTemplate.data;
      if (
        !det.txnToken ||
        !det.userId ||
        !det.paymentAmount ||
        !det.clientTransactionId
      ) {
        setError(langText[lang].oops.error);
        return;
      }
      setIrctcDetails({ ...irctcDetails, txnId: det.clientTransactionId });
      setBookingData(data);

      CloseDrawer();
      setCurrentBookingStep(1);
      if (oneTimeDone) {
        setShowBookingSteps(true);
      } else setShowNextSteps(true);
    }
  };

  const bookTicket = async (otp) => {
    if (loading) return;
    setLoading(true);

    setNextContext("ade4a7db-d819-417d-832a-259307fd94c7,13");
    const data = await sendQuery({
      query: "Verify OTP",
      next_context: "ade4a7db-d819-417d-832a-259307fd94c7,13",
      isFallback: false,
      langCode: "en",
      cxpayload: {
        otp: otp,
        lang: "en",
      },
    });
    setLoading(false);

    if (data.error) {
      return false;
    }

    if (!data || data?.status === 406) {
      return false;
    }

    //in correct OTP
    if (
      data.hasOwnProperty("data") &&
      data.data.hasOwnProperty("message") &&
      data.data.message === "UNAUTHORIZED"
    ) {
      return false;
    }

    if (data.hasOwnProperty("error") && data.hasOwnProperty("status")) {
      return false;
    }

    //CORRECT OTP AND BOOKING FAILUR
    if (
      data.data.hasOwnProperty("bookingErrorMessage") &&
      data.data.bookingErrorMessage !== ""
    ) {
      clearTempFlow();
      stopAudio();
      sendQueryPayload({
        data: {
          message: data.data.bookingErrorMessage,
        },
        query: "BOOKING_FAILURE",
        inputType: "",
      });
      return true;
    }

    if (data.data.errorMessage && data.data.errorMessage != "") {
      clearTempFlow();
      stopAudio();
      sendQueryPayload({
        data: {
          message: data.data.errorMessage.split("- (")[0],
        },
        query: "BOOKING_FAILURE",
        inputType: "",
      });
      return true;
    }

    //CORRECT OTP AND BOOKING SUCCESS
    clearTempFlow();
    stopAudio();
    sendQueryPayload({
      data: data.data,
      query: "BOOKING_SUCCESS",
      inputType: "",
    });
  };

  const bookTicketPassword = (data) => {
    setNextContext("ade4a7db-d819-417d-832a-259307fd94c7,13");
    clearTempFlow();
    stopAudio();

    if (data.status === "success") {
      sendQueryPayload({
        data: data.data,
        query: "BOOKING_SUCCESS",
        inputType: "",
      });
    } else if (data.status === "failed") {
      sendQueryPayload({
        data: {
          message: "SSO_FAILED",
        },
        query: "BOOKING_PENDING",
        inputType: "",
      });
    }
  };

  const handleErrorClose = () => {
    setError("");
    setSubError("");
  };
  useEffect(() => {
    setShowBookingSteps(isPending);
    setCurrentBookingStep(0);
  }, [setCurrentBookingStep]);

  return {
    currentPassengerFormStep: currentStep,
    loading,
    error,
    subError,
    showReview,
    reviewData,

    bookingData,
    showCaptcha,
    handlePassengerFormStepBack: handleBack,
    handlePassengerFormStepNext: handleNext,
    handleSubmitPassengers,
    handleSubmitPreferences,
    handleErrorClose,
    handleCloseReview: () => {
      setShowReview(false);
    },
    submitReview,
    nextStepsSubmit,
    backToReview,
    bookTicket,
    resendOTP,
    handlePaymentDone,
    handleCaptchaSubmit,
    bookTicketPassword,
    otpData,

    handleVerifyOTP,
    verfiedOTP,
    setOtpView,
    setShowReview,
  };
};

export default useBookingFlow;
