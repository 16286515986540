import Container from "./styles";
const PaymentDetails = ({ fares, orderId, qr }) => {
  return (
    <Container>
      <div className="acronyms">
        <p>Acronyms:</p>
        <div>
          <p>RLWL: REMOTE LOCATION WAITLIST</p>
          {/* <p>CNF: CONFIRM</p> */}
        </div>
        <div>
          <p>PQWL: POOLED QUOTA</p>

          {/* <p>WAITLIST RSWL: ROAD-SIDE WAITLIST</p> */}
        </div>
        <div>
          {/* <p>PQWL: POOLED QUOTA</p> */}

          <p>RSWL: ROAD-SIDE WAITLIST</p>
        </div>
      </div>
      <p style={{ marginTop: "6pt", fontWeight: "bold" }}>
        TRANSACTION ID: {orderId}
      </p>
      <p className="recovers">
        IR recovers only 57% of cost of travel on an average.
      </p>

      <div className="flex">
        <div className="payment">
          <h4>Payment Details</h4>
          <div className="flex">
            <div>
              <p>Ticket Fare:</p>
              <p>IRCTC Convenience Fee (Incl. of GST):</p>
              <p>Catering Charges (Incl. of GST):</p>

              <p>Travel Insurance Premium (Incl. of GST):</p>
              <p>Chatbot Ticket Charges (Incl. of GST):</p>
              {/* <p>PG Charges:</p> */}
              <p>Total Fare (all inclusive):</p>
            </div>
            <div>
              <p>₹ {fares.ticket}</p>
              <p>₹ {fares.convenience}</p>
              <p>₹ {fares.catering}</p>

              <p>₹ {fares.insurance}</p>
              <p>₹ {fares.ctc}</p>
              {/* <p>₹ 9.56</p> */}
              <p>₹ {fares.total}</p>
            </div>
          </div>
          <p style={{ fontSize: "9pt" }}>
            PG Charges as applicable (Additional)
          </p>
        </div>
        <img src={qr} style={{ width: "100pt", height: "100pt" }} alt="" />
      </div>

      <div className="other">
        <p>
          IRCTC Convenience Fee & Chatbot Ticket Charges are charged per
          e-ticket irrespective of no. of passengers on the ticket.
        </p>
        <p>
          The printed Departure and Arrival Times are liable to change. Please
          check correct departure, arrival from Railway Station Enquiry or Dial
          139 or SMS RAIL to 139.
        </p>
        <p>No Linen will be provided in AC Economy (3E) class.</p>
      </div>
    </Container>
  );
};

export default PaymentDetails;
