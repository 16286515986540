import { useState } from "react";
import { CloseDrawer } from "../../../../../Helpers/EventEmitter";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import {
  setGstDetails,
  setJourneyPreferences,
} from "../../../../../Store/Dispatcher/passengerForm";
import GstDetails from "./GstDetails/GstDetails";
import langText from "../translations";

const gst =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/gst2-refactored.png";
const info =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/info-refactored.png";

const GST = () => {
  const [isOpen, setIsOpen] = useState(false);
  const pref = useGetReduxState().passengerform.journeyPreferences;
  const gstSelected = pref.gstSelected;
  const [text, setText] = useState(false);
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  const handleSubmit = (data) => {
    setGstDetails(data);
    setJourneyPreferences({ ...pref, gstSelected: true });
    CloseDrawer();
  };

  const handleOpen = () => {
    if (!gstSelected) {
      setIsOpen(true);
    } else setJourneyPreferences({ ...pref, gstSelected: false });
  };

  return (
    <div
      className="additional"
      style={{ padding: "10px 15px", paddingBottom: "4px" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            columnGap: "5px",
          }}
        >
          <img src={gst} style={{ width: "20px" }} alt="" />
          <p
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "rgb(71 71 71)",
            }}
          >
            {langText[lang].gst.text}{" "}
            <span style={{ fontSize: "12px", color: "gray" }}>
              ({langText[lang].optional})
            </span>
          </p>
          <img
            onClick={() => setText((prev) => !prev)}
            style={{ width: "16px", cursor: "pointer" }}
            src={info}
            alt=""
          />
        </div>

        <div
          style={{
            width: "50px",
            height: "18px",
            backgroundColor: gstSelected ? "#b9daf4" : "#ddd8d8",
            borderRadius: "15px",
            position: "relative",
            cursor: "pointer",
            transition: "0.3s all ease-in-out",
          }}
          onClick={handleOpen}
        >
          <span
            style={{
              display: "block",
              backgroundColor: "rgb(50, 106, 187)",
              height: "18px",
              width: "18px",
              borderRadius: "50%",
              position: "absolute",
              left: !gstSelected ? "0%" : "100%",
              transform: gstSelected && "translateX(-100%)",
              transition: "0.3s all ease-in-out",
            }}
          ></span>
        </div>
      </div>

      {text && (
        <span
          style={{
            fontSize: "12px",
            lineHeight: 1,
            color: "slategray",
            // marginLeft: "25px",
          }}
        >
          {langText[lang].gst.subtext}
        </span>
      )}

      {isOpen && (
        <GstDetails
          handleSubmit={handleSubmit}
          handleCloseDrawer={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default GST;
