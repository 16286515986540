import useGetReduxState from "../useGetReduxState";
import useSendQuery from "../useSendQuery/useSendQuery";
import langText from "./translations";

const useButtonQueries = () => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const queries = langText[lang];
  const inputType = "";
  const { sendQueryPayload, refresh } = useSendQuery(() => {});

  const useBook = () => {
    refresh();
    sendQueryPayload({ query: queries.book, inputType: inputType, data: null });
  };

  const useCancel = () => {
    refresh();
    sendQueryPayload({
      query: queries.cancel,
      inputType: inputType,
      data: null,
    });
  };

  const usePNR = () => {
    refresh();
    sendQueryPayload({
      query: queries.pnr,
      inputType: inputType,
      data: null,
    });
  };

  const useRefund = () => {
    refresh();
    sendQueryPayload({
      query: queries.refund,
      inputType: inputType,
      data: null,
    });
  };

  const useBookingHistory = () => {
    refresh();
    sendQueryPayload({
      query: queries.history,
      inputType: inputType,
      data: null,
    });
  };

  const useChangeBoarding = () => {
    refresh();
    sendQueryPayload({
      query: queries.boarding,
      inputType: inputType,
      data: null,
    });
  };

  const useFileTDR = () => {
    refresh();
    sendQueryPayload({ query: queries.tdr, inputType: inputType, data: null });
  };

  const useCheckFailed = () => {
    refresh();
    sendQueryPayload({
      query: queries.failed,
      inputType: inputType,
      data: null,
    });
  };

  return {
    useBook,
    useBookingHistory,
    useCancel,
    useChangeBoarding,
    useCheckFailed,
    useFileTDR,
    useRefund,
    usePNR,
  };
};

export default useButtonQueries;
