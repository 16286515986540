import useGetReduxState from "../../../Hooks/useGetReduxState";
import Container from "./styles";
import langText from "./translations";

const time =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/time.svg";
const name =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/name.svg";
const fast =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/fast.svg";

const Content = ({ sortMode, handleSelect }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  return (
    <Container>
      <div
        style={{
          border:
            sortMode === 1 ? "1px solid #0b74fa" : "1px solid transparent",
          background: sortMode === 1 ? "#f0f8ff" : "white",
        }}
        onClick={() => handleSelect(1)}
      >
        <img src={name} alt="" style={{ width: "24px" }} />
        <p>{langText[lang].name}</p>
      </div>
      <div
        style={{
          border:
            sortMode === 2 ? "1px solid #0b74fa" : "1px solid transparent",
          background: sortMode === 3 ? "#f0f8ff" : "white",
        }}
        onClick={() => handleSelect(2)}
      >
        <img src={time} style={{ width: "44px" }} alt="" />
        <p>{langText[lang].arrival}</p>
      </div>
      <div
        style={{
          border:
            sortMode === 3 ? "1px solid #0b74fa" : "1px solid transparent",
          background: sortMode === 3 ? "#f0f8ff" : "white",
        }}
        onClick={() => handleSelect(3)}
      >
        <img src={time} style={{ width: "44px" }} alt="" />
        <p>{langText[lang].departure}</p>
      </div>
      <div
        style={{
          border:
            sortMode === 4 ? "1px solid #0b74fa" : "1px solid transparent",
          background: sortMode === 4 ? "#f0f8ff" : "white",
        }}
        onClick={() => handleSelect(4)}
      >
        <img src={fast} alt="" style={{ width: "44px" }} />
        <p>{langText[lang].fastest}</p>
      </div>
    </Container>
  );
};

export default Content;
