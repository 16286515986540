import { useEffect } from "react";
import ReactDOM from "react-dom";
import Container from "./styles";

const Alert = ({
  header,
  content,
  secondaryText,
  primaryText,
  handleSecondaryClick,
  handlePrimaryClick,
}) => {

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("disha-alert")?.focus();
    });
  }, []);

  return ReactDOM.createPortal(
    <Container
      tabIndex={1}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handlePrimaryClick();
        }
      }}
      id="disha-alert"
    >
      <div>
        {header && <h1>{header}</h1>}

        <p>{content}</p>
        <div className="footer">
          {secondaryText && (
            <button className="secondary" onClick={handleSecondaryClick}>
              {secondaryText}
            </button>
          )}

          <button className="primary" onClick={handlePrimaryClick}>
            {primaryText}
          </button>
        </div>
      </div>
    </Container>,
    document.getElementById("corover-body")
  );
};

export default Alert;
