import useGetReduxState from "../../../Hooks/useGetReduxState";

const Header = () => {
  const lang = useGetReduxState().behaviour.lang;
  if (lang === "en") return <div>Select Journey Quota</div>;
  if (lang === "hi") return <div>यात्रा का कोटा चुनें</div>;
  if (lang === "gu") return <div>મુસાફરીનો ક્વોટા પસંદ કરો</div>;
};

export default Header;
