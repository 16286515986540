import useGetReduxState from "../../../Hooks/useGetReduxState";
import langText from "./translations";

const Header = () => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  return <div>{langText[lang].header}</div>;
};

export default Header;
