import { faCaretDown, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useEffect, useState } from "react";
import { getBerthName } from "../../../../../Helpers/getBerthNames";
import useGetReduxState from "../../../../../Hooks/useGetReduxState";
import { setPassengers } from "../../../../../Store/Dispatcher/passengerForm";
import { CheckBox } from "../../../../../UI/UIComponents/Checkbox";
import Alert from "../../../../../UI/UIComponents/ErrorAlert/Alert";
import {
  getAge,
  getEntity,
  getFoodChoice,
  getGender,
  isAdult,
  isChild,
  isInfant,
} from "../../../utils";
import AddPassenger from "../AddPassenger/AddPassenger";
import ChoosePreference from "../ChoosePreference/ChoosePreference";
import langText from "../translations";

import Container from "./styles";
import ClearFilter from "../../../../Trains/NoTrains/ClearFilter";
const seat =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/seat-refactored.png";
const Passenger = ({ passenger }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;
  const [selected, setSelected] = useState(passenger.isSelected);
  const [openEdit, setOpenEdit] = useState(false);
  const irctcConfig = useGetReduxState().passengerform.irctcConfig;
  const [show, setShow] = useState("");
  const [isAlloted, setAlloted] = useState(
    passenger.isSelected && passenger.berth !== ""
  );
  const passengers = useGetReduxState().passengerform.passengers;

  
  if (!irctcConfig.applicableFoodChoices.includes(passenger.food)) {
    passenger.food = "";
  }
  const isLadiesQuotaViolated = () => {
    const quota = irctcConfig.quota;

    if (quota === "LD" && passenger.age > 11 && passenger.gender !== "F")
      return true;

    return false;
  };

  useEffect(() => {
    if (selected) {
      if (
        isChild(passenger.age) &&
        irctcConfig.childBerthMandatory &&
        passenger.berth === "" &&
        irctcConfig.showFoodChoice &&
        passenger.food === ""
      ) {
        setShow("BOTH");
        return;
      }

      if (
        isChild(passenger.age) &&
        irctcConfig.childBerthMandatory &&
        passenger.berth === ""
      ) {
        setShow("BERTH");
        return;
      }

      if (
        selected &&
        irctcConfig.showFoodChoice &&
        !passenger.food &&
        (!isInfant(passenger.age) || isAlloted)
      ) {
        setShow("FOOD");
        return;
      }
    }
  }, [selected]);

  useEffect(() => {
    if (
      isAlloted &&
      isInfant(passenger.age) &&
      irctcConfig.showFoodChoice &&
      !passenger.food
    ) {
      setShow("FOOD");
    }
  }, [isAlloted]);

  const showBerth = () => {
    if (isAdult(passenger.age)) return true;

    if (isChild(passenger.age) && !irctcConfig.childBerthMandatory && isAlloted)
      return true;

    if (irctcConfig.childBerthMandatory && passenger.berth) return true;

    if (isInfant(passenger.age) && isAlloted) return true;

    return false;
  };

  const showAllotBerth = () => {
    return (
      (isChild(passenger.age) && !irctcConfig.childBerthMandatory) ||
      isInfant(passenger.age)
    );
  };

  const handleAllot = () => {
    let temp = passengers;
    const ind = passengers.indexOf(passenger);
    let updatedPassenger = null;

    if (isAlloted) {
      updatedPassenger = { ...passenger, berth: "" };
    } else {
      updatedPassenger = { ...passenger, berth: "NBC" };
    }
    temp[ind] = updatedPassenger;
    setPassengers([...temp]);
    setAlloted((prev) => !prev);
  };

  const [error, setError] = useState("");

  const handleSelect = () => {
    if (isLadiesQuotaViolated()) {
      setError(langText[lang].error);
      return;
    }
    let temp = passengers;
    const ind = passengers.indexOf(passenger);
    let updatedPassenger = null;

    updatedPassenger = { ...passenger, isSelected: !selected };

    temp[ind] = updatedPassenger;
    setPassengers([...temp]);
    setSelected((prev) => !prev);
  };

  return (
    <Container selected={selected}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <CheckBox
            label={""}
            boxSize="18px"
            boxRadius={"3px"}
            fontColor={"#424242"}
            fontSize={"15px"}
            fontWeight={"400px"}
            lineHeight={"16px"}
            margin={"2px 0px 0px 0px"}
            space={"8px"}
            isChecked={selected}
            onCheck={handleSelect}
          />
          <div onClick={handleSelect} style={{ width: "100%" }}>
            <h4>{passenger.name}</h4>

            <div className="second-row">
              <span>{getEntity(passenger.age)}</span>
              <span>|</span>
              <span>{getGender(passenger.gender)}</span>
              <span>|</span>
              <span>{getAge(passenger.age)}</span>
              {irctcConfig.showFoodChoice && passenger.food && (
                <React.Fragment>
                  <span>|</span>
                  <span>{getFoodChoice(passenger.food)}</span>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        <FontAwesomeIcon
          icon={faPen}
          style={{ marginRight: "5px", fontSize: "14px", cursor: "pointer" }}
          onClick={() => {
            setOpenEdit(true);
          }}
        />
      </div>

      {selected && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "12px",
            alignItems: "flex-start",
            cursor: "pointer",
            marginTop: "5px",
          }}
        >
          {showAllotBerth() ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "12px",
                alignItems: "center",
                cursor: "pointer",
                flexShrink: 0,
                marginTop: "8px",
                marginLeft: "22px",
              }}
              onClick={handleAllot}
            >
              <span
                style={{
                  width: "15px",
                  height: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: isAlloted ? "#2a69b8" : "#dedcdc",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              >
                {isAlloted && (
                  <span
                    style={{
                      width: "7px",
                      height: "7px",
                      display: "block",

                      backgroundColor: "white",
                      borderRadius: "50%",
                    }}
                  ></span>
                )}
              </span>

              <p
                style={{
                  fontSize: "11px",
                  color: "#5c5c5c",
                  fontWeight: "500",
                }}
              >
                {langText[lang].allot}
              </p>
            </div>
          ) : (
            <p
              style={{
                flexShrink: "0",
                marginLeft: "25px",
                fontSize: "11px",
                color: "#5c5c5c",
                fontWeight: "500",
                marginTop: "8px",
              }}
            >
              {langText[lang].berth}:{" "}
            </p>
          )}

          {showBerth() && (
            <div className="select-berth" onClick={() => setShow("BERTH")}>
              {/* <p>Berth:</p> */}
              <div>
                <div className="berth">
                  <img src={seat} alt="" />
                  <p>{getBerthName(passenger.berth)}</p>
                </div>

                <FontAwesomeIcon
                  icon={faCaretDown}
                  style={{
                    fontSize: "12px",
                    color: "#45484a",
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {error !== "" && (
        <Alert error={error} suberror={""} handleClose={() => setError("")} />
      )}
      {openEdit && (
        <AddPassenger
          handleCloseDrawer={() => setOpenEdit(false)}
          passengerProp={passenger}
          isInMasterData={false}
          isAlloted={isAlloted}
        />
      )}
      {show !== "" && (
        <ChoosePreference
          mode={show}
          passenger={passenger}
          handleCloseDrawer={() => {
            setShow("");
          }}
        />
      )}
    </Container>
  );
};

export default Passenger;
