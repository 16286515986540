const translations = {
  hi: {
    header: "विकल्प",
    subtext: "नीचे दिए गए विकल्पों में से एक चुनें",
    button: {
      cancelled: "रद्द टिकट के लिए",
      tdr: "TDR के लिए",
      failed: "असफल ट्रांसक्शन के लिए",
    },
    query: {
      cancelled: "टिकट रद्द करना",
      tdr: "टीडीआर",
      failed: "असफल ट्रांसक्शन्स",
    },
  },
  en: {
    header: "Options",
    subtext: "Choose one of the options below",
    button: {
      cancelled: "For a Cancelled Ticket",
      tdr: "For a TDR",
      failed: "For a Failed Transaction",
    },
    query: {
      cancelled: "Ticket Cancellation",
      tdr: "TDR",
      failed: "For a Failed Transaction",
    },
  },
  gu: {
    header: "વિકલ્પો",
    subtext: "નીચે આપેલા વિકલ્પોમાંથી એક પસંદ કરો.",
    button: {
      cancelled: "રદ થયેલી ટિકિટ માટે",
      tdr: "ટીડીઆર માટે",
      failed: "નિષ્ફળ વ્યવહાર માટે",
    },
    query: {
      cancelled: "ટિકિટ રદ કરવી",
      tdr: "ટીડીઆર",
      failed: "નિષ્ફળ વ્યવહારો",
    },
  },
};

export default translations;
