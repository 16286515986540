const getStatus = (status) => {
  let check = status.toLowerCase();
  if (check.includes("cnf")) return "CNF";

  if (check.includes("rac")) return "CNF";

  return "WL";
};

export const getGender = (code) => {
  return code == "M" ? "Male" : code == "F" ? "Female" : "Transgender";
};

export const getColor = (status) => {
  if (status === "CNF") return { bg: "#e0f9e0", color: "green" };
  if (status === "RAC") return { bg: "#e0f9e0", color: "green" };
  else return { bg: "#ffe0e0", color: "red" };
};

export const getMessage = (status) => {
  if (status === "CNF")
    return "Coach/Berth Number will be available after chart preparation.";
  if (status === "RAC")
    return "Coach/Berth Number will be available after chart preparation.";
  else return "Coach/Berth Number will be available after chart preparation.";
};

const isAllotted = (passenger) => {
  if (
    passenger.coach &&
    passenger.berth &&
    passenger.seat &&
    passenger.seat !== "0"
  )
    return true;
  return false;
};

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getBookingStatus = (passengers) => {
  let status = "";
  let confirmed = [];
  let waiting = [];
  let allotted = false;
  for (let passenger of passengers) {
    let passengerParse = {
      name: toTitleCase(passenger.passengerName),
      gender: passenger.passengerGender,
      age: passenger.passengerAge,
      status: passenger.currentStatus,
      berth: passenger.currentBerthChoice,
      seat: passenger.currentBerthNo,
      coach: passenger.currentCoachId,
    };

    if (getStatus(passenger.currentStatus) === "WL")
      waiting.push(passengerParse);
    else confirmed.push(passengerParse);
  }
  if (confirmed.length > 0 && waiting.length > 0) status = "PARTIAL";
  else if (confirmed.length > 0) status = "CNF";
  else status = "WL";

  if (confirmed.length > 0) {
    allotted = isAllotted(confirmed[0]);
  }

  return {
    bookingStatus: status, //CNF | Partial | WL
    confirmed: confirmed,
    waiting: waiting,
    isAllotted: allotted,
  };
};
