const translations = {
  en: {
    like: "Thank you for your feedback",
  },
  hi: {
    like: "आपकी प्रतिक्रिया के लिए आपका धन्यवाद",
  },
  gu: {
    like: "તમારા પ્રતિભાવ બદલ આભાર.",
  },
};

export default translations;
