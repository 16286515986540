import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f4f4f4;
  max-height: calc(100% - 112px);
  overflow: auto;
`;
export default Container;
