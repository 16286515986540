import Container from "./styles";
const Ls = require("../../../Assets/Local/ls_2024.png");

const Instructions = () => {
  return (
    <Container>
      <h4 style={{ marginBottom: "6pt" }}>INSTRUCTIONS:</h4>
      <p>
        1. Prescribed Original ID proofs are:- Voter Identity Card / Passport /
        PAN Card / Driving License / Photo ID card issued by Central / State
        Govt./ Public Sector Undertakings of State / Central Government
        ,District Administrations , Municipal bodies and Panchayat
        Administrations which are having serial number / Student Identity Card
        with photograph issued by recognized School or College for their
        students / NationalizedBank Passbook with photograph /Credit Cards
        issued by Banks with laminated photograph/Unique Identification Card
        "Aadhaar", m-Aadhaar, eAadhaar. /Passenger showing the Aadhaar/Driving
        Licence from the "Issued Document" section by logging into his/her
        DigiLocker account considered as valid proof of identity. (Documents
        uploaded by the user i.e. the document in "Uploaded Document" section
        will not be considered as a valid proof of identity).
      </p>
      <p>
        2. PNRs having fully waitlisted status will be dropped and automatic
        refund of the booking amount shall be credited to the account used for
        payment for booking of the ticket. Fully waitlisted e-ticket are not
        allowed to board the train. However, the names of PARTIALLY waitlisted/
        confirmed and RAC ticket passenger will appear in the chart and will be
        allowed to board the train.
      </p>
      <p>
        3. Passengers travelling on a fully waitlisted e-ticket will be treated
        as Ticketless.
      </p>
      <p>
        4. Obtain certificate from the TTE /Conductor in case of (a) PARTIALLY
        waitlisted e-ticket when LESS NO. OF PASSENGERS travel, (b)A.C FAILURE,
        (c)TRAVEL IN LOWER CLASS. This original certificate must be sent to GGM
        (IT), IRCTC, Internet Ticketing Centre, IRCA Building, State Entry Road,
        New Delhi-110055 after filing TDR online within prescribed time for
        claiming refund.
      </p>
      <p>
        5. In case, on a party e-ticket or a family e-ticket issued for travel
        of more than one passenger, some passengers have confirmed reservation
        and others are on RAC or waiting list, full refund of fare, less
        clerkage, shall be admissible for confirmed passengers also subject to
        the condition that the ticket shall be cancelled online or online TDR
        shall be filed for all the passengers upto thirty minutes before the
        scheduled departure of the train.
      </p>

      <p>
        6. In case of train cancellation on its entire run, full refund is
        granted automatically by the system. However, if the train is cancelled
        partially on its run or diverted and not touching boarding/destination
        station, passengers are required to file online TDR within 72 hours of
        scheduled departure of the train from passengers boarding station.
      </p>

      <p>
        7. Never purchase e-ticket from unauthorized agents or persons using
        their personal IDs for commercial purposes. Such tickets are liable to
        be cancelled and forfeited without any refund of money, under section
        (143) of the Indian Railway Act 1989. List of authorized agents are
        available on www.irctc.com E-Ticket Agent Locator.
      </p>
      <p>
        8. For detail, Rules, Refund rules, Terms & Conditions of E-Ticketing
        services, Travel Insurance facility etc. Please visit{" "}
        <a href="https://www.irctc.co.in">www.irctc.co.in</a>{" "}
      </p>
      <p>
        9. While booking this ticket, you have agreed of having read the Health
        Protocol of Destination State of your travel. You are again advised to
        clearly read the Health Protocol advisory of destination state before
        start of your travel and follow them properly.
      </p>
      <p>
        10. The FIR forms are available with on board ticket checking staff,
        train guard and train escorting RPF/GRP staff.
      </p>
      <p>
        11. Variety of meals available in more than 1500 trains. For delivery of
        meal of your choice on your seat log on to www.ecatering.irctc.co.in or
        call 1323 Toll Free. For any suggestions/complaints related to Catering
        services, contact Toll Free No. 1800-111-321 (07.00 hrs to 22.00 hrs).
      </p>
      <p>
        12. Cancellations for E-tickets booked through chatbot are permitted
        through chatbot only.
      </p>

      <p>13. Chatbot Ticket Charges (CTC) - ₹10.00 + GST </p>

      <p>
        14. National Consumer Helpline (NCH) Toll Free Number: 1800-11-400 or
        14404
      </p>
      <p>
        15. You can book unreserved ticket from UTS APP or ATVMs (Automatic
        Ticket Vending Machines) located in Railway Stations.
      </p>
      <p style={{ margin: "5pt", textAlign: "center" }}>
        Contact us on: - <a href="mailto:care@irctc.co.in">care@irctc.co.in</a>{" "}
        OR 24*7 Hrs Customer Support at 0755-6610661, 0755-4090600
      </p>
      <div className="logos">
          <img src={Ls} alt="" className="center" />
        </div>
    </Container>
  );
};

export default Instructions;
