const translations = {
  hi: {
    changed: "आपका बोर्डिंग स्टेशन बदल दिया गया है।",
    boarding: "आपका नया बोर्डिंग स्टेशन है:",
    time: "बोर्डिंग का नया समय और तारीख:",
  },
  en: {
    changed: "Your boarding station has been changed.",
    boarding: "Your New Boarding Station is:",
    time: "New Boarding Time and Date:",
  },
  gu: {
    changed: "તમારું બોર્ડિંગ સ્ટેશન બદલવામાં આવ્યું છે.",
    boarding: "તમારું નવું બોર્ડિંગ સ્ટેશન છેઃ",
    time: "બોર્ડિંગનો નવો સમય અને તારીખઃ",
  },
};

export default translations;
