const translations = {
  en: {
    header: "Disclaimer",
    steps: {
      id: "Verify IRCTC ID",
      payment: "Complete Payment",
      otp: "Verify OTP",
    },
    text1:
      "After successful payment, you will get an OTP for booking on Mobile Number and Email ID registered with IRCTC for User ID",
    subtext1:
      "You will have to enter and verify that OTP in order to complete the booking.",
    text2: "Please ensure, you have access to mobile number ending with",
    text3: "OR Email ID -",
    text4:
      "In case payment is deducted and ticket is not booked due to browser refresh or closed by mistake, you can retry booking through AskDISHA within 15 mins.",
    button: "Yes, I understand",
  },
  hi: {
    header: "अस्वीकरण",
    steps: {
      id: "IRCTC ID की पुष्टि",
      payment: "भुगतान करें",
      otp: "OTP की पुष्टि",
    },
    text1: "सफल भुगतान के बाद, आपको User ID",
    subtext1:
      "के लिए IRCTC के साथ पंजीकृत मोबाइल नंबर और Email ID पर बुकिंग के लिए एक OTP मिलेगा। बुकिंग पूरी करने के लिए आपको उस OTP को दर्ज और पुष्टि करना होगा।",
    text2: "कृपया सुनिश्चित करें कि आपके पास मोबाइल नंबर",
    text3: "Email ID है",
    text4:
      "यदि भुगतान काटा जाता है और ब्राउजर रिफ्रेश होने या गलती से बंद होने के कारण टिकट बुक नहीं होता है, तो आप 15 मिनट के भीतर AskDisha के माध्यम से बुकिंग का पुनः प्रयास कर सकते हैं।",
    button: "हाँ, मैं समझता हूँ",
  },
  gu: {
    header: "અસ્વીકરણ",
    steps: {
      id: "આઈઆરસીટીસી આઈડીની ચકાસણી",
      payment: "ચૂકવણી કરો",
      otp: "ઓટિપી ચકાસણી",
    },
    text1: "સફળ ચુકવણી પછી, તમને એક વપરાશકર્તા ID પ્રાપ્ત થશે.",
    subtext1:
      "આઈ. આર. સી. ટી. સી. સાથે નોંધાયેલા મોબાઇલ નંબર અને ઈ-મેલ આઈડી પર બુકિંગ માટે એક ઓ. ટી. પી. પ્રાપ્ત થશે. તમારે બુકિંગ પૂર્ણ કરવા માટે તે ઓ. ટી. પી. દાખલ કરવાની અને તેની પુષ્ટિ કરવાની જરૂર પડશે.",
    text2: "કૃપા કરીને ખાતરી કરો કે તમારી પાસે મોબાઇલ ફોન નંબર છે.",
    text3: "ઇમેઇલ ID છે",
    text4:
      "જો ચુકવણી કાપવામાં આવે અને બ્રાઉઝર રિફ્રેશ થવાને કારણે અથવા આકસ્મિક રીતે બંધ થવાને કારણે ટિકિટ બુક કરવામાં ન આવે, તો તમે 15 મિનિટની અંદર આસ્કદિશા દ્વારા બુકિંગનો ફરીથી પ્રયાસ કરી શકો છો.",
    button: "હા, હું સમજું છું.",
  },
};

export default translations;
