import styled from "styled-components";

const BookingHistoryContainer = styled.div`
  width: 98%;
  margin: 0px auto;
  margin-bottom: 20px;
  /* padding: 12px 15px; */
  border: 1px solid gainsboro;
  border-radius: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  padding-bottom: 10px;
  > * {
    line-height: 1;
    font-family: "Roboto", sans-serif;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
  h1 {
    font-size: 18px;
    color: #4d4d4d;
    font-weight: 500;
    text-align: center;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 25px;
      margin-right: 10px;
    }
  }

  .advisory {
    color: #eaaf51;
    padding: 8px 15px;
    background-color: #fff3dd;
    font-size: 12px;
    span {
      font-weight: 600;
      display: block;
    }
  }

  .bookings-container {
    padding: 12px 15px;
    height: 270px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    .no-booking-message {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      color: gray;
    }

    &::-webkit-scrollbar {
      width: 8px;
      cursor: pointer, auto;
    }

    &::-webkit-scrollbar-track {
      background: rgb(226, 226, 226);
      border-radius: 100vw;
      margin-block: 0.5em;
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(167, 167, 167);

      border-radius: 100vw;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #4596ff;
    }
  }

  .failed {
    height: calc(270px - 52px);
  }
`;

export default BookingHistoryContainer;
