import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 5px;
  white-space: nowrap;
  padding-top: 0px;
  margin-top: 5px;
  .icon {
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;

    margin-bottom: 2px;
    height: 34px;
    margin-right: 5px;
    padding: 5px 10px;
    box-shadow:
      rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;

    img {
      margin-right: 8px;
    }

    p {
      font-size: 14px;
      margin: 0;
    }
  }
`;

export default Container;
