import { useEffect, useState } from "react";
import { getNearbyStations, searchStation } from "../../Api/stations";
import Drawer from "../../UI/UIComponents/Drawer";
import Content from "./Content/Content";
import Header from "./Header/Header";
import Fuse from "fuse.js";

import { getStationName } from "../../Helpers/getStationName";
import { CloseDrawer } from "../../Helpers/EventEmitter";
import useGetReduxState from "../../Hooks/useGetReduxState";
import React from "react";

const StationPicker = ({
  pickerType,
  handleClose,
  callBack,
  source,
  destination,
}) => {
  // const [close, setClose] = useState(false);
  const popularStations = useGetReduxState().utils.popularStations;
  const nextContext = useGetReduxState().app.nextContext;
  const [micOn, setMicOn] = useState(false);
  // const j = useRecoilValue(journeyAtom);
  const [showNoStations, setShowNoStations] = useState(false);
  const [key, setKey] = useState(
    getStationName(pickerType === "SOURCE" ? source : destination)
  );
  const [mode, setMode] = useState("POPULAR");
  const [findingLoader, setFindingLoader] = useState(false);
  const [nearbyStations, setNearbyStations] = useState([]);

  const filterRedundant = (arr) => {
    return arr;
    // if (nextContext) {
    //   if (pickerType !== "SOURCE")
    //     return arr.filter((station) => station.code !== source);
    //   else return arr;
    // } else {
    //   if (pickerType === "SOURCE")
    //     return arr.filter((station) => station.code !== destination);
    //   else return arr.filter((station) => station.code !== source);
    // }
  };
  const [stations, setStations] = useState(() =>
    filterRedundant(popularStations)
  );

  const [searchLoader, setSearchLoader] = useState(false);

  let allNearbyStations = [];

  const viewPopular = () => {
    setKey("");
    setShowNoStations(false);
    setMode("POPULAR");
    setStations(popularStations);
  };

  const handleClick = (value) => {
    callBack(value.code);
    CloseDrawer();
  };

  const handleVoice = (value) => {
    if (value) {
      setMicOn(false);
      searchStationsMic(value);
    }
  };

  const filterAsyncStations = async (key) => {
    let s = nearbyStations.filter(
      (station) => station.code.toLowerCase() == key
    );
    let s_mid = nearbyStations
      .filter((station) => station.code.toLowerCase().startsWith(key))
      .sort((st1, st2) =>
        st1.code.toLowerCase().localeCompare(st2.code.toLowerCase())
      );
    s = s.concat(
      s_mid.filter((element) => !s.map((e) => e.code).includes(element.code))
    );
    if (s.length > 0) {
      if (s.length < 10) {
        let moreStations = nearbyStations
          .filter(
            (station) =>
              station.name.toLowerCase().startsWith(key) ||
              station.name.toLowerCase().includes(key)
          )
          .slice(0, 50 - s.length)
          .sort((st1, st2) =>
            st1.name.toLowerCase().localeCompare(st2.name.toLowerCase())
          );
        let resultStations = s.concat(
          moreStations.filter(
            (element) => !s.map((e) => e.code).includes(element.code)
          )
        );
        setStations(resultStations);
      } else {
        setStations(s.slice(0, 50));
      }
      return;
    }

    let codes = nearbyStations.map((element) => element.code);
    let fullStations = nearbyStations.concat(
      stations.filter((element) => !codes.includes(element.code))
    );

    let fuse = new Fuse(fullStations, {
      keys: ["code", "name", "utterances", "district", "state"],
    });
    let searchValue = fuse.search(key);
    if (searchValue && searchValue.length > 0) {
      setStations(searchValue.map((element) => element.item).slice(0, 50));
      return;
    }
    setShowNoStations(true);
  };

  const getFromAPI = async (key) => {
    setSearchLoader(true);

    const stations = await searchStation(key);
    if (key === "") {
      setStations(filterRedundant(popularStations));
      setSearchLoader(false);
    }

    if (stations !== -1 && key !== "") {
      setStations(filterRedundant(stations));
      setSearchLoader(false);
      if (stations.length === 0) {
        setShowNoStations(true);
      } else setShowNoStations(false);
    }
  };
  const [showLabel, setShowLabel] = useState(true);

  const searchStations = (e) => {
    setShowNoStations(false);
    let value = e.target.value;
    setKey(value);
    if (mode === "POPULAR" && value === "") {
      getFromAPI(value);
    }
    if (value === "") {
      if (mode === "POPULAR") {
        setSearchLoader(false);
        setStations(popularStations);
        setShowLabel(true);
      } else setStations(nearbyStations);
      return;
    }
    if (mode === "NEARBY") {
      filterAsyncStations(value);
    } else {
      setShowLabel(false);
      getFromAPI(value);
    }
  };

  const findNearbyStations = async () => {
    setShowNoStations(false);
    setKey("");
    if (nearbyStations.length > 0) {
      setMode("NEARBY");
      setStations(nearbyStations);
      return;
    }
    setFindingLoader(true);
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        let { latitude, longitude } = position.coords;
        const stations = await getNearbyStations({ latitude, longitude });

        if (!stations.error) {
          allNearbyStations = stations.data;
          setNearbyStations(allNearbyStations.slice(0, 200));
          setStations(allNearbyStations.slice(0, 200));
          setMode("NEARBY");
        }

        setFindingLoader(false);
      },
      () => setFindingLoader(false)
    );
  };

  const searchStationsMic = (key) => {
    setShowNoStations(false);

    setKey(key);
    if (mode === "POPULAR" && key === "") {
      getFromAPI(key);
    }
    if (key === "") {
      if (mode === "POPULAR") {
        setSearchLoader(false);
        setStations(popularStations);
        setShowLabel(true);
      } else setStations(nearbyStations);
      return;
    }
    if (mode === "NEARBY") {
      filterAsyncStations(key);
    } else {
      setShowLabel(false);
      getFromAPI(key);
    }
  };

  useEffect(() => {
    if (key !== "") {
      setShowLabel(false);
      getFromAPI(key);

      const input = document.getElementById(
        "station-textbox"
      ) as HTMLInputElement;
      input.focus();
      input.select();
    }
  }, []);

  return (
    <Drawer
      header={
        <Header
          onChange={searchStations}
          value={key}
          setMicOn={setMicOn}
          micOn={micOn}
          pickerType={pickerType}
          handleVoice={handleVoice}
        />
      }
      content={
        <Content
          stations={stations}
          viewPopular={viewPopular}
          findingLoader={findingLoader}
          mode={mode}
          findNearbyStations={findNearbyStations}
          searchLoader={searchLoader}
          showLabel={showLabel}
          showNoStations={showNoStations}
          handleClick={handleClick}
        />
      }
      footer={<React.Fragment></React.Fragment>}
      handleCloseDrawer={handleClose}
      showCross={true}
      tatkal={false}
      fullHeight={true}
      showHeaderSeperator={false}
    />
  );
};

export default StationPicker;
