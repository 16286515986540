import React, { useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: 5px 0px;
  border-bottom: 1px solid gray;

  &:last-child {
    font-weight: 600;
    font-size: 16px;
  }
`;

const Breakup = ({ onClose, classObj }) => {
  const [notes, setNotes] = useState(
    classObj?.informationMessage
      ?.find((item) => item.paramName === "FARE_BREAKUP")
      .message.split(`\n`),
  );

  const isShow = (str) => {
    if (str === "0.0" || str === "0") return false;

    return true;
  };

  return createPortal(
    <div
      style={{
        position: "absolute",
        inset: "0px",
        background: "#00000085",
        zIndex: "99999",
      }}
    >
      <div
        style={{
          background: "white",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "90%",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: "18px",
            color: "#484848",
            marginBottom: "18px",
            textDecoration: "underline",
          }}
        >
          Fare Breakup (per passenger)
        </h1>
        <Row>
          <p>*Base Fare</p>
          <span>₹{parseFloat(classObj.baseFare).toFixed(2)}</span>
        </Row>
        {isShow(classObj.reservationCharge) && (
          <Row>
            <p>Reservation Charge</p>
            <span>₹{parseFloat(classObj.reservationCharge).toFixed(2)}</span>
          </Row>
        )}

        {isShow(classObj.superfastCharge) && (
          <Row>
            <p>Superfast Charge</p>
            <span>₹{parseFloat(classObj.superfastCharge).toFixed(2)}</span>
          </Row>
        )}

        {isShow(classObj.serviceTax) && (
          <Row>
            <p>Total GST</p>
            <span>₹{parseFloat(classObj.serviceTax).toFixed(2)}</span>
          </Row>
        )}

        {isShow(classObj.tatkalFare) && (
          <Row>
            <p>Tatkal Charge</p>
            <span>₹{parseFloat(classObj.tatkalFare).toFixed(2)}</span>
          </Row>
        )}

        {isShow(classObj.cateringCharge) && (
          <Row>
            <p>#Catering Charge</p>
            <span>₹{parseFloat(classObj.cateringCharge).toFixed(2)}</span>
          </Row>
        )}
        {isShow(classObj.dynamicFare) && (
          <Row>
            <p>Dynamic Fare</p>
            <span>₹{parseFloat(classObj.dynamicFare).toFixed(2)}</span>
          </Row>
        )}

        <Row
          style={{
            fontSize: "18px",
            padding: "10px 0px",
            marginBottom: "10px",
          }}
        >
          <p>Total Fare</p>
          <span>₹{parseFloat(classObj.totalFare).toFixed(2)}</span>
        </Row>

        {notes.map((note) => (
          <p style={{ fontSize: "12px", marginTop: "5px" }}>{note}</p>
        ))}

        <button
          style={{
            display: "block",
            width: "100%",
            marginTop: "15px",
            fontSize: "16px",
            padding: "10px",
            border: "none",
            color: "white",
            background: "rgb(50,106,187)",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>,
    document.getElementById("corover-body"),
  );
};

export default Breakup;
