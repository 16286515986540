const translations = {
  hi: {
    header: "Train Schedule",
    sno: "Sno",
    station: "स्टेशन",
    arrival: "अर्रिवाल",
    departure: "डिपार्चर",
    day: "दिन",
  },
  en: {
    header: "Train Schedule",
    sno: "Sno",
    station: "Station",
    arrival: "Arrival",
    departure: "Departure",
    day: "Day",
  },
  gu: {
    header: "Train Schedule",
    sno: "Sno",
    station: "સ્ટેશન",
    arrival: "આગમન",
    departure: "પ્રસ્થાન",
    day: "દિવસ",
  },
};

export default translations;
