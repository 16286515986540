import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useGetReduxState from "../../../../../../../Hooks/useGetReduxState";
// import SocketHandler from "../../../Socket/SocketHandler";
const bhompu =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/advertising.svg";
const text = {
  gu: {
    head: "આસ્ક દિશા પર જાહેરાત કરો",
    sub: "લાખો લોકોને તમારી બ્રાન્ડ બતાવો.",
  },
  hi: {
    head: "Ask DISHA पर विज्ञापन दें",
    sub: "लाखों लोगों को अपना ब्रांड दिखाएं",
  },
  en: {
    head: "Advertise on Ask DISHA",
    sub: "Show your brand to millions",
  },
};

const Advertise = () => {
  const lang = useGetReduxState().behaviour.lang;

  return (
    <>
      <a
        href="https://bit.ly/3PYbd22"
        target="_blank"
        style={{ textDecoration: "none", cursor: "pointer" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2px 10px",
            marginBottom: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <img
              src={bhompu}
              alt=""
              style={{ width: "28px", marginRight: "12px" }}
            />
            <div>
              <p
                style={{
                  margin: "0",
                  fontSize: "16px",
                  color: "#f14526",
                  fontWeight: "600",
                }}
              >
                {text[lang].head}
              </p>
              <p
                style={{
                  margin: "0",
                  marginTop: "2px",
                  fontSize: "14px",
                  color: "gray",
                  fontWeight: "600",
                }}
              >
                {text[lang].sub}
              </p>
            </div>
          </div>
          <FontAwesomeIcon
            icon={faAngleRight}
            style={{
              border: "1px solid #f44627",
              color: "#f44627",
              padding: "3px 7px",
              borderRadius: "50%",
              marginRight: "9px",
            }}
          />
        </div>
      </a>
    </>
  );
};

export default Advertise;
