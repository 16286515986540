import useGetReduxState from "../../../../../../Hooks/useGetReduxState";
import langText from "../translations";
const Header = ({ passengerProp }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  // if (entity === "ADULT")
  //   return (
  //     <p style={{ margin: "0", fontSize: "18px", fontWeight: "400" }}>
  //       {ddd} Adult{" "}
  //       <span style={{ color: "gray", marginLeft: "8px", fontSize: "16px" }}>
  //         (12 years and above)
  //       </span>
  //     </p>
  //   );
  // if (entity === "CHILD")
  //   return (
  //     <p style={{ margin: "0", fontSize: "18px", fontWeight: "400" }}>
  //       {ddd} Child{" "}
  //       <span style={{ color: "gray", marginLeft: "8px", fontSize: "16px" }}>
  //         (5 to 11 years)
  //       </span>
  //     </p>
  //   );
  // if (entity === "INFANT")
  //   return (
  //     <p style={{ margin: "0", fontSize: "18px", fontWeight: "400" }}>
  //       {ddd} Infant{" "}
  //       <span style={{ color: "gray", marginLeft: "8px", fontSize: "16px" }}>
  //         (0 to 4 years)
  //       </span>
  //     </p>
  //   );

  return (
    <p
      style={{
        margin: "0px",
        fontSize: "18px",
        fontWeight: "500",
        color: "#3f3f3f",
      }}
    >
      {passengerProp !== null
        ? langText[lang].header.edit
        : langText[lang].header.add}
    </p>
  );
};

export default Header;
