import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .train-selected {
    width: 90%;
    box-shadow:
      rgba(9, 30, 66, 0.25) 0px 1px 1px,
      rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    border-radius: 12px;
    border-bottom-right-radius: 0px;

    .header {
      padding: 12px 15px;
      border-bottom: 4px solid #f6f6f6;

      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        border: none;
        background: rgb(50, 106, 187);
        color: white;
        padding: 5px 15px;
        font-size: 12px;
        border-radius: 5px;
        cursor: pointer;
      }

      div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      img {
        width: 18px;
        margin-right: 6px;
      }
      p {
        font-size: 16px;
        color: #4779d3;
        font-weight: 500;
      }
    }
    .details {
      padding: 12px 15px;
    }

    .train {
      font-size: 16px;
      color: #3f3f3f;
      font-weight: 500;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 20px;
        margin-right: 4px;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        display: inline-block;
        margin-left: 4px;
      }
    }

    .second-row {
      margin-left: 24px;
      span {
        display: inline-block;
        margin-right: 5px;
        font-size: 14px;
        color: darkslategray;
      }
    }

    .stations {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      .from,
      .to {
        text-align: center;
        flex-shrink: 0;

        p {
          color: #363636;
          font-weight: 500;
        }

        span {
          font-size: 12px;
          color: gray;
          display: block;
          margin-top: 2px;
        }
      }

      .from {
        text-align: left;
      }
      .to {
        text-align: right;
      }

      .hr {
        width: 20%;
        border-top: 1px solid #cecece;
        margin-top: -2px;
      }

      h5 {
        font-weight: 400;
        color: dimgray;
        font-size: 12px;
        width: 20%;
        text-align: center;
      }
    }

    .date-time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: dimgray;
      margin-top: 2px;
    }
  }
`;

export default Container;
