import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  font-family: "Montserrat", sans-serif;
  border-bottom: 1px solid #d5d5d5;

  .first {
    width: 75px;
    border-right: 1px solid #9f9d9d;
  }

  .second {
    width: 100%;

    padding: 0px 10px;

    img {
      width: 22px;
      margin-right: 5px;
    }

    & > div {
      &:nth-child(1) {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          color: #1e1e1e;
          font-size: 14px;
          font-weight: 600;
        }

        span {
          color: gray;
          font-size: 12px;
          font-weight: 500;
        }
      }

      &:nth-child(2) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 27px;
      }
    }
  }
`;
export default Container;
