const translations = {
  en: {
    validName: "Please enter a valid name",
    nameLimit: "Name should be 3 to 16 alphabets long",
    validAge: "Please enter a valid age",
    minAge: "Minimum allowed age is 0",
    maxAge: "Maximum allowed age is 125",
    dob: "Please enter date in valid format (DD-MM-YYYY)",
    passport: "Please enter a valid passport number",
    match: "Date of Birth does not match with age",
    missing: {
      age: "Please Fill Age",
      name: "Please Fill Name",
      gender: "Please Select Gender",
      food: "Please Select Catering Service Option",
      nationality: "Please Select Nationality",
      passport: "Please Enter Passport Number",
      dob: "Please Enter your Date of Birth",
    },
  },
  hi: {
    validName: "कृपया कोई मान्य नाम दर्ज करें",
    nameLimit: "नाम 3 से 16 अक्षर लंबा होना चाहिए",
    validAge: "कृपया एक मान्य आयु दर्ज करें",
    minAge: "न्यूनतम अनुमत आयु 0 है",
    maxAge: "अधिकतम अनुमत आयु 125 है",
    dob: "कृपया मान्य प्रारूप में दिनांक दर्ज करें",
    passport: "कृपया एक वैध पासपोर्ट नंबर दर्ज करें",
    match: "जन्म तिथि उम्र से मेल नहीं खाती",
    missing: {
      age: "कृपया आयु भरें",
      name: "कृपया नाम भरें",
      gender: "कृपया जेंडर चुनें",
      food: "कृपया भोजन की पसंद चुनें",
      nationality: "कृपया राष्ट्रीयता का चयन करें",
      passport: "कृपया पासपोर्ट नंबर दर्ज करें",
      dob: "अपनी जन्मतिथि भरें",
    },
  },
  gu: {
    validName: "મહેરબાની કરીને માન્ય નામ દાખલ કરો",
    nameLimit: "નામ 3 થી 16 અક્ષરોની વચ્ચે હોવું જોઈએ.",
    validAge: "મહેરબાની કરીને માન્ય ઉંમર દાખલ કરો.",
    minAge: "લઘુત્તમ માન્ય વય 0 છે.",
    maxAge: "મહત્તમ માન્ય વય 125 છે.",
    dob: "મહેરબાની કરીને તારીખ માન્ય ફોર્મેટમાં દાખલ કરો.",
    passport: "મહેરબાની કરીને માન્ય પાસપોર્ટ નંબર દાખલ કરો.",
    match: "જન્મ તારીખ વય સાથે મેળ ખાતી નથી.",
    missing: {
      age: "મહેરબાની કરીને ઉંમર દાખલ કરો.",
      name: "મહેરબાની કરીને નામ દાખલ કરો",
      gender: "મહેરબાની કરીને લિંગ પસંદ કરો",
      food: "મહેરબાની કરીને ખોરાકની પસંદગીઓ પસંદ કરો",
      nationality: "મહેરબાની કરીને રાષ્ટ્રીયતા પસંદ કરો.",
      passport: "મહેરબાની કરીને પાસપોર્ટ નંબર દાખલ કરો.",
      dob: "તમારી જન્મ તારીખ દાખલ કરો",
    },
  },
};

export default translations;
