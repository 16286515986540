import styled from "styled-components";

const Container = styled.div`
  width: 98%;
  margin: 0px auto;
  margin-bottom: 20px;
  /* padding: 12px 15px; */
  border: 1px solid gainsboro;
  border-radius: 10px;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

  > * {
    line-height: 1;
    font-family: "Roboto", sans-serif;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .seperator {
    width: 100%;
    border: 2px solid #f2f2f2;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 8px 15px;
    border-bottom: 1px solid gainsboro;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
    }

    img {
      color: rgb(63, 68, 71);
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }

    p {
      color: rgb(63, 68, 71);
      font-weight: 500;
      font-size: 16px;
      margin: 0;
    }
  }

  .journey-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    width: 100%;
    margin: 12px auto;
    max-width: 390px;

    .hr {
      width: 100%;
      border: 1px solid gainsboro;
    }

    .from {
      text-align: left;
    }
    .to {
      text-align: right;
    }

    .middle {
      width: 100%;
      max-width: 180px;
    }

    .station {
      font-size: 12px;
      color: gray;
    }

    .code {
      font-size: 14px;
      font-weight: 500;
      color: #3c3c3c;
    }
  }

  .chart-status {
    padding: 15px;
    text-align: center;
    font-size: 14px;
    color: lightslategrey;

    span {
      font-weight: 500;
      letter-spacing: 1px;

      display: inline-block;
      margin-left: 6px;
    }
  }

  .other-details {
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    column-gap: 15px;
    align-items: center;
    font-size: 12px;

    div {
      span {
        display: block;
        margin-top: 5px;

        &:nth-child(1) {
          margin-top: 0px;
        }
      }
    }

    div:nth-child(1) {
      font-weight: 500;
      color: #4a4a4a;
    }
    div:nth-child(2) {
      color: darkslategray;
    }
  }

  .train-details {
    padding: 0px 15px;
    text-align: left;
    font-size: 16px;
    color: #5e5e5e;
    margin-top: 20px;
    font-weight: 500;

    span {
      font-weight: 400;
      font-size: 14px;
    }
  }

  .passengers {
    padding: 15px;

    h1 {
      font-size: 18px;
      font-weight: 500;
      color: #4a4a4a;
    }

    .passenger {
      border: 1px solid gainsboro;
      margin-top: 10px;
      padding: 8px 10px;
      border-radius: 8px;

      &:first-child {
        margin-top: 0px;
      }

      .number {
        font-size: 14px;
        font-weight: 500;
        color: dimgray;
        margin-bottom: 12px;

        padding-left: 4px;
      }

      .status-details {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .atBooking,
        .atCurrent {
          display: flex;
          align-items: center;
          justify-content: center;

          padding: 3px 8px;
          border-radius: 4px;
          box-shadow:
            rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        }

        h2 {
          font-size: 12px;
          color: #333333;
          font-weight: 500;
          margin-bottom: 5px;
        }

        .status {
          text-align: center;

          span {
            font-size: 12px;
            color: slategray;
          }

          p {
            font-size: 14px;

            border: 1px solid;
            border-radius: 3px;
            padding: 2px 6px;
            /* min-width: 94px; */
            margin-top: 4px;
          }
        }

        .berth-details {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          text-align: center;
          column-gap: 10px;
          /* margin-right: 15px; */

          span {
            font-size: 10px;
            color: slategray;
            font-weight: 500;
          }

          p {
            color: #393939;
            font-weight: 400;
            font-size: 14px;
            margin-top: 4px;
          }
        }
      }
    }
  }

  .passengers-header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    column-gap: 30px;

    div:nth-child(1) {
      width: 20%;
    }

    div:nth-child(2) {
      width: 40%;
    }

    div:nth-child(3) {
      width: 40%;
    }

    div {
      text-align: center;

      p {
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 1px;
      }

      span {
        font-size: 10px;
        color: slategray;
        font-weight: 500;
      }
      .status-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        column-gap: 10px;

        div {
          p {
            font-weight: 400;
            margin-top: 5px;
          }
        }
      }
    }
  }
`;

export default Container;
