const translations = {
  en: {
    header: "Booking History",
    advisory: {
      bold: "Dont worry, your money is safe!",
      light:
        "If money was debited from your account, it will be refunded in 1 to 7 working days.",
    },
    no: "No bookings available",
    advisoryPending: {
      bold: "Dont worry! Pending bookings are currently in process.",
      light:
        "Please wait before booking another ticket. If successful, you'll see the booking in upcoming bookings. Otherwise, you'll get your full refund",
    },
  },
  hi: {
    header: "बुकिंग इतिहास",
    advisory: {
      bold: "चिंता न करें, आपका पैसा सुरक्षित है!",
      light:
        "अगर आपके खाते से पैसा कट गया था, तो उसे 1 से 7 कार्य दिवसों में वापस कर दिया जाएगा।",
    },
    no: "कोई बुकिंग उपलब्ध नहीं है",
    advisoryPending: {
      bold: "चिंता मत करो! लंबित बुकिंग वर्तमान में प्रक्रिया में हैं।",
      light:
        "कृपया दूसरा टिकट बुक करने से पहले प्रतीक्षा करें। सफल होने पर, आपको आगामी बुकिंग में बुकिंग दिखाई देगी. नहीं तो आपको आपका पूरा रिफंड मिल जाएगा",
    },
  },
  gu: {
    header: "બુકિંગનો ઈતિહાસ",
    advisory: {
      bold: "ચિંતા ન કરો, તમારા પૈસા સુરક્ષિત છે!",
      light:
        "જો તમારા ખાતામાંથી પૈસા ડેબિટ કરવામાં આવ્યા હોય, તો તે 1 થી 7 કામકાજના દિવસોમાં પરત કરવામાં આવશે.",
    },
    no: "કોઈ બુકિંગ ઉપલબ્ધ નથી",
    advisoryPending: {
      bold: "ચિંતા ન કરો! હાલમાં બાકી બુકિંગની પ્રક્રિયા ચાલી રહી છે.",
      light:
        "મહેરબાની કરીને બીજી ટિકિટ બુક કરાવતા પહેલા રાહ જુઓ. જો સફળ થાય, તો તમે આગામી બુકિંગમાં બુકિંગ જોશો. નહિંતર, તમને તમારું સંપૂર્ણ રિફંડ મળશે.",
    },
  },
};

export default translations;
