import styled from "styled-components";

const BookingContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  box-shadow:
    rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 15px;
  background: white;
  padding-right: 40px;
  /* box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px; */

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;

    padding: 2px;
    border: 1px solid;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  &:first-child {
    margin-top: 0px;
  }

  .first-row {
    display: flex;
    column-gap: 4px;
    margin-bottom: 6px;
    p {
      margin: 0;
      font-size: 14px;
      color: #5c5c5c;
      font-weight: 500;
    }
    span {
      font-size: 14px;
      color: gray;
    }
  }

  .date-container {
    flex-shrink: 0;
    text-align: center;
    /* margin-right: 10px; */
    width: 68px;
    overflow: hidden;
    /* padding-right: 8px; */
    /* padding: 2px 12px; */

    h4 {
      font-size: 14px;
      font-weight: 500;
      color: #464646;
    }

    span {
      font-size: 12px;
      color: #464646;
      margin-top: 5px;
      display: block;
    }
  }

  .seperator {
    border-left: 4px solid #f6f6f6;
    height: 72px;
  }

  .details {
    width: 100%;
    padding: 8px 12px;
  }

  .second-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;

    p {
      margin: 0;
      font-size: 12px;
      color: #646464;
      font-weight: 500;
    }
  }

  .hr {
    border-top: 1px solid gainsboro;
    width: 100%;
    margin: 0 20px;
  }

  .third-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 10px;
      color: dimgray;

      &:nth-child(1) {
        text-align: left;
      }

      &:nth-child(2) {
        text-align: right;
      }
    }
  }

  .failed-booking-details {
    padding: 12px 12px;
    width: 100%;

    p {
      font-size: 12px;
      font-weight: 500;
      color: #4d4d4d;
      margin-bottom: 5px;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 80%;
    }

    p:last-child {
      margin-bottom: 0px;

      span {
        font-weight: 600;
        color: ${(props) => (props.refunded ? "green" : "#e9a83a")};
      }
    }
    span {
      font-weight: 400;
    }
  }
`;

export default BookingContainer;
