import { getGender } from "../Logic";

const PassengerWithBerthDetails = ({ passenger }) => {
  console.log(passenger);
  const getStatus = (status) => {
    if (status === "CNF") return "Confirmed";
    return status;
  };

  const getColor = (status) => {
    const check = status.toLowerCase();
    if (check.includes("wl")) return "tomato";
    return "#04cc04";
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "4px 0px",
        borderBottom: "1px solid gainsboro",
      }}
    >
      <div style={{ width: "55%" }}>
        <p
          style={{
            margin: "0px",
            fontSize: "14px",
            color: "rgb(91, 91, 91)",
            lineHeight: "14px",
          }}
        >
          {passenger.name}
        </p>
        <div
          style={{
            fontSize: "12px",
            marginTop: "2px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            color: "rgb(146, 146, 146)",
          }}
        >
          <span>{passenger.age} years</span>
          <span style={{ display: "block", margin: "0 5px" }}>|</span>
          <span>{getGender(passenger.gender)}</span>
        </div>
      </div>
      <div
        style={{
          width: "45%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <span
            style={{
              fontSize: "9px",
              color: "grey",
              textAlign: "center",
              display: "block",
            }}
          >
            Status
          </span>
          <p
            style={{
              margin: "0px",
              textAlign: "center",
              color: getColor(passenger.status),
              fontSize: "12px",
              marginTop: "1px",
            }}
          >
            {getStatus(passenger.status)}
          </p>
        </div>
        <div>
          <span
            style={{
              fontSize: "9px",
              color: "grey",
              textAlign: "center",
              display: "block",
            }}
          >
            Coach
          </span>
          <p
            style={{
              margin: "0px",
              fontSize: "12px",
              textAlign: "center",
              marginTop: "1px",
            }}
          >
            {passenger.coach}
          </p>
        </div>
        <div>
          <span
            style={{
              fontSize: "9px",
              color: "grey",
              textAlign: "center",
              display: "block",
            }}
          >
            Seat
          </span>
          <p
            style={{
              margin: "0px",
              fontSize: "12px",
              textAlign: "center",
              marginTop: "1px",
            }}
          >
            {passenger.seat}
          </p>
        </div>
        <div>
          <span
            style={{
              fontSize: "9px",
              color: "grey",
              textAlign: "center",
              display: "block",
            }}
          >
            Berth
          </span>
          <p
            style={{
              margin: "0px",
              fontSize: "12px",
              textAlign: "center",
              marginTop: "1px",
            }}
          >
            {passenger.berth}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PassengerWithBerthDetails;
