import useGetReduxState from "../../../Hooks/useGetReduxState";
import langText from "../translations";

const Address = ({ address, OnClick }) => {
  const lang: "hi" | "en" | "gu" = useGetReduxState().behaviour.lang;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid gainsboro",
        padding: "10px",
        borderRadius: "8px",
        marginTop: "12px",
        position: "relative",
      }}
    >
      <div style={{ width: "120px" }}>
        <span style={{ fontSize: "12px" }}>{address}</span>
      </div>
      <button
        onClick={OnClick}
        style={{
          border: "none",
          background: "#326abb",
          color: "white",
          padding: "8px 14px",
          borderRadius: "6px",
          cursor: "pointer",
        }}
      >
        {langText[lang].select}
      </button>
    </div>
  );
};

export default Address;
