const translations = {
  hi: {
    header: "IRCTC मेंटेनेंस अलर्ट!",
    text: "रात 11:45 बजे से 12:20 बजे तक IRCTC के सिस्टम का दैनिक रखरखाव किया जा रहा है। हमारा सुझाव है कि आप 12:20 पूर्वाह्न के बाद बुकिंग करने का प्रयास करें।",
    home: "Home पर जाएं",
  },
  en: {
    header: "IRCTC Maintainance Alert!",
    text: "IRCTC's systems are undergoing daily maintainance from 11:45 PM to 12:20 AM. We suggest you try booking after 12:20 AM.",
    home: "Back to Home",
  },
  gu: {
    header: "આઈઆરસીટીસી જાળવણી ચેતવણી!",
    text: "આઈ. આર. સી. ટી. સી. ની પ્રણાલીઓની રોજિંદી જાળવણી 11:45 વાગ્યાથી 12:20 વાગ્યા સુધી કરવામાં આવે છે. અમે સૂચવીએ છીએ કે તમે 12:20 AM પછી બુકિંગ કરવાનો પ્રયાસ કરો.",
    home: "ઘેર જાઓ.",
  },
};

export default translations;

export const transl_IRCTC_Maintainance = {
  hi: {
    text: "IRCTC's सिस्टम रखरखाव के लिए बंद है। हमारा सुझाव है कि आप बाद में बुकिंग करें।",
  },
  en: {
    text: "IRCTC's systems are undergoing  maintainance. We suggest you try booking after some time",
  },
  gu: {
    text: "IRCTC સિસ્ટમ જાળવણી માટે બંધ છે. અમે તમને પછીથી બુક કરવાનું સૂચન કરીએ છીએ.",
  },
};
