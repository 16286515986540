import useGetReduxState from "../../../../Hooks/useGetReduxState";
import TabsContainer from "./Tabs.styles";
import langText from "./translations";
const Tabs = ({ currentTab, handleChangeTab }) => {
  const lang: "en" | "gu" | "hi" = useGetReduxState().behaviour.lang;

  return (
    <TabsContainer>
      <button
        className={`${currentTab === 1 ? "active-tab" : "tab2"}`}
        onClick={() => handleChangeTab(1)}
      >
        {langText[lang].upcoming}
      </button>
      <button
        className={`${currentTab === 2 ? "active-tab" : "tab2"}`}
        onClick={() => handleChangeTab(2)}
      >
        {langText[lang].cancelled}
      </button>
      <button
        className={`${currentTab === 3 ? "active-tab" : "tab2"}`}
        onClick={() => handleChangeTab(3)}
      >
        {langText[lang].failed}
      </button>
      <button
        className={`${currentTab === 4 ? "active-tab" : "tab2"}`}
        onClick={() => handleChangeTab(4)}
      >
        {langText[lang].completed}
      </button>
      <button
        onClick={() => handleChangeTab(5)}
        className={`${currentTab === 5 ? "active-tab" : "tab2"}`}
      >
        {langText[lang].pending}
      </button>
    </TabsContainer>
  );
};

export default Tabs;
