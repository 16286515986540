import { useEffect, useState } from "react";

import ReactDOMServer from "react-dom/server";
import ERSTemplate from "../PDF/ERSTemplate";
import Eticket from "../PDF/Eticket";
import html2pdf from "html2pdf-jspdf2";
import { CloseDrawer } from "../Helpers/EventEmitter";
import { getQRCode } from "../Api/irctc";
import useSendQuery from "./useSendQuery/useSendQuery";

const usePDF = ({ template, data }) => {
  const [isShareEnabled, setIsShareEnabled] = useState(false);
  const { sendQueryPayload } = useSendQuery(() => {});
  const [isLoading, setIsLoading] = useState({
    share: false,
    download: false,
    view: false,
    print: false,
  });

  const [qr, setQR] = useState("");

  const getFileName = () => {
    if (template === "ERS") return "ERS.pdf";

    return "E-TICKET.pdf";
  };

  const getQR = async () => {
    const q = await getQRCode(data.pnr);
    setQR(q);
  };

  useEffect(() => {
    getQR();

    if (!window.navigator || window.navigator.share === undefined)
      setIsShareEnabled(false);
    else setIsShareEnabled(true);
  }, []);

  const getData = () => {
    if (template === "ERS")
      return ReactDOMServer.renderToString(
        <ERSTemplate ticketData={{ ...data, QR: qr }} />,
      );
    else
      return ReactDOMServer.renderToString(
        <Eticket
          isForCard={false}
          ticketData={{ ...data, QR: qr }}
          lang={"en"}
        />,
      );
  };

  const handleDownload = () => {
    if (isLoading.download) return;
    setIsLoading((prev) => ({ ...prev, download: true }));
    var element = getData();

    var opt = {
      margin: 0,
      filename: getFileName(),
      image: { type: "png", quality: 1 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .save()
      .then(() => {
        setIsLoading((prev) => ({ ...prev, download: false }));
      });
  };

  const handlePrint = () => {
    if (isLoading.print) return;
    setIsLoading((prev) => ({ ...prev, print: true }));
    var element = getData();

    var opt = {
      margin: 0,
      filename: getFileName(),
      image: { type: "png", quality: 1 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .output("bloburl")
      .then((data) => {
        let a = document.createElement("a") as HTMLAnchorElement;
        document.body.appendChild(a);
        a.style.display = "none";
        a.href = data;
        a.target = "_blank";
        a.click();
        document.body.removeChild(a);
        setIsLoading((prev) => ({ ...prev, print: false }));
      });
  };

  const handleView = () => {
    if (isLoading.view) return;
    if (template === "TICKET") {
      CloseDrawer();
      sendQueryPayload({
        query: "BOARDING_PASS",
        data: { ...data, QR: qr },
        inputType: "",
      });
      // EventEmitter.emit("VIEW_PASS", data);
      // drawer close
    } else {
      setIsLoading((prev) => ({ ...prev, view: true }));
      var element = getData();

      var opt = {
        margin: 0,
        filename: getFileName(),
        image: { type: "png", quality: 1 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      };

      html2pdf()
        .set(opt)
        .from(element)
        .output("bloburl")
        .then((data) => {
          // window.open(data, "_blank");

          let a = document.createElement("a") as HTMLAnchorElement;
          document.body.appendChild(a);
          a.style.display = "none";
          a.href = data;
          a.target = "_blank";
          a.click();
          document.body.removeChild(a);

          setIsLoading((prev) => ({ ...prev, view: false }));
        });
    }
  };

  const handleShare = () => {
    if (isLoading.share) return;
    setIsLoading((prev) => ({ ...prev, share: true }));
    var element = getData();

    var opt = {
      margin: 0,
      filename: getFileName(),
      image: { type: "png", quality: 1 },
      html2canvas: { scale: 1 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .output("datauristring")
      .then(async (blobData) => {
        const filenameset = "E-Ticket.pdf";
        const response = await fetch(blobData);
        const blob = await response.blob();
        const file = new File([blob], filenameset, { type: blob.type });

        const shareData = {
          title: "Share Boarding Pass",
          text: "Hi, We loved helping you with your Train ticket booking, and wish you a safe and happy journey! We hope to keep making your trips amazing with Ask Disha! (powered by CoRover) Thank you 😊",
          files: [file],
        };
        window.navigator
          .share(shareData)
          .then(() => setIsLoading((prev) => ({ ...prev, share: true })))
          .catch((e) => console.log(e));
      });
  };

  return {
    isShareEnabled,
    handleDownload,
    handlePrint,
    handleView,
    handleShare,
    isLoading,
  };
};

export default usePDF;
