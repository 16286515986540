import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import BookingForm from "./BookingForm";
// import BookingsContainer from "./Bookings/Bookings";
import howWorkstext from "./About/translations";
import About from "./About/About";
import HowWorks from "./HowWorks/HowWorks";
import Menu from "./Menu";
import Advertise from "./Advertise/Advertise";
import Greeting from "./Greeting";
import translations from "./translations";
import Container from "./styles";
import UpcomingBookings from "./UpcomingBookings";
import useGetReduxState from "../../../../../../Hooks/useGetReduxState";
import { setJourney } from "../../../../../../Store/Dispatcher/journey";
import useSendQuery from "../../../../../../Hooks/useSendQuery/useSendQuery";
import Speaker from "../../../../../../UI/UIComponents/Speaker";
const bookings =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/ticket-3d.svg";
const looking =
  "https://cdn.jsdelivr.net/gh/corover/assets@latest/askdisha-bucket/looking.svg";

const HomeScreen = () => {
  const [howWorksExpanded, setHowWorksExpanded] = useState(false);
  const [AboutExpanded, setAboutExpanded] = useState(true);
  // const [Bookings, setBookings] = useState([]);
  const [bookingsExpanded, setBookingsExpanded] = useState(false);
  // const journey = useGetReduxState().journey;
  // const [loading, setLoading] = useState(false);
  // const api = new Api();
  const lang = useGetReduxState().behaviour.lang;
  let langText = translations[lang];
  const updateBookings = () => {
    // setLoading(true);
    // api.getBookings().then((data) => {
    //   setBookings(data.renderTemplate.data.upcomingjourney);
    //   setLoading(false);
    // });
    // setBookings([]);
    // setLoading(false);
  };

  useEffect(() => {
    if (bookingsExpanded) {
      updateBookings();
    }
  }, [bookingsExpanded]);
  const { sendQueryPayload } = useSendQuery(() => {});

  const findTrains = ({ source, destination, date, quota, isDirect }) => {
    setJourney({ source, destination, quota, date, boardingStation: "" });
    if (source && destination && date && quota) {
      sendQueryPayload({
        inputType: "",
        query: "SHOW_TRAINS",
        data: "SHOW_TRAINS",
        isDirect,
      });
    }
    // showTrains();
  };

  // const flavoursContainer = document.getElementById('flavoursContainer');
  // const flavoursScrollWidth = flavoursContainer.scrollWidth;

  // window.addEventListener('load', () => {
  //   window.self.setInterval(() => {
  //     if (flavoursContainer.scrollLeft !== flavoursScrollWidth) {
  //       flavoursContainer.scrollTo(flavoursContainer.scrollLeft + 1, 0);
  //     }
  //   }, 15);
  // });

  return (
    <Container>
      {/* <div className="banner"
      style={{
        overflowX:"scroll",
        whiteSpace:"nowrap",
        background:"linear-gradient(90deg, rgba(0,19,65,1) 0%, rgba(17,46,108,1) 32%, rgba(0,212,255,1) 100%)",
        borderStyle:"double",
        display:"flex",
        msTextOverflow:"none",
        scrollbarWidth:"none",
        animation: "20s linear infinite",
      }}
      id="flavoursContainer">
        <div style={{
          position:"absolute",
          backgroundColor:"rgba(0,0,0 , 0.0001)",
        }}></div>
        <p style={{
          padding: "8px",
          fontWeight:"700",
          color:"wheat",
          whiteSpace:"pre",
          display: "inline-block",
          float: "left",
          transition: "all .2s ease-out",
        }}>Due to maintenance activities, there may be a delay in refunds today. We appreciate your patience.   ||   रखरखाव के कारण, आज रिफंड में कुछ देरी हो सकती है। आपकी सहनशीलता के लिए आभारी हैं।   ||   રાખવાવ કાર્યોના કારણ, આજ રિફંડમાં વિલંબ થવાની સંભાવના છે. તમારી ધૈર્યને આદરપૂર્વક સ्वીકારીએ.</p>
      </div> */}
      <Greeting />

      <div
        style={{
          padding: "10px 15px",
          border: "1px solid gainsboro",
          borderRadius: "5px",
          margin: "0 10px",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          paddingTop: "0px",
        }}
      >
        <BookingForm forEdit={false} findTrains={findTrains} />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: "10px",
          marginTop: "18px",
          cursor: "pointer",
        }}
        tabIndex={0}
        aria-label="Upcoming Bookings Toggle Button"
        onClick={() => setBookingsExpanded((prev) => !prev)}
        onKeyDown={(e) => {
          if (e.key === "Enter") setBookingsExpanded((prev) => !prev);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              backgroundColor: "rgb(235, 238, 253)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <img
              src={bookings}
              style={{
                width: "94%",
              }}
              alt=""
            />
          </div>
          <p
            style={{
              margin: "0",
              marginLeft: "8px",
              fontSize: "14px",
              fontWeight: "600",
              color: "#080808",
            }}
          >
            {langText.upcomingBookings.header}
          </p>
        </div>

        <FontAwesomeIcon
          icon={bookingsExpanded ? faAngleUp : faAngleDown}
          style={{
            border: "1px solid grey",
            color: "grey",
            padding: "3px 5px",
            borderRadius: "50%",
            marginRight: "20px",
          }}
        />
      </div>

      {bookingsExpanded && <UpcomingBookings />}

      <div
        style={{
          width: "95%",
          borderTop: "1px solid gainsboro",
          marginTop: "8px",
          marginBottom: "5px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ></div>
      <div
        style={{ padding: "10px 10px" }}
        tabIndex={0}
        aria-label="Are you looking for something else?"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "28px",
              height: "28px",
              borderRadius: "50%",
              backgroundColor: "rgb(235, 238, 253)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <img
              src={looking}
              style={{
                width: "94%",
              }}
              // onClick={handleSwap}
              alt=""
            />
          </div>
          <p
            style={{
              margin: "0",
              color: "#080808",
              fontSize: "14px",
              fontWeight: "600",
              marginLeft: "8px",
            }}
          >
            {langText.else}
          </p>
        </div>

        <Menu tab={0} />
      </div>
      <div
        style={{
          width: "95%",
          borderTop: "1px solid gainsboro",
          marginTop: "8px",
          marginBottom: "0px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ></div>
      <div
        style={{ padding: "10px 10px" }}
        tabIndex={0}
        aria-label={langText.howWorks.heading}
        onKeyDown={(e) => {
          if (e.key === "Enter") setHowWorksExpanded((prev) => !prev);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setHowWorksExpanded((prev) => !prev)}
        >
          <div>
            <p
              style={{
                margin: "0",
                color: "#080808",
                fontSize: "14px",
                fontWeight: "600",
                marginLeft: "8px",
              }}
            >
              {langText.howWorks.heading}
            </p>
            <p
              style={{
                margin: "0",
                color: "gray",
                fontSize: "12px",
                fontWeight: "400",
                marginLeft: "8px",
                marginTop: "2px",
              }}
            >
              {langText.howWorks.sub}
            </p>
          </div>

          <FontAwesomeIcon
            icon={howWorksExpanded ? faAngleUp : faAngleDown}
            style={{
              border: "1px solid grey",
              color: "grey",
              padding: "3px 5px",
              borderRadius: "50%",
              marginRight: "10px",
            }}
          />
        </div>

        <HowWorks text={langText.howWorks} expanded={howWorksExpanded} />
      </div>
      <div
        style={{
          width: "95%",
          borderTop: "1px solid gainsboro",
          marginTop: "0px",
          marginBottom: "5px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ></div>

      <Advertise />
      <div
        style={{
          width: "95%",
          borderTop: "1px solid gainsboro",
          marginTop: "0px",
          marginBottom: "5px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ></div>
      <div
        style={{ padding: "10px", paddingBottom: "30px" }}
        tabIndex={0}
        aria-label={langText.howWorks.heading}
        onKeyDown={(e) => {
          if (e.key === "Enter") setAboutExpanded((prev) => !prev);
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => setAboutExpanded((prev) => !prev)}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                backgroundColor: "rgb(235, 238, 253)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <img
                src="https://www.svgrepo.com/show/452112/tag.svg"
                style={{
                  width: "94%",
                }}
                alt=""
              />
            </div>
            <p
              style={{
                margin: "0",
                marginLeft: "8px",
                fontSize: "14px",
                fontWeight: "600",
                color: "#080808",
              }}
            >
              {howWorkstext[lang].head}
            </p>
          </div>
          <FontAwesomeIcon
            icon={AboutExpanded ? faAngleUp : faAngleDown}
            style={{
              border: "1px solid grey",
              color: "grey",
              padding: "3px 5px",
              borderRadius: "50%",
              marginRight: "10px",
            }}
          />
        </div>
        {AboutExpanded && <About />}
      </div>
    </Container>
  );
};

export default HomeScreen;
